
import React, { useEffect, useState } from 'react';
import { useMsal } from '@azure/msal-react';
import global from '../../classes/global';
import {
  NavLink
} from "react-router-dom";
import clsx from 'clsx';
import { alpha, createStyles, makeStyles, useTheme, Theme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Grid from '@material-ui/core/Grid';
import Notification from '../Notification/Notification';
import logowo from '../../images/logowo.png';
import cwLogo from '../../images/cw_logo.png';
import ClearIcon from '@material-ui/icons/Clear';
import BuildIcon from '@material-ui/icons/Build';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import EmojiTransportationIcon from '@material-ui/icons/EmojiTransportation';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Home from '@material-ui/icons/Home';
import AvTimerIcon from '@material-ui/icons/AvTimer';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MoreIcon from '@material-ui/icons/MoreVert';
import GavelIcon from '@material-ui/icons/Gavel';
import DashboardIcon from '@material-ui/icons/Dashboard';
import EventNoteIcon from '@material-ui/icons/EventNote';
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { Tooltip } from 'devextreme-react/tooltip';
import HistoryOutlined from '@material-ui/icons/HistoryOutlined';
import Config from '../../classes/config';
import utility from '../../classes/utility';
//route
interface Props {
  role: string;
  hideDrawer: Boolean;
  hideDetail?: Boolean;
  userId?: string;
}
let currentDetail = localStorage.getItem("currentDetail");
// 
if (!currentDetail) {
  //window.open('/#/SignIn', '_self');
}
const drawerWidth = 240;
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    text: {
      padding: theme.spacing(2, 2, 0),
    },
    paper: {
      paddingBottom: 50,
    },
    list: {
      marginBottom: theme.spacing(2),
    },
    subheader: {
      backgroundColor: theme.palette.background.paper,
    },
    grow: {
      flexGrow: 1,
    },
    fabButton: {
      position: 'absolute',
      zIndex: 1,
      top: -30,
      left: 0,
      right: 0,
      margin: '0 auto',
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginRight: 36,
      display: 'block',
      // [theme.breakpoints.down('md')]: {
      //   display: 'none',
      // },
    },
    hide: {
      display: 'none',
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
      display: 'block',
      // [theme.breakpoints.down('md')]: {
      //   display: 'none',
      // },
    },
    drawerOpen: {
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      width: theme.spacing(7) + 1,
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9) + 1,
      },
    },
    toolbar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
    iconButton: {
      padding: 10,
      color: 'white',
    },
    divider: {
      height: 28,
      margin: 4,
    },
    title: {
      display: 'none',
      marginTop: theme.spacing(1),
      [theme.breakpoints.up('sm')]: {
        display: 'block',
        width: '100px',
      },
    },
    search: {
      position: 'relative',
      marginTop: theme.spacing(1),
      borderRadius: theme.shape.borderRadius,
      backgroundColor: alpha(theme.palette.common.white, 0.15),
      '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
      },
      marginRight: theme.spacing(2),
      marginLeft: 0,
      width: '100%',
      // [theme.breakpoints.down('md')]: {
      //   width: '50%',
      // },
      [theme.breakpoints.up('sm')]: {
        // marginLeft: theme.spacing(25),
        // marginRight: theme.spacing(25),
        width: '80%',
        float: 'right'
      },
      [theme.breakpoints.up('xs')]: {
        width: '80%',
      },
      // display: 'flex',
    },
    searchIcon: {
      padding: theme.spacing(0, 0),
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      // display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: '#ffffff',
    },
    inputRoot: {
      color: '#ffffff',
      width: 'inherit',
      backgroundColor: '#ffffff'
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      color: '#ffffff',
      caretColor: '#ffffff',
      backgroundColor: '#ffffff',
      // vertical padding + font size from searchIcon
      // paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      paddingLeft: theme.spacing(2),
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '20ch',
      },
      [theme.breakpoints.up('sm')]: {
        width: '100%',
      },
    },
    sectionDesktop: {
      display: 'none',

      float: 'right',
      [theme.breakpoints.up('md')]: {
        display: 'flex',
      },
    },
    sectionMobile: {
      display: 'flex',
      float: 'right',
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
    bottomNavegation: {
      display: 'none',
      [theme.breakpoints.down('md')]: {
        display: 'block',
      },
    },
    mgright:
    {
      marginRight: '-10px !important'
    },
    headerTitle: {
      fontSize: '20px',
      color: '#f2f2f2',
      margin: 'auto',
      fontWeight: 500,
      display: 'flex',
      [theme.breakpoints.down('md')]: {
        fontSize: '16px',
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: '20px',
      },
      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
    },
    headerWorxTitle: {
      fontSize: '22px',
      color: '#85C445',
      paddingLeft: '5px',
    }
  }),
);

const Navigation = (props: Props) => {

  useEffect(() => {

    let searchTerm = sessionStorage.getItem('searchTerm');
    if (searchTerm) {
      searchChange(searchTerm);
    }
  }, []);

  const { instance } = useMsal();
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  //const [searchShow, setsearchShow] = React.useState(false);
  const role = props.role;
  const [search, searchChange] = React.useState('');
  //const [value, setValue] = React.useState('recents');
  // const _handleKeyDown = (e: any) => {
  //   if (e.key === 'Enter') {
  //     sessionStorage.setItem('searchTerm', search);
  //     window.open(`${window.location.origin}/#/SearchPage?search=${search}`, "_self");
  //     setsearchShow(false);
  //   }
  // }
  // const _searchChange = (e: any) => {
  //   searchChange(e.target.value);
  //   setsearchShow(true)
  // }
  // const _clearSearch = (e: any) => {
  //   searchChange('');
  //   sessionStorage.setItem('searchTerm', '');
  // }
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  // const handleChange = (event: React.ChangeEvent<{}>, newValue: string) => {
  //   setValue(newValue);
  // };
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState<null | HTMLElement>(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  // const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
  //   setAnchorEl(event.currentTarget);
  // };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const logOut = () => {
    const userEmail = localStorage.getItem("currentDetail");

    const logoutTime = new Date().toISOString();
    let dataToPassToService: any = {};
    dataToPassToService.loggedOutTime = logoutTime;
    if (userEmail) {
      dataToPassToService.createdBy = userEmail;
    }
    dataToPassToService.userId = props.userId ? props.userId : '';
    dataToPassToService.loggedInFrom = Config.portal;

    utility.genericPostAPIcall(Config.userlogins, dataToPassToService)
      .then((response: any) => {
        console.log('API call response', response);
      })
      .catch((error: any) => {
        console.log('API call error', error);
      })
      .finally(() => {
        instance.logoutRedirect({ postLogoutRedirectUri: "/" });
      });
  }

  const [visibleTooltip, showTooltip] = useState('')
  const toggleWithAnimation = (toolTipName: any) => {
    showTooltip(open ? '' : toolTipName);
  }

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <NavLink exact activeClassName="is-active" to="/Profile"><MenuItem onClick={handleMenuClose}>Profile</MenuItem></NavLink>
      {/* <NavLink exact activeClassName="is-active" to="/SignUp"><MenuItem>Sign Up</MenuItem></NavLink> */}
      <MenuItem onClick={logOut}>Logout</MenuItem>
      {/* {props.role === global.worxOnline_Admin && <NavLink exact activeClassName="is-active" to="/AdminHomePage"><MenuItem>Admin</MenuItem></NavLink>} */}
      {/* <MenuItem onClick={handleMenuClose}>Sign In</MenuItem> */}
    </Menu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >

      <MenuItem>
        <Notification isMobile={true} />
        <p>Notifications</p>
      </MenuItem>
      <NavLink exact activeClassName="is-active" to="/Profile">
        <MenuItem>
          <IconButton
            aria-label="account of current user"
            aria-controls="primary-search-account-menu"
            aria-haspopup="true"
            color="inherit"
          >
            <AccountCircle />
          </IconButton>
          <p>Profile</p>
        </MenuItem>
      </NavLink>
      <MenuItem onClick={logOut}>
        <IconButton
          aria-label="logout"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <ExitToAppIcon />
        </IconButton>
        <p>Logout</p>
      </MenuItem>
    </Menu>
  );
  const listOfNonReader = (
    <>
      <NavLink exact activeClassName="is-active" className="sideMenu" to="/Scheduler">
        <ListItem button >
          <ListItemIcon onMouseEnter={() => toggleWithAnimation('Scheduler')} onMouseLeave={() => toggleWithAnimation('')}><EventNoteIcon id="Scheduler" className={"NavIcon"} /></ListItemIcon>
          <ListItemText primary={"Scheduler"} className={'ml-2'} />
        </ListItem>

        <Tooltip
          target="#Scheduler"
          position="right"
          visible={visibleTooltip === 'Scheduler'}
          hideOnOutsideClick={false}
        >
          <div className='drawerTooltip'>Scheduler</div>
        </Tooltip>
      </NavLink>
      <NavLink exact activeClassName="is-active" className="sideMenu" to="/SearchTimesheet">
        <ListItem button >
          <ListItemIcon onMouseEnter={() => toggleWithAnimation('Timesheet')} onMouseLeave={() => toggleWithAnimation('')}><AvTimerIcon id="Timesheet" className={"NavIcon"} /></ListItemIcon>
          <ListItemText primary={"Timesheet"} className={'ml-2'} />
        </ListItem>
        <Tooltip
          target="#Timesheet"
          position="right"
          visible={visibleTooltip === 'Timesheet'}
          hideOnOutsideClick={false}
        >
          <div className='drawerTooltip'>Timesheet</div>
        </Tooltip>
      </NavLink>
      <NavLink exact activeClassName="is-active" className="sideMenu" to="/SearchEquipment">
        <ListItem button >
          <ListItemIcon onMouseEnter={() => toggleWithAnimation('SearchEquipment')} onMouseLeave={() => toggleWithAnimation('')}><HistoryOutlined id="SearchEquipment" className={"NavIcon"} /></ListItemIcon>
          <ListItemText primary={"Plant and Fleet Usage"} className={'ml-2'} />
        </ListItem>
        <Tooltip
          target="#SearchEquipment"
          position="right"
          visible={visibleTooltip === 'SearchEquipment'}
          hideOnOutsideClick={false}
        >
          <div className='drawerTooltip'>Plant and Fleet Usage</div>
        </Tooltip>
      </NavLink>

      <NavLink exact activeClassName="is-active" className="sideMenu" to="/EquipmentsInspection">
        <ListItem button >
          <ListItemIcon onMouseEnter={() => toggleWithAnimation('Equipments')} onMouseLeave={() => toggleWithAnimation('')}><BuildIcon id="Equipments" className={"NavIcon"} /></ListItemIcon>
          <ListItemText primary={"Plant and Fleet"} className={'ml-2'} />
        </ListItem>
        <Tooltip
          target="#Equipment"
          position="right"
          visible={visibleTooltip === 'Plant and Fleet'}
          hideOnOutsideClick={false}
        >
          <div className='drawerTooltip'>Plant and Fleet</div>
        </Tooltip>
      </NavLink>

      <NavLink exact activeClassName="is-active" className="sideMenu" to="/PrestartChecklist">
        <ListItem button >
          <ListItemIcon onMouseEnter={() => toggleWithAnimation('PrestartChecklist')} onMouseLeave={() => toggleWithAnimation('')}><PlaylistAddCheckIcon id="PrestartChecklist" className={"NavIcon"} /></ListItemIcon>
          <ListItemText primary={"Presstart Checklist"} className={'ml-2'} />
        </ListItem>
        <Tooltip
          target="#PrestartChecklist"
          position="right"
          visible={visibleTooltip === 'PrestartChecklist'}
          hideOnOutsideClick={false}
        >
          <div className='drawerTooltip'>Prestart Checklist</div>
        </Tooltip>
      </NavLink>
      <NavLink exact activeClassName="is-active" className="sideMenu" to="/InspectionForm">
        <ListItem button >
          <ListItemIcon onMouseEnter={() => toggleWithAnimation('InspectionForm')} onMouseLeave={() => toggleWithAnimation('')}><LibraryBooksIcon id="InspectionForm" className={"NavIcon"} /></ListItemIcon>
          <ListItemText primary={"Inspection Form"} className={'ml-2'} />
        </ListItem>
        <Tooltip
          target="#InspectionForm"
          position="right"
          visible={visibleTooltip === 'InspectionForm'}
          hideOnOutsideClick={false}
        >
          <div className='drawerTooltip'>Inspection Form</div>
        </Tooltip>
      </NavLink>


      <NavLink exact activeClassName="is-active" className="sideMenu" to="/ClearStorage">
        <ListItem button >
          <ListItemIcon onMouseEnter={() => toggleWithAnimation('ClearStorage')} onMouseLeave={() => toggleWithAnimation('')}><ClearIcon id="ClearStorage" className={"NavIcon"} /></ListItemIcon>
          <ListItemText primary={"Clear Storage"} className={'ml-2'} />
        </ListItem>
        <Tooltip
          target="#ClearStorage"
          position="right"
          visible={visibleTooltip === 'ClearStorage'}
          hideOnOutsideClick={false}
        >
          <div className='drawerTooltip'>Clear Storage</div>
        </Tooltip>
      </NavLink>



    </>
  );
  const listOfAdmin = (

    <NavLink exact activeClassName="is-active" className="sideMenu" to="/AdminHomePage">
      <ListItem button >
        <ListItemIcon onMouseEnter={() => toggleWithAnimation('AdminHomePage')} onMouseLeave={() => toggleWithAnimation('')}> <SettingsApplicationsIcon id="AdminHomePage" className={"NavIcon"} /></ListItemIcon>
        <ListItemText primary={"Administration"} className={'ml-2'} />
      </ListItem>
      <Tooltip
        target="#AdminHomePage"
        position="right"
        visible={visibleTooltip === 'AdminHomePage'}
        hideOnOutsideClick={false}
      >
        <div className='drawerTooltip'>Administration</div>
      </Tooltip>
    </NavLink>

  )
  const renderDrawerList = (
    <>
      <div className={classes.toolbar}>
        <img src={logowo} className="logowo" alt="pic" />
        <div className={classes.headerWorxTitle}><strong>worx</strong>Online</div>
        <IconButton onClick={handleDrawerClose}>
          {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
        </IconButton>
      </div>
      <Divider />
      <List>
        <NavLink exact activeClassName="is-active" className="sideMenu" to="/">
          <ListItem button >
            <ListItemIcon

              onMouseEnter={() => toggleWithAnimation('Home')}
              onMouseLeave={() => toggleWithAnimation('')}
            > <Home id="Home" className={"NavIcon"} />
            </ListItemIcon>
            <ListItemText primary={"Home"} className={'ml-2'} />
          </ListItem>
          <Tooltip
            target="#Home"
            position="right"
            visible={visibleTooltip === 'Home'}
            hideOnOutsideClick={false}
          >
            <div className='drawerTooltip'>Home</div>
          </Tooltip>
        </NavLink>
        <NavLink exact activeClassName="is-active" className="sideMenu" to="/Searchworkorder">
          <ListItem button >
            <ListItemIcon onMouseEnter={() => toggleWithAnimation('Searchworkorder')} onMouseLeave={() => toggleWithAnimation('')}>
              <GavelIcon id="Searchworkorder" className={"NavIcon"} /></ListItemIcon>
            <ListItemText primary={"Work Orders"} className={'ml-2'} />
          </ListItem>
          <Tooltip
            target="#Searchworkorder"
            position="right"
            // visible={true}
            visible={visibleTooltip === 'Searchworkorder'}
            hideOnOutsideClick={false}
          >
            <div className='drawerTooltip'>Work Orders</div>
          </Tooltip>
        </NavLink>
        {/* <NavLink exact activeClassName="is-active" className="sideMenu" to="/SearchScheduleWork">
                  <ListItem button >
                    <ListItemIcon onMouseEnter={()=>toggleWithAnimation('Searchworkorder')} onMouseLeave={()=>toggleWithAnimation('')}><ScheduleIcon className={"NavIcon"} /></ListItemIcon>
                    <ListItemText primary={"Scheduled Work"} className={'ml-2'} />
                  </ListItem>
                </NavLink> */}

        <NavLink exact activeClassName="is-active" className="sideMenu" to="/Dashboard">
          <ListItem button >
            <ListItemIcon onMouseEnter={() => toggleWithAnimation('Dashboard')} onMouseLeave={() => toggleWithAnimation('')}><DashboardIcon id="Dashboard" className={"NavIcon"} /></ListItemIcon>
            <ListItemText primary={"Dashboard"} className={'ml-2'} />
          </ListItem>
          <Tooltip
            target="#Dashboard"
            position="right"
            visible={visibleTooltip === 'Dashboard'}
            hideOnOutsideClick={false}
          >
            <div className='drawerTooltip'>Dashboard</div>
          </Tooltip>
        </NavLink>
        <NavLink exact activeClassName="is-active" className="sideMenu" to="/AssetSearch">
          <ListItem button >
            <ListItemIcon onMouseEnter={() => toggleWithAnimation('AssetSearch')} onMouseLeave={() => toggleWithAnimation('')}><EmojiTransportationIcon id="AssetSearch" className={"NavIcon"} /></ListItemIcon>
            <ListItemText primary={"Assets"} className={'ml-2'} />
          </ListItem>
          <Tooltip
            target="#AssetSearch"
            position="right"
            visible={visibleTooltip === 'AssetSearch'}
            hideOnOutsideClick={false}
          >
            <div className='drawerTooltip'>Assets</div>
          </Tooltip>
        </NavLink>
        {(role !== global.worxOnline_Reader) && (listOfNonReader)}
        {(role === global.worxOnline_Admin || role === global.worxOnline_Contractor || role === global.worxOnline_ContractorAdmin) && (listOfAdmin)}
      </List>
    </>
  )
  let isNarrow = false;
  if (window.innerWidth < 730) {
    isNarrow = true;
  }
  return (
    <div className={classes.root}>
      <AppBar position="fixed"
        className={(clsx('appBar', classes.appBar, 'theme', {
          [classes.appBarShift]: open,
        }))}
      >
        <Toolbar>
          <Grid container>
            <Grid item xs={5} sm={6} md={6}>
              <div className={'logoDiv'}>
                {!props.hideDrawer && (
                  <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={handleDrawerOpen}
                    edge="start"
                    className={clsx(classes.menuButton, {
                      [classes.hide]: open,
                    })}
                  >
                    <MenuIcon className={'navIcon'} />
                  </IconButton>
                )}
                {/* <NavLink to={'/'}> <img src={logowo} className="logowo" alt="pic" /></NavLink> */}
                <img src={cwLogo} className="" alt="pic" />

                <div className={classes.headerTitle + ' ' + classes.mgright}>worxOnline</div>
              </div>
            </Grid>
            {/* <Grid item xs={6} sm={5} md={4}>
              {!props.hideDetail && (
                <div className={classes.search}>

                  <InputBase
                    placeholder="Search…"
                    classes={{
                      root: classes.inputRoot,
                      input: classes.inputInput,
                    }}
                    inputProps={{ 'aria-label': 'search' }}
                    onChange={(e: any) => _searchChange(e)}
                    value={search}
                    onKeyDown={_handleKeyDown}
                    onFocus={() => setsearchShow(true)}
                  />
                  {search ?
                    <ClearIcon className="searchBarIcon fontInverse" onClick={_clearSearch} /> :
                    <SearchIcon className="searchBarIcon fontInverse" />}
                  {(searchShow && search) && (
                    <div className={'searchResultDiv'}>
                      <SearchDrop
                        showDrop={true}
                        searchProps={search}
                        onOutterClick={(e: any) => setsearchShow(e)}
                      />
                    </div>
                  )}
                </div>
              )}
            </Grid> */}

            {/* <div className={classes.grow} /> */}
            <Grid item xs={6} sm={6} md={6}>
              {!props.hideDetail ?
                <>
                  <div className={classes.sectionDesktop}>
                    <Notification isMobile={false} />
                    <NavLink exact to="/Profile">
                      <IconButton
                        edge="start"
                        aria-label="Go to profile"
                        // aria-controls={menuId}
                        // aria-haspopup="true"
                        // onClick={handleProfileMenuOpen}
                        color="inherit"
                      >
                        <AccountCircle className={'navIcon'} />
                      </IconButton >
                    </NavLink>
                    <IconButton
                      edge="start"
                      aria-label="Log out"
                      // aria-controls={menuId}
                      aria-haspopup="true"
                      onClick={logOut}
                      color="inherit"
                    >
                      <ExitToAppIcon className={'navIcon'} />
                    </IconButton >

                  </div>
                  <div className={classes.sectionMobile}>
                    <IconButton
                      aria-label="show more"
                      aria-controls={mobileMenuId}
                      aria-haspopup="true"
                      onClick={handleMobileMenuOpen}
                      color="inherit"
                    >
                      <MoreIcon className={'navIcon'} />
                    </IconButton>
                  </div>
                </> :
                <IconButton
                  edge="end"
                  aria-label="Log out"
                  // aria-controls={menuId}
                  aria-haspopup="true"
                  onClick={logOut}
                  color="inherit"
                >
                  <ExitToAppIcon className={'navIcon'} />
                </IconButton >
              }
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
      {/* </div> */}
      {/* */}
      {!props.hideDrawer && (
        <>
          {isNarrow ?
            <Drawer
              variant="temporary"
              anchor='left'
              open={open}
              onClose={handleDrawerClose}
            >
              {renderDrawerList}
            </Drawer>
            : <Drawer

              variant="permanent"
              className={clsx(classes.drawer, {
                [classes.drawerOpen]: open,
                [classes.drawerClose]: !open,
              })}
              classes={{
                paper: clsx({
                  [classes.drawerOpen]: open,
                  [classes.drawerClose]: !open,
                }),
              }}
            >
              {renderDrawerList}
            </Drawer>
          }
        </>
      )}
    </div>
  );


}
export default Navigation;