import * as React from 'react';
import utility from '../../../classes/utility';
import { Config } from '../../../classes/config';
import global from '../../../classes/global';
import DataTable from '../../common/DataTable/DataTable';
import AlertDialog from '../../common/alertDialog';
import DeleteDialog from '../../common/DeleteAlert';
import Spinner from '../../common/reusableComponents/Spinner';
import AddEditContractor from './AddEditContractor';

let _items: any = [];

export default class Contractors extends React.Component<any, any> {

    public teamsItems = [];
    public currentUser;
    public columns: any;
    public myRef: any;
    constructor(props: any) {
        super(props);
        this.DeleteItem = this.DeleteItem.bind(this);
        this.EditItemModal = this.EditItemModal.bind(this);
        this.DeleteDialog = this.DeleteDialog.bind(this);
        this.myRef = React.createRef();

        this.currentUser = {
            DisplayName: this.props.currentUser.DisplayName ? this.props.currentUser.DisplayName : this.props.currentUser.displayName,
            Email: this.props.currentUser.Email ? this.props.currentUser.Email : this.props.currentUser.email,
            LoginName: this.props.currentUser.LoginName ? this.props.currentUser.LoginName : this.props.currentUser.loginName
        };
        if (this.props.role !== global.worxOnline_Admin) {
            window.open('#/AccessDenied', '_self');
        };

        this.columns = [
            {
                field: 'id',
                title: 'ID',
                width: 80,
                type: 'string',
                allowHiding: false,
            },
            {
                field: 'name',
                title: 'Name',
                // width: 160,
                type: 'string',
                allowHiding: false,
            },
            {
                field: 'businessName',
                title: 'Business Name',
                // width: 160,
                type: 'string',
                allowHiding: false,
            },
            {
                field: 'phone',
                title: 'Phone',
                width: 160,
                type: 'string',
                allowHiding: true
            },
            {
                field: 'email',
                title: 'Email',
                // width: 160,
                type: 'string',
                allowHiding: true,
            },
            {
                field: 'teamNames',
                title: 'Team',
                // width: 160,
                type: 'string',
                allowHiding: true,
            },
            {
                field: 'workTypes',
                title: 'Activity',
                // width: 160,
                type: 'string',
                allowHiding: true,
            },
            {
                field: 'councilContactUserFullName',
                title: 'Council Contact User',
                // width: 160,
                type: 'string',
                allowHiding: true,
            },
            {
                field: 'councilContactEmail',
                title: 'Council Contact Email',
                width: 120,
                type: 'string',
                allowHiding: true
            },
            {
                field: 'councilContactPhone',
                title: 'Council Contact Phone',
                width: 160,
                type: 'string',
                allowHiding: true
            },
            {
                field: 'isActive',
                title: 'Active',
                width: 160,
                type: 'boolean',
                allowHiding: true
            },
            {
                field: 'updatedBy',
                title: 'Updated By',
                width: 160,
                type: 'string',
                visible: false,
                allowHiding: true
            },
            {
                field: 'createdOn',
                title: 'Created On',
                width: 160,
                type: 'datetime',
                visible: false,
                allowHiding: true
            },
            {
                field: 'createdBy',
                title: 'Created By',
                width: 160,
                type: 'string',
                visible: false,
                allowHiding: true
            },
        ];
        this.state = {
            items: _items,
            columns: this.columns
        };
    }

    public componentDidMount() {
        this._getItemData();

    }


    public DeleteItem(item: any) {
        this.setState({
            alertMessage: <div className={'alertLoader'}><Spinner size='large' label="Loading..." /></div>,
            hideAlertButton: true,
            showAlert: true,
            showDeleteAlert: false,
        });
        utility.genericDeleteAPICall(Config.Contractor, `${item},${this.currentUser.Email}`).then((data: any) => {
            this.setState({
                alertMessage: 'Contractor has been deleted.',
                showAlert: true,
                showDeleteAlert: false,
                hideAlertButton: false,
                loading: false
            });
            this._getItemData()
        }, (err) => {
            // console.log(err);
            this.setState({
                alertMessage: 'Cannot delete contractor. Please try again later.',
                showAlert: true,
                showDeleteAlert: false,
                hideAlertButton: false,
            });
        });

    }

    // edit activty modal

    private EditItemModal(item: any) {
        this.setState({ showModal: true, details: item, mode: 'edit' });
    }

    public renderNewModal() {
        return (
            <AddEditContractor
                showModal={this.state.showModal}
                onClose={() => this._closeModal()}
                onClick={() => this._getItemData()}
                message={(e: any) => this.message(e)}
                currentUser={this.currentUser}
                mode={this.state.mode}
                details={this.state.details}
            />
        );
    }
    private _showModal = (): void => {
        this.setState({ showModal: true, details: null, mode: 'add' });
    }
    private _closeModal = (): void => {
        this.setState({ showModal: false });
    }
    public message(message: any) {
        this.setState({
            alertMessage: message + ' successfully',
            showAlert: true
        });
    }
    private _getItemData() {
        this.setState({
            loading: true
        });
        utility.genericGetAPICall(Config.GetContractorsWithDetails).then((data: any) => {
            _items = [];
            if (data.length === null) {
                if (_items.length <= 0) {
                    this.setState({
                        alertMessage: 'No results found for this criteria',
                        showAlert: true
                    });
                }
                this.setState({ items: _items, loading: false });
                return;
            }
            else {
                _items = data.filter((f: any) => !f.IsDeleted).map((r: any) => {
                    let workTypeNames: String = '';
                    let TeamNames: String = '';
                    if (r.workorderTypes && r.workorderTypes.length > 0) {
                        r.workorderTypes.forEach((WorkTypeName: any) => {
                            workTypeNames += WorkTypeName.title + ',';
                        });
                        workTypeNames = workTypeNames.substring(0, workTypeNames.length - 1);
                    }
                    if (r.teams && r.teams.length > 0) {
                        r.teams.forEach((TeamName: any) => {
                            TeamNames += TeamName.title + ',';
                        });
                        TeamNames = TeamNames.substring(0, TeamNames.length - 1);
                    }

                    return {
                        id: r.id,
                        name: r.name,
                        email: r.email,
                        teamID: r.teamIds,
                        workTypes: workTypeNames,
                        isActive: r.isActive ? true : false,
                        isDeleted: r.isDeleted ? true : false,
                        workorderTypeIds: r.workorderTypeIds,
                        teamNames: TeamNames,
                        councilContactEmail: r.councilContactEmail || '',
                        councilContactPhone: r.councilContactPhone || '',
                        businessName: r.businessName || '',
                        phone: r.phone || '',
                        councilContactUserFullName: r.councilContactUserFullName || '',
                        councilContactUserId: r.councilContactUserId ? r.councilContactUserId : '',
                        pin: r.pin,
                        updatedBy: r.updatedBy,
                        updatedOn: r.updated ? r.updated : '',
                        createdBy: r.createdBy,
                        createdOn: r.created ? r.created : '',
                    }
                });
            }
            _items = utility._sortItems(_items, 'name', false);
            this.setState({ items: _items, callAfterInit: true, loading: false, preItems: _items });
        }, (err) => {
            console.log(err);
            this.setState({ loading: false });
        });
    }
    public setSelection = (id: any) => {
        this.setState({
            selectedRow: id
        });
    }
    private stopCommsAfterInit = (): void => {
        this.setState({ callAfterInit: false });
    }
    public render(): React.ReactElement<any> {
        const filterValue = [['isActive', '=', true]];
        return (
            <React.Fragment>
                {this.renderNewModal()}
                {this.state.showDeleteAlert && this.DeleteAlert()}
                {this.state.showAlert && this.alert()}
                <div className={"SearchDiv"}>
                    <div className={'headingDiv'}>
                        <span className={"heading"}> Contractors </span>
                    </div>
                </div>
                <div>
                    {!this.state.loading ?
                        <div ref={this.myRef}>
                            <DataTable
                                style={{ width: '100%' }}
                                filteralbe={true}
                                groupable={true}
                                rows={this.state.items}
                                columns={this.state.columns}
                                fileName={'Contractors'}
                                columnChooser={'contractorsColumns'}
                                selectionMode={false}
                                showHideColumnMenu={true}
                                callAfterInit={this.state.callAfterInit}
                                stopCommsAfterInit={() => this.stopCommsAfterInit()}
                                loading={this.state.loading}
                                allowExportSelectedData={false}
                                showNewModal={() => this._showModal()}
                                deleteItem={(a: any) => this.DeleteDialog(a)}
                                editItem={(a: any) => this.EditItemModal(a)}
                                // viewFile={(e)=>this.viewFile(e)}
                                showCommandBtn={true}
                                showNewBtn={true}
                                hideNewBtn={this.state.showModal || this.state.showEditModal || this.props.hideNewBtn}
                                showViewDetailBtn={false}
                                defaultfiltervalue={filterValue}
                            />
                        </div>
                        :
                        <Spinner size='large' type={'converging-spinner'} className="loader" />
                    }

                </div>
            </React.Fragment>
        );
    }
    public alert() {
        return (
            <AlertDialog
                showDialog={this.state.showAlert}
                message={this.state.alertMessage}
                title={Config.productTitle}
                hideAlertButton={this.state.hideAlertButton}
                onClose={() => this.setState({ alertMessage: '', showAlert: false })}
            />
        );
    }
    public DeleteDialog(item: any) {
        // //console.log({ item });
        this.setState({
            DeletealertMessage: 'Are you sure you want to delete?',
            showDeleteAlert: true,
            deleteItem: item
        });
    }
    public DeleteAlert() {
        return (
            <DeleteDialog
                showDialog={this.state.showDeleteAlert}
                message={this.state.DeletealertMessage}
                title={"worxOnline"}
                onClose={() => this.setState({ DeletealertMessage: '', showDeleteAlert: false })}
                onOkButton={() => this.DeleteItem(this.state.deleteItem)}
                onCancelButton={() => this.setState({ DeletealertMessage: '', showDeleteAlert: false })}
            />
        );
    }

}

