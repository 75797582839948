import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import moment from 'moment';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
export default class DetailsModal extends React.Component<any, any> {

    public state = {
        details: this.props.details,
    };

    public componentDidMount() {
        ////console.log('component load');

    }

    public afterInit() {
        ////console.log('init');

    }


    public render(): React.ReactElement<any> {

        return (
            <div>

                <Dialog
                    open={this.props.showModal}
                    onClose={() => this.props.onClick()}
                    // className={'addEditModal'}
                    fullWidth={true}
                    maxWidth={'md'}
                    aria-labelledby="max-width-dialog-title"
                >
                    <DialogTitle id="scroll-dialog-title">Work Order Details</DialogTitle>
                    <DialogContent dividers={true}>
                        <div>
                            {
                                (!this.props.details) && (
                                    <div>
                                        <h4 className="alignCenter">No data availabe.</h4>
                                        <br />
                                    </div>
                                )
                            }

                            {(this.props.details) && (
                                <div>
                                    <Grid container spacing={3}>
                                        <Grid item xs={6} sm={3}>Work Order Number</Grid>
                                        <Grid item xs={6} sm={9} className=" ms-fontWeight-semibold">{this.props.details.Workordernumber}</Grid>
                                    </Grid>
                                    <Grid container spacing={3}>
                                        <Grid item xs={6} sm={3}>Title</Grid>
                                        <Grid item xs={6} sm={9} className=" ms-fontWeight-semibold">{this.props.details.text}</Grid>
                                    </Grid>
                                    <Grid container spacing={3}>
                                        <Grid item xs={6} sm={3}>Street Address</Grid>
                                        <Grid item xs={6} sm={9} className=" ms-fontWeight-semibold">{this.props.details.location}</Grid>
                                    </Grid>
                                    <Grid container spacing={3}>
                                        <Grid item xs={6} sm={3}>Suburb</Grid>
                                        <Grid item xs={6} sm={9} className=" ms-fontWeight-semibold">{this.props.details.suburb}</Grid>
                                    </Grid>
                                    <Grid container spacing={3}>
                                        <Grid item xs={6} sm={3}>Priority</Grid>
                                        <Grid item xs={6} sm={9} className=" ms-fontWeight-semibold">{this.props.details.priority}</Grid>
                                    </Grid>
                                    <Grid container spacing={3}>
                                        <Grid item xs={6} sm={3}>Status</Grid>
                                        <Grid item xs={6} sm={9} className=" ms-fontWeight-semibold">{this.props.details.wostatus}</Grid>
                                    </Grid>
                                    {this.props.details.crewtitle && (
                                        <Grid container spacing={3}>
                                            <Grid item xs={6} sm={3}>Crew</Grid>
                                            <Grid item xs={6} sm={9} className=" ms-fontWeight-semibold">{this.props.details.crewtitle}</Grid>
                                        </Grid>
                                    )}
                                    {this.props.details.startDate && (
                                        <Grid container spacing={3}>
                                            <Grid item xs={6} sm={3}>Start Date</Grid>
                                            <Grid item xs={6} sm={9} className=" ms-fontWeight-semibold">{this.props.details.startDate ? moment(this.props.details.startDate).format("DD/MM/YY h:mm a") : ''}</Grid>
                                        </Grid>
                                    )}
                                    {this.props.details.endDate && (
                                        <Grid container spacing={3}>
                                            <Grid item xs={6} sm={3}>End Date</Grid>
                                            <Grid item xs={6} sm={9} className=" ms-fontWeight-semibold">{this.props.details.endDate ? moment(this.props.details.endDate).format("DD/MM/YY h:mm a") : ''}</Grid>
                                        </Grid>
                                    )}
                                </div>
                            )}
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.props.onClick()} variant='outlined' color="primary" className={'button'}>
                            Close
                        </Button>

                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

