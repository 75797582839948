// export {}
import * as React from 'react';
import * as PropTypes from 'prop-types';
import utility from '../../classes/utility';
import { Config } from '../../classes/config';
import moment from 'moment';
import AlertDialog from '../common/alertDialog';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import BarChartIcon from '@material-ui/icons/BarChart';
import MapIcon from '@material-ui/icons/Map';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import MyMapComponent from '../common/MapComponent/GoogleMapComponent';
import Autocomplete from "react-google-autocomplete";
import global from '../../classes/global';
import Spinner from '../common/reusableComponents/Spinner';
import DateBoxInput from '../common/reusableComponents/DateBox';
import SelectBoxInput from '../common/reusableComponents/SelectBox';
import NumberBoxInput from '../common/reusableComponents/NumberBox';
import { TagBox } from 'devextreme-react/tag-box';
import {
    CircularGauge, Scale, Label, RangeContainer, Range, Title, Font, Export
} from 'devextreme-react/circular-gauge';
import {
    Chart,
    CommonSeriesSettings,
    Series,
    ArgumentAxis,
    Legend,
    Point,
    SeriesTemplate,
} from 'devextreme-react/chart';
import PieChart, {
    Tooltip,
    Connector,
} from 'devextreme-react/pie-chart';
import Geocode from "react-geocode";
import DataSource from 'devextreme/data/data_source';

let markers: any = [];
let WOMarker: any = [];
let area: any = '';
const options = {
    fields: ["address_components", "geometry", "types", "name", "formatted_address"],
    strictBounds: true,
    bounds: {
        "east": 138.629951, "north": -34.894027, "south": -34.950187, "west": 138.569527
    },
    componentRestrictions: {
        country: 'au'
    },
    types: ['point_of_interest', 'street_address', 'street_number', 'locality', 'landmark'],
};
export default class Dashboard extends React.Component<any, any> {

    public selectedDeptKey: any;
    public selectedDeptText: any;
    public deptComboBoxOoptions: any = [];
    public setupDataPromise: any = [];
    public worktypeItems: any = [];
    public priorityItems: any = [];
    public StatusItems: any = [];
    public currentUser: any;
    public StatusdataSource: any = [];
    public prioritydataSource: any = [];
    public worktypedataSource: any = [];

    constructor(props: any) {
        super(props);
        this._getWOData = this._getWOData.bind(this);
        this.clearFilter = this.clearFilter.bind(this);
        this._getWODATAforDashboard = this._getWODATAforDashboard.bind(this);
        this.setDistanceInMetersValue = this.setDistanceInMetersValue.bind(this);
        this.pointClickHandler = this.pointClickHandler.bind(this);
        this.legendClickHandler = this.legendClickHandler.bind(this);

        //let currentDetail = localStorage.getItem("currentDetail");

        if (!this.props.Department) {
            window.open('#/AccessRequest', '_self');
        };
        this.currentUser = this.props.currentUser;
        this.deptComboBoxOoptions = [];
        this.StatusItems = [];
        this.worktypeItems = [];
        this.priorityItems = [];
        this.StatusdataSource = [];
        this.prioritydataSource = [];
        this.worktypedataSource = [];
        let date = new Date();
        date.setDate(date.getDate() - 7);
        let dashboardDateFrom = new Date();
        dashboardDateFrom.setDate(dashboardDateFrom.getDate() - 30);
        this.state = {
            showingInfoWindow: false,
            dashboardDateFrom: dashboardDateFrom,
            dashboardDateTo: new Date(),
            isInfowWindowOpen: false,
            searchShow: false,
            zoom: 9,
            useStyle: localStorage.getItem('theme') === 'theme-dark' ? true : false,
            suburbNotFound: false,
            selected: 0,
            showWOFilter: false,
            showRightArrow: true,
            loadingAddress: false,
            address: '',
            areasItems: [],
            worktype: [],
            priority: [],
            wostatus: [],
            distanceInMeters: 500,
            // department: this.props.Department?this.props.Department:''
        };

    }


    public componentDidMount() {
        let tempstate = localStorage.getItem('dashboardState');
        this.setState({ loadingFields: true });
        this.setupDataPromise = [];
        this._getPrioritiesData();
        this.getDepartments();
        this._getworkTypeDataAsync();
        this._getStatusDataAsync();
        // this._getAreaData();
        //this._getFormattedAddressData();
        // this.getMapIcons();
        Promise.all(this.setupDataPromise).then(items => {
            this._getWOData();
            if (tempstate) {
                let allState = JSON.parse(tempstate);

                this.setState({
                    address: allState.address ? allState.address : '',
                    distanceInMeters: allState.distanceInMeters ? allState.distanceInMeters : '',
                    area: allState.area ? allState.area : '',
                    WOMarker: allState.WOMarker ? allState.WOMarker : '',
                    allWorkorders: allState.allWorkorders ? allState.allWorkorders : '',
                    zoom: allState.zoom ? allState.zoom : '',
                    selectedTab: allState.selectedTab ? allState.selectedTab : 0,
                    showWOFilter: allState.showWOFilter ? allState.showWOFilter : '',
                    loadingFields: false,
                    worktype: allState.worktype ? this.getMultiselectProps(allState.worktype, 'worktypeItems') : '',
                    priority: allState.priority ? this.getMultiselectProps(allState.priority, 'priorityItems') : '',
                    wostatus: allState.wostatus ? this.getMultiselectProps(allState.wostatus, 'StatusItems') : '',
                })
            } else {
                this._getWODATAforDashboard();
                this.setState({ loadingFields: false });
            }

        });
    }
    public getMultiselectProps(data: any, listData: any) {
        let returnData: Array<any> = [];
        if (this.state[listData] && data) {
            data.forEach((item: any) => {
                let tempItem = this.state[listData].find((l: any) => l.id === item.id);
                returnData.push(tempItem);
            })
        }
        return returnData;
    }
    private _getStatusDataAsync() {
        let dataToPassToService: any = {};
        dataToPassToService.isActive = true;
        this.StatusdataSource = [];
        this.setupDataPromise.push(
            utility.genericGetAPICall(`${Config.statuslist}?showAll=true`).then((data: any) => {
                this.StatusItems = [];
                let selectedStatus: any = [];

                for (let r of data) {
                    this.StatusItems.push({ id: r.id, text: (r.title), 'Select All': (r.title) });
                }
                const sortedItems = utility._sortItems(this.StatusItems, 'text', false);
                this.StatusdataSource = new DataSource({
                    store: sortedItems,
                    key: 'id'
                });

                this.StatusItems = utility._sortItems(this.StatusItems, 'text', false);
                this.setState({
                    StatusItems: this.StatusdataSource,
                    allStatusItems: this.StatusdataSource,
                    wostatus: selectedStatus
                });

            }, (err) => {
                console.log(err);
            }));
    }
    private _getworkTypeDataAsync() {
        this.setupDataPromise.push(
            utility.genericGetAPICall(`${Config.WTlistname}?showAll=true`).then((data: any) => {
                // console.log(data);
                this.worktypeItems = [];
                this.worktypedataSource = [];
                for (let r of data) {
                    this.worktypeItems.push({ id: r.id, text: r.title, selected: true, 'Select All': r.title });

                }
                const sortedItems = utility._sortItems(this.worktypeItems, 'text', false);
                this.worktypedataSource = new DataSource({
                    store: sortedItems,
                    key: 'id'
                });
                this.setState({
                    worktypeItems: this.worktypedataSource,
                    allworktypeItems: this.worktypedataSource
                });
            }, (err) => {
                console.log(err);
            }));
    }
    private _getPrioritiesData() {
        this.setupDataPromise.push(
            utility.genericGetAPICall(`${Config.PriorityListName}?showAll=true`).then((data: any) => {
                //console.log(data);
                this.priorityItems = [];
                this.prioritydataSource = [];

                for (let r of data) {
                    if (r.type === 'WorkOrder') {
                        this.priorityItems.push({ id: (r.id), text: `${r.title}`, 'Select All': (r.title) });
                    }
                }
                const sortedItems = utility._sortItems(this.priorityItems, 'text', false);
                this.prioritydataSource = new DataSource({
                    store: sortedItems,
                    key: 'id'
                });
                this.setState({ priorityItems: this.prioritydataSource, allpriorityItems: this.prioritydataSource });
            }, (err) => {
                console.log(err);
            }));
    }
    public getDepartments() {
        this.setupDataPromise.push(
            utility.genericGetAPICall(`${Config.DepartmentList}?showAll=true`).then((results: any) => {
                //console.log(results);
                let deptList = results;
                this.deptComboBoxOoptions = [];
                deptList.forEach((element: any) => {
                    this.deptComboBoxOoptions.push({
                        id: element.id,
                        text: element.title
                    });
                });
                this.setState({
                    deptOptions: this.deptComboBoxOoptions,
                    alldeptOptions: this.deptComboBoxOoptions,
                    department: this.props.Department ? this.props.Department : ''
                });


            }, (err) => {
                console.log(err);
            }));
    }
    private _getWODATAforDashboard() {
        let criteriaSelected = false;
        let dataToPassToService: any = {};
        if (!this.state.worktype) {
            this.setState({
                alertMessage: 'Please select an activity',
                showAlert: true
            });
            return;
        }
        if (this.state.addressSearch) {
            dataToPassToService.searchAddress = this.state.addressSearch
            criteriaSelected = true;
        }
        if (this.state.distanceInMeters) {
            dataToPassToService.searchDistanceInMeters = this.state.distanceInMeters ? +this.state.distanceInMeters : 500
            criteriaSelected = true;

        }
        if (this.state.wostatus && this.state.wostatus.length > 0) {

            let tempwostatus: any = [];
            this.state.wostatus.forEach((element: any) => {
                tempwostatus.push(element);
            });
            dataToPassToService.workorderStatusId = tempwostatus;
            criteriaSelected = true;
        }
        if (this.state.worktype && this.state.worktype.length > 0) {
            let tempworktype: any = [];
            this.state.worktype.forEach((element: any) => {
                tempworktype.push(element);
            });

            dataToPassToService.workorderTypeId = tempworktype;
            criteriaSelected = true;
        }

        if (this.state.priority && this.state.priority.length > 0) {
            let temppriority: any = [];
            this.state.priority.forEach((element: any) => {

                temppriority.push(element);
            });
            dataToPassToService.priorityIds = temppriority;
            criteriaSelected = true;
        }
        if (this.state.reportedDate) {
            let reportfrom;
            let reportTo;
            if (this.state.reportedDate) {
                reportfrom = new Date(this.state.reportedDate);
                reportfrom.setHours(0, 0, 0, 0);
                dataToPassToService.dateReportedFrom = reportfrom.toISOString();

                var extradaytoreportTodate = new Date(this.state.reportedDate);
                extradaytoreportTodate.setDate(extradaytoreportTodate.getDate() + 1);
                reportTo = extradaytoreportTodate.toISOString().substring(0, 10) + "T13:59:59.000Z";
                // actualcreatedTo = this.state.actdateCreatedTo.toISOString();
                dataToPassToService.DateReportedTo = reportTo;
            }
            criteriaSelected = true;
        }
        if (!criteriaSelected) {
            this.setState({
                alertMessage: 'Please select search criteria',
                showAlert: true
            });
            return;
        }
        // console.log({ dataToPassToService });
        this.setState({ WOMarker: [], loadingWODashboard: true, loading: true });
        utility.genericPostAPIcall(Config.SearchWorkordersForDashboard, dataToPassToService).then((results: any) => {
            WOMarker = [];
            let center: any;
            if (results.length > 0) {

                for (let r of results) {
                    var status = r.status + '_s';
                    let icon = status;
                    let woLink = "/WorkOrderDetail?WOID=" + r.id + "&ReqId=" + r.workRequestId;
                    let AsseticwoLink = global.AsseticWOLink + r.erpWorkorderNumber;
                    let WRDetailLink = "/WRDetail?ReqId=" + r.workRequestId;
                    if (this.state.addressSearch) {
                        let address = r.workorderLocation ? r.workorderLocation : this.state.addressSearch;
                        this.getLatLongbyAddress(address, "workorder");
                    }
                    else if (r.locationLatLong) {
                        center = { lng: r.locationLatLong ? r.locationLatLong.x : 0, lat: r.locationLatLong ? r.locationLatLong.y : 0 }
                    }
                    this.setState({
                        center: center,
                        singleMarkers: center,
                        zoom: this.state.addressSearch ? 19 : 9
                    });
                    WOMarker.push({
                        id: r.id,
                        title: r.title,
                        wonumber: r.id,
                        erpWorkorderNumber: r.erpWorkorderNumber,
                        link: woLink,
                        AsseticwoLink: AsseticwoLink,
                        icon: icon,
                        WRDetailLink: WRDetailLink,
                        status: r.status,
                        workType: r.worktypeName,
                        //suburb: r.Suburb,
                        // location: r.locationLatLong ? tempAddress : r.workorderLocation,
                        crew: r.crewTitle,
                        workReq: r.workRequestNumber,
                        area: area ? area : "",
                        reporteddate: r.dateStarted ? moment(r.dateStarted).format("DD/MM/YYYY h:mm a") : " ",
                        //latestStatusDate: lateststatusdate ? moment(lateststatusdate).format("DD/MM/YYYY h:mm a") : " ",
                        priority: r.priority,
                        lat: r.locationLatLong && r.locationLatLong.y ? r.locationLatLong.y : 0,
                        lng: r.center && r.locationLatLong.x ? r.locationLatLong.x : 0,
                        workRequestId: r.workRequestId
                    });
                }
                WOMarker = utility._sortItems(WOMarker, 'location', false);

                let resultGroup = utility.groupBy(WOMarker, 'lat');

                this.setState({
                    WOMarker: resultGroup,
                    allWorkorders: results
                });
                setTimeout(() => this.setState({ loading: false, loadingWODashboard: false }), 500)
                // var stateData = { ...this.state };

                //delete stateData['formattedAddressItems'];
                //localStorage.setItem('dashboardState', JSON.stringify(stateData));
            }
            else {
                if (this.state.addressSearch) {

                    this.getLatLongbyAddress(this.state.addressSearch, "noworkorder");
                }
                else {
                    let center = { lng: 138.59863, lat: -34.92866 }
                    this.setState({
                        singleMarkers: center,
                        WOMarker: [],
                        allWorkorders: [],
                        center: center,
                        loading: false,
                        loadingWODashboard: false
                    });
                }
            }
        },
            (err) => {
                console.log(err);
            });

    }

    public getLatLongbyAddress(place: any, type: any) {
        if (this.state.distanceInMeters <= 200) {
            this.setState({ zoom: 19 });
        }
        else if (this.state.distanceInMeters <= 400 || this.state.distanceInMeters > 200) {
            this.setState({ zoom: 17 });
        }
        else if (this.state.distanceInMeters <= 600 || this.state.distanceInMeters > 400) {
            this.setState({ zoom: 15 });
        }
        else if (this.state.distanceInMeters <= 800 || this.state.distanceInMeters > 600) {
            this.setState({ zoom: 13 });
        }
        else if (this.state.distanceInMeters <= 1000 || this.state.distanceInMeters > 800) {
            this.setState({ zoom: 11 });
        }
        else {
            this.setState({ zoom: 9 });
        }
        let address = place.toLowerCase();
        if (address.includes('street')) {
            address = address.replace("street", "st");
        }
        Geocode.fromAddress(address).then(
            (response) => {
                const { lat, lng } = response.results[0].geometry.location;

                if (lat && lng) {
                    let marker = { lat: lat, lng: lng };

                    if (type === "noworkorder") {
                        this.setState({
                            singleMarkers: marker,
                            WOMarker: [],
                            allWorkorders: [],
                            center: marker
                        });
                        setTimeout(() => this.setState({ loading: false, loadingWODashboard: false }), 500)
                        // var stateData = { ...this.state };

                        //delete stateData['formattedAddressItems'];
                        // localStorage.setItem('dashboardState', JSON.stringify(stateData));
                    }
                    else {
                        this.setState({
                            singleMarkers: marker,
                            center: marker,
                            loading: false,
                            loadingWODashboard: false
                        });
                    }
                }
            },
            (error) => {
                console.error(error);
                this.setState({
                    loading: false,
                    loadingWODashboard: false
                });
            }
        );

    }

    private _getWOData(allDepartments?: any) {

        let dataToPassToService: any = {};
        if (this.state.department && this.state.department.id !== 'All') {
            dataToPassToService.departmentId = this.state.department.id;
        }
        dataToPassToService.scheduledStartFrom = new Date(this.state.dashboardDateFrom).toISOString();
        dataToPassToService.scheduledStartTo = new Date(this.state.dashboardDateTo).toISOString();
        this.setState({ loading: true });
        utility.genericPostAPIcall(Config.SearchWorkordersForDashboard, dataToPassToService).then((results: any) => {

            let woCrewData: any = this._getChartData('crewTitle', results);
            let woStatusData: any = this._getChartData('status', results);
            let woDateData: any = this._getLineChartData(results);
            // For  Gauge data
            let woAverageTimeData: any = this._getGaugeChartData(results);
            let woCategoryData: any = this._getChartData('worktypeName', results);
            //For GoogleMap data
            let groupedBySuburbData = utility.groupBy(results, 'Suburb');
            // let SuburblabelList = (Object.keys(groupedBySuburbData));
            // let DataSuburbChart = [];
            markers.forEach((marker: any, index: any) => {
                if (groupedBySuburbData[marker.title]) {
                    marker["Count"] = groupedBySuburbData[marker.title].length;
                } else {
                    marker["Count"] = 0;
                }
            });

            this.setState({
                allWorkordersdata: results,
                woCrewData: woCrewData,
                woStatusData: woStatusData,
                woCategoryData: woCategoryData,
                woDateData: woDateData,
                woAverageTimeData: woAverageTimeData,
                loading: false,
                markers: markers,
                //loadingWODashboard: false
            });
        });
    }
    public _getLineChartData(results: any) {
        let InitiatedDates: any = [];
        if (results && results.length > 0) {
            results.forEach((item: any) => {
                let datefomat = moment(item.Datescheduledstart).format("MM-DD");
                if (InitiatedDates[datefomat]) {
                    InitiatedDates[datefomat] += 1;
                } else {
                    InitiatedDates[datefomat] = 1;
                }
            });
        }
        let getDateArray = function (start: any, end: any, groupedDatesWorkOrder: any) {
            var arr = new Array();
            var dt = start;
            while (dt <= end) {
                let datefomat: any = moment(dt).format("MM-DD");
                if (!arr[datefomat]) {
                    if (groupedDatesWorkOrder[datefomat])
                        arr[datefomat] = groupedDatesWorkOrder[datefomat]
                    else
                        arr[datefomat] = 0;

                }
                dt.setDate(dt.getDate() + 1);
            }
            return arr;
        }
        // var end = this.state.dashboardDateFrom;
        // var start = this.state.dashboardDateTo;
        var end = new Date(this.state.dashboardDateFrom);
        var start = new Date(this.state.dashboardDateTo);
        // end.setDate(start.getDate() - 30);
        let DatesListArray = getDateArray(end, start, InitiatedDates);
        let WODatelabelList = (Object.keys(DatesListArray));
        let DataWODate: any = [];
        WODatelabelList.forEach((item: any) => {
            DataWODate.push({ name: item, value: DatesListArray[item] });
        });
        return DataWODate;
    }
    public _getGaugeChartData(results: any) {

        let MeterGaugeData: any = [];
        if (results && results.length > 0) {
            results.forEach((item: any) => {
                if (item.Datescheduledstart !== null && item.Datescheduledend !== null) {

                    let start = moment(item.Datescheduledstart);
                    let end = moment(item.Datescheduledend);

                    let diff = end.diff(start);
                    //let diffDuration = moment(diff).format("h:mm:ss");
                    MeterGaugeData.push(diff);
                }
            });
        }
        let maxValue = Math.max.apply(null, MeterGaugeData);
        let maxValueTime = parseFloat(moment(maxValue).format("h:mm:ss"));
        // let sumof = MeterGaugeData.reduce(function (memo, num) {
        //   return memo + num;
        // }, 0);
        var sumof = 0;
        for (var i = 0; i < MeterGaugeData.length; i++) {
            sumof += MeterGaugeData[i];
        }
        let average = Math.round(sumof / MeterGaugeData.length);
        let avgTime = parseFloat(moment(average).format("h.mm.ss"));
        let gaugeValue: any = {
            avgTime: avgTime,
            maxValueTime: maxValueTime,
        };
        return gaugeValue
    }

    public _getChartData(chart: any, data: any) {

        let groupedByData = utility.groupBy(data, chart);
        let groupedByDataKeys = (Object.keys(groupedByData));
        let retunrData: any = [];
        groupedByDataKeys && groupedByDataKeys.forEach((item: any) => {
            retunrData.push({ name: item === 'null' ? 'Unassigned' : item, value: groupedByData[item].length });
        });
        return retunrData;
    }
    public clearFilter() {
        debugger;
        let date = new Date();
        date.setDate(date.getDate() - 7);
        let dashboardDateFrom = new Date();
        dashboardDateFrom.setDate(dashboardDateFrom.getDate() - 30);
        this.setState({
            dashboardDateFrom: dashboardDateFrom,
            dashboardDateTo: new Date(),
            // department: '',
            wostatus: [],
            priority: [],
            worktype: [],
            addressSearch: '',
            reportedDate: '',
            suburbNotFound: false,
            WOMarker: []
        });
        // localStorage.removeItem('dashboardState');
        setTimeout(this._getWOData.bind(this), 1000);

    }
    private _fieldChanged = (event: any, fieldName: string, options?: any) => {
        if (options) {
            let alloption = `all${options}`;
            this.setState({ [options]: this.state[alloption] });
        }
        this.setState({ [fieldName]: event.target.value });
        if (fieldName === 'addressSearch') {
            this.setState({ callAfterInit: true, searchShow: true });
        }
    }
    public handleValueChange(e: any, fieldName: string) {

        this.setState({ [fieldName]: e.value });
    }
    public handleSelect = (event: any, newValue: any) => {
        this.setState({ selected: newValue });
    }
    public handleDropdownChange(e: any, fieldName: string) {
        this.setState({ [fieldName]: e.selectedItem });
    }
    private _multiFieldChanged = (event: any, fieldName: string) => {

        this.setState({ [fieldName]: event.value });
    }


    public handleSelectTabStrip = (e: any) => {
        this.setState({ selectedTab: e.selected });
    }
    public onSelectDept(dept: any) {
        this.setState({ department: dept });
        setTimeout(this._getWOData.bind(this), 1000);
    }

    public onHorizontalChange = (event: any) => {
        this.setState({
            horizontalPanes: event.newState,
        });
    };
    private stopCommsAfterInit = (): void => {
        this.setState({ callAfterInit: false });
    }
    public getSelectedItem(item: any) {
        let tempAddress = `${item.street} ${item.suburb} ${item.state}`
        this.setState({
            addressSearch: tempAddress,
            searchShow: false,
        });
    }
    public setDistanceInMetersValue(e: any) {
        this.setState({ distanceInMeters: e.value });
    }

    pointClickHandler(e: any) {
        this.toggleVisibility(e.target);
    }

    legendClickHandler(e: any) {
        const arg = e.target;
        const item = e.component.getAllSeries()[0].getPointsByArg(arg)[0];

        this.toggleVisibility(item);
    }

    toggleVisibility(item: any) {
        item.isVisible() ? item.hide() : item.show();
    }
    CenterTemplate(pieChart: any) {
        return (
            <svg>
                <circle cx="100" cy="100" r={pieChart.getInnerRadius() - 6} fill="#eee"></circle>

                <text textAnchor="middle" x="100" y="120" style={{ fontSize: 18, fill: '#494949' }}>
                    <tspan x="100" y="90">Total</tspan>
                    <tspan x="100" y="100" dy="20px" style={{ fontWeight: 600, fontSize: '34px' }}>{this.state.allWorkordersdata?.length}</tspan>
                </text>
            </svg>
        )
    }
    renderAddress(data: any) {
        return (
            <div className={'searchDropContent pointer'} >
                <strong>{data.street}</strong><br />
                {data.suburb + " " + data.state}
            </div>
        );
    }
    onchange(data: any) {
        this.setState({ addressSearch: data.formatted_address });
    }
    public render(): React.ReactElement<any> {
        return (
            <div className={'dashboardHome tabsDiv'}>
                {/* {this.state.showAlert && this.alert()} */}
                <div className={"SearchDiv"}>
                    <div className={'headingDiv'}>
                        <span className={"heading"}>Dashboard</span>
                    </div>
                </div>
                <Paper square>

                    <Tabs
                        value={this.state.selected}
                        onChange={this.handleSelect}
                        indicatorColor="primary"
                        // textColor="primary"
                        variant="scrollable"
                        scrollButtons="auto"
                        aria-label="scrollable auto tabs example"
                    >
                        <Tab label={<span className='tabHeading'><BarChartIcon className={"subMenuLinkIcon"} />Work Order Dashboard</span>} {...a11yProps(0)} />
                        {this.props.role !== global.worxOnline_Reader && <Tab label={<span className='tabHeading' ><MapIcon className={"subMenuLinkIcon"} />My Team's Dashboard</span>}  {...a11yProps(1)} />}
                    </Tabs>
                </Paper>
                <TabPanel value={this.state.selected} index={0}>
                    <div className={"searchFieldDiv"}>
                        <Grid item xs={12} sm={12}>
                            {!this.state.loadingFields ?
                                <div>
                                    <Paper elevation={6} className="mt-30 homeChart displayFlex">
                                        <Grid container spacing={1}>
                                            <Grid item xs={12} sm={12} md={4}>
                                                <div style={{ maxHeight: '100vh', minHeight: '60vh', overflowY: 'auto', overflowX: 'hidden' }}>
                                                    <Grid container spacing={3}>
                                                        <Grid item xs={12} sm={12}>
                                                            <div className='filterOptionDiv'><h4 className='pl-30'>Search Criteria</h4></div>
                                                            <div className="autocompletDiv mt-10">

                                                                <div className='filterOptionDiv'>
                                                                    <Grid container spacing={3}>
                                                                        <Grid item xs={12} sm={12} md={12}>
                                                                            <Autocomplete
                                                                                apiKey={global.googleAPIKey}
                                                                                style={{ width: "90%" }}
                                                                                onPlaceSelected={(place) => {
                                                                                    this.onchange(place);
                                                                                }}
                                                                                options={options}
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs={12} sm={12} md={12}>
                                                                            <NumberBoxInput
                                                                                stylingMode='underlined'
                                                                                label="Distance In Meters"
                                                                                min={1}
                                                                                // pattern={"[A-Za-z]+"}
                                                                                // minLength={2}
                                                                                value={this.state.distanceInMeters}
                                                                                onValueChanged={(e: any) => this.handleValueChange(e, 'distanceInMeters')}

                                                                            />
                                                                        </Grid>
                                                                        {this.state.worktypeItems &&
                                                                            <Grid item xs={12} sm={12} md={12}>
                                                                                <TagBox
                                                                                    dataSource={this.state.worktypeItems}
                                                                                    valueExpr="id"
                                                                                    value={this.state.worktype ? this.state.worktype : ''}
                                                                                    searchEnabled={true}
                                                                                    displayExpr="text"
                                                                                    onValueChanged={(e: any) => this._multiFieldChanged(e, 'worktype')}
                                                                                    //onContentReady={(e: any) => this._fieldChanged(e, 'swms')}
                                                                                    label="Activity" />
                                                                            </Grid>
                                                                        }
                                                                        {this.state.priorityItems &&
                                                                            <Grid item xs={12} sm={12} md={12}>
                                                                                <TagBox
                                                                                    dataSource={this.state.priorityItems}
                                                                                    valueExpr="id"
                                                                                    value={this.state.priority ? this.state.priority : ''}
                                                                                    searchEnabled={true}
                                                                                    displayExpr="text"
                                                                                    onValueChanged={(e: any) => this._multiFieldChanged(e, 'priority')}
                                                                                    //onContentReady={(e: any) => this._fieldChanged(e, 'swms')}
                                                                                    label="Priority" />
                                                                            </Grid>
                                                                        }
                                                                        {this.state.StatusItems &&
                                                                            <Grid item xs={12} sm={12} md={12}>
                                                                                <TagBox
                                                                                    dataSource={this.state.StatusItems}
                                                                                    valueExpr="id"
                                                                                    value={this.state.wostatus ? this.state.wostatus : ''}
                                                                                    searchEnabled={true}
                                                                                    displayExpr="text"
                                                                                    onValueChanged={(e: any) => this._multiFieldChanged(e, 'wostatus')}
                                                                                    //onContentReady={(e: any) => this._fieldChanged(e, 'swms')}
                                                                                    label="Status" />
                                                                            </Grid>
                                                                        }
                                                                        <Grid item xs={12} sm={12} md={12}>
                                                                            <DateBoxInput
                                                                                stylingMode='underlined'
                                                                                type="date"
                                                                                displayFormat={"dd/MM/yy"}
                                                                                label={'Reported Date '}
                                                                                showClearButton={true}
                                                                                value={this.state.reportedDate ? new Date(this.state.reportedDate) : null}
                                                                                onValueChanged={(e: any) => this.handleValueChange(e, 'reportedDate')}
                                                                            />
                                                                        </Grid>
                                                                    </Grid>
                                                                </div>
                                                                {/* )} */}
                                                            </div>
                                                        </Grid>


                                                        <Grid item xs={12} sm={12}>
                                                            <div className={"searchButtonDiv"}>

                                                                <span className="mr-10">Result count : {this.state.allWorkorders && this.state.allWorkorders.length}</span>
                                                                <Button onClick={this.clearFilter} variant='outlined'>
                                                                    Clear
                                                                </Button>
                                                                <Button onClick={this._getWODATAforDashboard} variant='outlined' color="primary" className={'button'}>
                                                                    Apply
                                                                </Button>
                                                            </div>
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={8}>
                                                {!this.state.loadingWODashboard ?
                                                    <MyMapComponent
                                                        // googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${global.googleAPIKey}&sensor=false&libraries=geometry,drawing,places`}
                                                        loadingElement={<div style={{ height: `100%` }} />}
                                                        containerElement={<div style={{ height: `850px` }} />}
                                                        mapElement={<div style={{ height: `100%` }} />}
                                                        markers={this.state.WOMarker}
                                                        useStyle={this.state.useStyle}
                                                        center={this.state.center}
                                                        showWODetail={true}
                                                        showMarker={true}
                                                        zoom={this.state.zoom}
                                                        height={'100vh'}
                                                        singleMarkers={this.state.singleMarkers}
                                                    />
                                                    :
                                                    <>
                                                        <br />
                                                        <Spinner size="large" label="Loading..." />
                                                    </>
                                                }
                                            </Grid>
                                        </Grid>
                                    </Paper>

                                    <br />
                                </div> :
                                <>
                                    <br />
                                    <Spinner size="large" label="Loading..." />
                                </>
                            }
                        </Grid>
                        <br />
                    </div>
                </TabPanel >
                {
                    this.props.role === global.worxOnline_Reader ? <TabPanel></TabPanel> : <TabPanel value={this.state.selected} index={1}>
                        <div className={"searchFieldDiv"}>
                            <div className='mt-20'>
                                <Grid container spacing={3}>

                                    <Grid item xs={12} sm={12}>
                                        <Paper elevation={6} className="homeChart">
                                            <div className={'m-10'}>
                                                <Grid container spacing={3}>
                                                    <Grid item xs={12} sm={6} md={3}>
                                                        <SelectBoxInput
                                                            stylingMode='underlined'
                                                            displayExpr="text"
                                                            valueExpr="id"
                                                            showClearButton={false}
                                                            items={this.state.deptOptions}
                                                            selectedItem={this.state.department}
                                                            disabled={(this.props.role === global.worxOnline_Contractor || this.props.role === global.worxOnline_ContractorAdmin) ? true : false}
                                                            label='Department'
                                                            onSelectionChanged={(e: any) => this.handleDropdownChange(e, 'department')}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} sm={6} md={3}>
                                                        <DateBoxInput
                                                            stylingMode='underlined'
                                                            label={'Date From'}
                                                            value={this.state.dashboardDateFrom ? new Date(this.state.dashboardDateFrom) : null}
                                                            type="date"
                                                            displayFormat={"dd/MM/yy"}
                                                            showClearButton={true}
                                                            onValueChanged={(e: any) => this.handleValueChange(e, 'dashboardDateFrom')}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} sm={6} md={3}>
                                                        <DateBoxInput
                                                            stylingMode='underlined'
                                                            label={'Date To'}
                                                            value={this.state.dashboardDateTo ? new Date(this.state.dashboardDateTo) : null}
                                                            type="date"
                                                            displayFormat={"dd/MM/yy"}
                                                            showClearButton={true}
                                                            onValueChanged={(e: any) => this.handleValueChange(e, 'dashboardDateTo')}
                                                        />
                                                    </Grid>

                                                    <Grid item xs={12} sm={12} md={3}>
                                                        <div className={"searchButtonDiv"}>

                                                            <Button onClick={this.clearFilter} variant='outlined'>
                                                                Clear
                                                            </Button>
                                                            <Button onClick={this._getWOData} variant='outlined' color="primary" className={'button'}>
                                                                Apply
                                                            </Button>
                                                        </div>

                                                    </Grid>
                                                </Grid>
                                            </div>
                                        </Paper>
                                    </Grid>
                                </Grid>
                            </div>
                        </div>
                        <div>

                            {!this.state.loading ?
                                <Grid container>


                                    <Grid item xs={12} sm={12} md={12}>
                                        <div className="chart">
                                            <Grid container spacing={1}>
                                                <Grid item xs={12} sm={6} md={4}>
                                                    <Paper elevation={6} className="chartPaper">
                                                        <div className='m-10'>
                                                            {this.state.woCrewData && this.state.woCrewData.length > 0 ?

                                                                <Chart id="chart" dataSource={this.state.woCrewData}>
                                                                    <CommonSeriesSettings
                                                                        argumentField="name"
                                                                        valueField="value"
                                                                        type="bar"
                                                                        ignoreEmptyPoints={true}
                                                                    />
                                                                    <SeriesTemplate nameField="name" />
                                                                    <Title
                                                                        text="Work Orders"
                                                                        subtitle="BY CREW"
                                                                    />
                                                                    <Legend
                                                                        verticalAlignment="bottom"
                                                                        horizontalAlignment="center"
                                                                    />
                                                                    <Export enabled={true} />
                                                                </Chart > :
                                                                <p className="alignCenter">No data found</p>}
                                                            <br />
                                                        </div>
                                                    </Paper>
                                                </Grid>
                                                <Grid item xs={12} sm={6} md={4}>
                                                    <Paper elevation={6} className="chartPaper">
                                                        <div className='m-10'>
                                                            {this.state.woAverageTimeData ?
                                                                <CircularGauge
                                                                    id="gauge"
                                                                    value={this.state.woAverageTimeData.avgTime}
                                                                    height="377px"
                                                                >
                                                                    <Scale startValue={0} endValue={this.state.woAverageTimeData.maxValueTime} tickInterval={1}>
                                                                        <Label useRangeColors={true} />
                                                                    </Scale>
                                                                    <Tooltip enabled={true}>
                                                                        <Font size={40} color="#DCD0FF" />
                                                                    </Tooltip>
                                                                    <RangeContainer palette="pastel">
                                                                        <Range startValue={0} endValue={this.state.woAverageTimeData.avgTime} />
                                                                        <Range startValue={this.state.woAverageTimeData.avgTime} endValue={this.state.woAverageTimeData.maxValueTime} />

                                                                    </RangeContainer>
                                                                    {/* <Geometry
                                                                    startAngle={180}
                                                                    endAngle={360}
                                                                >
                                                                </Geometry> */}
                                                                    <Title
                                                                        horizontalAlignment={'left'}
                                                                        text="Work Orders"
                                                                        subtitle="BY AVERAGE TIME"
                                                                    />
                                                                    <Export enabled={true} />
                                                                </CircularGauge> :
                                                                <p className="alignCenter">No data found</p>}
                                                            <br />
                                                            <br />
                                                        </div>
                                                    </Paper>
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={4}>
                                                    <Paper elevation={6} className="chartPaper">
                                                        <div className='m-10'>
                                                            {this.state.woStatusData && this.state.woStatusData.length > 0 ?
                                                                <PieChart
                                                                    id="pie"
                                                                    type='doughnut'
                                                                    dataSource={this.state.woStatusData}
                                                                    palette="Bright"
                                                                    onPointClick={this.pointClickHandler}
                                                                    onLegendClick={this.legendClickHandler}

                                                                    innerRadius={0.65}
                                                                    centerRender={(e) => this.CenterTemplate(e)}
                                                                >
                                                                    <Series
                                                                        argumentField="name"
                                                                        valueField="value"
                                                                    >
                                                                        <Label visible={true}>
                                                                            <Connector visible={true} width={1} />
                                                                        </Label>
                                                                    </Series>
                                                                    <Title
                                                                        horizontalAlignment={'left'}
                                                                        text="Work Orders"
                                                                        subtitle="BY STATUS"
                                                                    />
                                                                    <Export enabled={true} />
                                                                </PieChart> :
                                                                <p className="alignCenter">No data found</p>}
                                                            <br />
                                                        </div>
                                                    </Paper>
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={8}>
                                                    <Grid item xs={12} sm={12} md={12}>
                                                        <Paper elevation={6} className="chartPaper">
                                                            <div className='m-10'>
                                                                {this.state.woDateData && this.state.woDateData.length > 0 ?
                                                                    <Chart
                                                                        id="chart"
                                                                        dataSource={this.state.woDateData}
                                                                    >
                                                                        <Series
                                                                            argumentField="name"
                                                                            valueField="value"
                                                                        >
                                                                            <Point hoverMode="allArgumentPoints" />
                                                                        </Series>
                                                                        <ArgumentAxis>
                                                                            <Label
                                                                                // wordWrap="none"
                                                                                overlappingBehavior={'stagger'}
                                                                            />
                                                                        </ArgumentAxis>
                                                                        <Title
                                                                            text="Work Orders"
                                                                            subtitle="OVER THE PERIOD"
                                                                        />
                                                                        <Legend
                                                                            verticalAlignment="bottom"
                                                                            horizontalAlignment="center"
                                                                        />
                                                                        <Export enabled={true} />
                                                                    </Chart>
                                                                    :
                                                                    <p className="alignCenter">No data found</p>}
                                                                <br />
                                                            </div>
                                                        </Paper>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={12}>
                                                        <Paper elevation={6} className="chartPaper">
                                                            <div className='m-10'>
                                                                {this.state.allWorkordersdata && this.state.allWorkordersdata.length > 0 ?

                                                                    <Chart id="chart" dataSource={this.state.woCategoryData} rotated={true}>
                                                                        <CommonSeriesSettings
                                                                            argumentField="name"
                                                                            valueField="value"
                                                                            type="bar"
                                                                            ignoreEmptyPoints={true}
                                                                        />
                                                                        <SeriesTemplate nameField="name" />
                                                                        <Title
                                                                            text="Work Orders"
                                                                            subtitle="BY ACTIVITY"
                                                                        />
                                                                        <Legend
                                                                            verticalAlignment="bottom"
                                                                            horizontalAlignment="center"
                                                                        />
                                                                        <Export enabled={true} />
                                                                    </Chart>

                                                                    :
                                                                    <p className="alignCenter">No data found</p>}
                                                                <br />
                                                            </div>
                                                        </Paper>
                                                    </Grid>

                                                </Grid>
                                            </Grid>
                                        </div>
                                    </Grid>
                                </Grid> :
                                <div>
                                    <br />
                                    <Spinner size='large' label="Loading.." />
                                </div>
                            }
                        </div>

                    </TabPanel>
                }
            </div >
        );
    }

    public alert() {
        return (
            <AlertDialog
                showDialog={this.state.showAlert}
                message={this.state.alertMessage}
                title={Config.productTitle}
                onClose={() => this.setState({ alertMessage: '', showAlert: false })}
                hideAlertButton={this.state.hideAlertButton}
            />
        );
    }
}

function TabPanel(props: any) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index: any) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}