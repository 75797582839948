import * as React from 'react';
import Draggable from 'devextreme-react/draggable';
import Grid from '@material-ui/core/Grid';
import ViewListIcon from '@material-ui/icons/ViewList';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import UndoIcon from '@material-ui/icons/Undo';
import RoomIcon from '@material-ui/icons/Room';
import moment from 'moment';
import { Config } from '../../../classes/config';
import global from '../../../classes/global';
import CheckBoxInput from '../../common/reusableComponents/CheckBox';

export default class SchedulerCard extends React.Component<any, any> {
    public column: any
    constructor(props: any) {
        super(props);

        this.state = {
        };
    }

    public componentDidMount() {

    }

    public render() {
        const { woData } = this.props;
        return (
            <>
                {this.props.view === 'List' ?
                    <div style={{ display: 'flex', borderLeft: `8px solid ${Config.priorityColors[woData.priority]}`, paddingLeft: '5px' }}>
                        {this.props.schedulerlistDraggingAllowed ?

                            <div
                                key={woData.Workordernumber}
                                draggable={!this.props.isMapListItem}
                                onDragStart={(e: any) => this.props.onDragStart(e, woData)}
                                style={{ width: '55%', float: 'left' }}>
                                <div className="schedulerCardTitle" title={woData.text} style={{ height: '23px', display: this.props.isMapListItem ? '-webkit-box' : '' }} >
                                    {woData.text}
                                </div>
                                <div className='displayFlex'>
                                    <div style={{ fontSize: '12px', color: '#999' }}>{woData.Workordernumber} {woData.erpWorkorder ? ` (${woData.erpWorkorder})` : ''}</div>
                                    {woData.fieldstatus && <div style={{ fontSize: '12px', color: Config.statusColors[woData.fieldstatus] }}>&nbsp;|&nbsp;{woData.fieldstatus}</div>}
                                    <div className="schedulerCardSubtitle" title={woData.location}>
                                        &nbsp;|&nbsp;{woData.suburb}
                                    </div>
                                </div>
                            </div>
                            :
                            <Draggable
                                key={woData.text}
                                style={{ width: '55%', float: 'left' }}
                                // className="item dx-card dx-theme-text-color dx-theme-background-color"
                                clone={true}
                                group={this.props.draggingGroupName}
                                data={woData}
                                onDragStart={(e: any) => this.props.onItemDragStart(e)}
                                onDragEnd={(e: any) => this.props.onItemDragEnd(e)}>
                                <div className="schedulerCardTitle" title={woData.text}>
                                    {woData.text}
                                </div>
                                <div className='displayFlex'>
                                    <div style={{ fontSize: '12px', color: '#999' }}>{woData.Workordernumber} {woData.erpWorkorder ? ` (${woData.erpWorkorder})` : ''}</div>
                                    {woData.fieldstatus && <div style={{ fontSize: '12px', color: Config.statusColors[woData.fieldstatus] }}>&nbsp;|&nbsp;{woData.fieldstatus}</div>}
                                    <div className="schedulerCardSubtitle" title={woData.location}>
                                        &nbsp;|&nbsp;{woData.suburb}
                                    </div>
                                </div>
                            </Draggable>}
                        <div
                            className={'pt-10 displayFlex'}
                            style={{ width: '45%', float: 'right', flexWrap: 'wrap', justifyContent: 'end' }}
                        >
                            <InfoOutlinedIcon className={'schedulerCradIcon'} titleAccess="View Detail" onClick={() => this.props.viewItem(woData)} />
                            {!this.props.mapView && <RoomIcon className={'schedulerCradIcon'} titleAccess="View on Map" onClick={() => this.props.zoomToSelectWO(woData)} />}
                            <ViewListIcon className={'schedulerCradIcon'} titleAccess="Go to Detail Screen" onClick={() => this.props.viewWO(woData)} />

                            {this.props.showUnassignWO && (
                                <UndoIcon className="schedulerCradIcon link" titleAccess="Unassign Workorder" onClick={() => this.props.unassignWO(woData)} />
                            )}
                        </div>
                        {(woData.fieldstatus === global.Unassigned && this.props.onUnassignedSelectionOption) && (
                            <div style={{ float: 'right', margin: '6px' }}>
                                <CheckBoxInput
                                    value={woData.selectedWO}
                                    onValueChanged={() => this.props.onUnassignedSelection(woData)}
                                />
                            </div>
                        )}
                    </div>
                    :
                    <div style={{ textAlign: 'left' }}
                        className={woData.selectedWO ? 'SelectSchedulerCard' : ''}
                    >
                        <Grid container spacing={1} style={{ borderLeft: `8px solid ${Config.priorityColors[woData.priority]}`, }}>
                            <Grid item xs={12} sm={12}>
                                <div className="schedulerCardWO displayFlex" >
                                    <div style={{ width: '100%' }}>{woData.Workordernumber} {woData.erpWorkorder ? ` (${woData.erpWorkorder})` : ''}</div>
                                    {woData.fieldstatus && <div className='schedulerStatusWrapper' style={{ color: Config.statusColors[woData.fieldstatus], border: `2px solid ${Config.statusColors[woData.fieldstatus]}` }}>{woData.fieldstatus}</div>}
                                </div>
                                {this.props.schedulerlistDraggingAllowed ?
                                    <div
                                        className="schedulerCardTitle"
                                        title={woData.text}
                                        key={woData.Workordernumber}
                                        draggable
                                        onDragStart={(e: any) => this.props.onDragStart(e, woData)}
                                    >
                                        {woData.text}
                                    </div> :
                                    this.props.draggingAllowed ?
                                        <Draggable
                                            key={woData.text}
                                            // className="item dx-card dx-theme-text-color dx-theme-background-color"
                                            clone={true}
                                            group={this.props.draggingGroupName}
                                            data={woData}
                                            onDragStart={(e: any) => this.props.onItemDragStart(e)}
                                            onDragEnd={(e: any) => this.props.onItemDragEnd(e)}>
                                            <div className="schedulerCardTitle" title={woData.text}>
                                                {woData.text}
                                            </div>
                                        </Draggable>
                                        :
                                        <div className="schedulerCardTitle" title={woData.text}>
                                            {woData.text}
                                        </div>
                                }

                                {this.props.dashBoardCard && (
                                    <div className="dashBoardCardDetails" title={woData.startDate}>
                                        {woData.startDate ? moment(woData.startDate).format("DD/MM/YYYY h:mm a") : ''}
                                    </div>
                                )}
                                <div className="schedulerCardSubtitle" title={woData.location}>
                                    {woData.location}
                                </div>
                                <div className={'pt-10 displayFlex'}>
                                    <InfoOutlinedIcon className={'schedulerCradIcon'} titleAccess="View Detail" onClick={() => this.props.viewItem(woData)} />
                                    {!this.props.mapView && <RoomIcon className={'schedulerCradIcon'} titleAccess="View on Map" onClick={() => this.props.zoomToSelectWO(woData)} />}
                                    <ViewListIcon className={'schedulerCradIcon'} titleAccess="Go to Detail Screen" onClick={() => this.props.viewWO(woData)} />

                                    {this.props.showUnassignWO && <span className='tooltipUnassignBtn'>
                                        <span className="link" onClick={() => this.props.unassignWO(woData)} >Unassign WO</span>
                                    </span>}
                                    {(woData.fieldstatus === global.Unassigned && this.props.onUnassignedSelectionOption) && (
                                        <div style={{ width: '100%' }}>
                                            <div style={{ float: 'right', margin: '6px' }}>
                                                <CheckBoxInput
                                                    value={woData.selectedWO}
                                                    onValueChanged={() => this.props.onUnassignedSelection(woData)}
                                                />
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                }
            </>
        );
    }

}