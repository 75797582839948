import * as React from 'react';
import utility from '../../../classes/utility';
import { Config } from '../../../classes/config';
import Grid from '@material-ui/core/Grid';
import ScrollView from 'devextreme-react/scroll-view';
import AlertDialog from '../../common/alertDialog';
import Spinner from '../../common/reusableComponents/Spinner';
import CheckBoxInput from '../../common/reusableComponents/CheckBox';
import capitalize from '@material-ui/core/utils/capitalize';
import TextBoxInput from '../../common/reusableComponents/TextBox';
import SelectBoxInput from '../../common/reusableComponents/SelectBox';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import ColorBox from 'devextreme-react/color-box';

const TypeItems = [
    {
        id: 'WorkOrder',
        text: 'WorkOrder',
    },
    {
        id: 'WorkRequest',
        text: 'WorkRequest',
    },
]
export default class AddEditPriority extends React.Component<any, any> {

    constructor(props: any) {
        super(props);

        this.updatePriority = this.updatePriority.bind(this);
        this.clearFilter = this.clearFilter.bind(this);
        this.state = {
            isActive: true,
            TypeItems: TypeItems

        };
    }

    public didMount() {
        if (this.props.details && this.props.mode === 'edit') {
            this.populateValue(this.props.details, this.props.mode);
        } else {
            this.setState({
                hideAlertButton: false,
                mode: this.props.mode
            });
        }

    }
    public populateValue(details: any, mode?: any) {
        this.setState({
            id: details.id,
            title: details.title,
            code: details.code,
            isActive: details.isActive,
            colorCode: details.colorCode ? details.colorCode : '',
            type: details.type ? { id: details.type, text: details.type } : '',
            createdBy: details.createdBy,
            created: details.createdOn,
        });
    }
    public updatePriority() {
        let dataToPassToService: any = {};

        if (!this.state.title) {
            this.setState({
                alertMessage: 'Please enter title',
                showAlert: true
            });
            return;
        }
        if (!this.state.code) {
            this.setState({
                alertMessage: 'Please enter code',
                showAlert: true
            });
            return;
        }
        if (!this.state.colorCode) {
            this.setState({
                alertMessage: 'Please enter Color Code',
                showAlert: true
            });
            return;
        }

        this.setState({
            alertMessage: <div className={'alertLoader'}><Spinner size='large' label="Loading..." /></div>,
            hideAlertButton: true,
            showAlert: true
        });

        dataToPassToService.title = this.state.title;
        dataToPassToService.code = this.state.code;
        //dataToPassToService.isDefault = false;
        dataToPassToService.type = this.state.type.text;
        dataToPassToService.isActive = this.state.isActive;
        dataToPassToService.colorCode = this.state.colorCode;
        dataToPassToService.tenantId = Config.tenantId;
        dataToPassToService.updatedBy = this.props.currentUser.Email;
        if (this.props.mode === 'edit') {
            dataToPassToService.updated = new Date();
            dataToPassToService.id = this.state.id;
            console.log("dataToPassToService", dataToPassToService);
            utility.genericUpdateAPICall(Config.PriorityListName, this.state.id, dataToPassToService).then((data: any) => {
                //console.log(data);
                this.setState({ showAlert: false });
                this.clearFilter();
                this.props.onClick();
                this.props.message("Priority has been updated");
            }, (err: any) => {
                console.log(err);
                this.setState({
                    alertMessage: 'Something went wrong!',
                    hideAlertButton: false,
                    showAlert: true
                });
            });
        } else {
            dataToPassToService.createdBy = this.props.currentUser.Email;
            dataToPassToService.created = new Date();
            utility.genericPostAPIcall(Config.PriorityListName, dataToPassToService).then((data: any) => {
                //console.log(data);
                this.setState({ showAlert: false });
                this.clearFilter();
                this.props.onClick();
                this.props.message("Priority has been created");
            }, (err: any) => {
                console.log(err);
                this.setState({
                    alertMessage: 'Something went wrong!',
                    hideAlertButton: false,
                    showAlert: true
                });
            });
        }

    }

    public clearFilter() {
        this.setState({ title: '', code: '', type: '' });
        this.props.onClose();

    }
    private alert() {
        return (
            <AlertDialog
                showDialog={this.state.showAlert}
                message={this.state.alertMessage}
                title={Config.productTitle}
                onClose={() => this.setState({ alertMessage: '', showAlert: false })}
                hideAlertButton={this.state.hideAlertButton}
            />
        );
    }
    private handleValueChange = (event: any, fieldName: string) => {
        this.setState({ [fieldName]: event.value });
    }
    public handleDropdownChange(e: any, fieldName: string) {
        this.setState({ [fieldName]: e.selectedItem });
    }


    public render(): React.ReactElement<any> {

        return (
            <div>
                {this.state.showAlert && this.alert()}

                <Dialog
                    open={this.props.showModal}
                    onClose={() => this.clearFilter()}
                    fullWidth={true}
                    maxWidth={'lg'}
                    aria-labelledby="max-width-dialog-title"
                    onRendered={() => this.didMount()}
                >
                    <DialogTitle id="scroll-dialog-title">
                        {`${this.props.mode && capitalize(this.props.mode)} Priority`}
                    </DialogTitle>
                    <IconButton aria-label="close" onClick={() => this.clearFilter()} className="closeIcon">
                        <CloseIcon />
                    </IconButton>
                    <DialogContent dividers={true}>
                        <ScrollView width='100%' height='100%' showScrollbar='onScroll' scrollByThumb={true}>
                            <div className='popContentDiv'>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={6}>
                                        <TextBoxInput
                                            stylingMode='underlined'
                                            label="Code *"
                                            // pattern={"[A-Za-z]+"}
                                            // minLength={2}
                                            value={this.state.code}
                                            onValueChanged={(e: any) => this.handleValueChange(e, 'code')}
                                            required={true}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextBoxInput
                                            stylingMode='underlined'
                                            label="Title *"
                                            // pattern={"[A-Za-z]+"}
                                            // minLength={2}
                                            value={this.state.title}
                                            onValueChanged={(e: any) => this.handleValueChange(e, 'title')}
                                            required={true}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <SelectBoxInput
                                            stylingMode='underlined'
                                            displayExpr="text"
                                            valueExpr="id"
                                            showClearButton={true}
                                            label='Type'
                                            items={this.state.TypeItems}
                                            selectedItem={this.state.type}
                                            onSelectionChanged={(e: any) => this.handleDropdownChange(e, 'type')}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <ColorBox
                                            label="Color Code *"
                                            value={this.state.colorCode}
                                            applyValueMode="instantly"

                                            onValueChanged={(e: any) => this.handleValueChange(e, 'colorCode')}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <CheckBoxInput value={this.state.isActive} onValueChanged={(e: any) => this.handleValueChange(e, 'isActive')} text={'Is Active'} />
                                    </Grid>
                                </Grid>
                            </div>
                        </ScrollView>
                    </DialogContent>
                    <DialogActions>

                        <Button onClick={() => this.clearFilter()} variant='contained' color="primary" className={''}>
                            Cancel
                        </Button>
                        <Button onClick={() => this.updatePriority()} variant='outlined' color="primary" className={'button'}>
                            Save
                        </Button>

                    </DialogActions>
                </Dialog>
            </div >

        );
    }

}

