import * as React from 'react';
import utility from '../../../classes/utility';
import { Config } from '../../../classes/config';
import DataTable from '../../common/DataTable/DataTable';
import AlertDialog from '../../common/alertDialog';
import DeleteDialog from '../../common/DeleteAlert';
import Spinner from '../../common/reusableComponents/Spinner';
import AddEditEquipment from './AddEditEquipment';
import global from '../../../classes/global';

let _items: any = [];


export default class Equipment extends React.Component<any, any> {


    public currentUser: any;
    public columns: any;

    constructor(props: any) {
        super(props);

        this._getItemData = this._getItemData.bind(this);
        this.DeleteDialog = this.DeleteDialog.bind(this);

        this.currentUser = this.props.currentUser;
        if (this.props.role !== global.worxOnline_Admin) {
            window.open('#/AccessDenied', '_self');
        }


        this.columns = [


            {
                field: 'id',
                title: 'ID',
                width: 80,
                type: 'string',
                allowHiding: true,
            },
            {
                field: 'resourceCode',
                title: 'Resource Code',
                // width: 280,
                type: 'string',
                allowHiding: false,
            },
            {
                field: 'title',
                title: 'Title',
                // width: 160,
                type: 'string',
            },
            {
                field: 'category',
                title: 'Category',
                // width: 160,
                type: 'string',
                allowHiding: true
            },
            {
                field: 'regonumber',
                title: 'Rego Number',
                // width: 160,
                type: 'string',
                allowHiding: true
            },
            {
                field: 'department',
                title: 'Department',
                // width: 160,
                type: 'string',
                allowHiding: true
            },
            {
                field: 'team',
                title: 'Team',
                // width: 160,
                type: 'string',
                allowHiding: true
            },
            {
                field: 'crew',
                title: 'Crew',
                // width: 160,
                type: 'string',
                allowHiding: true
            },
            {
                field: 'prestartformName',
                title: 'Prestart Form',
                // width: 160,
                type: 'string',
                allowHiding: true
            },
            {
                field: 'active',
                title: 'Is Active',
                width: 80,
                type: 'boolean',
                allowHiding: true
            },
            {
                field: 'inspectionFailed',
                title: 'Inspection failed',
                width: 110,
                type: 'boolean',
                allowHiding: true,
            },
            {
                field: 'updatedOn',
                title: 'Updated',
                width: 160,
                type: 'datetime',
                visibleColumn: false,
                allowHiding: true,
                sortOrder: 'desc'
            },
            {
                field: 'updatedBy',
                title: 'Updated By',
                width: 160,
                type: 'string',
                visibleColumn: false,
                allowHiding: true
            },
            {
                field: 'createdOn',
                title: 'Created',
                width: 160,
                type: 'datetime',
                visibleColumn: false,
                allowHiding: true
            },
            {
                field: 'createdBy',
                title: 'Created By',
                width: 160,
                type: 'string',
                visibleColumn: false,
                allowHiding: true
            },
        ];
        this.state = {
            items: _items,
            columns: this.columns,
            showSearchDiv: false,
            tableHeight: 665
        };
    }

    public componentDidMount() {
        this._getItemData();

    }

    public DeleteItem(item: any) {
        //console.log("item", item);

        this.setState({
            alertMessage: <div className={'alertLoader'}><Spinner size='large' label="Loading..." /></div>,
            hideAlertButton: true,
            showAlert: true,
            showDeleteAlert: false,
        });

        utility.genericDeleteAPICall(Config.EquipmentAPi, item).then((data: any) => {
            this.setState({
                alertMessage: 'Plant and Fleet has been deleted.',
                showAlert: true,
                showDeleteAlert: false,
                hideAlertButton: false,
                loading: false
            });
            this._getItemData();
        }, (err) => {
            console.log(err);
            this.setState({
                alertMessage: 'Cannot delete Plant and Fleet. Please try again later.',
                showAlert: true,
                showDeleteAlert: false,
                hideAlertButton: false,
            });
        });

    }

    // edit activty modal

    private EditItemModal(item: any) {
        //console.log("item", item);
        this.setState({ showModal: true, details: item, mode: "edit" });
    }
    public renderNewModal() {
        return (
            <AddEditEquipment
                showModal={this.state.showModal}
                onClose={() => this._closeModal()}
                onClick={() => this._getItemData()}
                message={(e: any) => this.message(e)}
                currentUser={this.currentUser}
                details={this.state.details}
                role={this.props.role}
                Department={this.props.Department}
                mode={this.state.mode}
            />
        );
    }


    private _showModal = (): void => {
        this.setState({ showModal: true, details: null, mode: "add" });
    }

    private _closeModal = (): void => {
        this.setState({ showModal: false });
    }

    public message(message: any) {
        //console.log(message);
        this.setState({
            alertMessage: message + ' successfully',
            showAlert: true
        });
    }

    private _getItemData() {
        this.setState({
            loading: true
        });
        utility.genericGetAPICallForList(Config.equipmentwithprestartForms).then((data: any) => {
            _items = [];

            if (data.length === null) {
                if (_items.length <= 0) {
                    this.setState({
                        alertMessage: 'No results found for this criteria',
                        showAlert: true
                    });
                }
                this.setState({ items: _items, loading: false });
                return;
            }
            else {
                data.forEach((item: any) => {
                    item.equipmentTypes && item.equipmentTypes.forEach((r: any) => {
                        _items.push({
                            id: r.id,
                            title: r.title,
                            resourceCode: r.resourceCode,
                            category: r.category,
                            //categoryId: r.Categoryid,
                            regonumber: r.regoNumber,
                            department: r.department,
                            departmentId: r.departmentId,
                            teamId: r.teamId,
                            team: r.team,
                            crew: r.crew,
                            crewId: r.defaultCrewId,
                            resurrectedOn: r.resurrectedOn,
                            prestartformid: r.prestartFormId,
                            inspectionFailedOn: r.inspectionFailedOn,
                            prestartformName: r.prestartForm,
                            inspectionFailed: r.inspectionFailed,
                            active: r.isActive ? true : false,
                            default: r.isDefault ? true : false,
                            updatedBy: r.updatedBy,
                            updatedOn: r.updated ? r.updated : null,
                            createdBy: r.createdBy,
                            createdOn: r.created ? r.created : null,

                        });

                    });

                });
            }
            _items = utility._sortItems(_items, 'updatedOn', true);

            this.setState({ items: _items, callAfterInit: true, loading: false, preItems: _items });
        }, (err) => {
            console.log(err);
            this.setState({ loading: false, callAfterInit: true, });
        });
    }

    public setSelection = (id: any) => {
        this.setState({
            selectedRow: id
        });
    }
    private stopCommsAfterInit = (): void => {
        this.setState({ callAfterInit: false });
    }

    public render(): React.ReactElement<any> {
        return (
            <React.Fragment>
                {this.renderNewModal()}
                {this.state.showDeleteAlert && this.DeleteAlert()}
                {this.state.showAlert && this.alert()}
                <div className={"SearchDiv"}>
                    <div className={'headingDiv'}>
                        <span className={"heading"}> Plant and Fleet </span>
                    </div>
                </div>
                <div>

                    {!this.state.loading ?
                        <DataTable
                            style={{ width: '100%' }}
                            filteralbe={true}
                            groupable={true}
                            rows={this.state.items}
                            columns={this.state.columns}
                            fileName={'Plant and Fleet'}
                            columnChooser={'EquipmentColumns'}
                            selectionMode={false}
                            showHideColumnMenu={true}
                            callAfterInit={this.state.callAfterInit}
                            stopCommsAfterInit={() => this.stopCommsAfterInit()}
                            loading={this.state.loading}
                            allowExportSelectedData={false}
                            showNewModal={() => this._showModal()}
                            deleteItem={(a: any) => this.DeleteDialog(a)}
                            editItem={(a: any) => this.EditItemModal(a)}
                            // viewFile={(e:any)=>this.viewFile(e)}
                            showCommandBtn={true}
                            showNewBtn={true}
                            hideNewBtn={this.state.showModal || this.state.showModal || this.props.hideNewBtn}
                            showViewDetailBtn={false}
                        />
                        :
                        <Spinner size='large' label="Loading.." />
                    }

                </div>
            </React.Fragment>
        );
    }
    public alert() {
        return (
            <AlertDialog
                showDialog={this.state.showAlert}
                message={this.state.alertMessage}
                title={Config.productTitle}
                hideAlertButton={this.state.hideAlertButton}
                onClose={() => this.setState({ alertMessage: '', showAlert: false })}
            />
        );
    }
    public DeleteDialog(item: any) {
        this.setState({
            DeletealertMessage: 'Are you sure you want to delete?',
            showDeleteAlert: true,
            deleteItem: item
        });
    }
    public DeleteAlert() {
        return (
            <DeleteDialog
                showDialog={this.state.showDeleteAlert}
                message={this.state.DeletealertMessage}
                title={"worxOnline"}
                onClose={() => this.setState({ DeletealertMessage: '', showDeleteAlert: false })}
                onOkButton={() => this.DeleteItem(this.state.deleteItem)}
                onCancelButton={() => this.setState({ DeletealertMessage: '', showDeleteAlert: false })}
            />
        );
    }
}

