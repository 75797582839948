import * as React from 'react';

import global from '../../classes/global';

import { Popup } from 'devextreme-react/popup';
import ScrollView from 'devextreme-react/scroll-view';

export interface ITestFrameState {
    iFramePanelOpened: boolean;
    iFrameUrl: string;
}

export default class AssetMap extends React.Component<any, any> {

    constructor(props: any) {
        super(props);

        this.state = {
            iFramePanelOpened: false,
            iFrameUrl: global.AssetMapURL
        };

    }

    public _onIframeLoaded() {

        window.addEventListener('message', this.handleIframeTaskMap);
    }

    public componentDidMount() {
        // window.addEventListener('message', this.handleIframeTask);
        //console.log(this.props);
    }
    public handleValueChange(e: any, fieldName: string) {

        this.setState({ [fieldName]: e.value });
    }
    renderAddress(data: any) {

        return (
            <div className={'searchDropContent pointer'} >
                <strong>{data.street}</strong><br />
                {data.suburb + " " + data.state}
            </div>
        );
    }
    public handleIframeTaskMap = (e: any) => {

        // if (e.origin !== 'https://worxonline.sharepoint.com') {
        //     return;
        // }

        if (e.data.command === 'asset selected') {

            let assetData = e.data.assets;
            if (this.props.selectModeMap === true) {
                this.props.selectAssets(assetData);
            } else if (this.props.selectParent === true) {
                this.props.selectAssetsforParent(assetData);

            }
            this.setState({
                iFramePanelOpened: false
            });


        }
    };


    onchange(data: any) {
        this.setState({ address: data.formatted_address });
    }
    public render(): React.ReactElement<any> {
        let url = "";
        if (this.props.coordinates && this.props.coordinates.length > 0) {
            const firstCoordinate = this.props.coordinates[0];

            // Check if it's a point
            if (firstCoordinate.type === "Point") {
                const point  = firstCoordinate.coordinates;
                url = `${this.state.iFrameUrl}?lat=${point[1]}&long=${point[0]}`;
            }
            // Check if it's a multipolygon
            else if (firstCoordinate.type === "MultiPolygon") {
                const multipolygonString = this.props.coordinates.map((coordinate:any) =>
                    coordinate.coordinates.map((point:any) => `${point.lat},${point.lng}`).join(';')
                ).join(',');


                url = `${this.state.iFrameUrl}?multipolygon=${multipolygonString}`;
            }
            if (this.props.al) {
                url += `&al=${this.props.al}`;
            }
        }
        else if (this.props.address && this.props.al) {
            url = `${this.state.iFrameUrl}${this.props.address && this.props.address !== "" ? `?loc=${this.props.address}` : ''}${this.props.al ? `&al=${this.props.al}` : ''}`;
        }
        else if (this.props.address) {
            url = `${this.state.iFrameUrl}${this.props.address && this.props.address !== "" ? `?loc=${this.props.address}` : ''}`;
        }
        else {
            url = `${this.state.iFrameUrl}${this.props.al && this.props.al !== "" ? `?al=${this.props.al}` : ''}`;
        }
         //console.log('url:: ', url);

        return (
            <React.Fragment>
                <div>

                    <Popup
                        width={'80vw'}
                        maxHeight={'90vh'}
                        minHeight={200}
                        height={'auto'}
                        showTitle={true}
                        showCloseButton={true}
                        title={this.props.polylines ? this.props.department : this.props.address}
                        dragEnabled={true}
                        hideOnOutsideClick={false}
                        visible={this.props.iFramePanelOpened}
                        onVisibleChange={() => this.props.onClick()}
                        onShowing={() => this._onIframeLoaded()}

                    >

                        <ScrollView width='100%' height='100%' showScrollbar='onScroll' scrollByThumb={true}>
                            <iframe
                                src={url}
                                allowFullScreen={true}
                                width={'100%'}
                                style={{ height: '70vh' }}
                                onLoad={this._onIframeLoaded.bind(this)}
                            />
                        </ScrollView>
                    </Popup>
                </div>
            </React.Fragment>
        );
    }
}
