import * as React from 'react';
import utility from '../../../classes/utility';
import { Config } from '../../../classes/config';
import global from '../../../classes/global';
import DataTable from '../../common/DataTable/DataTable';
import AlertDialog from '../../common/alertDialog';
import DeleteDialog from '../../common/DeleteAlert';
import AddEditWorkTypeField from "./AddEditWorkTypeField";
import Spinner from '../../common/reusableComponents/Spinner';


let _items: any = [];

export default class WorkTypeField extends React.Component<any, any> {

    public departmentItems: any = [];
    public worktypeItems: any = [];
    public currentUser: any;
    public columns: any;
    public detailColumns: any;


    constructor(props: any) {
        super(props);

        this.departmentItems = [];
        this.worktypeItems = [];
        this.DeleteDialog = this.DeleteDialog.bind(this);

        if (this.props.role !== global.worxOnline_Admin) {
            window.open('#/AccessDenied', '_self');
        }

        this.currentUser = this.props.currentUser;

        this.columns = [
            {
                field: 'id',
                title: 'ID',
                width: 80,
                type: 'string',
                allowHiding: true,
            },
            {
                field: 'type',
                title: 'Type',
                // width: 160,
                type: 'string',
            },
            {
                field: 'title',
                title: 'Title',
                // width: 160,
                type: 'string',
                allowHiding: false,
            },
            {
                field: 'worktypeName',
                title: 'Activity',
                // width: 160,
                type: 'string',
                allowHiding: true
            },

            {
                field: 'updatedOn',
                title: 'Updated',
                width: 160,
                type: 'datetime',
                visibleColumn: false,
                allowHiding: true,
                sortOrder: 'desc'
            },
            {
                field: 'updatedBy',
                title: 'Updated By',
                width: 160,
                type: 'string',
                visibleColumn: false,
                allowHiding: true
            },
            {
                field: 'createdOn',
                title: 'Created',
                width: 160,
                type: 'datetime',
                visibleColumn: false,
                allowHiding: true
            },
            {
                field: 'createdBy',
                title: 'Created By',
                width: 160,
                type: 'string',
                visibleColumn: false,
                allowHiding: true
            },
        ];
        this.detailColumns = [
            {
                field: 'title',
                title: 'Title',
                // width: 280,
                type: 'string',
            },
            {
                field: 'sequenceNo',
                title: 'Sequence #',
                // width: 280,
                type: 'string',
            },
            {
                field: 'parentSequenceNo',
                title: 'Parent Sequence #',
                // width: 280,
                type: 'string',
            },
            {
                field: 'trigger',
                title: 'Trigger',
                // width: 160,
                type: 'string',
                allowHiding: true,
            },
            {
                field: 'erpField',
                title: 'ERP Field',
                // width: 160,
                type: 'string',
                allowHiding: true,
            },
            {
                field: 'erpTable',
                title: 'ERP Table',
                // width: 160,
                type: 'string',
                allowHiding: true,
            },
            {
                field: 'controlName',
                title: 'Control Name',
                // width: 160,
                type: 'string',
                allowHiding: true,
            },
            {
                field: 'condition',
                title: 'Condition',
                // width: 160,
                type: 'string',
                allowHiding: true,
            },
            {
                field: 'isActive',
                title: 'Active',
                // width: 160,
                type: 'boolean',
                allowHiding: true,
            },
            {
                field: 'isRequired',
                title: 'Is Required',
                // width: 160,
                type: 'boolean',
                allowHiding: true,
            },
        ]
        this.state = {
            items: _items,
            columns: this.columns,
            hideAlertButton: false,
        };
    }

    public componentDidMount() {
        this._getItemsData();
    }
    public DeleteItem(id: any) {
        this.setState({
            alertMessage: <div className={'alertLoader'}><Spinner size='large' label="Loading..." /></div>,
            hideAlertButton: true,
            showAlert: true,
            showDeleteAlert: false,
        });

        utility.genericDeleteAPICall(Config.workTypeFieldList, id).then((data: any) => {
            this.setState({
                alertMessage: 'Activity field has been deleted.',
                showAlert: true,
                showDeleteAlert: false,
                hideAlertButton: false,
                loading: false
            });
            this._getItemsData()
        }, (err) => {
            // console.log(err);
            this.setState({
                alertMessage: 'Cannot delete Activity field . Please try again later.',
                showAlert: true,
                showDeleteAlert: false,
                hideAlertButton: false,
            });
        });

    }
    // edit activty modal

    private EditItemModal(item: any) {
        //console.log("item", item);
        this.setState({ showModal: true, details: item, mode: 'edit' });
    }
    private _showModal = (): void => {
        this.setState({ showModal: true, details: null, mode: 'add' });
    }
    public renderNewModal() {
        return (
            <AddEditWorkTypeField
                showModal={this.state.showModal}
                onClose={() => this._closeModal()}
                onClick={() => this._getItemsData()}
                message={(e: any) => this.message(e)}
                currentUser={this.currentUser}
                tenantConfig={this.props.tenantConfig}
                mode={this.state.mode}
                details={this.state.details}
                items={this.state.items}
            />
        );
    }
    private _closeModal = (): void => {
        this.setState({ showModal: false });
    }


    public message(message: any) {
        //console.log(message);
        this.setState({
            alertMessage: message + ' successfully',
            showAlert: true
        });
    }

    private _getItemsData() {
        let dataToPassToService: any = {};
        this.setState({
            loading: true
        });
        //console.log("dataToPassToService", dataToPassToService);
        utility.genericGetAPICallForList(Config.SearchWorkTypeField, "POST", dataToPassToService).then((data: any) => {
            _items = [];
            // console.log(data);
            if (data.length === null) {
                if (_items.length <= 0) {
                    this.setState({
                        alertMessage: 'No results found for this criteria',
                        showAlert: true
                    });
                }
                this.setState({ items: _items, loading: false });
                return;
            }
            else {
                for (let r of data) {
                    let workTypeNameArray: any = [];
                    r.workTypes && r.workTypes.forEach((wt: any) => {
                        workTypeNameArray.push(wt.title);
                    });
                    _items.push({
                        id: r.dbId ? r.dbId : r.id,
                        title: r.title,
                        text: r.title,
                        type: r.type,
                        worksystemname: r.workSystemName,
                        sequenceno: r.sequenceNo,
                        trigger: r.trigger,
                        // worktypeId: r.Worktypeid,
                        worktypeName: workTypeNameArray.join(),
                        worktypes: r.workTypes,
                        parentsequenceno: r.parentSequenceNo,
                        controlname: r.controlName,
                        choices: r.choices ? JSON.stringify(r.choices) : '',
                        extraAttributes: r.extraAttributes,
                        condition: r.condition,
                        active: r.isActive ? true : false,
                        updatedBy: r.updatedBy,
                        updatedOn: r.updated,
                        createdBy: r.created,
                        createdOn: r.createdBy,
                    });
                }
            }
            _items = utility._sortItems(_items, 'updatedOn', true);

            this.setState({ items: _items, callAfterInit: true, loading: false });
            //console.log(_items);
        }, (err) => {
            console.log(err);
            this.setState({ loading: false });
        });
    }

    private stopCommsAfterInit = (): void => {
        this.setState({ callAfterInit: false });
    }
    public render(): React.ReactElement<any> {

        return (
            <React.Fragment>
                {this.renderNewModal()}
                {this.state.showDeleteAlert && this.DeleteAlert()}
                {this.state.showAlert && this.alert()}
                <div className={"SearchDiv"}>
                    <div className={'headingDiv'}>
                        <span className={"heading"}> Activity Field </span>
                    </div>
                </div>
                <div>

                    {!this.state.loading ?
                        <DataTable
                            style={{ width: '100%' }}
                            filteralbe={true}
                            groupable={true}
                            rows={this.state.items}
                            columns={this.state.columns}
                            fileName={'WorktypeFields'}
                            columnChooser={'worktypeColumns'}
                            selectionMode={false}
                            showHideColumnMenu={true}
                            callAfterInit={this.state.callAfterInit}
                            stopCommsAfterInit={() => this.stopCommsAfterInit()}
                            loading={this.state.loading}
                            allowExportSelectedData={false}
                            showNewModal={() => this._showModal()}
                            deleteItem={(a: any) => this.DeleteDialog(a)}
                            editItem={(a: any) => this.EditItemModal(a)}
                            // viewFile={(e:any)=>this.viewFile(e)}
                            showCommandBtn={true}
                            showNewBtn={true}
                            hideNewBtn={this.state.showModal || this.state.showEditModal || this.props.hideNewBtn}
                            showViewDetailBtn={false}
                            showDetailTable={true}
                            detailColumns={this.detailColumns}
                        />
                        :
                        <Spinner size='large' label="Loading..." />
                    }

                </div>
            </React.Fragment>
        );
    }
    public alert() {
        return (
            <AlertDialog
                showDialog={this.state.showAlert}
                message={this.state.alertMessage}
                title={Config.productTitle}
                hideAlertButton={this.state.hideAlertButton}
                onClose={() => this.setState({ alertMessage: '', showAlert: false })}
            />
        );
    }
    public DeleteDialog(item: any) {
        this.setState({
            DeletealertMessage: 'Are you sure you want to delete?',
            showDeleteAlert: true,
            deleteItem: item
        });
    }
    public DeleteAlert() {
        return (
            <DeleteDialog
                showDialog={this.state.showDeleteAlert}
                message={this.state.DeletealertMessage}
                title={"worxOnline"}
                onClose={() => this.setState({ DeletealertMessage: '', showDeleteAlert: false })}
                onOkButton={() => this.DeleteItem(this.state.deleteItem)}
                onCancelButton={() => this.setState({ DeletealertMessage: '', showDeleteAlert: false })}
            />
        );
    }

}
