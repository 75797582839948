import * as React from 'react';
import utility from '../../../classes/utility';
import { Config } from '../../../classes/config';
import DataTable from '../../common/DataTable/DataTable';
import AlertDialog from '../../common/alertDialog';
import DeleteDialog from '../../common/DeleteAlert';
import Spinner from '../../common/reusableComponents/Spinner';
import AddEditSuburb from './AddEditSuburb';
import global from '../../../classes/global';

let _items: any = [];

export default class Suburbs extends React.Component<any, any> {

  public currentUser: any;

  public columns: any;
  constructor(props: any) {
    super(props);

    this.DeleteDialog = this.DeleteDialog.bind(this);

    this.currentUser = this.props.currentUser;
    if (this.props.role !== global.worxOnline_Admin) {
      window.open('#/AccessDenied', '_self');
    }
    this.columns = [
      {
        field: 'id',
        title: 'ID',
        width: 80,
        type: 'string',
        allowHiding: false,
      },
      {
        field: 'title',
        title: 'Title',
        // width: 160,
        type: 'string',
        allowHiding: false,
      },
      {
        field: 'code',
        title: 'Code',
        // width: 160,
        type: 'string',
        allowHiding: true,
      },
      {
        field: 'lat',
        title: 'Latitude',
        // width: 160,
        type: 'string',
        allowHiding: true,
      },
      {
        field: 'lng',
        title: 'Longitude',
        // width: 160,
        type: 'string',
        allowHiding: true,
      },
      {
        field: 'isActive',
        title: 'is Active',
        // width: 160,
        type: 'Boolean',
        allowHiding: true,
      },
      {
        field: 'updatedOn',
        title: 'Updated',
        width: 160,
        type: 'datetime',
        visibleColumn: false,
        allowHiding: true,
        sortOrder: 'desc'
      },
      {
        field: 'updatedBy',
        title: 'Updated By',
        width: 160,
        type: 'string',
        visibleColumn: false,
        allowHiding: true
      },
      {
        field: 'createdOn',
        title: 'Created',
        width: 160,
        type: 'datetime',
        visibleColumn: false,
        allowHiding: true
      },
      {
        field: 'createdBy',
        title: 'Created By',
        width: 160,
        type: 'string',
        visibleColumn: false,
        allowHiding: true
      },
    ];
    this.state = {
      items: _items,
      columns: this.columns,
    };
  }

  public componentDidMount() {
    this._getSuburbData();
  }
  public DeleteItem(item: any) {
    //console.log("item", item);

    this.setState({
      alertMessage: <div className={'alertLoader'}><Spinner size='large' label="Loading..." /></div>,
      hideAlertButton: true,
      showAlert: true,
      showDeleteAlert: false,
    });
    let dataToPassToService: any = {};
    dataToPassToService.id = item;
    utility.genericDeleteAPICall(Config.suburblistname,
      item
    )
      .then(
        (data: any) => {
          this.setState({
            alertMessage: "Suburb has been deleted.",
            showAlert: true,
            showDeleteAlert: false,
            hideAlertButton: false,
            loading: false
          });
          this._getSuburbData();
        },
        (err) => {
          console.log(err);
          this.setState({
            alertMessage: "Cannot delete suburb. Please try again later.",
            showAlert: true,
            showDeleteAlert: false,
            hideAlertButton: false,
          });
        }
      );

  }

  // edit activty modal

  private EditItemModal(item: any) {
    //console.log("item", item);
    this.setState({ showModal: true, details: item, mode: 'edit' });
  }
  public renderNewModal() {
    return (
      <AddEditSuburb
        showModal={this.state.showModal}
        onClose={() => this._closeModal()}
        onClick={() => this._getSuburbData()}
        message={(e: any) => this.message(e)}
        currentUser={this.currentUser}
        aadHttpClient={this.props.aadHttpClient}
        details={this.state.details}
        mode={this.state.mode}
      />
    );
  }


  private _showModal = (): void => {
    this.setState({ showModal: true, details: null, mode: 'add' });
  };

  private _closeModal = (): void => {
    this.setState({ showModal: false });
  };

  public message(message: any) {
    //console.log(message);
    this.setState({
      alertMessage: message + " successfully",
      showAlert: true,
    });
  }
  private _getSuburbData() {
    this.setState({
      loading: true,
    });
    utility.genericGetAPICallForList(Config.suburblistname, 'GET')
      .then(
        (data: any) => {
          _items = [];
          //console.log(data);
          for (let r of data) {
            _items.push({
              id: r.id,
              title: r.title,
              code: r.code,
              isActive: r.isActive,
              lng: r.latLong && r.latLong.x ? r.latLong.x : "",
              lat: r.latLong && r.latLong.y ? r.latLong.y : "",
              updatedBy: r.updatedBy,
              updatedOn: r.updated,
              createdBy: r.createdBy,
              createdOn: r.created,

            });
          }
          _items = utility._sortItems(_items, 'updatedOn', true);
          this.setState({ items: _items, callAfterInit: true, loading: false });

          //console.log(_items);
        },
        (err) => {
          console.log(err);
          this.setState({ loading: false });
        }
      );
  }

  private stopCommsAfterInit = (): void => {
    this.setState({ callAfterInit: false });
  }


  public render(): React.ReactElement<any> {

    return (
      <React.Fragment>
        {this.renderNewModal()}
        {this.state.showDeleteAlert && this.DeleteAlert()}
        {this.state.showAlert && this.alert()}
        <div className={"SearchDiv"}>
          <div className={'headingDiv'}>
            <span className={"heading"}> Suburb </span>
          </div>
        </div>
        <div>
          {!this.state.loading ?
            <DataTable
              style={{ width: '100%' }}
              filteralbe={true}
              groupable={true}
              rows={this.state.items}
              columns={this.state.columns}
              fileName={'Suburbs'}
              columnChooser={'suburbColumns'}
              selectionMode={false}
              showHideColumnMenu={true}
              callAfterInit={this.state.callAfterInit}
              stopCommsAfterInit={() => this.stopCommsAfterInit()}
              loading={this.state.loading}
              allowExportSelectedData={false}
              showNewModal={() => this._showModal()}
              deleteItem={(a: any) => this.DeleteDialog(a)}
              editItem={(a: any) => this.EditItemModal(a)}
              // viewFile={(e:any)=>this.viewFile(e)}
              showCommandBtn={true}
              showNewBtn={true}
              hideNewBtn={this.state.showModal || this.state.showModal || this.props.hideNewBtn}
              showViewDetailBtn={false}
            /> :
            <Spinner size='large' label="Loading..." />
          }

        </div>
      </React.Fragment>
    );
  }
  public alert() {
    return (
      <AlertDialog
        showDialog={this.state.showAlert}
        message={this.state.alertMessage}
        title={Config.productTitle}
        hideAlertButton={this.state.hideAlertButton}
        onClose={() => this.setState({ alertMessage: '', showAlert: false })}
      />
    );
  }
  public DeleteDialog(item: any) {
    this.setState({
      DeletealertMessage: 'Are you sure you want to delete?',
      showDeleteAlert: true,
      deleteItem: item
    });
  }
  public DeleteAlert() {
    return (
      <DeleteDialog
        showDialog={this.state.showDeleteAlert}
        message={this.state.DeletealertMessage}
        title={"worxOnline"}
        onClose={() => this.setState({ DeletealertMessage: '', showDeleteAlert: false })}
        onOkButton={() => this.DeleteItem(this.state.deleteItem)}
        onCancelButton={() => this.setState({ DeletealertMessage: '', showDeleteAlert: false })}
      />
    );
  }
}