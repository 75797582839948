import * as React from 'react';
import DropDownBox from 'devextreme-react/drop-down-box';
import TreeView from 'devextreme-react/tree-view';
interface Props {
    label?: string;
    stylingMode: string | any;
    placeholder?: string | any;
    displayExpr: string | any;
    valueExpr?: string | any
    grouped?: Boolean | any;
    selectedItem: any;
    showClearButton?: Boolean | any;
    readOnly?: Boolean | any;
    disabled?: Boolean | any;
    onChange: any;
    required?: any;
    items: any;
    spellcheck?: Boolean | any;
}
export default class MultiSelectTreeView extends React.Component<Props, any> {
    public treeView: any;
    constructor(props: Props) {
        super(props);

        this.treeView = null;

        this.treeViewItemSelectionChanged = this.treeViewItemSelectionChanged.bind(this);
        this.syncTreeViewSelection = this.syncTreeViewSelection.bind(this);
        this.treeViewRender = this.treeViewRender.bind(this);

        this.state = {
            items: this.props.items,
            loading: true
        }

    }
    public afterInit() {
        this.setState({
            items: this.props.items
        });
    }
    public afterInitValue() {

        this.setState({
            selectedItem: this.props.selectedItem
        });

    }
    public render(): React.ReactElement<any> {
        if ((this.props.items && !this.state.items) || ((this.props.items && this.props.items.length) !== (this.state.items && this.state.items.length))) {

            this.afterInit();
        }
        if ((this.props.selectedItem && !this.state.selectedItem) || ((this.props.selectedItem && this.props.selectedItem.length) !== (this.state.selectedItem && this.state.selectedItem.length))) {

            this.afterInitValue()
        }
        return (

            <DropDownBox
                label={this.props.label}
                labelMode={'floating'}
                disabled={this.props.disabled}
                displayExpr={this.props.displayExpr}
                valueExpr={this.props.valueExpr}
                placeholder={this.props.placeholder}
                showClearButton={this.props.showClearButton}
                dataSource={this.state.items}
                value={this.state.selectedItem}

                onValueChanged={this.syncTreeViewSelection}
                contentRender={this.treeViewRender}
            />
        );
    }

    treeViewRender() {
        return (
            <TreeView dataSource={this.state.items}
                ref={(ref) => { this.treeView = ref; }}
                dataStructure="plain"
                displayExpr={this.props.displayExpr}
                keyExpr={this.props.valueExpr}
                selectionMode="multiple"
                showCheckBoxesMode={this.state.items.length > 0 ? "selectAll" : "normal"}
                selectNodesRecursive={true}
                //selectNodesRecursive={false}
                searchEnabled={true}
                searchExpr={"text"}
                searchMode='contains'
                selectByClick={true}
                onContentReady={this.syncTreeViewSelection}
                onSelectionChanged={this.treeViewItemSelectionChanged}
            />
        );
    }

    syncTreeViewSelection(e: any) {
        const treeView = (e.component.selectItem && e.component)
            || (this.treeView && this.treeView.instance);

        if (treeView) {
            if (e.value === null || e.value === undefined) {
                this.setState({
                    selectedItem: e.value,
                });
                treeView.unselectAll();

            } else {
                const values = e.value || this.state.treeBoxValue;
                values && values.forEach((value: any) => {
                    treeView.selectItem(value);
                });
            }
        }

        if (e.value !== undefined || e.value === null) {
            this.setState({
                selectedItem: e.value,
            });
            let gridBoxValue = e.value || []
            this.props.onChange(gridBoxValue)
        }
    }
    treeViewItemSelectionChanged(e: any) {
        this.setState({
            selectedItem: e.component.getSelectedNodeKeys(),
        });
    }
}





