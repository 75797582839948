import React from 'react';
import { HashRouter, Route } from 'react-router-dom';
import { CacheSwitch } from 'react-router-cache-route'
import AtenLogo from './images/AtenLogo.png';
import AtenLogoLight from './images/AtenLogoLight.png';
import global from './classes/global';
import Home from './components/Home/Home';
import SearchTimesheet from './components/SearchTimesheet/SearchTimesheet';
import SignInOld from './components/SignIn/SignIn-old';
import Navigation from './components/Navigation/Navigation';
import Scheduler from './components/Scheduler/Scheduler';
import Profile from './components/Profile/Profile';
import Dashboard from './components/Dashboard/Dashboard';
import AccessDenied from './components/AccessDenied/AccessDenied';
import AccessRequest from './components/AccessDenied/AccessRequest';
import CreateWO from './components/CreateWorkOrder/CreateWO';
import SearchMaterials from './components/MaterialSearch/MaterialSearch';
import PrestartChecklist from './components/PrestartChecklist/PrestartChecklist';
import InspectionForm from './components/InspectionForm/InspectionForm';
import SearchEquipment from './components/SearchEquipment/Equipmentsearch';
import EquipmentsInspection from "./components/EquipmentsInspection/EquipmentsInspection";
import Searchworkorder from './components/Searchworkorder/Searchworkorder';
import Assetsearch from './components/AssetSearch/AssetSearch';
import SearchWODetail from './components/SearchWODetail/SearchWODetail';
import AssetDetail from "./components/SearchAssetDetail/SearchAssetDetail";
import WRDetail from "./components/SearchWorkRequestDetail/SearchWorkRequestDetail";
import SearchPage from "./components/SearchPage/SearchPage";
import CreateScheduleWork from "./components/ScheduledWork/CreateScheduleWork";
import SearchScheduleWork from "./components/ScheduledWork/SearchScheduleWork";
import EditScheduleWork from "./components/ScheduledWork/EditScheduleWork";
import NotFound from "./components/NotFound/NotFound";
import SearchWorkRequest from "./components/SearchWorkRequest/SearchWorkRequest";
import TimesheetExport from "./components/Administrator/WeeklyTimesheet/WeeklyTimesheet";
import FailedWorkorders from "./components/Administrator/FailedWorkorders/FailedWorkorders";
import MobileAppIssues from "./components/Administrator/MobileAppIssues/MobileAppIssues";

// admin pages routing
import AdminHomePage from './components/Administrator/AdminHome/AdminHome';
import AdminListBuilder from './components/Administrator/AdminListBuilder/AdminListBuilder';

import Employees from './components/Administrator/Employee/Employee';
import Crews from './components/Administrator/Crew/Crew';
import Contractors from './components/Administrator/Contractors/Contractors';
import Departments from './components/Administrator/Department/Department';
import Teams from './components/Administrator/Teams/Teams';
import Roles from './components/Administrator/Role/Role';
import Activities from './components/Administrator/Activities/Activities';
import WorkRequestActivities from './components/Administrator/WorkRequestActivities/WorkRequestActivities';
import Equipment from './components/Administrator/Equipment/Equipment';
import Suburbs from './components/Administrator/Suburbs/Suburbs';
import Status from './components/Administrator/Status/Status';
import Priority from './components/Administrator/Priority/Priority';
import Forms from "./components/Administrator/Forms/Forms";
import PrestartForms from "./components/Administrator/PrestartForms/PrestartForms";
import Materials from "./components/Administrator/Materials/Materials";

import Swms from "./components/Administrator/SWMS/swms";
import Tcp from "./components/Administrator/TCP/tcp";
import WorkTypeField from "./components/Administrator/WorkTypeFields/WorkTypeField";
import DefectCode from "./components/Administrator/DefectCodes/DefectCode";
import DefectQuestion from "./components/Administrator/DefectQuestions/DefectQuestion";
import AssetQuestion from "./components/Administrator/AssetQuestions/AssetQuestion";
import RiskCategory from "./components/Administrator/RiskCategories/Riskcategory";
import RiskForm from "./components/Administrator/RiskForms/RiskForm";
import RiskGroup from "./components/Administrator/RiskGroup/RiskGroup";
import ShowListItems from "./components/Administrator/RenderListItems/ShowListItems";
import RiskControlMeasure from "./components/Administrator/RiskControlMeasure/RiskControlMeasure";
import Counters from "./components/Administrator/Counter/Counter";
import Risks from "./components/Administrator/Risks/Risk";
import ClearStorage from './components/Administrator/ClearStorage/ClearStorage';
import OnHoldReasons from "./components/Administrator/OnHoldReasons/OnHoldReason";
import Suppliers from "./components/Administrator/Suppliers/Suppliers";
import IntegrationStatus from "./components/Administrator/IntegrationStatus/IntegrationStatus";
import EventsAndCapitalWorks from "./components/Administrator/Events/EventsAndCapitalWorks";
import LoginDetails from "./components/Administrator/LoginDetails/LoginDetails";
import WeeklyTimesheet from './components/Administrator/WeeklyTimesheet/WeeklyTimesheet';
import UnitRates from './components/Administrator/UnitRates/UnitRates';
const WorxOnline = (homeprops: any) => {
  const Theme = localStorage.getItem('theme') || 'theme-light';
  let gridWidth = (window.innerWidth - (73 + 15)) + 'px';
  if (window.document.getElementsByClassName('MuiDrawer-paper').length > 0) {

    var drawerWidth = window.document.getElementsByClassName('MuiDrawer-paper')[0].clientWidth;

    gridWidth = (window.innerWidth - (drawerWidth + 15)) + 'px';
  }

  return (
    <HashRouter>
      <React.Fragment>
        <CacheSwitch>
          <Route exact path="/SignInOld" render={(props: any) => (<SignInOld {...props} {...homeprops} />)} />
          <Route exact path="/" render={(props: any) => (<Home {...props} {...homeprops} />)} />
          <Route exact path="/AccessRequest" render={(props: any) => (<AccessRequest {...props} {...homeprops} />)} />
          <Route exact path="/AccessDenied" render={(props: any) => (<AccessDenied {...props} {...homeprops} />)} />
          <Route>
            <div className="maindiv">
              <div className={'displayFlex'}>
                <Navigation
                  hideDrawer={false}
                  role={homeprops.role}
                  userId={homeprops.userId}
                />

                <main className="content" style={{ width: gridWidth }}>
                  <div className={"toolbar"}>
                    {/* Main page routing */}
                    <React.Fragment>
                      <CacheSwitch>
                        <Route exact path="/SearchTimesheet" render={(props: any) => (<SearchTimesheet {...props} {...homeprops} />)} />
                        <Route exact path="/Scheduler" render={(props: any) => (<Scheduler {...props} {...homeprops} />)} />
                        <Route exact path="/Profile" render={(props: any) => (<Profile {...props} {...homeprops} />)} />
                        <Route exact path="/Dashboard" render={(props: any) => (<Dashboard {...props} {...homeprops} />)} />
                        <Route exact path="/CreateWO" render={(props: any) => (<CreateWO {...props} {...homeprops} />)} />
                        <Route exact path="/SearchMaterials" render={(props: any) => (<SearchMaterials {...props} {...homeprops} />)} />
                        <Route exact path="/PrestartChecklist" render={(props: any) => (<PrestartChecklist {...props} {...homeprops} />)} />
                        <Route exact path="/ClearStorage" render={(routeProps) => (<ClearStorage {...routeProps} {...homeprops} />)} />
                        <Route exact path="/InspectionForm" render={(props: any) => (<InspectionForm {...props} {...homeprops} />)} />
                        <Route exact path="/SearchEquipment" render={(props: any) => (<SearchEquipment {...props} {...homeprops} />)} />
                        <Route exact path="/Searchworkorder" render={(props: any) => (<Searchworkorder {...props} {...homeprops} />)} />
                        <Route exact path="/Assetsearch" render={(props: any) => (<Assetsearch {...props} {...homeprops} />)} />
                        <Route exact path="/WorkOrderDetail" render={(props: any) => (<SearchWODetail {...props} {...homeprops} />)} />
                        <Route exact path="/AssetDetail" render={(props: any) => (<AssetDetail {...props} {...homeprops} />)} />
                        <Route exact path="/WRDetail" render={(props: any) => (<WRDetail {...props} {...homeprops} />)} />
                        <Route exact path="/SearchPage" render={(props: any) => (<SearchPage {...props} {...homeprops} />)} />
                        <Route exact path="/CreateScheduleWork" render={(props: any) => (<CreateScheduleWork {...props} {...homeprops} />)} />
                        <Route exact path="/EditScheduleWork" render={(props: any) => (<EditScheduleWork {...props} {...homeprops} />)} />
                        <Route exact path="/SearchScheduleWork" render={(props: any) => (<SearchScheduleWork {...props} {...homeprops} />)} />
                        <Route exact path="/EquipmentsInspection" render={(props: any) => (<EquipmentsInspection {...props} {...homeprops} />)} />



                        {/* Admin Pages Routing */}
                        <Route exact path="/AdminListBuilder" render={(props: any) => (<AdminListBuilder {...props} {...homeprops} />)} />
                        <Route exact path="/AdminHomePage" render={(props: any) => (<AdminHomePage {...props} {...homeprops} />)} />
                        <Route exact path="/Employees" render={(props: any) => (<Employees {...props} {...homeprops} />)} />
                        <Route exact path="/Crews" render={(props: any) => (<Crews {...props} {...homeprops} />)} />
                        <Route exact path="/Departments" render={(props: any) => (<Departments {...props} {...homeprops} />)} />
                        <Route exact path="/Contractors" render={(props: any) => (<Contractors {...props} {...homeprops} />)} />
                        <Route exact path="/Teams" render={(props: any) => (<Teams {...props} {...homeprops} />)} />
                        <Route exact path="/Roles" render={(props: any) => (<Roles {...props} {...homeprops} />)} />
                        <Route exact path="/Activities" render={(props: any) => (<Activities {...props} {...homeprops} />)} />
                        <Route exact path="/WorkRequestActivities" render={(props: any) => (<WorkRequestActivities {...props} {...homeprops} />)} />
                        <Route exact path="/Equipment" render={(props: any) => (<Equipment {...props} {...homeprops} />)} />
                        <Route exact path="/EventsAndCapitalWorks" render={(props: any) => (<EventsAndCapitalWorks {...props} {...homeprops} />)} />

                        <Route exact path="/Suburbs" render={(props: any) => (<Suburbs {...props} {...homeprops} />)} />
                        <Route exact path="/Status" render={(props: any) => (<Status {...props} {...homeprops} />)} />
                        <Route exact path="/Priority" render={(props: any) => (<Priority {...props} {...homeprops} />)} />
                        <Route exact path="/Forms" render={(props: any) => (<Forms {...props} {...homeprops} />)} />
                        <Route exact path="/Materials" render={(props: any) => (<Materials {...props} {...homeprops} />)} />
                        <Route exact path="/PrestartForms" render={(props: any) => (<PrestartForms {...props} {...homeprops} />)} />
                        <Route exact path="/Tcp" render={(props: any) => (<Tcp {...props} {...homeprops} />)} />
                        <Route exact path="/Swms" render={(props: any) => (<Swms {...props} {...homeprops} />)} />
                        <Route exact path="/Counters" render={(props: any) => (<Counters {...props} {...homeprops} />)} />
                        {/* <Route exact path="/ClearStorage" render={(props) => (<ClearStorage {...props} {...homeprops} />)} /> */}
                        <Route exact path="/WorkTypeField" render={(props: any) => (<WorkTypeField {...props} {...homeprops} />)} />
                        <Route exact path="/Suppliers" render={(props: any) => (<Suppliers {...props} {...homeprops} />)} />
                        <Route exact path="/OnHoldReasons" render={(props: any) => (<OnHoldReasons {...props} {...homeprops} />)} />
                        <Route exact path="/DefectCode" render={(props: any) => (<DefectCode {...props} {...homeprops} />)} />
                        <Route exact path="/DefectQuestion" render={(props: any) => (<DefectQuestion {...props} {...homeprops} />)} />
                        <Route exact path="/AssetQuestion" render={(props: any) => (<AssetQuestion {...props} {...homeprops} />)} />
                        <Route exact path="/ShowListItems" render={(props: any) => (<ShowListItems {...props} {...homeprops} />)} />
                        <Route exact path="/IntegrationStatus" render={(props: any) => (<IntegrationStatus {...props} {...homeprops} />)} />
                        <Route exact path="/SearchWorkRequest" render={(props: any) => (<SearchWorkRequest {...props} {...homeprops} />)} />
                        <Route exact path="/RiskGroup" render={(props: any) => (<RiskGroup {...props} {...homeprops} />)} />
                        <Route exact path="/RiskCategory" render={(props: any) => (<RiskCategory {...props} {...homeprops} />)} />
                        <Route exact path="/RiskForm" render={(props: any) => (<RiskForm {...props} {...homeprops} />)} />
                        <Route exact path="/Risks" render={(props: any) => (<Risks {...props} {...homeprops} />)} />
                        <Route exact path="/LoginDetails" render={(props: any) => (<LoginDetails {...props} {...homeprops} />)} />
                        <Route exact path="/RiskControlMeasure" render={(props: any) => (<RiskControlMeasure {...props} {...homeprops} />)} />
                        <Route exact path="/TimesheetExport" render={(props: any) => (<WeeklyTimesheet {...props} {...homeprops} />)} />
                        <Route exact path="/UnitRates" render={(props: any) => (<UnitRates {...props} {...homeprops} />)} />
                        <Route exact path="/FailedWorkorders" render={(props: any) => (<FailedWorkorders {...props} {...homeprops} />)} />
                        <Route exact path="/MobileAppIssues" render={(props: any) => (<MobileAppIssues {...props} {...homeprops} />)} />

                        <Route path='*' component={NotFound} />
                      </CacheSwitch>
                    </React.Fragment>
                  </div>
                  <div className="footerlogo">
                    <div className="atenLogoContainer">
                      <h4 className="poweredBy">Powered By </h4>
                      <img className="atenLogo" src={Theme === 'theme-light' ? AtenLogoLight : AtenLogo} alt="" />
                      <b><h4 className="poweredBy">Version - {global.version} </h4></b>
                    </div>
                  </div>
                </main>
              </div>
            </div>
          </Route>

        </CacheSwitch>
      </React.Fragment>
    </HashRouter>
  );

}
export default WorxOnline;