import * as React from 'react';
import utility from '../../classes/utility';
import { Config } from '../../classes/config';
import AlertDialog from '../common/alertDialog';
import TextAreaInput from '../common/reusableComponents/TextArea';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
export default class RejectWR extends React.Component<any, any> {

    constructor(props: any) {
        super(props);

        this.clearFilter = this.clearFilter.bind(this);
        this.RejectWR = this.RejectWR.bind(this);
        this.state = {
            comments: ''
        };
    }
    public didMount() {
    }

    public RejectWR() {
        if (!this.state.comments) {
            this.setState({
                alertMessage: 'Please select comments',
                showAlert: true
            });
            return;
        }
        let dataToPassToService: any = {};
        dataToPassToService.id = this.props.WRId;
        dataToPassToService.comment = this.state.comments;
        utility.genericUpdatePortalAPICall(Config.RejectWR, dataToPassToService).then((data: any) => {
            console.log("success");
            this.props.onClose();
        });
    }

    public clearFilter() {
        this.setState({ comments: '' });

    }
    public _textAreaChanged = (e: any, fieldName: string) => {

        this.setState({ [fieldName]: e.value });
    }

    private alert() {
        return (
            <AlertDialog
                showDialog={this.state.showAlert}
                message={this.state.alertMessage}
                title={Config.productTitle}
                onClose={() => this.setState({ alertMessage: '', showAlert: false })}
                hideAlertButton={this.state.hideAlertButton}
            />
        );
    }

    public render(): React.ReactElement<any> {
        return (
            <div>
                {this.state.showAlert && this.alert()}
                < div>
                    <div className={'headingDiv'}>
                        <span className={"heading"}> Reject Work Request</span>
                    </div>
                </div>
                <div className={"searchFieldDiv"}>
                    <Grid container spacing={3}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={12}>
                                <TextAreaInput
                                    value={this.state.comments}
                                    label="Comment"
                                    onValueChanged={(e: any) => this._textAreaChanged(e, 'comments')}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={12}>
                            <div className={"searchButtonDiv"}>
                                <Button onClick={() => this.clearFilter()} variant='outlined'>
                                    Clear
                                </Button>
                                <Button onClick={() => this.RejectWR()} variant='outlined' color="primary" className={'button'}>
                                    Reject
                                </Button>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </div >

        );
    }
}

