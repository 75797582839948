import React from 'react';
import { Button } from 'devextreme-react/button';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Grid from '@material-ui/core/Grid';
import logowo from '../../images/logowo.png';
import dashboard from '../../images/dashboardHome.png';
import LPHeroImg from '../../images/LPHeroImg.png';
import cwLogo from '../../images/cw_logo_colour.png';
import AtenLogo from '../../images/AtenLogo.png';
import AtenLogoLight from '../../images/AtenLogoLight.png';
import { loginRequest } from "../../classes/authConfig";
// const useStyles = makeStyles((theme: Theme) =>
//   createStyles({
//     root: {
//       display: 'flex',
//     },
//     appBar: {
//       zIndex: theme.zIndex.drawer + 1,
//       transition: theme.transitions.create(['width', 'margin'], {
//         easing: theme.transitions.easing.sharp,
//         duration: theme.transitions.duration.leavingScreen,
//       }),
//     },
//     text: {
//       padding: theme.spacing(2, 2, 0),
//     },
//     paper: {
//       paddingBottom: 50,
//     },
//     list: {
//       marginBottom: theme.spacing(2),
//     },
//     subheader: {
//       backgroundColor: theme.palette.background.paper,
//     },
//     grow: {
//       flexGrow: 1,
//     },
//     fabButton: {
//       position: 'absolute',
//       zIndex: 1,
//       top: -30,
//       left: 0,
//       right: 0,
//       margin: '0 auto',
//     },

//     menuButton: {
//       marginRight: 36,
//       display: 'block',
//       // [theme.breakpoints.down('md')]: {
//       //   display: 'none',
//       // },
//     },
//     hide: {
//       display: 'none',
//     },

//     toolbar: {
//       display: 'flex',
//       alignItems: 'center',
//       justifyContent: 'flex-end',
//       padding: theme.spacing(0, 1),
//       // necessary for content to be below app bar
//       ...theme.mixins.toolbar,
//     },
//     content: {
//       flexGrow: 1,
//       padding: theme.spacing(3),
//     },
//     iconButton: {
//       padding: 10,
//       color: 'white',
//     },
//     divider: {
//       height: 28,
//       margin: 4,
//     },
//     title: {
//       display: 'none',
//       marginTop: theme.spacing(1),
//       [theme.breakpoints.up('sm')]: {
//         display: 'block',
//         width: '100px',
//       },
//     },
//   }),
// );

const SignIn = (props: any) => {
  const PageTheme: any = localStorage.getItem('theme') || 'theme-light';

  const signInUser = async (props: any) => {
    clearStorage();
    props.instance.loginRedirect(loginRequest);

    // let link = `https://worxonlineaad.b2clogin.com/worxonlineaad.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1_SignupAndSignin&client_id=366c279e-70e4-4f7d-9a5e-88f52520a1bd&nonce=defaultNonce&redirect_uri=https%3A%2F%2Fstruatworxonline.z8.web.core.windows.net%2F&scope=openid&response_type=id_token&prompt=login`;
    // window.open(link, '_self')
  }

  const clearStorage = () => {
    localStorage.clear();
    var indexedDB = window.indexedDB;
    // Open (or create) the database

    indexedDB.deleteDatabase("coa-worxOnline");

  }

  return (
    <div>
      <AppBar position="fixed" className="appBar">
        <Toolbar>
          <Grid container>
            <Grid item xs={2} sm={2}>
              <div className={'logoDiv'}>
                <img src={logowo} className="logowo" alt="" />
              </div>
            </Grid>
            <Grid item xs={8} sm={10} className={'alignRight'} style={{ marginTop: '8px' }}>

              {/* <Button variant="contained" className={''} onClick={() => props.instance.loginRedirect(loginRequest)}>
                Sign In
          </Button> */}
              <Button
                width={120}
                text="Sign In"
                type="success"
                stylingMode="contained"
                // onClick={() => props.instance.loginRedirect(loginRequest)}
                onClick={() => signInUser(props)}
                className='signin'
              />
            </Grid>


          </Grid>
        </Toolbar>
      </AppBar>
      <div className={"LPHeroTop"}>
        <Grid container spacing={3}>

          <Grid item xs={12} sm={12} md={6}>
            <div className="LPHeroImgDivTop">
              <img src={dashboard} alt='' className="LPHeroImgTop" />
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <div className="LPHeroContentTop">
              <img src={cwLogo} alt='' className="CWLogoHome" />
              <hr />
              <div className='LPHeroContentTopTitle'>Welcome to <br /> <strong>worxOnline</strong></div>
              <h2>Powered by Aten Systems </h2>
              <p>
                An agile works management platform to enable faster processing of reactive or proactive capital works as well as timely maintenance of assets.
              </p>
              {/* <p>The application is authenticated with Azure AD. The council staff can sign in with their councils' Azure Active Directory credentials and can access their organization's work order related information in a secure manner. The council system administrators can assign various application roles to its member staff.</p> */}
            </div>
          </Grid>
        </Grid>
      </div>
      <div className={"LPHero"}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={6}>
            <div className="LPHeroImgDiv">
              <img src={LPHeroImg} alt='' className="LPHeroImg" />
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <div className="LPHeroContent">
              <h1>worxOnline provides a dedicated
                platform agnostic mobile/tablet app for
                the field crew.</h1>
              <p> All work order related
                information including service request
                details, risk assessment, WHS and other
                information is available through a secured
                mobile API together with caching and
                offline capability.</p>
              <h3>Other functionality includes:</h3>

              <div>Faster and efficient works reporting</div>
              <div>Plant and Fleet specific checklist</div>
              <div>Crew management</div>
              <div>Automatic time & material calculation</div>
              <div>Inspection forms</div>
              <div>Traffic control plans</div>
              <div>Digital signature capture</div>

            </div>
          </Grid>
        </Grid>
      </div>
      <div>

        <div className="footerlogo">
          <div className="atenLogoContainer">
            <h4 className="poweredBy">Powered By </h4>
            <img className="atenLogo" src={PageTheme === 'theme-light' ? AtenLogoLight : AtenLogo} alt='' />
          </div>
        </div>
      </div>
    </div>
  );

}
export default SignIn;