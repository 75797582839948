import './App.css';
import './css/global.css';
import './css/dropzone.css';
import './css/home.css';
import './css/NotFound.css';
import 'devextreme/dist/css/dx.common.css';
import { MsalProvider } from "@azure/msal-react";
import HomePage from './Home';

if (localStorage.getItem('theme') === null) {
  document.documentElement.className = 'theme-light';
} else {
  let theme = localStorage.getItem('theme')
  document.documentElement.className = theme;
}

export default function App({ msalInstance, loginTime}) {

  return (
    <MsalProvider instance={msalInstance}>
      <HomePage loginTime={loginTime}/>
    </MsalProvider>

  );
}
