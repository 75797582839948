import * as React from 'react';
import utility from '../../../classes/utility';
import ClearStorageService from './../../../ClearStorageService';
import { Config } from '../../../classes/config';
import Grid from '@material-ui/core/Grid';
import ScrollView from 'devextreme-react/scroll-view';
import AlertDialog from '../../common/alertDialog';
import Spinner from '../../common/reusableComponents/Spinner';
import TextBoxInput from '../../common/reusableComponents/TextBox';
import CheckBoxInput from '../../common/reusableComponents/CheckBox';
import SelectBoxInput from '../../common/reusableComponents/SelectBox';
import capitalize from '@material-ui/core/utils/capitalize';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import global from '../../../classes/global';

const UserTypeItems: any = [
    { id: 'Portal', text: 'Portal' },
    { id: 'App', text: 'App' },
    { id: 'Portal and App', text: 'Portal and App' },
];
export default class EditEmployee extends React.Component<any, any> {
    ClearStorageService = new ClearStorageService();
    public departmentItems: any = [];
    public crewItems: any = [];
    public teamsItems: any = [];
    public userRoleItems: any = [];
    constructor(props: any) {
        super(props);

        this.saveData = this.saveData.bind(this);
        this.clearFilter = this.clearFilter.bind(this);
        this.crewItems = [];
        this.departmentItems = [];
        this.teamsItems = [];
        this.state = {
            // disableTeam: true,
            // disableCrew: true
            disableUserrole: true,
            isActive: false,
            isPlantOperator: false,

        };
    }

    public async didMount() {
        await Promise.all([
            this._getDepartmentData(),
            this._getTeamDataAsync(),
            this._getCrewDataAsync(),
            this._getUserRoleData()
        ]);
        if (this.props.details && this.props.mode === 'edit') {
            this.setState({ loading: true })
            this.populateValue(this.props.details, this.props.mode);
        } else {
            this.setState({
                disableTeam: true,
                disableCrew: true,
                isRequired: false,
                hideAlertButton: false,
                mode: this.props.mode
            })
        }

    }
    public populateValue(details: any, mode?: any) {

        this._getUserRoleData();
        this.setState({
            id: details.id,
            title: details.title,
            firstName: details.firstName,
            lastName: details.lastName,
            loginName: details.loginName,
            employeeNo: details.employeeno,
            email: details.email,
            phone: details.phone,
            JobTitle: details.jobTitle,
            pin: details.pin,
            isActive: details.active,
            isPlantOperator: details.isPlantOperator,
            isContractor: details.contractor,
            created: details.createdOn,
            createdBy: details.createdBy,
            department: details.department ? { id: details.departmentid, text: details.department } : '',
            userType: details.userType ? { id: details.userType, text: details.userType } : '',
            previuosuserRoleId: this.props.details.roleId,
            hideAlertButton: false,
            mode: mode,
            showRole: details.userType !== "App" ? true : false
        });

        let { departmentid, teamId } = details;

        let filteredTeam = this.state.teamsItems && this.state.teamsItems.filter((t: any) => t.departmentId === departmentid);
        let team: any = '';
        if (filteredTeam && filteredTeam.length > 0) {
            let filteredTeams = filteredTeam.filter((t: any) => t.id === teamId);
            if (filteredTeams && filteredTeams.length > 0) {
                team = { id: filteredTeams[0].id, text: filteredTeams[0].text };
            }
        }

        let filteredCrew = this.state.crewItems && this.state.crewItems.filter((c: any) => c.teamId === teamId && c.departmentId === departmentid);

        let crew: any = '';
        if (filteredCrew && filteredCrew.length > 0) {
            let selectedcrew = filteredCrew && filteredCrew.filter((c: any) => c.id === details.crewId && c.text === details.crewName);
            if (selectedcrew.length > 0) {
                crew = { id: selectedcrew[0].id, text: selectedcrew[0].text };
            }

        }


        this.setState({
            disableTeam: false,
            disableCrew: false,
            tempTeamItems: filteredTeam,
            tempCrewItems: filteredCrew,
            team: team ? team : '',
            crew: crew ? crew : '',
        });
        setTimeout(() => this.setState({ loading: false }), 1000);
    }
    private async _getUserRoleData() {
        try {
            const data: any = await utility.genericGetAPICall(`${Config.roleList}?showAll=true`);

            this.userRoleItems = [];

            for (let r of data) {
                this.userRoleItems.push({ id: (r.id), text: (r.title) });
            }
            const sortedItems = utility._sortItems(this.userRoleItems, 'text', false);
            if (this.props.details && this.props.details.roleId) {
                this.setState({
                    userRole: sortedItems.find((user: any) => user.id === this.props.details.roleId)
                });
            }
            this.setState({ userRoleItems: sortedItems });
        } catch (err) {
            console.log(err);
        }
    }
    private async _getCrewDataAsync() {
        try {
            const data: any = await utility.genericGetAPICall(`${Config.crewswithdepartment}?showAll=true`);

            this.crewItems = [];

            data.filter((r: any) => r.isActive === true).map((r: any) => {
                (
                    this.crewItems.push({ id: (r.id), text: r.title, title: (r.title), teamId: r.teamId, departmentId: r.departmentId })
                )
            }
            );
            const sortedItems = utility._sortItems(this.crewItems, 'text', false);
            this.setState({ crewItems: sortedItems, tempCrewItems: sortedItems });
        } catch (err) {
            console.log(err);
        }
    }
    private async _getDepartmentData() {
        try {
            const data: any = await utility.genericGetAPICall(`${Config.DepartmentList}?showAll=true`);
            this.departmentItems = [];

            data.filter((r: any) => r.isActive === true).map((r: any) => {
                (
                    this.departmentItems.push({ id: r.id, text: r.title })
                )
            }
            );

            const sortedItems = utility._sortItems(this.departmentItems, 'text', false);
            this.setState({
                departmentItems: sortedItems,
                alldepartmentItems: sortedItems,
            });
        } catch (err) {
            console.log(err);
        }
    }
    private async _getTeamDataAsync() {
        try {
            const data: any = await utility.genericGetAPICall(`${Config.TeamList}?showAll=true`);
            this.teamsItems = [];

            data.filter((r: any) => r.isActive === true).map((r: any) => {
                (
                    this.teamsItems.push({ id: r.id, text: r.title, departmentId: r.departmentId })
                )
            }
            );
            const sortedItems = utility._sortItems(this.teamsItems, 'text', false);
            this.setState({ teamsItems: sortedItems, tempTeamItems: sortedItems });
        } catch (err) {
            console.log(err);
        }
    }

    public saveData() {
        let dataToPassToService: any = {};

        if (!this.state.firstName) {
            this.setState({
                alertMessage: 'Please enter first name',
                showAlert: true
            });
            return;
        }
        if (!this.state.lastName) {
            this.setState({
                alertMessage: 'Please enter last name',
                showAlert: true
            });
            return;
        }
        if (!this.state.employeeNo) {
            this.setState({
                alertMessage: 'Please enter  user code',
                showAlert: true
            });
            return;
        } if (!this.state.loginName) {
            this.setState({
                alertMessage: 'Please enter login name',
                showAlert: true
            });
            return;
        } if (!this.state.email) {
            this.setState({
                alertMessage: 'Please enter email',
                showAlert: true
            });
            return;
        }

        if (!this.state.department) {
            this.setState({
                alertMessage: 'Please select Department',
                showAlert: true
            });
            return;
        }
        if (!this.state.userType) {
            this.setState({
                alertMessage: 'Please select User Type',
                showAlert: true
            });
            return;
        }
        if (this.state.userType && this.state.userType.id !== 'Portal') {
            if (!this.state.crew) {
                this.setState({
                    alertMessage: 'Please select Crew',
                    showAlert: true
                });
                return;
            }
        }

        this.setState({
            alertMessage: <div className={'alertLoader'}><Spinner size='large' /> <br /> Loading...</div>,
            hideAlertButton: true,
            showAlert: true
        });
        dataToPassToService.fullName = this.state.firstName + ' ' + this.state.lastName;
        dataToPassToService.firstName = this.state.firstName;
        dataToPassToService.lastName = this.state.lastName;
        dataToPassToService.resourceCode = this.state.employeeNo;
        dataToPassToService.loginName = this.state.loginName;
        dataToPassToService.email = this.state.email;
        dataToPassToService.phone = this.state.phone;
        dataToPassToService.jobTitle = this.state.JobTitle;
        dataToPassToService.roleId = this.state.userRole.id;
        dataToPassToService.departmentId = this.state.department.id;
        dataToPassToService.crewId = this.state.crew ? this.state.crew.id : null;
        dataToPassToService.teamId = this.state.team ? this.state.team.id : null;
        dataToPassToService.pin = this.state.pin ? this.state.pin : '';
        dataToPassToService.userType = this.state.userType.id;
        dataToPassToService.isActive = this.state.isActive ? this.state.isActive : false;
        dataToPassToService.isContractor = this.state.isContractor ? this.state.isContractor : false;
        dataToPassToService.tenantId = Config.tenantId;
        dataToPassToService.isPlantOperator = this.state.isPlantOperator ? this.state.isPlantOperator : false;

        if (this.props.mode === 'edit') {
            dataToPassToService.id = this.state.id;
            dataToPassToService.updatedBy = this.props.currentUser.Email;
            dataToPassToService.updated = new Date();
            utility.genericUpdateAPICall(Config.UpdateEmployeeAPI, this.state.id, dataToPassToService).then((data: any) => {
                this.props.onClick();
                this.props.message("User has been updated");
                this.clearFilter();
                this.setState({ showAlert: false, hideAlertButton: false, });
                this.ClearStorageService.clearStorage();
            }, (err: any) => {
                console.log(err);
                this.setState({
                    alertMessage: 'Something went wrong!',
                    hideAlertButton: false,
                    showAlert: true
                });

            });
        } else {
            dataToPassToService.createdBy = this.props.currentUser.Email;
            dataToPassToService.created = new Date();
            utility.genericPostAPIcall(Config.CreateEmployeeAPI, dataToPassToService).then((data: any) => {
                this.props.onClick();
                this.props.message("User has been created");
                this.clearFilter();
                this.setState({ hideAlertButton: false, showAlert: false });
                this.ClearStorageService.clearStorage();
            }, (err: any) => {
                // console.log(err);
                console.log(err);
                this.setState({
                    alertMessage: 'Something went wrong!',
                    hideAlertButton: false,
                    showAlert: true
                });
            });
        }
    }

    public clearFilter() {
        this.setState({
            firstName: '',
            lastName: '',
            employeeNo: '',
            loginName: '',
            email: '',
            department: '',
            pin: '',
            userType: '',
            phone: '',
            team: '',
            crew: '',
            JobTitle: '',
            wbsGroup: '',
            isPlantOperator: false,
            userRole: ''
        });
        this.props.onClose();
    }
    private alert() {
        return (
            <AlertDialog
                showDialog={this.state.showAlert}
                message={this.state.alertMessage}
                title={Config.productTitle}
                onClose={() => this.setState({ alertMessage: '', showAlert: false })}
                hideAlertButton={this.state.hideAlertButton}
            />
        );
    }
    public handleDropdownChange(e: any, fieldName: string) {
        //this.setState({ loading: true })
        if (fieldName === "userType") {
            if (e.selectedItem.id === "Portal") {
                this.setState({
                    isReqired: false
                })
            } else {
                this.setState({
                    isReqired: true
                })
            }
            if (e.selectedItem && (e.selectedItem.id === "Portal" || e.selectedItem.id === "Portal and App")) {
                this.setState({
                    showRole: true
                })
            } else {
                this.setState({
                    showRole: false
                })
            }
            setTimeout(() => this.setState({ loading: false }), 1000);
        }
        if (fieldName === 'department') {
            const { teamsItems } = this.state;
            this.setState({
                tempCrewItems: [],
                tempTeamItems: [],
                team: '',
                crew: ''
            });
            const filteredTeam = teamsItems.filter((t: any) => t.departmentId === e.selectedItem?.id);

            this.setState({
                tempTeamItems: filteredTeam,
                alltempTeamItems: filteredTeam
            });
            setTimeout(() => this.setState({ loading: false }), 1000);
        }
        if (fieldName === 'team') {
            const { crewItems, department } = this.state;
            this.setState({
                tempCrewItems: [],
                crew: ''
            });
            const filteredCrew = crewItems.filter((c: any) => c.teamId === e.selectedItem?.id && c.departmentId === department.id);

            this.setState({
                disableCrew: false,
                tempCrewItems: filteredCrew,
                alltempCrewItems: filteredCrew
            });
        }
        this.setState({ [fieldName]: e.selectedItem });

    }
    private handleValueChange = (event: any, fieldName: string) => {
        this.setState({ [fieldName]: event.value });

    }

    public render(): React.ReactElement<any> {

        return (
            <div>
                {this.state.showAlert && this.alert()}
                <Dialog
                    open={this.props.showModal}
                    onClose={() => this.clearFilter()}
                    fullWidth={true}
                    maxWidth={'lg'}
                    aria-labelledby="max-width-dialog-title"
                    onRendered={() => this.didMount()}
                >
                    <DialogTitle id="scroll-dialog-title">
                        {`${this.props.mode && capitalize(this.props.mode)} User`}
                    </DialogTitle>
                    <IconButton aria-label="close" onClick={() => this.clearFilter()} className="closeIcon">
                        <CloseIcon />
                    </IconButton>
                    <DialogContent dividers={true}>
                        <ScrollView width='100%' height='100%' showScrollbar='onScroll' scrollByThumb={true}>
                            <div className='popContentDiv'>
                                {!this.state.loading ?
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={6}>
                                            <TextBoxInput
                                                stylingMode='underlined'
                                                label="First Name *"
                                                // pattern={"[A-Za-z]+"}
                                                // minLength={2}
                                                value={this.state.firstName}
                                                onValueChanged={(e: any) => this.handleValueChange(e, 'firstName')}
                                                onKeyDown={() => { }}
                                                required={true}
                                            //disabled={true}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextBoxInput
                                                stylingMode='underlined'
                                                label="Last Name *"
                                                // pattern={"[A-Za-z]+"}
                                                // minLength={2}
                                                value={this.state.lastName}
                                                onValueChanged={(e: any) => this.handleValueChange(e, 'lastName')}
                                                onKeyDown={() => { }}
                                                required={true}
                                            //disabled={true}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <SelectBoxInput
                                                stylingMode='underlined'
                                                displayExpr="text"
                                                valueExpr="id"
                                                showClearButton={true}
                                                items={UserTypeItems}
                                                selectedItem={this.state.userType}
                                                label='User Type *'
                                                onSelectionChanged={(e: any) => this.handleDropdownChange(e, 'userType')}

                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextBoxInput
                                                stylingMode='underlined'
                                                label="User Code"
                                                // pattern={"[A-Za-z]+"}
                                                // minLength={2}
                                                value={this.state.employeeNo ? this.state.employeeNo : ''}
                                                onValueChanged={(e: any) => this.handleValueChange(e, 'employeeNo')}
                                                onKeyDown={() => { }}
                                                required={true}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextBoxInput
                                                stylingMode='underlined'
                                                label="Login Name*"
                                                // pattern={"[A-Za-z]+"}
                                                // minLength={2}
                                                value={this.state.loginName ? this.state.loginName : ''}
                                                onValueChanged={(e: any) => this.handleValueChange(e, 'loginName')}
                                                onKeyDown={() => { }}
                                                required={true}
                                            // disabled={true}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextBoxInput
                                                stylingMode='underlined'
                                                label="Email*"
                                                mode="email"
                                                // pattern={"[A-Za-z]+"}
                                                // minLength={2}
                                                value={this.state.email ? this.state.email : ''}
                                                onValueChanged={(e: any) => this.handleValueChange(e, 'email')}
                                                onKeyDown={() => { }}
                                                required={true}
                                            //disabled={true}
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={6}>
                                            <TextBoxInput
                                                stylingMode='underlined'
                                                label="Phone"
                                                // pattern={"[A-Za-z]+"}
                                                // minLength={2}
                                                value={this.state.phone ? this.state.phone : ''}
                                                onValueChanged={(e: any) => this.handleValueChange(e, 'phone')}
                                                onKeyDown={() => { }}
                                                required={false}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <SelectBoxInput
                                                stylingMode='underlined'
                                                displayExpr="text"
                                                valueExpr="id"
                                                showClearButton={true}
                                                items={this.state.departmentItems}
                                                selectedItem={this.state.department}
                                                label='Department *'
                                                onSelectionChanged={(e: any) => this.handleDropdownChange(e, 'department')}
                                            />
                                        </Grid>
                                        {/* {this.state.userType && this.state.userType.id !== 'Portal'?
                                <> */}
                                        <Grid item xs={12} sm={6}>
                                            <SelectBoxInput
                                                stylingMode='underlined'
                                                displayExpr="text"
                                                valueExpr="id"
                                                disabled={(this.state.disableTeam || this.state.userType) === "Portal" ? true : false}
                                                showClearButton={true}
                                                items={this.state.tempTeamItems}
                                                selectedItem={this.state.team}
                                                label='Teams *'
                                                onSelectionChanged={(e: any) => this.handleDropdownChange(e, 'team')}

                                            // disabled={this.props.role === global.worxOnline_Contractor ? true:this.state.disableTeam}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <SelectBoxInput
                                                stylingMode='underlined'
                                                displayExpr="text"
                                                valueExpr="id"
                                                showClearButton={true}
                                                items={this.state.tempCrewItems}
                                                disabled={(this.state.disableCrew || this.state.userType) === "Portal" ? true : false}
                                                selectedItem={this.state.crew}
                                                label='Crew *'
                                                onSelectionChanged={(e: any) => this.handleDropdownChange(e, 'crew')}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextBoxInput
                                                stylingMode='underlined'
                                                label="Pin"
                                                mode="password"
                                                required={true}
                                                value={this.state.pin}
                                                onValueChanged={(e: any) => this.handleValueChange(e, 'pin')}
                                                onKeyDown={() => { }}
                                                disabled={this.state.userType === "Portal" ? true : false}
                                            />
                                        </Grid>
                                        {/* </>:
                               null } */}
                                        <Grid item xs={12} sm={6}>
                                            <TextBoxInput
                                                stylingMode='underlined'
                                                label="Job Title"
                                                value={this.state.JobTitle}
                                                onValueChanged={(e: any) => this.handleValueChange(e, 'JobTitle')}
                                                onKeyDown={() => { }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={3}>
                                            <CheckBoxInput value={this.state.isActive} onValueChanged={(e: any) => this.handleValueChange(e, 'isActive')} text={'Is Active'} />
                                        </Grid>
                                        <Grid item xs={12} sm={3}>
                                            <CheckBoxInput value={this.state.isPlantOperator} onValueChanged={(e: any) => this.handleValueChange(e, 'isPlantOperator')} text={'Is Plant Operator'} />
                                        </Grid>
                                        {this.state.showRole ?
                                            <Grid item xs={12} sm={6}>
                                                <SelectBoxInput
                                                    stylingMode='underlined'
                                                    displayExpr="text"
                                                    valueExpr="id"
                                                    showClearButton={true}
                                                    items={this.state.userRoleItems}
                                                    selectedItem={this.state.userRole}
                                                    label='User Role *'
                                                    onSelectionChanged={(e: any) => this.handleDropdownChange(e, 'userRole')}
                                                    disabled={this.props.role !== global.worxOnline_Admin ? true : false}
                                                />
                                            </Grid>
                                            : null}
                                    </Grid>
                                    :
                                    <Spinner size="large" label="Loading all component. Please wait..." />
                                }
                            </div>
                        </ScrollView>
                    </DialogContent>
                    <DialogActions>

                        <Button onClick={() => this.clearFilter()} variant='contained' color="primary" className={''}>
                            Cancel
                        </Button>
                        <Button onClick={() => this.saveData()} variant='outlined' color="primary" className={'button'}>
                            Save
                        </Button>

                    </DialogActions>
                </Dialog>

            </div>

        );
    }

}


