import * as React from 'react';
import RadioGroup from 'devextreme-react/radio-group';

export default class RadioGroupInput extends React.Component<any, any> {

    public state = {
    };

    public render(): React.ReactElement<any> {
        return (
            <div className={this.props.displayFlex?'displayFlex':''}>
                <span style={{margin:this.props.displayFlex?'12px 0':'0px'}}>{this.props.label}</span>
                <RadioGroup
                    valueExpr={this.props.valueExpr}
                    displayExpr={this.props.displayExpr}
                    value={this.props.value}
                    items={this.props.items}
                    layout={this.props.layout}
                    onValueChanged={(e:any) => this.props.onValueChanged(e)}
                    disabled={this.props.disabled}
                />
            </div>
        );
    }
}


