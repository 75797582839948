import * as React from 'react';
import utility from '../../../classes/utility';
import ClearStorageService from './../../../ClearStorageService';
import { Config } from '../../../classes/config';
import Grid from '@material-ui/core/Grid';
import ScrollView from 'devextreme-react/scroll-view';
import AlertDialog from '../../common/alertDialog';
import Spinner from '../../common/reusableComponents/Spinner';
import SelectBoxInput from '../../common/reusableComponents/SelectBox';
import TextBoxInput from '../../common/reusableComponents/TextBox';
import NumberBoxInput from "../../common/reusableComponents/NumberBox";
import capitalize from '@material-ui/core/utils/capitalize';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
const typeItems: any = [
    { id: 'Department', text: 'Department' },
    { id: 'Team', text: 'Team' },
    { id: 'Crew', text: 'Crew' },
    { id: 'User', text: 'User' }
];
export default class AddEditUnitRates extends React.Component<any, any> {
    ClearStorageService = new ClearStorageService();
    public departmentItems: any = [];

    constructor(props: any) {
        super(props);

        this.updateUnitRate = this.updateUnitRate.bind(this);
        this.clearFilter = this.clearFilter.bind(this);
        this.departmentItems = [];
        this.state = {
            type: '', department: '', team: '', crew: '', user: '', rate: 0, items: [], loading: true
        };
    }
    public async didMount() {
        await this._getDepartmentData();
        await this._getTeamDataAsync();
        await this._getCrewDataAsync();
        await this._getUserData();
        this.populateValue(this.props.details, this.props.mode)
    }

    public populateValue(details?: any, mode?: any) {
        this.setState({
            hideAlertButton: false,
            mode: this.props.mode
        })
        if (this.props.details && this.props.mode === 'edit') {
            this.setState({
                id: details.id,
                entityId: details.id,
                type: { id: details.type, text: details.type },
                rate: details.rate,
                created: this.props.details.createdOn,
                createdby: this.props.details.createdBy
            });
        } else {
            this.setState({
                hideAlertButton: false,
                mode: this.props.mode
            })
        }
    }
    public async _getTeamDataAsync() {
        try {
            const data: any = await utility.genericGetAPICall(`${Config.TeamList}?showAll=true`);
            const teamsItems: any = [];
            let selectedTeam: any = '';
            data.filter((r: any) => r.isActive === true).map((r: any) => {
                (
                    teamsItems.push({ id: (r.id), text: r.title, title: (r.title), departmentId: r.departmentId })
                )
            }
            );
            const sortedItems = utility._sortItems(teamsItems, 'text', false);
            if (this.props.mode === 'edit' && this.props.details.type === 'Team') {
                selectedTeam = sortedItems.filter((r: any) => r.id === this.props.details.entityId);
            }
            this.setState({ teamsItems: sortedItems, team: selectedTeam ? selectedTeam[0] : '' });
        } catch (err) {
            console.log(err);
        }
    }
    private async _getCrewDataAsync() {
        try {
            const data: any = await utility.genericGetAPICall(`${Config.crewswithdepartment}?showAll=true`);

            const crewItems: any = [];
            let selectedCrew: any = '';
            data.filter((r: any) => r.isActive === true).map((r: any) => {
                (
                    crewItems.push({ id: (r.id), text: r.title, title: (r.title), teamId: r.teamId, departmentId: r.departmentId })
                )
            }
            );
            const sortedItems = utility._sortItems(crewItems, 'text', false);
            if (this.props.mode === 'edit' && this.props.details.type === 'Crew') {
                selectedCrew = sortedItems.filter((r: any) => r.id === this.props.details.entityId);
            }
            this.setState({ crewItems: sortedItems, tempCrewItems: sortedItems, crew: selectedCrew ? selectedCrew[0] : '' });
        } catch (err) {
            console.log(err);
        }
    }
    public async _getDepartmentData() {
        try {
            const data: any = await utility.genericGetAPICall(`${Config.DepartmentList}?showAll=true`);
            this.departmentItems = [];
            let selectedDepartment: any = '';
            data.filter((r: any) => r.isActive === true).map((r: any) => {
                (
                    this.departmentItems.push({ id: (r.id), text: r.title })
                )
            }
            );
            const sortedItems = utility._sortItems(this.departmentItems, 'text', false);
            if (this.props.mode === 'edit' && this.props.details.type === 'Department') {
                selectedDepartment = sortedItems.filter((r: any) => r.id === this.props.details.entityId);
            }
            this.setState({ departmentItems: sortedItems, department: selectedDepartment ? selectedDepartment[0] : '' });
        } catch (err) {
            console.log(err);
        }
    }
    public async _getUserData() {
        try {
            const data: any = await utility.genericGetAPICallForList(Config.SearchEmployeeApi, 'POST', {});
            let selectedUser: any = '';
            const activeItems = data.filter((r: any) => r.isActive === true).map((r: any) => ({
                id: r.id,
                text: r.fullName
            }));

            const sortedItems = utility._sortItems(activeItems, 'text', false);
            if (this.props.mode === 'edit' && this.props.details.type === 'User') {
                selectedUser = sortedItems.filter((r: any) => r.id === this.props.details.entityId);
            }
            this.setState({
                userItems: sortedItems, user: selectedUser ? selectedUser[0] : ''
            });
        } catch (err) {
            console.log(err);
            this.setState({ loading: false });
        }
    }

    public updateUnitRate() {
        let dataToPassToService: any = {};

        if (!this.state.type) {
            this.setState({
                alertMessage: 'Please select a type',
                showAlert: true
            });
            return;
        }
        if (!this.state.rate) {
            this.setState({
                alertMessage: 'Please enter rate',
                showAlert: true
            });
            return;
        }
        if (this.state.type && this.state.type.text === "Department" && !this.state.department) {
            this.setState({
                alertMessage: 'Please select a Title',
                showAlert: true
            });
            return;
        }
        if (this.state.type && this.state.type.text === "Crew" && !this.state.crew) {
            this.setState({
                alertMessage: 'Please select a Title',
                showAlert: true
            });
            return;
        }
        if (this.state.type && this.state.type.text === "Team" && !this.state.team) {
            this.setState({
                alertMessage: 'Please select a Title',
                showAlert: true
            });
            return;
        }
        if (this.state.type && this.state.type.text === "User" && !this.state.user) {
            this.setState({
                alertMessage: 'Please select a Title',
                showAlert: true
            });
            return;
        }
        this.setState({
            alertMessage: <div className={'alertLoader'}><Spinner size='large' label="Loading..." /></div>,
            hideAlertButton: true,
            showAlert: true
        });
        dataToPassToService.entityType = this.state.type?.text;
        dataToPassToService.rate = this.state.rate;
        dataToPassToService.tenantId = Config.tenantId;
        dataToPassToService.entityId = this.state.type?.text === "Department" ? this.state.department?.id : this.state.type?.text === "Team" ? this.state.team?.id : this.state.type?.text === "Crew" ? this.state.crew?.id : this.state.user?.id;
        if (this.props.mode === 'edit') {
            dataToPassToService.id = this.state.id;
            dataToPassToService.updated = new Date();
            dataToPassToService.updatedBy = this.props.currentUser.Email;
            utility.genericUpdateAPICall(Config.UnitRates, this.state.id, dataToPassToService).then((data: any) => {
                //console.log(data);
                this.setState({ showAlert: false });
                this.clearFilter();
                this.props.onClick();
                this.props.message("Unit Rate has been updated");
                this.ClearStorageService.clearStorage();
            }, (err: any) => {
                console.log(err);
                this.setState({
                    alertMessage: 'Something went wrong!',
                    hideAlertButton: false,
                    showAlert: false
                });
            });
        } else {
            dataToPassToService.createdBy = this.props.currentUser.Email;
            dataToPassToService.created = new Date();
            utility.genericPostAPIcall(Config.UnitRates, dataToPassToService).then((data: any) => {
                //console.log(data);
                this.setState({ showAlert: false });
                this.clearFilter();
                this.props.onClick();
                this.props.message("Unit Rate has been created");
                this.ClearStorageService.clearStorage();
            }, (err: any) => {
                console.log(err);
                this.setState({
                    alertMessage: 'Something went wrong!',
                    hideAlertButton: false,
                    showAlert: false
                });
            });
        }
    }

    public clearFilter() {

        this.setState({ type: '', department: '', rate: 0, team: '', crew: '', user: '' });
        this.props.onClose();

    }

    private alert() {
        return (
            <AlertDialog
                showDialog={this.state.showAlert}
                message={this.state.alertMessage}
                title={Config.productTitle}
                onClose={() => this.setState({ alertMessage: '', showAlert: false })}
                hideAlertButton={this.state.hideAlertButton}
            />
        );
    }
    private handleValueChange = (event: any, fieldName: string) => {

        this.setState({ [fieldName]: event.value });

    }
    public handleDropdownChange = (e: any, fieldName: string) => {
        if (e.selectedItem !== null) {
            this.setState({
                [fieldName]: e.selectedItem
            });
        }
    }

    public render(): React.ReactElement<any> {

        return (
            <div>
                {this.state.showAlert && this.alert()}
                < Dialog
                    open={this.props.showModal}
                    onClose={() => this.clearFilter()}
                    fullWidth={true}
                    maxWidth={'lg'}
                    aria-labelledby="max-width-dialog-title"
                    onRendered={() => this.didMount()}
                >
                    <DialogTitle id="scroll-dialog-title">
                        {`${this.props.mode && capitalize(this.props.mode)} Unit Rate`}
                    </DialogTitle>
                    <IconButton aria-label="close" onClick={() => this.clearFilter()} className="closeIcon">
                        <CloseIcon />
                    </IconButton>
                    <DialogContent dividers={true}>
                        <ScrollView width='100%' height='100%' showScrollbar='onScroll' scrollByThumb={true}>
                            <div className='popContentDiv'>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={6}>
                                        <SelectBoxInput
                                            stylingMode='underlined'
                                            displayExpr="text"
                                            valueExpr="id"
                                            showClearButton={true}
                                            items={typeItems}
                                            selectedItem={this.state.type}
                                            label='Type *'
                                            onSelectionChanged={(e: any) => this.handleDropdownChange(e, 'type')}
                                        />

                                    </Grid>
                                    {this.state.type && this.state.type.text === "Department" &&
                                        <Grid item xs={12} sm={6}>
                                            <SelectBoxInput
                                                stylingMode='underlined'
                                                displayExpr="text"
                                                valueExpr="id"
                                                showClearButton={true}
                                                items={this.state.departmentItems}
                                                selectedItem={this.state.department}
                                                label='Title *'
                                                onSelectionChanged={(e: any) => this.handleDropdownChange(e, 'department')}
                                            />
                                        </Grid>
                                    }
                                    {this.state.type && this.state.type.text === "Team" &&
                                        <Grid item xs={12} sm={6}>
                                            <SelectBoxInput
                                                stylingMode='underlined'
                                                displayExpr="text"
                                                valueExpr="id"
                                                showClearButton={true}
                                                items={this.state.teamsItems}
                                                selectedItem={this.state.team}
                                                label='Title *'
                                                onSelectionChanged={(e: any) => this.handleDropdownChange(e, 'team')}
                                            />
                                        </Grid>
                                    }
                                    {this.state.type && this.state.type.text === "Crew" &&
                                        <Grid item xs={12} sm={6}>
                                            <SelectBoxInput
                                                stylingMode='underlined'
                                                displayExpr="text"
                                                valueExpr="id"
                                                showClearButton={true}
                                                items={this.state.tempCrewItems}
                                                disabled={(this.state.disableCrew || this.state.userType) === "Portal" ? true : false}
                                                selectedItem={this.state.crew}
                                                label='Title *'
                                                onSelectionChanged={(e: any) => this.handleDropdownChange(e, 'crew')}
                                            />
                                        </Grid>
                                    }
                                    {this.state.type && this.state.type.text === "User" &&
                                        <Grid item xs={12} sm={6}>
                                            <SelectBoxInput
                                                stylingMode='underlined'
                                                displayExpr="text"
                                                valueExpr="id"
                                                showClearButton={true}
                                                items={this.state.userItems}
                                                selectedItem={this.state.user}
                                                label='Title'
                                                onSelectionChanged={(e: any) => this.handleDropdownChange(e, 'user')}
                                            />
                                        </Grid>
                                    }
                                    <Grid item xs={12} sm={6}>
                                        <NumberBoxInput
                                            stylingMode='underlined'
                                            label="Rate *"
                                            value={this.state.rate ? this.state.rate : ''}
                                            onValueChanged={(e: any) =>
                                                this.handleValueChange(e, "rate")
                                            }
                                            required={true}
                                        />
                                    </Grid>
                                </Grid>
                            </div>
                        </ScrollView>
                    </DialogContent>
                    <DialogActions>

                        <Button onClick={() => this.clearFilter()} variant='contained' color="primary" className={''}>
                            Cancel
                        </Button>
                        <Button onClick={() => this.updateUnitRate()} variant='outlined' color="primary" className={'button'}>
                            Save
                        </Button>

                    </DialogActions>
                </Dialog >

            </div >

        );
    }

}

