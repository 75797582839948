import global from "./global";

export const Config: any = {
    productTitle: "worxOnline",
    tenantId: 1002,
    portal: "Portal",

    ListConfiguration: 'ConfigList ',
    WOHistorylistname: "Work Order History",
    WODocumentslistname: "Work Order Documents",
    // for get api
    workorderlistname: "Workorders",
    workorderMateriallistname: "Materials/SearchMaterialUsage",
    materialsListName: "Materials",
    scheduledworklistname: "Scheduledworks",
    workGroup: "WorkGroups",
    suburblistname: "Suburbs",
    townlistname: "Towns",
    crewlistname: "Crews",
    SearchWorkordersForDashboard: "Workorders/SearchWorkordersForDashboard",
    crewswithdepartment: "Crews/GetAllCrewsWithDepartmentAndTeam",
    employeelistname: "Users",
    WTlistname: "WorkorderTypes",
    WRTlistname: "WorkRequestTypes",
    WRlistname: "WorkRequests/SearchWorkRequests",
    SearchWRTlist: "WorkRequestTypes/SearchWorkRequestTypes",
    userlogins: "UserLogins",
    searchUserlogin: "UserLogins/SearchUserLogins",
    searchForms: "Forms/SearchForms",
    WRbyWRNo: "WorkRequests/GetWorkRequestByWorkRequestNumber",
    WorkRequestHistories: "WorkRequestHistories",
    WRDocuments: "WorkRequestDocuments/GetDocumentsByWorkOrderId",
    WRList: "WorkRequests",
    RejectWR: "WorkRequests/RejectWorkRequest",
    statuslist: "WorkorderStatus",
    WRstatuslist: "WorkRequestStatus",
    WOMaterialListName: "materials",
    EquipmentsListName: "Equipments",
    equipmentwithprestartForms: "Equipments/GetAllEquipmentsWithPrestartForms",
    assetType: "AssetTypes",
    assetClass: "AssetClasses",
    AssetCategories: "AssetCategories",
    assetStatus: "AssetStatus",
    unitType: "UnitTypes",
    DepartmentList: "Departments",
    WorkorderEquipments: "WorkorderEquipments",
    CounterList: "Counter",
    TeamList: "Teams",
    SearchTeamList: "Teams/SearchTeams",
    DefectCodeList: "DefectTypes",
    AssetQuestionList: "Assetfields",
    workorderHistoryList: "WorkorderHistories",
    workorderDocumentList: "WorkorderDocuments",
    workorderDocumentListGet: "WorkorderDocuments/GetDocumentsByWorkOrderId",
    assetDocumentList: "Assetdocuments",
    swmsDocumentList: "SwmsDocuments",
    searchswmsDocumentList: "SwmsDocuments",
    tcpDocumentList: "TrafficControlPlans",
    dowloadDocumentList: "Documents/DownloadDocumentFromAzureAsAdHocUrl",
    FormsListName: "Forms",
    EventsListName: "Events",
    PrestartFormsListName: "PrestartForms",
    PriorityListName: "Priorities",
    workTypeFieldList: "WorktypeFields",
    worktypeSubcategories: "Workordertypes/GetWoWorkordertypesSubcategories",
    defectQuestionList: "Defectfields",
    roleList: "Roles",
    userRoleList: "Userroles",
    inventoryLocationList: "InventoryLocations",
    InspectionFormList: "CommonForm",
    ServiceRequestDetail: 'ServiceRequestDetail',
    MaintenanceSchedule: 'MaintenanceSchedule',
    OnHoldReasonDetailList: "Onholdreasons/details",
    OnHoldReasonList: "WorkorderOnHoldReasons",
    GISLayer: "gisicons/layer-datasource.json",
    AreaList: "area",
    ReasoneToAttendList: "reasontoattend",
    ScopeList: "scope",
    FormattedAddressList: "formattedaddress",
    AddressList: "Address",
    //update api for Schedule
    SearchWRHistory: "/WorkRequestHistories/SearchWorkRequestHistory",
    SchedulerWorkUpdateList: "UpdateWorkOrderSchedule",
    WorkOrderUpdateList: "Workorders",
    SearchFormsSubmitted: "FormsSubmitted/SearchFormsSubmitted",
    FormsSubmitted: "FormsSubmitted/SearchFormsSubmitted",
    workorderPlantDetails: "Workorders/UpdateOperatorWorkorderDetails",
    // for searc api
    WOSearchAPI: "Workorders/SearchWorkorders",
    FormTypes: "FormTypes",

    WOdetailsSearchAPI: "Workorders",
    SearchRelatedWOAPI: "Workorders/SearchRelatedWorkorders",
    UpdateWO: "Workorders/UpdateWorkorderFromPortal",
    FcrCategorisation: "FCRCategorisation?maintenanceType=",
    //material
    WOMaterialSearchAPI: "SearchMaterialUsage",
    SearchMaterialApi: "Materials/SearchMaterials",
    MaterialCategoryApi: "MaterialCategories",

    //Equipments
    WOequipmentSearchAPI: "Equipments/SearchEquipments",
    EquipmentAPi: "Equipments",
    SearchPrestartChecklist: "PrestartChecklists/SearchPrestartChecklists",
    SearchInspectionForm: "CommonForm/SearchCommonFormsDocuments",
    SearchEmployeeApi: "Users/SearchUsers",
    UpdateEmployeeAPI: "Users/UpdateUserWithRole",
    CreateEmployeeAPI: "Users/CreateUserWithRole",
    SearchActivitiesApi: "WorkorderTypes/SearchWorkorderTypes",
    SearchWorkTypeField: "Worktypefields/SearchWorktypeFields",
    UnitRates: "UnitRates",
    SearchUnitRates: "UnitRates/SearchUnitRates",
    SearchCrewApi: "Crews/SearchCrews",
    SearchTeamApi: "SearchTeams",
    SearchAssetFields: "SearchAssetFields",
    AssetSearchAPI: "Assets/SearchAssets",
    SearchAssetDetails: "Assets/SearchAssetDetails",
    AssetSearchAPIForDetail: "/SearchAssets",
    AddEditAset: "Assets/AddAssetFromApp",
    WOTimesheetSearchAPI: "TimeSheets",
    WOTimeSheetAPI: "TimeSheets/TimeSheetExport",
    WOHistorySearchAPI: "WorkorderHistories/SearchWorkorderHistory",

    WOSearchScheduleAPI: "SearchScheduledWorks",
    SearchControlMeasure: "SearchControlMeasures",
    SearchRiskControlMeasure: "SearchControlMeasures",
    SearchPurchaseRequisition: "PurchaseRequisitions/searchPurchaseRequisitions",
    searchSuppliersList: "Suppliers/searchSuppliers",
    //Risk
    RiskGroup: "RiskGroups",
    RiskGroupWithRisks: "RiskGroups/GetAllRiskGroupsWithRisks",
    RiskGroupCM: "RiskGroups/GetAllRiskGroupsWithRisksAndControlmeasures",
    RiskCategory: "RiskCategories",
    RiskForms: "RiskForms",
    Risks: "Risks",
    AssetAttributes: "AssetAttributes/GetAssetAttributesValues",
    RiskControlmeasures: "RiskControlmeasures",
    RiskCategorieswithGroups: "RiskCategories/GetAllRiskCategoriesWithRiskGroups",
    RiskGroupsWithRiskCategories: "RiskGroups/GetAllRiskGroupsWithRiskCategories",
    RiskFormsWithDepartments: "RiskForms/GetAllRiskFormsWithDepartments",
    GetAllRisksWithRiskGroups: "Risks/GetAllRisksWithRiskGroups",
    RiskHierarchy: "RiskHierarchy",

    //extra
    WTQuestionslistname: "Activity Questions",
    timesheetListName: "New Employee Timesheet",
    gisAssetCoordinates: "GisAsset/getAssetCoordinates",
    //Defects
    searchDefects: "defects/searchDefects",
    WeeklyTimesheet: "WeeklyTimesheets/SearchWeeklyTimesheets",
    TenantApi: 'tenants',
    GloabSearchAPI: 'globalsearch?searchterm=',
    AssetdetailURL: global.homeURL + "/AssetDetail?ASSETNO=",
    seacrhWorkOrderURL: global.homeURL + "/Searchworkorder",

    IntegrationStatusapi: 'IntegrationStatus',
    Notificationapi: "Notifications/SearchByDate",
    CreateCrewWithUsers: "Crews/CreateCrewAlongWithUsers",

    //Meter Readings
    AssetMeterReadingList: "AssetMeterReadings",
    AssetMeterReadingListByWOID: "AssetMeterReadings/GetMeterReadingsByWorkOrderId",

    //FailedWO
    SearchSyncFailedWorkordersapi: "Workorders/SearchSyncFailedWorkorders",
    RetrySyncFailedWorkordersapi: "Workorders/RetrySyncFailedWorkorders",

    //Financial Ledger
    FinancialLedgerList: "FinancialLedger/SearchFinancialLedger",

    //FailedMobileAppIssues
    MobileAppFailedItemsList: "MobileAppFailedItems",
    SearchMobileAppFailedItems: "MobileAppFailedItems/SearchMobileAppFailedItems",
    //DepartmentUnitRate
    getDepartmentUnitRate: "Departments/GetDepartmentsWithUnitRate",
    createDepartmentUnitRate: "Departments/CreateDepartmentsWithUnitRate",
    UpdateDepartmentUnitRate: "Departments/UpdateDepartmentsWithUnitRate",
    DeleteDepartmentUnitRate: "Departments/DeleteDepartmentsWithUnitRate",
    //Contractor
    Contractor: 'Contractors',
    PortalLink: 'ContractorWorkorders/AssignWorkorder',
    GetContractorsWithDetails: 'Contractors/GetContractorsWithDetails',
    statusColors: {
        'Open': '#90C3D4',
        'Dispatched': '#90C3D4',
        'OffsiteHold': '#FB8E54',
        'JobComplete': '#A1D490',
        'OffsiteComplete': '#A1D490',
        'WorkComplete': '#A1D490',
        'Complete': '#A1D490',
        'Accepted': '#F4A498',
        'Disapatched': '#C9C65F',
        'Travelling': '#6666FF',
        'WaterOff': '#C733FF',
        'WaterOn': '#C733FF',
        'WorkPaid': '#C733FF',
        'Cancelled': '#C733FF',
        'CID': '#C733FF',
        'CIP': '#C733FF',
        'Closed': '#C733FF',
        'Onsite': '#C733FF',
        'Queried': '#C733FF',
        'Rejected': '#C733FF',
        'Restored': '#C733FF',
        'ServiceRestored': '#C733FF',
        'SewerContained': '#C733FF'
    },
    priorityColors: {
        'Priority 1': '#ff0000',
        'Priority 2': '#e6b800',
        'Priority 3': '#00cc00',
        'Planned within 14 Days': '#0066ff',
        'Planned within 30 Days': '#cc99ff',
        'Planned within 60 Days': '#ff4d88',
        'Proposed Work': '#e60099',
        'Planned Shutdown': '#00b3b3',
        'PWeekly within 7 Days': '#9494b8',
        'Yearly (Due 30 Jun of FY)': '#999999',
    },

};
export default Config;