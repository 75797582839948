import * as React from 'react';
import ArrayStore from 'devextreme/data/array_store';
import List from 'devextreme-react/list';
import { LoadIndicator } from 'devextreme-react/load-indicator';

// const colors: any = {
//     'In Progress': '#90C3D4',
//     'On Hold': '#FB8E54',
//     'Complete': '#A1D490',
//     'Unassigned': '#F4A498',
//     'Assigned': '#C9C65F',
//     'Field Complete': '#6666FF',
//     'Reassigned': '#C733FF',
// };
export default class SchedulerCrewListView extends React.Component<any, any> {
    public column: any;
    public list: any;
    constructor(props: any) {
        super(props);
        this.handleGroupChange = this.handleGroupChange.bind(this);
        // this.list = React.createRef();
        this.handleListSelectionChange = this.handleListSelectionChange.bind(this);
        // this.onSelectionChanged = this.onSelectionChanged.bind(this);

        this.state = {
            group: 'teamName',
            checkBoxValue: false,
            schedulerCrewPanes: [{ size: '30%' }, { min: '30%' }],
            // selectedItem: [this.props.crews[0].id],
        };
    }

    public componentDidMount() {
        let crewId = localStorage.getItem('selectedcrewId') ? localStorage.getItem('selectedcrewId') : this.props.crews[0].id;
        let selectedCrewObj = this.props.crews && this.props.crews.find((c: any) => c.id === +crewId);

        let selectedWorkorderbyCrew = this.props.workorders && this.props.workorders.filter((wo: any) => wo.crew === crewId);
        this.setState({
            selectedWorkorderbyCrew: selectedWorkorderbyCrew,
            selectedItem: [+crewId],
            selectedCrewObj: selectedCrewObj
        });

        this.props.selectedWorkorderbyCrew(selectedWorkorderbyCrew, selectedCrewObj);
    }
    public handleGroupChange = (value: any) => {

        this.setState({
            group: value
        })
    }
    public onHorizontalChange = (fieldName: any, event: any) => {
        this.setState({
            [fieldName]: event.newState,
        });
    }
    // public onSelectionChanged(e) {
    //     console.log({e})
    //     const currentCrew = e.addedItems[0];
    //     // let selectedWorkorderbyCrew = this.props.workorders && this.props.workorders.filter(wo => wo.crew === currentCrew.id)

    //     this.setState({
    //         selectedItem:[currentCrew.id],
    //         // selectedWorkorderbyCrew: selectedWorkorderbyCrew
    //     });
    //     // this.props.selectedWorkorderbyCrew(selectedWorkorderbyCrew);
    //   }
    public handleListSelectionChange(e: any) {

        let selectedWorkorderbyCrew = this.props.workorders && this.props.workorders.filter((wo: any) => wo.crew === e.id);
        localStorage.setItem('selectedcrewId', e.id);
        this.setState({
            selectedItem: [e.id],
            selectedWorkorderbyCrew: selectedWorkorderbyCrew,
            selectedCrewObj: e,
        });
        this.props.selectedWorkorderbyCrew(selectedWorkorderbyCrew, e);

    }
    public afterInit() {

        let crewID = this.state.selectedItem ? this.state.selectedItem[0] : this.props.crews && this.props.crews.length > 0 ? this.props.crews[0].id : '';
        // if(this.props.crews && this.props.crews.length > 0){
        let selectedWorkorderbyCrew = this.props.workorders && this.props.workorders.filter((wo: any) => wo.crew === crewID)
        this.setState({
            selectedWorkorderbyCrew: selectedWorkorderbyCrew
        });
        // }
        this.props.selectedWorkorderbyCrew(selectedWorkorderbyCrew, this.state.selectedCrewObj);
        this.props.stopOnCrewSelected();

    }
    public render() {

        const dataSourceOptions = {
            store: new ArrayStore({
                data: this.props.crews,
                key: 'id',
            }),
            group: this.state.group,
            searchExpr: ['text'],
        };
        if (this.props.onCrewSelected) {
            this.afterInit();
        }
        return (
            <div>
                <div className={'ptlr-20'}>Crews ({this.props.crews && this.props.crews.length})</div>
                {!this.props.onCrewSelected ?
                    <div className={'m-10 p-10'} >
                        <List
                            // ref={this.list}
                            // className='listView'
                            selectionMode="single"
                            dataSource={dataSourceOptions}
                            grouped={true}
                            searchEnabled={true}
                            selectedItemKeys={this.state.selectedItem}
                            // onItemClick={(e)=>this.handleListSelectionChange(e)}
                            // onSelectionChanged={(e)=>this.onSelectionChanged(e)}
                            itemRender={(e, index) => this.renderCrewListItem(e, index, this.props)}
                            groupRender={(group, Id) => this.renderListGroup(group, Id)}
                            elementAttr={{ class: 'list' }}
                            collapsibleGroups={true}
                            activeStateEnabled={true}
                            scrollingEnabled={true}

                        >
                        </List>
                    </div> :
                    <LoadIndicator id="large-indicator" height={60} width={60} />
                }
            </div>
        );
    }


    public renderListGroup(group: any, id: any) {

        return <div className="city">{group.key}    </div>;
    }
    public renderCrewListItem(ev: any, index: any, props: any) {

        let totalWOPerCrew = this.props.workorders && this.props.workorders.filter((wo: any) => wo.crew === ev.id);
        return (
            <div className="schedulerResourceCell"
                onClick={() => this.handleListSelectionChange(ev)}
                onDragOver={(e: any) => this.props.onDragOver(e)}
                onDrop={(e: any) => this.props.onDrop(e, ev)}
            >
                <div className='displayFlex'>
                    <div className="crewTitleColorScheduler" style={{ backgroundColor: ev.color ? ev.color : "#333" }}></div>
                    <div style={{ width: '90%' }}>
                        <div className="schedulerResourceCellText schedulerListCrewTitle" title={ev.text}>{ev.text}</div>
                        <div className="schedulerResourceCellWO">{`${totalWOPerCrew.length} workorder${totalWOPerCrew.length > 1 ? 's' : ''}`}</div>
                    </div>
                </div>
            </div>
        );
    }

}

