import * as React from 'react';
import {
    NavLink
} from "react-router-dom";
import utility from '../../classes/utility';
import { Config } from '../../classes/config';
import global from '../../classes/global';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import AlertDialog from '../common/alertDialog';
import DataTable from '../common/DataTable/DataTable';
import AssetSearch from '../AssetSearch/AssetSearch';
import AssetMap from '../AssetSearch/AssetMap';
import RoomIcon from '@material-ui/icons/Room';
import EmojiTransportationIcon from '@material-ui/icons/EmojiTransportation';
import DateBoxInput from '../common/reusableComponents/DateBox';
import TextBoxInput from '../common/reusableComponents/TextBox';
import SelectBoxInput from '../common/reusableComponents/SelectBox';
import Spinner from '../common/reusableComponents/Spinner';


let _items: any = [];

const ScheduleStatus: any = [
    { id: 'Yes', text: 'Active' },
    { id: 'No', text: 'Inactive' }
];

export default class SearchScheduleWork extends React.Component<any, any> {

    public suburbItems: any = [];
    public crewItems: any = [];
    public departmentItems: any = [];
    public worktypeItems: any = [];
    public StatusItems: any = [];
    public priorityItems: any = [];
    public setupDataPromis: any = [];
    public columns: any;

    constructor(props: any) {
        super(props);

        this.suburbItems = [];
        this.crewItems = [];
        this.departmentItems = [];
        this.StatusItems = [];
        this.worktypeItems = [];
        this.priorityItems = [];
        this.setupDataPromis = [];
        this.getWorkOrder = this.getWorkOrder.bind(this);
        this._handleKeyDown = this._handleKeyDown.bind(this);
        this.clearFilter = this.clearFilter.bind(this);
        this.SearchClicked = this.SearchClicked.bind(this);

        this.columns = [
            {
                field: 'scheduleCode',
                title: 'Schedule',
                width: 160,
                type: 'string',
                allowHiding: false,
                link: 'scheduleLink',
            },
            {
                field: 'title',
                title: 'Title',
                width: 280,
                type: 'string',
                allowHiding: false,
            },
            {
                field: 'assets',
                title: 'Asset',
                width: 110,
                type: 'string',
                allowHiding: true
            },
            {
                field: 'priority',
                title: 'Priority',
                width: 110,
                type: 'string',
                allowHiding: true
            },
            {
                field: 'activity',
                title: 'Activity',
                width: 110,
                type: 'string',
                allowHiding: true
            },
            {
                field: 'crew',
                title: 'Crew',
                width: 160,
                type: 'string',
                allowHiding: true
            },
            {
                field: 'location',
                title: 'Location',
                width: 180,
                type: 'string',
                allowHiding: true
            },
            {
                field: 'scheduleStart',
                title: 'Scheduled Start',
                width: 180,
                type: 'datetime',
                allowHiding: true
            },
            {
                field: 'nextSchedule',
                title: 'Next Scheduled',
                width: 180,
                type: 'datetime',
                allowHiding: true
            },
            {
                field: 'scheduleEnd',
                title: 'Scheduled End',
                width: 180,
                type: 'datetime',
                allowHiding: true
            },
            {
                field: 'duration',
                title: 'Duration',
                width: 160,
                type: 'string',
                allowHiding: true
            },
            {
                field: 'updatedOn',
                title: 'Updated',
                width: 160,
                type: 'datetime',
                visibleColumn: false,
                allowHiding: true
            },
            {
                field: 'updatedBy',
                title: 'Updated By',
                width: 160,
                type: 'string',
                visibleColumn: false,
                allowHiding: true
            },
            {
                field: 'createdOn',
                title: 'Created',
                width: 160,
                type: 'datetime',
                visibleColumn: false,
                allowHiding: true
            },
            {
                field: 'createdBy',
                title: 'Created By',
                width: 160,
                type: 'string',
                visibleColumn: false,
                allowHiding: true
            },
        ];
        this.state = {
            items: _items,
            columns: this.columns,
            callAfterInit: false,
            loading: false
        };
        if (props.cacheLifecycles) {
            props.cacheLifecycles.didCache(this.componentDidCache);
        }
    }

    public componentDidCache = () => {
        ////console.log('view cached. Scroll position = ' + document.getElementById("s4-workspace").scrollTop);
        //scrollPosition = document.getElementById("s4-workspace").scrollTop;
    }


    public componentDidMount() {
        this.setState({ loadingFields: true });
        this.setupDataPromis = [];
        this._getCrewDataAsync();
        this._getworkTypeDataAsync();
        this._getDepartmentData();
        this._getPrioritiesData();
        Promise.all(this.setupDataPromis).then(items => {
            //console.log('---all promise done---');
            this.setState({ loadingFields: false });
        });

    }


    public _handleKeyDown = (e: any) => {
        if (e.event.originalEvent.key === 'Enter') {
            this.getWorkOrder();
        }
    }
    public clearFilter() {
        this.setState({
            items: '',
            scheduleCode: '',
            ServiceRequest: '',
            wostatus: '',
            crewteam: '',
            woworktype: '',
            scheduleStatus: '',
            priority: '',
            nextSchedulefrom: '',
            nextScheduleTo: '',
            scheduleGroup: '',
            department: '',
            asset: '',
            worktype: ''
        });
    }
    private _getPrioritiesData() {
        this.setupDataPromis.push(
            utility.genericGetAPICall(Config.PriorityListName).then((data: any) => {
                //console.log(data);
                this.priorityItems = [];

                for (let r of data) {
                    this.priorityItems.push({ id: (r.id), text: (r.title) });
                }
                this.setState({
                    priorityItems: this.priorityItems,
                    allpriorityItems: this.priorityItems,
                });
            }, (err) => {
                console.log(err);
            }));
    }
    private _getCrewDataAsync() {
        this.setupDataPromis.push(
            utility.genericGetAPICall(Config.crewlistname).then((data: any) => {
                //console.log(data);
                this.crewItems = [];

                for (let r of data) {
                    this.crewItems.push({ id: (r.id), text: (r.code + ' - ' + r.title) });
                }
                this.setState({
                    crewItems: this.crewItems,
                    allcrewItems: this.crewItems,
                });
            }, (err) => {
                console.log(err);
            }));
    }
    private _getDepartmentData() {
        this.setupDataPromis.push(
            utility.genericGetAPICall(Config.DepartmentList).then((data: any) => {
                //console.log(data);
                this.departmentItems = [];

                if (data && data.length > 0) {
                    this.departmentItems = data
                        .filter((r: any) => r.isActive === true)
                        .map((r: any) => ({
                            id: r.id, text: r.title
                        }));
                }

                this.setState({
                    departmentItems: this.departmentItems,
                    alldepartmentItems: this.departmentItems,
                });
            }, (err) => {
                console.log(err);
            }));
    }
    private _getworkTypeDataAsync() {
        this.setupDataPromis.push(
            utility.genericGetAPICall(Config.WTlistname).then((data: any) => {
                //console.log(data);
                this.worktypeItems = [];

                for (let r of data) {
                    this.worktypeItems.push({ id: r.id, text: (r.code + ' - ' + r.title) });
                }
                this.worktypeItems = utility._sortItems(this.worktypeItems, 'text', false);
                this.setState({
                    worktypeItems: this.worktypeItems,
                    allworktypeItems: this.worktypeItems,
                });
            }, (err) => {
                console.log(err);
            }));
    }

    public getWorkOrder() {

        let criteriaSelected = false;
        let dataToPassToService: any = {};

        if (this.state.scheduleCode) {
            dataToPassToService.ScheduleCode = this.state.scheduleCode;
            criteriaSelected = true;
        }
        if (this.state.scheduleGroup) {
            dataToPassToService.ScheduleGroup = this.state.scheduleGroup;
            criteriaSelected = true;
        }
        if (this.state.asset) {
            dataToPassToService.AssetNumber = this.state.asset;
            criteriaSelected = true;
        }
        if (this.state.department) {
            dataToPassToService.DepartmentId = this.state.department.id;
            criteriaSelected = true;
        }
        if (this.state.priority) {
            dataToPassToService.PriorityId = this.state.priority.id;
            criteriaSelected = true;
        }
        if (this.state.worktype) {
            dataToPassToService.WorkTypeId = this.state.worktype.id;
            criteriaSelected = true;
        }

        if (this.state.crewteam) {
            dataToPassToService.CrewId = this.state.crewteam.id;
            criteriaSelected = true;
        }

        if (this.state.scheduleStatus) {
            let status;
            if (this.state.scheduleStatus.text === "Active") {
                status = true;
            } else {
                status = false;
            }
            dataToPassToService.IsActive = status;
            criteriaSelected = true;
        }
        if (this.state.nextScheduleTo && this.state.nextSchedulefrom) {
            let dschedulefrom = new Date(this.state.nextSchedulefrom);
            let dscheduleTo = new Date(this.state.nextScheduleTo);
            if (dscheduleTo.getTime() < dschedulefrom.getTime()) {
                this.setState({
                    alertMessage: 'Next schedule date to should be greater than next scheduled date trom',
                    showAlert: true
                });
                return;
            }
        }
        // add deparment id 
        if (this.state.nextSchedulefrom || this.state.nextScheduleTo) {
            // let scheduledatefrom;
            // let scheduleTo;
            if (this.state.nextSchedulefrom) {
                dataToPassToService.ScheduleDateFrom = new Date(this.state.nextSchedulefrom).toISOString();

            }
            if (this.state.nextScheduleTo) {
                //extraday.setDate(extraday.getDate() + 1);
                dataToPassToService.ScheduleDateTo = new Date(this.state.nextScheduleTo).toISOString();

            }
            criteriaSelected = true;
        }
        if (!criteriaSelected) {
            this.setState({
                alertMessage: 'Please select search criteria',
                showAlert: true
            });
            return;
        }
        this.setState({
            callAfterInit: true,
            loading: true
        });

        //console.log("dataToPassToService", dataToPassToService);
        // utility.getWorkOrderfromCamlQuery(this.rootWeb, Config.ScheduleWork, q).then((results) => {
        utility.genericPostAPIcall(Config.WOSearchScheduleAPI, dataToPassToService).then((results: any) => {

            _items = [];
            if (results.length === null) {
                if (_items.length <= 0) {
                    this.setState({
                        alertMessage: 'No results found for this criteria',
                        showAlert: true
                    });
                }
                this.setState({ items: _items, loading: false });
                return;
            }
            else {
                results.forEach((item: any) => {
                    let tempWOAsset = JSON.parse(item.Assets);
                    if (tempWOAsset && !Array.isArray(tempWOAsset)) {
                        tempWOAsset = [tempWOAsset];
                    }
                    _items.push({
                        title: item.Title,
                        id: item.Id,
                        scheduleCode: item.Code,
                        assets: tempWOAsset ? tempWOAsset[0].Description : "",
                        assetNumber: tempWOAsset ? tempWOAsset[0].AssetNumber : "",
                        activity: item.Worktypecode + "-" + item.Worktypename,
                        priority: item.Priority,
                        crew: item.Crewtitle,
                        scheduleStart: item.Datecyclestart ? item.Datecyclestart : null,
                        scheduleEnd: item.Datecycleend ? item.Datecycleend : null,
                        nextSchedule: item.Datenextscheduled ? item.Datenextscheduled : null,
                        location: item.Location,
                        duration: item.Plannedduration,
                        updatedBy: item.updatedBy,
                        updatedOn: item.updated ? item.updated : null,
                        createdBy: item.createdBy,
                        createdOn: item.created ? item.created : null,

                    });
                });

            }
            _items = utility._sortItems(_items, 'createdOn', true);
            if (_items.length <= 0) {
                this.setState({
                    alertMessage: 'No results found for this criteria',
                    showAlert: true
                });
            }
            this.setState({ items: _items, loading: false });
            //console.log(_items);
            localStorage.workorders = JSON.stringify(_items);
        }, (err) => {
            console.log(err);
            this.setState({ loading: false });
        });

    }

    public renderAssetPanel() {
        return (
            <div>
                <Dialog
                    open={this.state.showPanel}
                    onClose={this._onClosePanel}
                    // className={'addEditModal'}
                    fullWidth={true}
                    maxWidth={'lg'}
                    aria-labelledby="max-width-dialog-title"
                >
                    <DialogContent dividers={true}>
                        <AssetSearch
                            selectMode={true}
                            onClick={(Asset: any) => this.selectAssets(Asset)}
                            heading={false}
                            hideAssetMapButton={true}
                            Department={this.props.Department}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this._onClosePanel} >
                            Cancel
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>);
    }


    public selectAssets(Asset: any) {
        //console.log(Asset);
        this.setState({
            asset: Asset.AssetNumber,
            assetDetailList: true,
            showPanel: false
        });
    }

    private SearchClicked() {
        //console.log("mapseacrh clicke");
        this.setState(
            {
                iFramePanelOpened: true,
                selectModeMap: true,
                selectParent: false,
            });

    }
    public renderAssetMap() {
        return (
            <AssetMap
                selectAssets={(Asset: any) => this.selectAssetsonMap(Asset)}
                iFramePanelOpened={this.state.iFramePanelOpened}
                onClose={() => this._onDismissMap()}
                selectParent={this.state.selectParent}
                selectModeMap={this.state.selectModeMap}
                aadHttpClient={this.props.aadHttpClient}
            />
        );
    }


    public selectAssetsonMap(selectedAsset: any) {
        let Asset = selectedAsset[0];
        // get cordinates in lat/lng format 
        //console.log("Asset", Asset);
        //console.log("Asset", Asset.id);
        this.setState({
            asset: Asset.id,
            iFramePanelOpened: false,
            selectParent: false,
            selectModeMap: false,
        });
        //console.log(this.state.asset);

    }

    public _onDismissMap() {
        this.setState({
            iFramePanelOpened: false
        });
    }
    private _onShowPanel = (): void => {
        this.setState({ showPanel: true });
    }
    private _onClosePanel = (): void => {
        this.setState({ showPanel: false });
    }

    private _closeModal = (): void => {
        this.setState({ showModal: false });
    }
    private stopCommsAfterInit = (): void => {
        this.setState({ callAfterInit: false });
    }
    private handleValueChange(newValue: any, fieldName: string): void {
        this.setState({ [fieldName]: newValue.value });
    }
    public handleDropdownChange(e: any, fieldName: string) {

        this.setState({ [fieldName]: e.selectedItem });
    }
    public render(): React.ReactElement<any> {
        return (
            <div>
                {this.state.showAlert && this.alert()}
                <div className={"SearchDiv"}>
                    <div className={'headingDiv'}>
                        <span className={"heading"}> Search Schedule Work </span>
                    </div>
                </div>
                <div className={"searchFieldDiv"}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6} md={6} lg={4}>
                            <TextBoxInput
                                stylingMode='underlined'
                                label="Schedule Code"
                                value={this.state.scheduleCode}
                                onValueChanged={(e: any) => this.handleValueChange(e, 'scheduleCode')}
                                onKeyDown={(e: any) => this._handleKeyDown(e)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={4}>

                            <SelectBoxInput
                                stylingMode='underlined'
                                displayExpr="text"
                                valueExpr="id"
                                showClearButton={true}
                                items={this.state.worktypeItems}
                                selectedItem={this.state.worktype}
                                label='Activity'
                                onSelectionChanged={(e: any) => this.handleDropdownChange(e, 'worktype')}

                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={4}>

                            <SelectBoxInput
                                stylingMode='underlined'
                                displayExpr="text"
                                valueExpr="id"
                                showClearButton={true}
                                items={this.state.crewItems}
                                selectedItem={this.state.crewteam}
                                label='Crew '
                                onSelectionChanged={(e: any) => this.handleDropdownChange(e, 'crewteam')}

                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={4}>

                            <SelectBoxInput
                                stylingMode='underlined'
                                displayExpr="text"
                                valueExpr="id"
                                showClearButton={true}
                                items={ScheduleStatus}
                                selectedItem={this.state.scheduleStatus}
                                label='Schedule Status'
                                onSelectionChanged={(e: any) => this.handleDropdownChange(e, 'scheduleStatus')}

                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={4}>
                            <SelectBoxInput
                                stylingMode='underlined'
                                displayExpr="text"
                                valueExpr="id"
                                showClearButton={true}
                                items={this.state.priorityItems}
                                selectedItem={this.state.priority}
                                label='Priority'
                                onSelectionChanged={(e: any) => this.handleDropdownChange(e, 'priority')}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={4}>
                            <TextBoxInput
                                stylingMode='underlined'
                                label="Schedule Group"
                                // pattern={"[A-Za-z]+"}
                                // minLength={2}
                                value={this.state.scheduleGroup}
                                onValueChanged={(e: any) => this.handleValueChange(e, 'scheduleGroup')}
                                onKeyDown={(e: any) => this._handleKeyDown(e)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={4}>
                            <DateBoxInput
                                stylingMode='underlined'
                                label={'Schedule Start Date From'}
                                value={this.state.nextSchedulefrom ? new Date(this.state.nextSchedulefrom) : null}
                                type="date"
                                displayFormat={"dd/MM/yy"}
                                showClearButton={true}
                                onValueChanged={(e: any) => this.handleValueChange(e, 'nextSchedulefrom')}
                            />

                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={4}>
                            <DateBoxInput
                                stylingMode='underlined'
                                label={'Scheduled Start Date To'}
                                value={this.state.nextScheduleTo ? new Date(this.state.nextScheduleTo) : null}
                                type="date"
                                displayFormat={"dd/MM/yy"}
                                showClearButton={true}
                                onValueChanged={(e: any) => this.handleValueChange(e, 'nextScheduleTo')}
                            />

                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={4} className={''}>
                            <SelectBoxInput
                                stylingMode='underlined'
                                displayExpr="text"
                                valueExpr="id"
                                showClearButton={true}
                                items={this.state.departmentItems}
                                selectedItem={this.state.department}
                                label='Department'
                                onSelectionChanged={(e: any) => this.handleDropdownChange(e, 'department')} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={4}>
                            <Grid container>
                                <Grid item xs={8} sm={8}>

                                    <TextBoxInput
                                        stylingMode='underlined'
                                        label="Asset"
                                        // pattern={"[A-Za-z]+"}
                                        // minLength={2}
                                        value={this.state.asset}
                                        onValueChanged={(e: any) => this.handleValueChange(e, 'asset')}
                                        onKeyDown={(e: any) => this._handleKeyDown(e)}
                                    /></Grid>
                                <Grid item xs={2} sm={2}>
                                    <div className="assetSearch">
                                        <EmojiTransportationIcon onClick={() => this._onShowPanel()} className={'assetSearchButton pointer'} />
                                    </div>
                                </Grid>
                                <Grid item xs={2} sm={2}>
                                    <div className="assetSearch">
                                        <RoomIcon onClick={this.SearchClicked} className={'assetSearchButton pointer'} />
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={12}>
                            {(this.props.role === global.worxOnline_Admin || this.props.role === global.worxOnline_Writer) && (

                                <div className={'floatLeft m-20'}>
                                    <NavLink to='/CreateScheduleWork'>

                                        <Button variant='outlined' color="primary" className={'button'}>
                                            New Schedule Work
                                        </Button>
                                    </NavLink>
                                </div>
                            )}
                            <div className={"searchButtonDiv"}>
                                <Button onClick={this.clearFilter} variant='outlined'>
                                    Clear
                                </Button>
                                <Button onClick={this.getWorkOrder} variant='outlined' color="primary" className={'button'}>
                                    Search
                                </Button>
                            </div>
                        </Grid>
                    </Grid>
                </div>
                <div>

                    {!this.state.loading ?
                        <>
                            {(this.state.items && this.state.items.length > 0) && (
                                <DataTable
                                    style={{ width: '100%' }}
                                    filteralbe={true}
                                    groupable={true}
                                    rows={this.state.items}
                                    columns={this.state.columns}
                                    fileName={'Schedulework'}
                                    columnChooser={'scheduleWorkorderColumns'}
                                    selectionMode={false}
                                    showHideColumnMenu={true}
                                    callAfterInit={this.state.callAfterInit}
                                    stopCommsAfterInit={() => this.stopCommsAfterInit()}
                                    loading={this.state.loading}
                                    allowExportSelectedData={false}
                                    showNewBtn={false}
                                    // showNewModal = {() => this._showModal()}
                                    // deleteItem = {(a:any) => this.DeleteDialog(a)}
                                    // editItem = {(a:any) => this.EditItemModal(a)}
                                    showCommandBtn={false}
                                    showViewDetailBtn={false}
                                />
                            )}
                        </>
                        :
                        // <CircularProgress className="loader" />
                        <Spinner size='large' />
                    }
                </div>


                {this.renderAssetPanel()}
                {this.renderAssetMap()}
            </div>

        );
    }


    public alert() {
        return (
            <AlertDialog
                showDialog={this.state.showAlert}
                message={this.state.alertMessage}
                title={Config.productTitle}
                onClose={() => this.setState({ alertMessage: '', showAlert: false })}
            />
        );
    }
}

