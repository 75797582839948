import * as React from 'react';
import utility from '../../../classes/utility';
import { Config } from '../../../classes/config';
import Grid from '@material-ui/core/Grid';
import ScrollView from 'devextreme-react/scroll-view';
import AlertDialog from '../../common/alertDialog';
import capitalize from '@material-ui/core/utils/capitalize';
import TextBoxInput from '../../common/reusableComponents/TextBox';
import Spinner from '../../common/reusableComponents/Spinner';
import NumberBoxInput from '../../common/reusableComponents/NumberBox';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
export default class AddEditSuburb extends React.Component<any, any> {


    constructor(props: any) {
        super(props);

        this.updateSuburb = this.updateSuburb.bind(this);
        this.clearFilter = this.clearFilter.bind(this);

        this.state = {

        };
    }

    public componentDidMount() {
    }
    public didMount() {
        // console.log(this.props.details);
        if (this.props.details && this.props.mode === 'edit') {
            this.populateValue(this.props.details, this.props.mode);
        } else {
            this.setState({
                isActive: true,
                hideAlertButton: false,
                mode: this.props.mode,
            })
        }

    }
    public populateValue(details: any, mode?: any) {
        this.setState({
            id: details.id,
            title: details.title,
            code: details.code,
            //Latlong: {X:(this.props.details.latLng? details.latLng.Value.X: ""), Y:this.props.details.latLng? details.latLng.Value.Y:""},
            lat: details.lat,
            lng: details.lng,
            hideAlertButton: false,
            mode: this.props.mode,
        });
    }
    public updateSuburb() {
        let dataToPassToService: any = {};

        if (!this.state.title) {
            this.setState({
                alertMessage: 'Please enter title',
                showAlert: true
            });
            return;
        }

        if (!this.state.code) {
            this.setState({
                alertMessage: 'Please enter code',
                showAlert: true
            });
            return;
        }
        this.setState({
            alertMessage: <div className={'alertLoader'}><Spinner size='large' label='Loading...' /></div>,
            hideAlertButton: true,
            showAlert: true
        });
        dataToPassToService.title = this.state.title;
        dataToPassToService.code = this.state.code;
        dataToPassToService.latLong = { x: (+this.state.lng), y: (+this.state.lat) };

        dataToPassToService.isActive = true;
        dataToPassToService.tenantId = Config.tenantId;

        //console.log("dataToPassToService", dataToPassToService);
        if (this.props.mode === 'edit') {
            dataToPassToService.id = this.state.id;
            dataToPassToService.updatedBy = this.props.currentUser.Email;
            dataToPassToService.updated = new Date();
            utility.genericUpdateAPICall(Config.suburblistname, this.state.id, dataToPassToService).then((data: any) => {
                //console.log(data);
                this.setState({ showAlert: false });
                this.clearFilter();
                this.props.onClick();
                this.props.message("Suburb has been updated");
            }, (err: any) => {
                console.log(err);
                this.setState({
                    alertMessage: 'Something went wrong!',
                    hideAlertButton: false,
                    showAlert: true
                });
            });
        } else {
            dataToPassToService.createdBy = this.props.currentUser.Email;
            dataToPassToService.created = new Date();
            utility.genericPostAPIcall(Config.suburblistname, dataToPassToService).then((data: any) => {
                //console.log(data);
                this.setState({ showAlert: false });
                this.clearFilter();
                this.props.onClick();
                this.props.message("Suburb has been created");
            }, (err: any) => {
                console.log(err);
                this.setState({
                    alertMessage: 'Something went wrong!',
                    hideAlertButton: false,
                    showAlert: true
                });
            });
        }

    }

    public clearFilter() {
        this.setState({ title: '', code: '', lat: '', lng: '' });
        this.props.onClose();

    }
    private alert() {
        return (
            <AlertDialog
                showDialog={this.state.showAlert}
                message={this.state.alertMessage}
                title={Config.productTitle}
                onClose={() => this.setState({ alertMessage: '', showAlert: false })}
                hideAlertButton={this.state.hideAlertButton}
            />
        );
    }
    private handleValueChange = (event: any, fieldName: string) => {
        this.setState({ [fieldName]: event.value });
    }
    public render(): React.ReactElement<any> {

        return (
            <div>
                {this.state.showAlert && this.alert()}
                <Dialog
                    open={this.props.showModal}
                    onClose={() => this.clearFilter()}
                    fullWidth={true}
                    maxWidth={'lg'}
                    aria-labelledby="max-width-dialog-title"
                    onRendered={() => this.didMount()}
                >
                    <DialogTitle id="scroll-dialog-title">
                        {`${this.props.mode && capitalize(this.props.mode)} Suburb`}
                    </DialogTitle>
                    <IconButton aria-label="close" onClick={() => this.clearFilter()} className="closeIcon">
                        <CloseIcon />
                    </IconButton>
                    <DialogContent dividers={true}>
                        <ScrollView width='100%' height='100%' showScrollbar='onScroll' scrollByThumb={true}>
                            <div className='popContentDiv'>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={6}>
                                        <TextBoxInput
                                            stylingMode='underlined'
                                            label="Title *"
                                            value={this.state.title}
                                            onKeyDown={() => { }}
                                            onValueChanged={(e: any) => this.handleValueChange(e, 'title')}
                                            required={true}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextBoxInput
                                            stylingMode='underlined'
                                            label="Code *"
                                            value={this.state.code}
                                            onKeyDown={() => { }}
                                            onValueChanged={(e: any) => this.handleValueChange(e, 'code')}
                                            required={true}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <NumberBoxInput
                                            stylingMode='underlined'
                                            label="Latitude"
                                            value={this.state.lat}
                                            onKeyDown={() => { }}
                                            onValueChanged={(e: any) => this.handleValueChange(e, 'lat')}
                                            required={true}

                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <NumberBoxInput
                                            stylingMode='underlined'
                                            label="Longitude"
                                            value={this.state.lng}
                                            onKeyDown={() => { }}
                                            onValueChanged={(e: any) => this.handleValueChange(e, 'lng')}
                                            required={true}

                                        />
                                    </Grid>

                                </Grid>
                            </div>
                        </ScrollView>
                    </DialogContent>
                    <DialogActions>

                        <Button onClick={() => this.clearFilter()} variant='contained' color="primary" className={''}>
                            Cancel
                        </Button>
                        <Button onClick={() => this.updateSuburb()} variant='outlined' color="primary" className={'button'}>
                            Save
                        </Button>

                    </DialogActions>
                </Dialog>
            </div>

        );
    }

}