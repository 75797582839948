import * as React from 'react';
import utility from '../../../classes/utility';
import { Config } from '../../../classes/config';
import DataTable from '../../common/DataTable/DataTable';
import AlertDialog from '../../common/alertDialog';
import DeleteDialog from '../../common/DeleteAlert';
import Spinner from '../../common/reusableComponents/Spinner';
import AddEditEvent from './AddEditEventAndCapitalWork';
import global from '../../../classes/global';
export default class Events extends React.Component<any, any> {
    public currentUser: any;
    public columns: any;
    public myRef: any;
    constructor(props: any) {
        super(props);


        this._getItemData = this._getItemData.bind(this);
        this.DeleteDialog = this.DeleteDialog.bind(this);
        this.myRef = React.createRef();
        this.currentUser = this.props.currentUser;
        if (this.props.role !== global.worxOnline_Admin) {
            window.open('#/AccessDenied', '_self');
        }
        this.columns = [
            {
                field: 'id',
                title: 'ID',
                // width: 160,
                type: 'string',
                visibleColumn: false,
                allowHiding: true
            },
            {
                field: 'code',
                title: 'Code',
                // width: 160,
                type: 'string',
                allowHiding: true
            },
            {
                field: 'title',
                title: 'Title',
                // width: 160,
                type: 'string',
                allowHiding: false,
                sortOrder: 'asc'
            },
            {
                field: 'projectId',
                title: 'Project ID',
                // width: 160,
                type: 'string',
                allowHiding: true
            },
            {
                field: 'taskId',
                title: 'Task ID',
                // width: 280,
                type: 'string',
                allowHiding: true,
            },
            {
                field: 'type',
                title: 'Type',
                // width: 280,
                type: 'string',
                allowHiding: true,
            },
            {
                field: 'activity',
                title: 'Activity',
                // width: 160,
                type: 'string',
                allowHiding: true
            },
            {
                field: 'costCentre',
                title: 'Cost Centre',
                // width: 160,
                type: 'string',
                allowHiding: true
            },
            {
                field: 'isActive',
                title: 'Is Active',
                // width: 160,
                type: 'boolean',
                allowHiding: true
            },
            {
                field: 'updatedOn',
                title: 'Updated',
                width: 160,
                type: 'datetime',
                visibleColumn: false,
                allowHiding: true,
                sortOrder: 'desc'
            },
            {
                field: 'updatedBy',
                title: 'Updated By',
                width: 160,
                type: 'string',
                visibleColumn: false,
                allowHiding: true
            },
            {
                field: 'createdOn',
                title: 'Created',
                width: 160,
                type: 'datetime',
                visibleColumn: false,
                allowHiding: true
            },
            {
                field: 'createdBy',
                title: 'Created By',
                width: 160,
                type: 'string',
                visibleColumn: false,
                allowHiding: true
            },
        ];
        this.state = {
            columns: this.columns,
            disableTeam: true,
            disableCrew: true,
        };
    }

    public componentDidMount() {
        this._getItemData();

    }

    public DeleteItem(item: any) {

        this.setState({
            alertMessage: <div className={'alertLoader'}><Spinner size='large' type={'converging-spinner'} /> <br /> Loading...</div>,
            hideAlertButton: true,
            showAlert: true,
            showDeleteAlert: false,
        });
        let dataToPassToService: any = {};
        dataToPassToService.id = item;
        dataToPassToService.isActive = false;
        dataToPassToService.updated = new Date();
        dataToPassToService.updatedBy = this.props.currentUser.Email;
        dataToPassToService.created = this.state.created;
        utility.genericUpdateAPICall(Config.EventsListName, item, dataToPassToService).then((data: any) => {
            this.setState({
                alertMessage: 'Event has been deleted.',
                showAlert: true,
                showDeleteAlert: false,
                hideAlertButton: false,
                loading: false
            });
            this._getItemData();
        }, (err: any) => {
            console.log(err);
            this.setState({
                alertMessage: 'Something went wrong!',
                hideAlertButton: false,
                showAlert: false
            });
        });

    }
    private EditItemModal(item: any) {
        this.setState({ showModal: true, details: item, mode: 'edit' });
    }
    private _showModal = (): void => {
        this.setState({ showModal: true, details: null, mode: 'add' });
    }
    public renderNewModal() {
        return (
            <AddEditEvent
                showModal={this.state.showModal}
                onClose={() => this._closeModal()}
                onClick={() => this._getItemData()}
                message={(e: any) => this.message(e)}
                currentUser={this.currentUser}
                details={this.state.details}
                role={this.props.role}
                mode={this.state.mode}
            />
        );
    }
    private _closeModal = (): void => {
        this.setState({ showModal: false });
    }
    public message(message: any) {
        //console.log(message);
        this.setState({
            alertMessage: message + ' successfully',
            showAlert: true
        });
    }

    private _getItemData() {
        let dataToPassToService: any = {};
        this.setState({
            loading: true
        });
        utility.genericGetAPICallForList(Config.EventsListName, 'GET', dataToPassToService).then((data: any) => {
            const items = data.map((r: any) => ({
                id: r.id,
                title: r.title,
                isActive: r.isActive ? true : false,
                code: r.code,
                projectId: r.projectId,
                type: r.type,
                activity: r.activity,
                taskId: r.taskId,
                costCentre: r.costCentre,
                updatedBy: r.updatedBy,
                updatedOn: r.updated,
                createdBy: r.createdBy,
                createdOn: r.created


            }));
            const sorteditems = utility._sortItems(items, 'title', false);

            this.setState({ items: sorteditems, callAfterInit: true, loading: false, preItems: sorteditems });

        }, (err) => {
            console.log(err);
            this.setState({ loading: false, callAfterInit: true, });
        });
    }


    public setSelection = (id: any) => {
        this.setState({
            selectedRow: id
        });
    }
    private stopCommsAfterInit = (): void => {
        this.setState({ callAfterInit: false });
    }
    public render(): React.ReactElement<any> {
        const filterValue = [['isActive', '=', true]];
        return (
            <React.Fragment>
                {this.renderNewModal()}
                {this.state.showDeleteAlert && this.DeleteAlert()}
                {this.state.showAlert && this.alert()}
                <div className={"SearchDiv"}>
                    <div className={'headingDiv'}>
                        <span className={"heading"}> Events and Capital Works </span>
                    </div>
                </div>
                <div>
                    {!this.state.loading ?
                        <div ref={this.myRef}>
                            <DataTable
                                style={{ width: '100%' }}
                                filteralbe={true}
                                groupable={true}
                                rows={this.state.items}
                                columns={this.state.columns}
                                fileName={'Events'}
                                columnChooser={'EventsColumns'}
                                selectionMode={false}
                                showHideColumnMenu={true}
                                callAfterInit={this.state.callAfterInit}
                                stopCommsAfterInit={() => this.stopCommsAfterInit()}
                                loading={this.state.loading}
                                allowExportSelectedData={false}
                                showNewModal={() => this._showModal()}
                                deleteItem={(a: any) => this.DeleteDialog(a)}
                                editItem={(a: any) => this.EditItemModal(a)}
                                // viewFile={(e)=>this.viewFile(e)}
                                showCommandBtn={true}
                                hideCommandDeleteBtn={false}
                                showNewBtn={true}
                                showViewDetailBtn={false}
                                hideNewBtn={this.state.showModal}
                                defaultfiltervalue={filterValue}
                            />
                        </div>
                        :
                        <Spinner size='large' type={'converging-spinner'} className="loader" />
                    }

                </div>
            </React.Fragment>
        );
    }
    public alert() {
        return (
            <AlertDialog
                showDialog={this.state.showAlert}
                message={this.state.alertMessage}
                title={Config.productTitle}
                hideAlertButton={this.state.hideAlertButton}
                onClose={() => this.setState({ alertMessage: '', showAlert: false })}
            />
        );
    }
    public DeleteDialog(item: any) {
        this.setState({
            DeletealertMessage: 'Are you sure you want to delete?',
            showDeleteAlert: true,
            deleteItem: item
        });
    }
    public DeleteAlert() {
        return (
            <DeleteDialog
                showDialog={this.state.showDeleteAlert}
                message={this.state.DeletealertMessage}
                title={"worxOnline"}
                onClose={() => this.setState({ DeletealertMessage: '', showDeleteAlert: false })}
                onOkButton={() => this.DeleteItem(this.state.deleteItem)}
                onCancelButton={() => this.setState({ DeletealertMessage: '', showDeleteAlert: false })}
            />
        );
    }
}
