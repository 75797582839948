import * as React from 'react';
import utility from '../../classes/utility';
import { Config } from '../../classes/config';
import ScrollView from 'devextreme-react/scroll-view';
import Grid from '@material-ui/core/Grid';
import AlertDialog from '../common/alertDialog';
import Spinner from '../common/reusableComponents/Spinner';
import SelectBoxInput from '../common/reusableComponents/SelectBox';
import TextAreaInput from '../common/reusableComponents/TextArea';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
export default class EditWorkRequest extends React.Component<any, any> {

    public workRequest = this.props.details;
    public requesttypeItems: any;
    public priorityItems: any = [];
    public currentUser: any;
    public users: any;
    public setupDataPromise: any = [];
    constructor(props: any) {
        super(props);
        this.requesttypeItems = [];
        this.currentUser = this.props.currentUser;
        this.setupDataPromise = [];
        this.priorityItems = [];
        this.users = [];
        this.state = {
            show2: false,
        };
        this.update = this.update.bind(this);
    }


    public componentDidMount() {

    }
    public didMount() {
        this.setupDataPromise = [];
        this.setState({ loading: true })
        this.getWRType();
        this.getUsers();
        this._getPrioritiesData();
        Promise.all(this.setupDataPromise).then(items => {
            this.workRequest = this.props.details;
            this.setState({
                workRequest: this.props.details
            });
            if (!Array.isArray(this.workRequest)) {
                this.workRequest = new Array(this.workRequest);
            }
            this.workRequest.forEach((item: any) => {
                this.setState({
                    location: item.location,
                    comments: item.comments ? item.comments : '',
                    loading: false
                });


            });

        });

    }
    public getWRType() {
        this.setupDataPromise.push(
            utility.genericGetAPICall(`${Config.WRTlistname}?showAll=true`).then((data: any) => {
                this.requesttypeItems = [];
                let requesttype: any = '';
                for (let r of data) {
                    if (this.props.details?.workRequestTypeId && (this.props.details.workRequestTypeId === r.id)) {
                        requesttype = { id: r.id, text: r.title };
                    }
                    this.requesttypeItems.push({ id: r.id, text: r.title, 'Select All': (r.title) });
                }
                this.requesttypeItems = utility._sortItems(this.requesttypeItems, 'text', false);
                this.setState({
                    requesttypeItems: this.requesttypeItems,
                    requesttype: requesttype ? requesttype : ''
                });
                var stateData = { ...this.state };
                localStorage.setItem('woState', JSON.stringify(stateData));

            }));
    }
    public getUsers() {
        this.users = [];
        let responsibleofficer: any = '';
        this.setupDataPromise.push(
            utility.genericGetAPICallForList(Config.employeelistname, 'GET').then((data: any) => {
                for (let r of data) {
                    if (this.props.details?.responsibilityCode && (this.props.details.responsibilityCode === r.id)) {
                        responsibleofficer = {
                            id: r.id, text: r.fullName,
                            email: r.email
                        };
                    }
                    this.users.push({
                        id: r.id,
                        text: r.fullName,
                        email: r.email
                    });
                }
                const sortedItems = utility._sortItems(this.users, 'text', false);
                this.setState({
                    users: sortedItems,
                    responsibleofficer: responsibleofficer ? responsibleofficer : ''
                });
            }));
    }
    private _getPrioritiesData() {
        this.setupDataPromise.push(
            utility.genericGetAPICallForList(Config.PriorityListName, 'GET').then((data: any) => {
                //console.log(data);
                this.priorityItems = [];
                let prioritytext: any = '';

                for (let r of data) {

                    if (r.type === 'WorkRequest') {
                        this.priorityItems.push({ id: (r.id), text: `${r.title}`, 'Select All': (r.title) });
                        if (this.props.details?.priorityId && (this.props.details.priorityId === r.id)) {
                            prioritytext = { id: r.id, text: r.title };
                        }
                    }


                }
                this.setState({ priorityItems: this.priorityItems, priority: prioritytext ? prioritytext : '' });
            }, (err) => {
                console.log(err);
            }));
    }

    public handleDropdownChange(e: any, fieldName: string) {
        this.setState({ [fieldName]: e.selectedItem });
    }

    public update() {
        if (!this.state.requesttype) {
            this.setState({
                alertMessage: 'Please select Request Type',
                showAlert: true
            });
            return;
        }

        if (!this.state.location) {
            this.setState({
                alertMessage: 'Please select Location',
                showAlert: true
            });
            return;
        }

        this.setState({ updating: true });
        this.setState({
            alertMessage: <Spinner label='Updating Work Request' />,
            hideAlertButton: true,
            showAlert: true
        });
        let currentdate = new Date();
        let dataToPassToService: any = {};
        dataToPassToService.workRequestNumber = this.props.details.workrequestNumber;
        dataToPassToService.location = this.state.location;
        //dataToPassToService.priorityId = this.state.priority.id;
        dataToPassToService.workRequestTypeId = this.state.requesttype.id;
        dataToPassToService.responsibleOfficerId = this.state.responsibleofficer.id;
        dataToPassToService.responsibleOfficerName = this.state.responsibleofficer.text;
        dataToPassToService.comments = this.state.comments;
        dataToPassToService.updated = currentdate.toISOString();
        dataToPassToService.updatedBy = this.currentUser.Email;
        dataToPassToService.id = this.props.details.id;
        utility.genericUpdateAPICall(Config.WRList, this.props.details.id, dataToPassToService).then((results: any) => {
            this.setState({
                hideAlertButton: false,
                alertMessage: 'Work Request has been updated',
                showAlert: true
            });
            this.props.onClose();
        }, (err: any) => {
            console.log(err);
            this.setState({
                alertMessage: 'An error occoured. Unable to update details.',
                showAlert: true,
                hideAlertButton: false,
            });
        });
    }
    public alert() {
        return (
            <AlertDialog
                showDialog={this.state.showAlert}
                message={this.state.alertMessage}
                title={Config.productTitle}
                onClose={() => this._closeDialog()}
                hideAlertButton={this.state.hideAlertButton}
            />
        );
    }
    private _closeDialog = (): void => {
        this.setState({ alertMessage: '', showAlert: false });
    }
    public _textAreaChanged = (e: any, fieldName: string) => {

        this.setState({ [fieldName]: e.value });
    }

    public render(): React.ReactElement<any> {

        return (
            <div>
                {this.state.showAlert && this.alert()}
                <Dialog
                    open={this.props.showModal}
                    onClose={() => this.props.onClose()}
                    // className={'addEditModal'}
                    fullWidth={true}
                    maxWidth={'lg'}
                    aria-labelledby="max-width-dialog-title"
                    onRendered={() => this.didMount()}
                >
                    <DialogTitle id="scroll-dialog-title">
                        Edit Work Request
                    </DialogTitle>
                    <IconButton aria-label="close" onClick={() => this.props.onClose()} className="closeIcon">
                        <CloseIcon />
                    </IconButton>
                    <DialogContent dividers={true}>
                        <ScrollView width='100%' height='100%' showScrollbar='onScroll' scrollByThumb={true}>
                            <div className='popContentDiv'>
                                {!this.state.loading ?
                                    <Grid container spacing={3}>
                                        {this.state.requesttypeItems &&
                                            <Grid item xs={12} sm={6} >
                                                <SelectBoxInput
                                                    stylingMode='underlined'
                                                    displayExpr="text"
                                                    valueExpr="id"
                                                    showClearButton={true}
                                                    items={this.state.requesttypeItems}
                                                    selectedItem={this.state.requesttype}
                                                    label='Request Type'
                                                    onSelectionChanged={(e: any) => this.handleDropdownChange(e, 'requesttype')}
                                                />
                                            </Grid>
                                        }
                                        <Grid item xs={12} sm={6}>
                                            <TextAreaInput
                                                value={this.state.location}
                                                label="location"
                                                onValueChanged={(e: any) => this._textAreaChanged(e, 'location')}
                                                spellcheck={true}
                                                showClearButton={true}
                                            />
                                        </Grid>
                                        {this.state.users &&
                                            <Grid item xs={12} sm={6} className={''}>
                                                <SelectBoxInput
                                                    items={this.state.users}
                                                    stylingMode='underlined'
                                                    label="Responsible Officer"
                                                    selectedItem={this.state.responsibleofficer}
                                                    displayExpr="text"
                                                    valueExpr="key"
                                                    showClearButton={true}
                                                    onSelectionChanged={(e: any) => this.handleDropdownChange(e, 'responsibleofficer')}
                                                />
                                            </Grid>
                                        }
                                    </Grid>
                                    :
                                    <Spinner size="large" label="Loading all componenent. Please wait..." />
                                }

                            </div>
                        </ScrollView>

                    </DialogContent>
                    <DialogActions>

                        <Button onClick={() => this.props.onClose()} variant='contained' color="primary" className={''}>
                            Cancel
                        </Button>
                        <Button onClick={() => this.update()} variant='outlined' color="primary" className={'button'}>
                            Update
                        </Button>

                    </DialogActions>
                </Dialog>

            </div>

        );
    }

}
