import * as React from 'react';
import utility from '../../../classes/utility';
import { Config } from '../../../classes/config';
import global from '../../../classes/global';
import DataTable from '../../common/DataTable/DataTable';
import AlertDialog from '../../common/alertDialog';
import DeleteDialog from '../../common/DeleteAlert';
import Spinner from '../../common/reusableComponents/Spinner';
import AddEditActivities from './AddEditActivities';

let _items: any = [];

export default class Activities extends React.Component<any, any> {

    public currentUser: any;
    public columns: any;
    public myRef: any;
    constructor(props: any) {
        super(props);
        this.DeleteItem = this.DeleteItem.bind(this);
        this.EditItemModal = this.EditItemModal.bind(this);
        this.DeleteDialog = this.DeleteDialog.bind(this);
        this.myRef = React.createRef();

        this.currentUser = {
            DisplayName: this.props.currentUser.DisplayName ? this.props.currentUser.DisplayName : this.props.currentUser.displayName,
            Email: this.props.currentUser.Email ? this.props.currentUser.Email : this.props.currentUser.email,
            LoginName: this.props.currentUser.LoginName ? this.props.currentUser.LoginName : this.props.currentUser.loginName
        };
        if (this.props.role !== global.worxOnline_Admin) {
            window.open('#/AccessDenied', '_self');
        };

        this.columns = [
            {
                field: 'id',
                title: 'ID',
                width: 80,
                type: 'string',
                visibleColumn: false,
                allowHiding: false,
            },
            {
                field: 'title',
                title: 'Title',
                width: 360,
                type: 'string',
            },
            {
                field: 'code',
                title: 'Code',
                width: 220,
                type: 'string',
                allowHiding: false,
            },
            {
                field: 'department',
                title: 'Department',
                // width: 160,
                type: 'string',
                allowHiding: true
            },
            {
                field: 'teamIdsstring',
                title: 'Team',
                width: 160,
                type: 'string',
                allowHiding: true
            },
            {
                field: 'workRequestTypeTitle',
                title: 'Parent Activity',
                // width: 280,
                type: 'string',
                allowHiding: true,
            },
            {
                field: 'workGroups',
                title: 'Work Groups',
                width: 160,
                type: 'string',
                allowHiding: true
            },

            {
                field: 'category',
                title: 'Activity Catergory',
                width: 120,
                type: 'string',
                allowHiding: true
            },
            {
                field: 'projectId',
                title: 'Project ID',
                width: 120,
                type: 'string',
                allowHiding: true
            },
            {
                field: 'taskId',
                title: 'Task ID',
                width: 120,
                type: 'string',
                allowHiding: true
            },
            {
                field: 'activity',
                title: 'Activity',
                width: 120,
                type: 'string',
                allowHiding: true
            },
            {
                field: 'costCentre',
                title: 'Cost Centre',
                width: 120,
                type: 'string',
                allowHiding: true
            },
            {
                field: 'befpicreq',
                title: 'Before Photo Required',
                width: 120,
                type: 'boolean',
                allowHiding: true
            },
            {
                field: 'aftpicreq',
                title: 'After Photo Required',
                width: 120,
                type: 'boolean',
                allowHiding: true
            },
            {
                field: 'riskassesment',
                title: 'Site Risk Assessment',
                width: 120,
                type: 'boolean',
                allowHiding: true
            },
            {
                field: 'highRisk',
                title: 'High Risk',
                width: 120,
                type: 'boolean',
                allowHiding: true
            },
            {
                field: 'reuseRiskassessmentforDays',
                title: 'Reuse Risk Assessment',
                width: 120,
                type: 'boolean',
                allowHiding: true
            },
            {
                field: 'fastCompleted',
                title: 'Fast Complete',
                width: 120,
                type: 'boolean',
                allowHiding: true
            },
            {
                field: 'fastCreated',
                title: 'Fast Create',
                width: 120,
                type: 'boolean',
                allowHiding: true
            },
            {
                field: 'tcp',
                title: 'TCP',
                width: 120,
                type: 'boolean',
                allowHiding: true
            },
            {
                field: 'softComplete',
                title: 'Soft Complete',
                width: 120,
                type: 'boolean',
                visibleColumn: false,
                allowHiding: true
            },
            {
                field: 'template',
                title: 'Template',
                width: 120,
                type: 'string',
                allowHiding: true
            },
            {
                field: 'isActive',
                title: 'Active',
                width: 120,
                type: 'boolean',
                allowHiding: true
            },
            {
                field: 'updatedOn',
                title: 'Updated',
                width: 160,
                type: 'datetime',
                visibleColumn: false,
                allowHiding: true,
                sortOrder: 'desc'
            },
            {
                field: 'updatedBy',
                title: 'Updated By',
                width: 160,
                type: 'string',
                visibleColumn: false,
                allowHiding: true
            },
            {
                field: 'createdOn',
                title: 'Created',
                width: 160,
                type: 'datetime',
                visibleColumn: false,
                allowHiding: true
            },
            {
                field: 'createdBy',
                title: 'Created By',
                width: 160,
                type: 'string',
                visibleColumn: false,
                allowHiding: true
            },
        ];
        this.state = {
            items: _items,
            columns: this.columns
        };
    }

    public componentDidMount() {
        this._getItemsData();

    }


    public DeleteItem(item: any) {
        this.setState({
            alertMessage: <div className={'alertLoader'}><Spinner size='large' label="Loading..." /></div>,
            hideAlertButton: true,
            showAlert: true,
            showDeleteAlert: false,
        });
        utility.genericDeleteAPICall(Config.WTlistname, item).then((data: any) => {
            this.setState({
                alertMessage: 'Activity has been deleted.',
                showAlert: true,
                showDeleteAlert: false,
                hideAlertButton: false,
                loading: false
            });
            this._getItemsData()
        }, (err) => {
            // console.log(err);
            this.setState({
                alertMessage: 'Cannot delete activity. Please try again later.',
                showAlert: true,
                showDeleteAlert: false,
                hideAlertButton: false,
            });
        });

    }

    // edit activty modal

    private EditItemModal(item: any) {
        this.setState({ showModal: true, details: item, mode: 'edit' });
    }

    public renderNewModal() {
        return (
            <AddEditActivities
                showModal={this.state.showModal}
                onClose={() => this._closeModal()}
                onClick={() => this._getItemsData()}
                message={(e: any) => this.message(e)}
                currentUser={this.currentUser}
                mode={this.state.mode}
                details={this.state.details}
            />
        );
    }    // add activity modal
    private _showModal = (): void => {

        this.setState({ showModal: true, details: null, mode: 'add' });
    }

    private _closeModal = (): void => {
        this.setState({ showModal: false });
    }

    public message(message: any) {
        // //console.log(message);
        this.setState({
            alertMessage: message + ' successfully',
            showAlert: true
        });
    }

    private _getItemsData() {

        let dataToPassToService: any = {};
        this.setState({
            loading: true
        });
        utility.genericGetAPICallForList(Config.SearchActivitiesApi, 'POST', dataToPassToService).then((data: any) => {
            _items = [];
            data.map((r: any) => {
                let workGrpTitle: String = '';
                let teamsTitle:String = '';
                if (r.workGroups && r.workGroups.length > 0) {
                    r.workGroups.forEach((workGrp: any) => {
                        workGrpTitle += workGrp.title + ',';
                    });
                    workGrpTitle = workGrpTitle.substring(0, workGrpTitle.length - 1);
                }
                if(r.teams && r.teams.length > 0){
                    r.teams.forEach((team: any) => {
                        teamsTitle += team.title + ', ';
                    });
                    teamsTitle = teamsTitle.substring(0, teamsTitle.length - 1);
                }
                
                _items.push({
                    id: r.id,
                    title: r.title,
                    code: r.code,
                    departmentId: r.departmentId,
                    department: r.department,
                    order: r.order,
                    riskassesment: r.riskAssessmentRequired ? true : false,
                    aftpicreq: r.photoRequiredAfter ? true : false,
                    befpicreq: r.photoRequiredBefore ? true : false,
                    highRisk: r.highRisk ? true : false,
                    fastCreated: r.allowFastCreate ? true : false,
                    fastCompleted: r.allowFastComplete ? true : false,
                    softComplete: r.softComplete ? true : false,
                    workRequestTypeId: r.workRequestTypeId,
                    workRequestTypeTitle: r.workRequestTypeTitle ? r.workRequestTypeTitle : '',
                    reuseRiskassessmentforDays: r.reuseRiskAssessmentForDays === 1 ? true : false,
                    category: r.category,
                    teamIds: r.teamIds,
                    teamIdsstring: teamsTitle,
                    workGroups: workGrpTitle,
                    formId: r.riskFormIds,
                    riskGroupId: r.riskGroupIds,
                    workGroupIds: r.workGroupIds,
                    swmsDocuments: r.swmsDocuments,
                    swms: r.swms,
                    projectId: r.projectId,
                    activity: r.activity,
                    costCentre: r.costCentre,
                    taskId: r.taskId,
                    tcp: r.tcp ? true : false,
                    template: r.template,
                    isActive: r.isActive ? true : false,
                    form: r.formIds,
                    gisLayer: r.gisLayers ? r.gisLayers.layers : "",
                    updatedBy: r.updatedBy,
                    updatedOn: r.updated || null,
                    createdBy: r.createdBy,
                    createdOn: r.created || null,
                })
            });

            const sortedItems = utility._sortItems(_items, 'updatedOn', true);

            if (sortedItems.length === 0) {
                this.setState({
                    alertMessage: 'No results found for this criteria',
                    showAlert: true,
                    items: [],
                    preItems: [],
                    callAfterInit: true,
                    loading: false,
                });
            } else {
                this.setState({
                    items: sortedItems,
                    preItems: sortedItems,
                    callAfterInit: true,
                    loading: false,
                });
            }
        }, (err) => {
            this.setState({ loading: false, callAfterInit: true, });
        });
    }

    public setSelection = (id: any) => {
        this.setState({
            selectedRow: id
        });
    }
    private stopCommsAfterInit = (): void => {
        this.setState({ callAfterInit: false });
    }
    private _sortItems = (items: any[], sortBy: string, descending = false): any[] => {
        if (descending) {
            return items.sort((a: any, b: any) => {
                if (a[sortBy] < b[sortBy]) {
                    return 1;
                }
                if (a[sortBy] > b[sortBy]) {
                    return -1;
                }
                return 0;
            });
        } else {
            return items.sort((a: any, b: any) => {
                if (a[sortBy] < b[sortBy]) {
                    return -1;
                }
                if (a[sortBy] > b[sortBy]) {
                    return 1;
                }
                return 0;
            });
        }
    }
    public render(): React.ReactElement<any> {
        const filterValue = [['isActive', '=', true]];
        return (
            <React.Fragment>
                {this.renderNewModal()}
                {this.state.showDeleteAlert && this.DeleteAlert()}
                {this.state.showAlert && this.alert()}
                <div className={"SearchDiv"}>
                    <div className={'headingDiv'}>
                        <span className={"heading"}> Activities </span>
                    </div>
                </div>
                <div>
                    {!this.state.loading ?
                        <div ref={this.myRef}>
                            <DataTable
                                style={{ width: '100%' }}
                                filteralbe={true}
                                groupable={true}
                                rows={this.state.items}
                                columns={this.state.columns}
                                fileName={'Activities'}
                                columnChooser={'activitiesColumns'}
                                selectionMode={false}
                                showHideColumnMenu={true}
                                callAfterInit={this.state.callAfterInit}
                                stopCommsAfterInit={() => this.stopCommsAfterInit()}
                                loading={this.state.loading}
                                allowExportSelectedData={false}
                                showNewModal={() => this._showModal()}
                                deleteItem={(a: any) => this.DeleteDialog(a)}
                                editItem={(a: any) => this.EditItemModal(a)}
                                // viewFile={(e)=>this.viewFile(e)}
                                showCommandBtn={true}
                                showNewBtn={true}
                                hideNewBtn={this.state.showModal || this.state.showEditModal || this.props.hideNewBtn}
                                showViewDetailBtn={false}
                                defaultfiltervalue={filterValue}
                            />
                        </div>
                        :
                        <Spinner size='large' type={'converging-spinner'} className="loader" />
                    }

                </div>
            </React.Fragment>
        );
    }
    public alert() {
        return (
            <AlertDialog
                showDialog={this.state.showAlert}
                message={this.state.alertMessage}
                title={Config.productTitle}
                hideAlertButton={this.state.hideAlertButton}
                onClose={() => this.setState({ alertMessage: '', showAlert: false })}
            />
        );
    }
    public DeleteDialog(item: any) {
        // //console.log({ item });
        this.setState({
            DeletealertMessage: 'Are you sure you want to delete?',
            showDeleteAlert: true,
            deleteItem: item
        });
    }
    public DeleteAlert() {
        return (
            <DeleteDialog
                showDialog={this.state.showDeleteAlert}
                message={this.state.DeletealertMessage}
                title={"worxOnline"}
                onClose={() => this.setState({ DeletealertMessage: '', showDeleteAlert: false })}
                onOkButton={() => this.DeleteItem(this.state.deleteItem)}
                onCancelButton={() => this.setState({ DeletealertMessage: '', showDeleteAlert: false })}
            />
        );
    }

}

