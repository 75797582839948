import * as React from 'react';
import moment from 'moment';
import utility from '../../classes/utility';
import { Config } from '../../classes/config';
import AlertDialog from '../common/alertDialog';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import Paper from '@material-ui/core/Paper';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import AssetSearch from '../AssetSearch/AssetSearch';
import AssetMap from '../AssetSearch/AssetMap';
import Spinner from '../common/reusableComponents/Spinner';
import SelectBoxInput from '../common/reusableComponents/SelectBox';
import CheckBoxInput from '../common/reusableComponents/CheckBox';
import TextBoxInput from '../common/reusableComponents/TextBox';
import DateBoxInput from '../common/reusableComponents/DateBox';
import TextAreaInput from '../common/reusableComponents/TextArea';
import DataGrid, { Column, Scrolling, Sorting, LoadPanel } from 'devextreme-react/data-grid';
import RadioGroupInput from '../common/reusableComponents/RadioGroup';
import NumberBoxInput from '../common/reusableComponents/NumberBox';


let _items: any = [];

// momentLocalizer(Moment);

const options: any = [
  { value: 'Maintenance', label: 'Maintenance' },
  { value: 'Inspection', label: 'Inspection' },
];
const optionsofSingle: any = [
  { value: 'A', label: 'One work order for all assets', disabled: true },
  { value: 'B', label: 'Work order per asset' },
];
// const optionsofGenerateImmediately: IChoiceGroupOption[] = [
//   { id: 'A', text: 'Scheduled run' },
//   { id: 'B', text: 'Generate work order immediately', disabled: this.state.generateWO? this.state.generateWO:this.state.generateWO},
// ];


const Period: any = [
  { id: '1', text: 'Day' },
  { id: '7', text: 'Week' },
  { id: '30', text: 'Month' }
];
const ScheduleType: any = [
  { id: 'Time', text: 'Time' },
  // { id: 'Counter', text: 'Counter' }
];


export default class CreateScheduleWork extends React.Component<any, any> {

  public Assets: any;
  //public AssetsonMap;
  public suburbItems: any = [];
  public crewItems: any = [];
  public worktypeItems: any = [];
  public currentUser: any;
  public departmentItems: any = [];
  public teamsItems: any = [];
  public priorityItems: any = [];
  public inspectionFormItems: any = [];
  public StatusItems: any = [];
  constructor(props: any) {
    super(props);

    this.suburbItems = [];
    this.departmentItems = [];
    this.teamsItems = [];
    this.StatusItems = [];
    this.crewItems = [];
    this.worktypeItems = [];
    this.priorityItems = [];
    this.inspectionFormItems = [];
    this.Assets = [];
    // this.AssetsonMap = [];
    // this.attachmentBase64Array = [];
    this.clearFilter = this.clearFilter.bind(this);
    this.clearAsset = this.clearAsset.bind(this);
    this.createWorkOrder = this.createWorkOrder.bind(this);
    this.createScheduleWorkOrder = this.createScheduleWorkOrder.bind(this);
    this.createLineItems = this.createLineItems.bind(this);
    this.SearchClicked = this.SearchClicked.bind(this);
    this.generateTask = this.generateTask.bind(this);

    this.currentUser = this.props.currentUser;

    const _optionsofGenerateImmediately: any = [
      { value: 'A', label: 'Scheduled run', disabled: true },
      { value: 'B', label: 'Generate work order immediately' },
    ];


    this.state = {
      lineItems: _items,

      optionsofGenerateImmediately: _optionsofGenerateImmediately,
      serviceRequest: '',
      location: '',
      priority: null,
      suburb: '',
      woworktype: null,
      title: '',
      Eststartdate: null,
      Estenddate: null,
      befpicreq: false,
      aftpicreq: false,
      riskassessment: false,
      crew: '',
      comments: '',
      description: '',
      Assets: this.Assets,
      //  AssetsonMap: this.AssetsonMap,
      showPanel: false,
      folderName: "",
      departmentID: "",
      generateWO: true,
      disableTeam: true,
      disableCrew: true,
      workOrderType: 'Maintenance',
      scheduleType: { id: 'Time', text: 'Time' },
      generateImmediately: 'B',
      createSingleWO: 'B',
      disabledTeamleader: true,
      isActive: true
    };

  }

  public componentDidMount() {
    this._getCrewDataAsync();
    this._getworkTypeDataAsync();
    this._getsuburbDataAsync();
    this._getDepartmentData();
    this._getTeamDataAsync();
    this._getPrioritiesData();
    this._getInspectionFormData();
    // this._getStatusDataAsync();
  }
  public clearFilter() {

    this.setState({
      location: '',
      scheduleCode: '',
      woworktype: '',
      scheduleType: '',
      priority: null,
      title: '',
      isActive: '',
      befpicreq: false,
      aftpicreq: false,
      crew: '',
      department: '',
      teams: '',
      comment: '',
      riskassessment: false,
      Assets: [],
      generateImmediately: '',
      createSingleWO: '',
      workOrderType: '',
      plannedHours: '',
      description: '',
      Eststartdate: '',
      Estenddate: '',
      period: '',
      frequency: '',
      lineItems: [],
      teamLeader: '',
      suburb: '',
    });
    this.Assets = [];
  }

  public clearAsset() {
    this.Assets = [];
    this.setState({ Assets: [] });
  }

  private _getInspectionFormData() {
    utility.genericGetAPICall(Config.InspectionFormList).then((data: any) => {
      //console.log(data);
      this.inspectionFormItems = [];

      for (let r of data) {
        this.inspectionFormItems.push({ id: (r.id), text: (r.title) });
      }
      this.setState({
        inspectionFormItems: this.inspectionFormItems,
        allinspectionFormItems: this.inspectionFormItems,
      });
    }, (err) => {
      console.log(err);
    });
  }
  private _getPrioritiesData() {
    utility.genericGetAPICall(Config.PriorityListName).then((data: any) => {
      //console.log(data);
      this.priorityItems = [];

      for (let r of data) {
        this.priorityItems.push({ id: (r.id), text: (r.title) });
        if (r.Title === "Priority 3") {
          this.setState({ priority: { id: r.id, text: r.title } });
        }
      }
      this.setState({ priorityItems: this.priorityItems });
    }, (err) => {
      console.log(err);
    });
  }
  private _getsuburbDataAsync() {
    utility.genericGetAPICall(Config.suburblistname).then((data: any) => {
      this.suburbItems = [];
      // console.log("suburb result", data);
      for (let r of data) {
        this.suburbItems.push({ id: r.id, text: r.title });
      }
      this.setState({
        suburbItems: this.suburbItems,
        allsuburbItems: this.suburbItems,
      });
    });
  }

  private _getDepartmentData() {

    utility.genericGetAPICall(Config.DepartmentList).then((data: any) => {
      //console.log(data);
      this.departmentItems = [];

      for (let r of data) {
        this.departmentItems.push({ id: r.id, text: r.title });
      }
      this.setState({
        departmentItems: this.departmentItems,
        alldepartmentItems: this.departmentItems,
      });
    }, (err) => {
      console.log(err);
    });
  }

  private _getTeamDataAsync() {

    utility.genericGetAPICall(Config.TeamList).then((data: any) => {
      //console.log(data);
      this.teamsItems = [];

      for (let r of data) {
        this.teamsItems.push({ id: r.id, text: (r.title), departmentId: r.departmentId });
      }
      this.setState({ teamsItems: this.teamsItems });
    }, (err) => {
      console.log(err);
    });
  }
  private _getCrewDataAsync() {

    utility.genericGetAPICall(Config.crewlistname).then((data: any) => {
      //console.log(data);
      this.crewItems = [];

      for (let r of data) {
        this.crewItems.push({ id: (r.id), text: (r.code + ' - ' + r.title), teamId: r.teamId, departmentId: r.departmentId });
      }
      this.setState({ crewItems: this.crewItems });
    }, (err) => {
      console.log(err);
    });
  }

  private _getworkTypeDataAsync() {

    utility.genericGetAPICall(Config.WTlistname).then((data: any) => {
      //console.log(data);
      this.worktypeItems = [];

      for (let r of data) {
        this.worktypeItems.push({
          id: r.id,
          text: (r.title),
          befpicreq: r.photoRequiredBefore ? true : false,
          aftpicreq: r.photoRequiredAfter ? true : false,
          riskassessment: r.riskAssessmentRequired ? true : false,
        });
      }

      this.worktypeItems = utility._sortItems(this.worktypeItems, 'text', false);
      this.setState({
        worktypeItems: this.worktypeItems,
        allworktypeItems: this.worktypeItems,
      });
    }, (err) => {
      console.log(err);
    });
  }


  public createLineItems() {

    if (this.state.scheduleCode && this.state.Eststartdate && this.state.Estenddate && this.state.frequency && this.state.period && this.state.woworktype) {
      // debugger;
      let startDate = new Date(this.state.Eststartdate);
      let startDateFirstOccurance = new Date(this.state.Eststartdate);
      let endDate = new Date(this.state.Estenddate);
      let frequency = this.state.frequency;
      let interval = frequency * + this.state.period.id;
      let tempInterval = frequency * + this.state.period.id
      // To calculate the time difference of two dates 
      var DifferenceInTime = endDate.getTime() - startDate.getTime();
      // To calculate the no. of days between two dates 
      var DifferenceInDays = DifferenceInTime / (1000 * 3600 * 24);
      let intervalDifference;
      if (this.state.firstOccurance) {
        intervalDifference = DifferenceInDays / interval;
      } else {
        intervalDifference = (DifferenceInDays / interval) - 1;
      }
      //console.log("intervalDifference", intervalDifference);
      //console.log("frequency", frequency);
      let line = 1;
      let lineItems = [];
      for (let i = 0; i <= intervalDifference; i++) {
        if (this.state.period.text === "Month") {
          startDateFirstOccurance.setMonth(startDate.getMonth() + (+frequency));
        }
        else {
          startDateFirstOccurance.setDate(startDate.getDate() + (frequency * +this.state.period.id));
        }
        let dateInterval = this.state.firstOccurance ? (interval - tempInterval) / +this.state.period.id : interval / +this.state.period.id;
        let dInterval = dateInterval + ' ' + this.state.period.text;
        let tempScheduleStartdate = this.state.firstOccurance ? startDate.toISOString() : startDateFirstOccurance.toISOString();
        lineItems.push({
          LineNo: line,
          ScheduledDate: tempScheduleStartdate,
          ScheduledDateView: moment(tempScheduleStartdate).format("DD/MM/YY h:mm a"),
          DateInterval: dateInterval,
          DateIntervalType: this.state.period.text,
          Interval: dInterval,
          Crew: this.state.crew.text,
          CrewId: this.state.crew.id,
          Status: this.state.generateImmediately === "B" ? "Work Order Generated" : "Planned",
          GenerateWorkOrder: true,
          Activity: this.state.woworktype.text,
          ScheduleCode: this.state.scheduleCode,
          Department: this.state.department.text,
          DepartmentID: this.state.department.id,
          // WorkOrderNos: []

        })
        if (this.state.period.text === "Month") {
          startDate.setMonth(startDate.getMonth() + (+frequency));
        }
        else {
          startDate.setDate(startDate.getDate() + (frequency * +this.state.period.id));
        }
        interval = interval + (frequency * +this.state.period.id);
        line++;
      }
      this.setState({
        lineItems: lineItems,
        nextSchedule: lineItems.length > 0 ? lineItems[0].ScheduledDate : '',
      });
    }
  }

  public createScheduleWorkOrder() {
    if (this.state.generateImmediately && this.state.generateImmediately === "B") {
      this.createLineItems();
    }
    if (!this.state.title) {
      this.setState({
        alertMessage: 'Please enter title',
        showAlert: true
      });
      return;
    }
    if (!this.state.scheduleCode) {
      this.setState({
        alertMessage: 'Please enter schedule code',
        showAlert: true
      });
      return;
    }
    if (!this.state.scheduleType) {
      this.setState({
        alertMessage: 'Please select schedule type',
        showAlert: true
      });
      return;
    }
    if (!this.state.period) {
      this.setState({
        alertMessage: 'Please select period',
        showAlert: true
      });
      return;
    }
    if (!this.state.frequency) {
      this.setState({
        alertMessage: 'Please enter frequency',
        showAlert: true
      });
      return;
    }

    if (!this.state.Eststartdate) {
      this.setState({
        alertMessage: 'Please select Start date',
        showAlert: true
      });
      return;
    }
    if (!this.state.Estenddate) {
      this.setState({
        alertMessage: 'Please select Finish date',
        showAlert: true
      });
      return;
    }
    if (this.Assets && this.Assets.length === 0) {
      this.setState({
        alertMessage: 'Please select an asset',
        showAlert: true
      });
      return;
    }


    if (!this.state.woworktype) {
      this.setState({
        alertMessage: 'Please select Activity',
        showAlert: true
      });
      return;
    }
    if (!this.state.location) {
      this.setState({
        alertMessage: 'Please enter location',
        showAlert: true
      });
      return;
    }
    if (!this.state.suburb) {
      this.setState({
        alertMessage: 'Please select suburb',
        showAlert: true
      });
      return;
    }
    if (!this.state.priority) {
      this.setState({
        alertMessage: 'Please select Priority',
        showAlert: true
      });
      return;
    }
    if (!this.state.crew) {
      this.setState({
        alertMessage: 'Please select Crew/Team',
        showAlert: true
      });
      return;
    }
    if (!this.state.plannedHours) {
      this.setState({
        alertMessage: 'Please enter planned hours',
        showAlert: true
      });
      return;
    }

    let JSONAsset = "";

    this.setState({
      alertMessage: <Spinner label='Loading..' />,
      hideAlertButton: true,
      showAlert: true
    });
    let dataToPassToService: any = {};
    dataToPassToService.Title = this.state.title;
    dataToPassToService.Code = this.state.scheduleCode;
    dataToPassToService.Schedulegroup = this.state.scheduleType.text;
    dataToPassToService.Scheduletype = this.state.scheduleType.id;
    dataToPassToService.Description = this.state.comment ? this.state.comment : '';
    dataToPassToService.Workordertypeid = this.state.woworktype.id;
    dataToPassToService.Tenantid = Config.tenantId;
    dataToPassToService.Location = this.state.location;
    dataToPassToService.Suburbid = this.state.suburb.id;
    dataToPassToService.Priorityid = this.state.priority.id;
    dataToPassToService.Recurrenceperiod = this.state.period.text;
    dataToPassToService.Recurrencenumber = +this.state.frequency;
    dataToPassToService.Datecyclestart = new Date(this.state.Eststartdate).toISOString();
    dataToPassToService.Datecycleend = new Date(this.state.Estenddate).toISOString();
    dataToPassToService.Departmnentid = this.state.department.id;
    dataToPassToService.Teamid = this.state.teams.id;
    dataToPassToService.CrewId = this.state.crew.id;
    dataToPassToService.Riskassessmentrequired = this.state.riskassessment ? true : false;
    dataToPassToService.Photorequiredbefore = this.state.befpicreq ? true : false;
    dataToPassToService.Photorequiredafter = this.state.aftpicreq ? true : false;

    if (this.state.Assets) {
      JSONAsset = JSON.stringify(this.state.Assets);
      dataToPassToService.Assets = JSONAsset;
    }
    dataToPassToService.Lineitems = JSON.stringify(this.state.lineItems);
    dataToPassToService.Wocategory = "test";
    dataToPassToService.Plannedduration = +this.state.plannedHours;
    if (this.state.inspectionForm) {
      dataToPassToService.Inspectionform = this.state.inspectionForm.id;
    }
    dataToPassToService.Generateimmediately = this.state.generateImmediately ? (this.state.generateImmediately === "A" ? false : true) : false;
    dataToPassToService.Issingleworkorder = this.state.createSingleWO ? (this.state.createSingleWO.id === "A" ? true : false) : true;
    dataToPassToService.Isactive = this.state.isActive;
    dataToPassToService.Maintenancetype = this.state.workOrderType;
    dataToPassToService.Datenextscheduled = this.state.nextSchedule;
    dataToPassToService.createdBy = this.currentUser.Email;
    dataToPassToService.updatedBy = this.currentUser.Email;

    utility.genericPostAPIcall(Config.scheduledworklistname, dataToPassToService).then((data: any) => {

      this.setState({
        scheduleId: data.Id,
        alertMessage: 'Scheduled work has been setup successfully',
        showAlert: true,
        hideAlertButton: false,
        previousPage: true,
      });

      if (this.state.generateImmediately && this.state.generateImmediately === "B") {
        this.createWorkOrder(data.Id);
      }
    }, (err: any) => {
      console.log(err);
      this.setState({
        alertMessage: 'Something went wrong. Please try again later',
        showAlert: true,
        hideAlertButton: false,
      });
    });

  }
  public createWorkOrder(scheduleId: any) {
    let lineItemsLength = this.state.lineItems.length;
    let tempLineItems = this.state.lineItems;
    let lineNo = 1;
    let k = 0;
    for (var i = 0; i < lineItemsLength; i += 1) {
      let startDate = new Date(tempLineItems[k].ScheduledDate);
      let endDate = new Date(tempLineItems[k].ScheduledDate);
      //console.log(startDate);
      endDate.setHours(endDate.getHours() + (+this.state.plannedHours));
      let dataToPassToServiceforWO: any = {};
      dataToPassToServiceforWO.Title = this.state.woworktype.text;
      dataToPassToServiceforWO.Description = this.state.comment ? this.state.comment : '';
      dataToPassToServiceforWO.Workordertypeid = this.state.woworktype.id;
      if (this.state.workOrderType === "Inspection") {
        dataToPassToServiceforWO.Inspectionform = this.state.inspectionForm && this.state.inspectionForm.id;
      }
      dataToPassToServiceforWO.DepartmentId = this.state.department.id;
      dataToPassToServiceforWO.Location = this.state.location;
      dataToPassToServiceforWO.Suburbid = this.state.suburb.id;
      dataToPassToServiceforWO.priorityId = this.state.priority.id;
      dataToPassToServiceforWO.Datescheduledstart = startDate.toISOString();
      dataToPassToServiceforWO.Datescheduledend = endDate.toISOString();
      dataToPassToServiceforWO.LatLng = { X: this.state.lat ? this.state.lat : 0, Y: this.state.lng ? this.state.lng : 0 };
      dataToPassToServiceforWO.Tenantid = Config.tenantId;
      dataToPassToServiceforWO.CrewId = this.state.crew.id;
      dataToPassToServiceforWO.Riskassessmentrequired = this.state.riskassessment ? true : false;
      dataToPassToServiceforWO.Photorequiredbefore = this.state.befpicreq ? true : false;
      dataToPassToServiceforWO.Photorequiredafter = this.state.aftpicreq ? true : false;

      if (this.Assets?.length) {
        const JSONAsset = this.Assets.map((element: any) => JSON.stringify(element));
        dataToPassToServiceforWO.Assetsinfo = JSONAsset.join(',');
      }
      dataToPassToServiceforWO.Category = "test";
      dataToPassToServiceforWO.Workorderstatusid = this.state.wostatus.id;
      dataToPassToServiceforWO.ErpWoStatus = this.state.wostatus.text;
      dataToPassToServiceforWO.Scheduleid = scheduleId;
      dataToPassToServiceforWO.createdBy = this.currentUser.Email;
      dataToPassToServiceforWO.updatedBy = this.currentUser.Email;

      utility.genericPostAPIcall(Config.workorderlistname, dataToPassToServiceforWO).then((data: any) => {
        this.clearFilter();
        //this.props.onClick();
      }, (err: any) => {
        console.log(err);
      });
      lineNo++;
      k++;
    }

  }


  public generateTask(item: any) {
    let itemIndex = -1;
    for (var k = 0; k < this.state.lineItems.length; k++) {
      if (item.LineNo === this.state.lineItems[k].LineNo) {
        itemIndex = k;
        break;
      }
    }
    //console.log("itemIndex", itemIndex);
    let tempLineItem = JSON.parse(JSON.stringify(this.state.lineItems));
    if (tempLineItem[itemIndex].GenerateWorkOrder === true) {
      tempLineItem[itemIndex].GenerateWorkOrder = false;
    } else if (tempLineItem[itemIndex].GenerateWorkOrder === false) {
      tempLineItem[itemIndex].GenerateWorkOrder = true;
    }
    //console.log(tempLineItem);
    this.setState({ lineItems: tempLineItem });
  }

  public renderAssetPanel() {
    return (
      <div>
        <Dialog
          open={this.state.showPanel}
          onClose={this._onClosePanel}
          // className={'addEditModal'}
          fullWidth={true}
          maxWidth={'lg'}
          aria-labelledby="max-width-dialog-title"
        >
          <DialogTitle id="scroll-dialog-title">Select Asset</DialogTitle>
          <DialogContent dividers={true}>
            <AssetSearch
              selectMode={true}
              onClick={(Asset: any) => this.selectAssets(Asset)}
              heading={false}
              hideHeading={true}
              hideAssetMapButton={true}
              myhttpclient={this.props.myhttpclient}
              aadHttpClient={this.props.aadHttpClient}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.setState({ showPanel: false })} >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>);
  }
  public selectAssets(Asset: any) {
    let suburbFound;
    let locationFound;
    let Address;
    let cheackAllAsset = this.Assets.find((a: any) => a.AssetNumber === Asset.AssetNumber)
    //console.log(cheackAllAsset);
    if (!cheackAllAsset) {
      this.Assets.push(Asset);
    }
    Address = JSON.stringify(Asset.Address);
    locationFound = Asset.Location;
    suburbFound = this.state.suburbItems.find((s: any) => s.text === Asset.Suburb);
    // });
    // }
    this.setState({
      Assets: this.Assets,
      Address: Address,
      assetDetailList: true,
      showPanel: false,
      location: locationFound,
      suburb: suburbFound
    });
  }

  private SearchClicked() {
    this.setState(
      {
        iFramePanelOpened: true,
        selectModeMap: true,
        selectParent: false,
      });

  }
  public renderAssetMap() {
    return (
      <AssetMap
        selectAssets={(Asset: any) => this.selectAssetsonMap(Asset)}
        iFramePanelOpened={this.state.iFramePanelOpened}
        onClose={() => this._onDismissMap()}
        selectParent={this.state.selectParent}
        selectModeMap={this.state.selectModeMap}
      />
    );
  }


  public selectAssetsonMap(selectedAssetArray: any) {
    let flatCoordinates: any = [];

    selectedAssetArray.forEach((selectedAsset: any) => {

      let Asset = JSON.parse(selectedAsset.properties);
      let checkassetonMap = this.Assets.find((a: any) => a.AssetNumber === selectedAsset.id);
      if (checkassetonMap) {
        this.setState({
          alertMessage: 'Asset: (' + selectedAsset.name + ') already added once',
          showAlert: true
        });
        return;
      } else {
        this.Assets.push({
          Register: "Asset",
          AssetID: selectedAsset.id,
          AssetNumber: Asset.UFI ? Asset.UFI : Asset.UFI_1 ? Asset.UFI_1 : Asset.UFI_US,
          AssetType: Asset.ASSET_TYPE,
          AssetClass: Asset.LAYER_NAME,
          Description: selectedAsset.name,
          Suburb: Asset.Suburb,
          Location: Asset.BUILDING_N,
          GISInfo: {
            type: "Feature",
            geometry: {
              type: selectedAsset.shape,
              coordinates: flatCoordinates
            },
            properties: {
              AssetID: selectedAsset.id,
              Name: selectedAsset.name,
              Type: Asset.BUILDING_T,
              Hierarchy: "1.0",
              Zone: Asset.Suburb,
              RoadGroupName: selectedAsset.name,
              Link: null,
              GID: Asset.GIS_ID,
              ExternalOSID: null,
              Location: Asset.BUILDING_N,
              Ownership: "Council as CoM",
              ExtAssetID: "",
              subclass: Asset.LAYER_NAME
            }
          },
          ERPInfo: {
            MaintenanceScheduleCode: "NR_BWT_M_INSP",
            MaintenanceHistoryUniqueID: "0",
            PercentAllocation: "1.000000",
            AttrCode: "ASSET_TYPE",
            SearchPath: "Buildings"
          },
          AssetQuestions: [],
          AttachmentUrls: []


        });
        // get locality and use is for location
        this.setState({ location: Asset.LOCATION });

      }
    });

    let suburbFound = this.state.suburbItems.find((s: any) => s.text === this.Assets[0].Suburb);
    let FuncLocation = this.Assets.length > 1 ? 'BCC-AST-MAS-01' : this.Assets.length === 0 ? 'BCC-AST-NAS-01' : this.Assets[0].SAPFunctionLocation;
    this.setState({
      Assets: this.Assets,
      //showPanel: false
      suburb: suburbFound,
      lat: selectedAssetArray[0].center[1].toString(),
      lng: selectedAssetArray[0].center[0].toString(),
      latLong: flatCoordinates[0],
      // latLong:flatCoordinates[0].lng+','+flatCoordinates[0].lat,
      iFramePanelOpened: false,
      selectParent: false,
      selectModeMap: false,
      functionlocation: FuncLocation,
    });
  }

  public _onDismissMap() {
    this.setState({
      iFramePanelOpened: false
    });
  }
  private _onShowPanel = (): void => {
    this.setState({ showPanel: true });
  }
  private _onClosePanel = (): void => {
    this.setState({ showPanel: false });
  }
  public removeAssetNumber(index: any) {
    this.Assets.splice(index, 1);
    this.setState({
      Assets: this.Assets
    });
  }

  public handleDropdownChange(e: any, fieldName: string) {

    if (fieldName === "period" || fieldName === "crew" || fieldName === "Eststartdate" || fieldName === "Estenddate" || fieldName === "frequency" || fieldName === "scheduleCode") {
      //this.createLineItems();
      setTimeout(this.createLineItems.bind(this), 2000);
    }
    if (fieldName === 'department' && e.selectedItem !== null) {
      let tempTeams = this.state.teamsItems;

      let filteredTeam = tempTeams.filter((t: any) => t.departmentId === e.selectedItem.id);

      this.setState({
        disableTeam: false,
        tempTeamItems: filteredTeam,
        alltempTeamItems: filteredTeam,
        disableCrew: true,
        tempCrewItems: [],
        // [fieldName]: newValue
      });
    }
    if (fieldName === 'teams' && e.selectedItem !== null) {
      let tempCrew = this.state.crewItems;
      let filteredCrew: any = [];
      tempCrew.forEach((c: any) => {
        if (c.teamId === e.selectedItem.id || c.title === "Default Crew") {
          filteredCrew.push(c);
        }
      });
      this.setState({
        disableCrew: false,
        tempCrewItems: filteredCrew,
        alltempCrewItems: filteredCrew,
        // [fieldName]: newValue
      });
    }
    this.setState({ [fieldName]: e.selectedItem });
  }
  private handleValueChange = (event: any, fieldName: string) => {


    this.setState({ [fieldName]: event.value });
  }

  public _textAreaChanged = (e: any, fieldName: string) => {

    this.setState({ [fieldName]: e.value });
  }
  public _onRadioChange = (e: any, fieldName: string) => {
    this.setState({ [fieldName]: e.value });

  }
  public alert() {
    return (
      <AlertDialog
        showDialog={this.state.showAlert}
        message={this.state.alertMessage}
        title={Config.productTitle}
        onClose={() => this._closeDialog()}
        hideAlertButton={this.state.hideAlertButton}
      />
    );
  }

  private _closeDialog = (): void => {
    this.setState({
      alertMessage: '',
      showAlert: false
    });

    if (this.state.previousPage) {
      window.history.back();
    }
  }
  public render(): React.ReactElement<any> {
    return (
      <div className={'dashboardHome'}>
        {this.state.showAlert && this.alert()}
        <div className={"SearchDiv"}>
          <div className={'headingDiv'}>
            <span className={"heading"}> Create Schedule Work </span>
          </div>
        </div>
        <div className={"searchFieldDiv"}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <Paper elevation={6} className={'p-20'}>
                <div className="DocumentCardHeading">Work Order Details</div>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={12} className=' mb-10'>
                    <div style={{ display: 'flex', alignItems: 'stretch', height: '40px', background: '#dbdbdb' }}>
                    </div>
                  </Grid>

                  {this.state.Assets && this.state.Assets.map((item: any, i: any) => {
                    return (
                      <Grid item xs={12} sm={12}>

                        <Paper elevation={6} className={"assetSelectPaper"}>
                          <span className={''}>{item.AssetNumber}</span>
                          <span className="floatRight">
                            <DeleteIcon className={'danger'} onClick={() => this.removeAssetNumber(i)} />
                          </span><br />
                          <span className="mt-0 mb-3">{item.Description}</span>
                        </Paper>

                      </Grid>
                    );
                  })}
                </Grid>
              </Paper>
            </Grid>
          </Grid>
          <br />
          <br />
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <Paper elevation={6} className="scheduelPaper">
                <span className="DocumentCardHeading">Schedule Details</span>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={4}>
                    <TextBoxInput
                      stylingMode='underlined'
                      label="Title"
                      // pattern={"[A-Za-z]+"}
                      // minLength={2}
                      value={this.state.title}
                      onValueChanged={(e: any) => this.handleValueChange(e, 'title')}

                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextBoxInput
                      stylingMode='underlined'
                      label="Schedule Code"
                      // pattern={"[A-Za-z]+"}
                      // minLength={2}
                      value={this.state.scheduleCode}
                      onValueChanged={(e: any) => this.handleValueChange(e, 'scheduleCode')}

                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <br />
                    <br />
                    <CheckBoxInput text="Active"
                      value={this.state.isActive}
                      defaultValue={this.state.isActive}
                      onValueChanged={(e: any) => this.handleValueChange(e, 'isActive')}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <SelectBoxInput
                      stylingMode='underlined'
                      displayExpr="text"
                      valueExpr="id"
                      showClearButton={true}
                      items={ScheduleType}
                      selectedItem={this.state.scheduleType}
                      label='Schedule Type'
                      onSelectionChanged={(e: any) => this.handleDropdownChange(e, 'scheduleType')}

                    />

                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextBoxInput
                      stylingMode='underlined'
                      placeholder={"Interval"}
                      value={this.state.frequency}
                      onValueChanged={(e: any) => this.handleValueChange(e, 'frequency')}

                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <SelectBoxInput
                      stylingMode='underlined'
                      displayExpr="text"
                      valueExpr="id"
                      showClearButton={true}
                      items={Period}
                      selectedItem={this.state.period}
                      label='Period'
                      onSelectionChanged={(e: any) => this.handleDropdownChange(e, 'period')}

                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <DateBoxInput
                      stylingMode='underlined'
                      label={"Cycle Start Date"}
                      value={this.state.Eststartdate ? new Date(this.state.Eststartdate) : null}
                      type="date"
                      displayFormat={"dd/MM/yy"}
                      showClearButton={true}
                      onValueChanged={(e: any) => this.handleValueChange(e, 'Eststartdate')}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <DateBoxInput
                      stylingMode='underlined'
                      label={"Cycle End Date"}
                      value={this.state.Estenddate ? new Date(this.state.Estenddate) : null}
                      type="date"
                      displayFormat={"dd/MM/yy"}
                      showClearButton={true}
                      onValueChanged={(e: any) => this.handleValueChange(e, 'Estenddate')}
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
          <br />
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <Paper elevation={6} className="scheduelPaper">

                <span className="DocumentCardHeading">Work Order Details</span>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={4}>
                    <SelectBoxInput
                      stylingMode='underlined'
                      displayExpr="text"
                      valueExpr="id"
                      showClearButton={true}
                      items={this.state.worktypeItems}
                      selectedItem={this.state.woworktype}
                      label='Activity *'
                      onSelectionChanged={(e: any) => this.handleDropdownChange(e, 'woworktype')}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextBoxInput
                      stylingMode='underlined'
                      label="Location"
                      // pattern={"[A-Za-z]+"}
                      // minLength={2}
                      value={this.state.location}
                      onValueChanged={(e: any) => this.handleValueChange(e, 'location')}

                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <SelectBoxInput
                      stylingMode='underlined'
                      displayExpr="text"
                      valueExpr="id"
                      showClearButton={true}
                      items={this.state.suburbItems}
                      selectedItem={this.state.suburb}
                      label='Suburb'
                      onSelectionChanged={(e: any) => this.handleDropdownChange(e, 'suburb')}
                    />

                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Grid container spacing={1}>

                      <Grid item xs={12} sm={4}>
                        <CheckBoxInput value={this.state.befpicreq} onValueChanged={(e: any) => this.handleValueChange(e, 'befpicreq')} text={'Before Photo Required'} />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <CheckBoxInput value={this.state.aftpicreq} onValueChanged={(e: any) => this.handleValueChange(e, 'aftpicreq')} text={'After Photo Required'} />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <CheckBoxInput value={this.state.riskassessment} onValueChanged={(e: any) => this.handleValueChange(e, 'riskassessment')} text={'Site Risk Assessment'} />
                      </Grid>
                    </Grid>

                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <SelectBoxInput
                      stylingMode='underlined'
                      displayExpr="text"
                      valueExpr="id"
                      showClearButton={true}
                      items={this.state.departmentItems}
                      selectedItem={this.state.department}
                      label='Department *'
                      onSelectionChanged={(e: any) => this.handleDropdownChange(e, 'department')}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <SelectBoxInput
                      stylingMode='underlined'
                      displayExpr="text"
                      valueExpr="id"
                      showClearButton={true}
                      items={this.state.tempTeamItems}

                      disabled={this.state.disableTeam}
                      selectedItem={this.state.teams}
                      label='Teams *'
                      onSelectionChanged={(e: any) => this.handleDropdownChange(e, 'teams')}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <SelectBoxInput
                      stylingMode='underlined'
                      displayExpr="text"
                      valueExpr="id"
                      showClearButton={true}
                      items={this.state.tempCrewItems}
                      selectedItem={this.state.crew}
                      disabled={this.state.disableCrew}
                      label='Crew *'
                      onSelectionChanged={(e: any) => this.handleDropdownChange(e, 'crew')}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <SelectBoxInput
                      stylingMode='underlined'
                      displayExpr="text"
                      valueExpr="id"
                      showClearButton={true}
                      items={this.state.priorityItems}
                      selectedItem={this.state.priority}
                      label='Priority'
                      onSelectionChanged={(e: any) => this.handleValueChange(e, 'priority')}

                    />

                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <NumberBoxInput
                      stylingMode='underlined'
                      label="Planned Hours of Task"
                      min={1}
                      value={this.state.plannedHours}
                      onValueChanged={(e: any) => this.handleValueChange(e, 'plannedHours')}

                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>

                    <RadioGroupInput
                      label="Workorder Type"
                      valueExpr={'id'}
                      displayExpr={'text'}
                      value={this.state.workOrderType}
                      items={options}
                      layout={'horizontal'}
                      onValueChanged={(e: any) => this._onRadioChange(e, 'workOrderType')}
                    />
                  </Grid>
                  {this.state.workOrderType === "Inspection" ?
                    <Grid item xs={12} sm={4}>

                      <SelectBoxInput
                        stylingMode='underlined'
                        displayExpr="text"
                        valueExpr="id"
                        showClearButton={true}
                        items={this.state.inspectionFormItems}
                        selectedItem={this.state.inspectionForm}
                        label='Inspection Form'
                        onSelectionChanged={(e: any) => this.handleDropdownChange(e, 'inspectionForm')}
                      />
                    </Grid> : null
                  }
                  <Grid item xs={12} sm={4}>

                    <TextAreaInput
                      label='comment'
                      value={this.state.comment}

                      onValueChanged={(e: any) => this._textAreaChanged(e, 'comment')}
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
          <br />
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <Paper elevation={6} className="scheduelPaper">
                <span className="DocumentCardHeading">Planned Work Orders</span>
                <span className="floatRight includeFirst">
                  <CheckBoxInput
                    text="Include first occurance"
                    value={this.state.firstOccurance}
                    onValueChanged={(e: any) => this.handleValueChange(e, 'firstOccurance')}
                  />
                </span>

                <Grid item xs={12} sm={12}>
                  <br />

                  <DataGrid
                    dataSource={this.state.lineItems}
                    keyExpr="LineNo"
                    height={440}
                    showBorders={true}
                    allowColumnResizing={true}
                    allowColumnReordering={true}
                    columnMinWidth={10}
                    columnAutoWidth={true}
                  >
                    <Sorting mode="none" />
                    <Scrolling mode="infinite" />
                    <LoadPanel enabled={false} />

                    <Column
                      dataField='LineNo'
                      caption='Line #'
                      width={90}
                    />
                    <Column
                      dataField='GenerateWorkOrder'
                      caption='Create Work Order'
                      cellRender={(props) => {
                        return (
                          <div>
                            <CheckBoxInput value={props.data.GenerateWorkOrder} onValueChanged={() => this.generateTask(props.data)} />
                          </div>
                        );
                      }}
                    />
                    <Column
                      dataField='ScheduledDate'
                      caption='Schedule Date'
                      dataType='datetime'
                      format={'dd/MM/yyyy'}
                    // width={90}
                    />
                    <Column
                      dataField='Interval'
                      caption='Interval'
                      cellRender={(props) => {
                        return (
                          <div>
                            {props.data.DateInterval + '-' + props.data.DateIntervalType}
                          </div>
                        );
                      }}
                    // width={90}
                    />
                    <Column
                      dataField='Crew'
                      caption='Crew'
                    // width={90}
                    />
                    <Column
                      dataField='Status'
                      caption='Status'
                    // width={90}
                    />


                  </DataGrid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
          <br />
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <Paper elevation={6} className="scheduelPaper">
                <span className="DocumentCardHeading">Options</span>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>

                    <RadioGroupInput
                      label="Create"
                      valueExpr={'id'}
                      displayExpr={'text'}
                      value={this.state.createSingleWO}
                      items={optionsofSingle}
                      layout={'vertical'}
                      onValueChanged={(e: any) => this._onRadioChange(e, 'createSingleWO')}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} className={'borderLeft'}>

                    <RadioGroupInput
                      label="Generate Workorder"
                      valueExpr={'id'}
                      displayExpr={'text'}
                      value={this.state.generateImmediately}
                      items={this.state.optionsofGenerateImmediately}
                      layout={'vertical'}
                      onValueChanged={(e: any) => this._onRadioChange(e, 'generateImmediately')}
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
          <br />
          <br />
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <div className={"searchButtonDiv"}>
                <Button onClick={this.clearFilter} variant='outlined'>
                  Clear
                </Button>
                <Button onClick={this.createScheduleWorkOrder} variant='outlined' color="primary" className={'button'}>
                  Create
                </Button>
              </div>
            </Grid>
          </Grid>
        </div>
        {this.renderAssetPanel()}
        {this.renderAssetMap()}

      </div >

    );
  }
}

