import * as React from 'react';
import utility from '../../../classes/utility';
import ClearStorageService from './../../../ClearStorageService';
import { Config } from '../../../classes/config';
import DataTable from '../../common/DataTable/DataTable';
import AlertDialog from '../../common/alertDialog';
import DeleteDialog from '../../common/DeleteAlert';
import Spinner from '../../common/reusableComponents/Spinner';
import AddEditEmployee from './AddEditEmployee';
import global from '../../../classes/global';
let _items: any = [];

export default class Employees extends React.Component<any, any> {
    ClearStorageService = new ClearStorageService();
    public currentUser: any;
    public columns: any;
    public myRef: any;
    constructor(props: any) {
        super(props);


        this._getItemData = this._getItemData.bind(this);
        this.DeleteDialog = this.DeleteDialog.bind(this);
        this.myRef = React.createRef();
        this.currentUser = this.props.currentUser;
        if (this.props.role !== global.worxOnline_Admin) {
            window.open('#/AccessDenied', '_self');
        }
        this.columns = [
            // {
            //     field: 'id',
            //     title: 'ID',
            //     // width: 160,
            //     type: 'string',
            //     allowHiding: false,
            // },
            {
                field: 'title',
                title: 'Title',
                // width: 160,
                type: 'string',
                allowHiding: false,
                sortOrder: 'asc'
            },
            {
                field: 'userType',
                title: 'User Type',
                // width: 160,
                type: 'string',
                allowHiding: true
            },
            {
                field: 'email',
                title: 'Email',
                // width: 160,
                type: 'string',
                allowHiding: true
            },
            {
                field: 'department',
                title: 'Department',
                // width: 160,
                type: 'string',
                allowHiding: true
            },
            {
                field: 'teamName',
                title: 'Team',
                // width: 160,
                type: 'string',
                allowHiding: true
            },
            {
                field: 'employeeno',
                title: 'Code',
                // width: 160,
                type: 'string',
                visibleColumn: false,
                allowHiding: true,
            },
            {
                field: 'loginName',
                title: 'Login Name',
                // width: 280,
                type: 'string',
                visibleColumn: false,
                allowHiding: true,
            },
            {
                field: 'phone',
                title: 'Phone',
                // width: 160,
                type: 'string',
                visibleColumn: false,
                allowHiding: true
            },
            {
                field: 'crewName',
                title: 'Crew',
                // width: 160,
                type: 'string',
                allowHiding: true
            },
            {
                field: 'jobTitle',
                title: 'Job Title',
                // width: 160,
                type: 'string',
                allowHiding: true
            },
            {
                field: 'roleName',
                title: 'Role',
                // width: 160,
                type: 'string',
                allowHiding: true
            },
            {
                field: 'active',
                title: 'Is Active',
                // width: 160,
                type: 'boolean',
                visibleColumn: false,
                allowHiding: true
            },
            {
                field: 'isPlantOperator',
                title: 'Is Plant Operator',
                // width: 160,
                type: 'boolean',
                allowHiding: true
            },
            {
                field: 'updatedOn',
                title: 'Updated',
                width: 160,
                type: 'datetime',
                visibleColumn: false,
                allowHiding: true,
                sortOrder: 'desc'
            },
            {
                field: 'updatedBy',
                title: 'Updated By',
                width: 160,
                type: 'string',
                visibleColumn: false,
                allowHiding: true
            },
            {
                field: 'createdOn',
                title: 'Created',
                width: 160,
                type: 'datetime',
                visibleColumn: false,
                allowHiding: true
            },
            {
                field: 'createdBy',
                title: 'Created By',
                width: 160,
                type: 'string',
                visibleColumn: false,
                allowHiding: true
            },
        ];
        this.state = {
            items: _items,
            columns: this.columns,
            disableTeam: true,
            disableCrew: true,
        };
    }

    public componentDidMount() {
        this._getItemData();

    }

    public DeleteItem(item: any) {

        this.setState({
            alertMessage: <div className={'alertLoader'}><Spinner size='large' type={'converging-spinner'} /> <br /> Loading...</div>,
            hideAlertButton: true,
            showAlert: true,
            showDeleteAlert: false,
        });
        let dataToPassToService: any = {};
        dataToPassToService.id = item;
        dataToPassToService.isActive = false;
        dataToPassToService.updated = new Date();
        dataToPassToService.updatedBy = this.props.currentUser.Email;
        dataToPassToService.created = this.state.created;
        utility.genericUpdateAPICall(Config.UpdateEmployeeAPI, item, dataToPassToService).then((data: any) => {
            this.setState({
                alertMessage: 'User has been deactivated.',
                showAlert: true,
                showDeleteAlert: false,
                hideAlertButton: false,
                loading: false
            });
            this._getItemData();
            this.ClearStorageService.clearStorage();
        }, (err: any) => {
            console.log(err);
            this.setState({
                alertMessage: 'Something went wrong!',
                hideAlertButton: false,
                showAlert: false
            });
        });

    }

    // edit activty modal

    private EditItemModal(item: any) {
        //console.log("item", item);
        this.setState({ showModal: true, details: item, mode: 'edit' });
    }
    private _showModal = (): void => {
        this.setState({ showModal: true, details: null, mode: 'add' });
    }
    public renderNewModal() {
        return (
            <AddEditEmployee
                showModal={this.state.showModal}
                onClose={() => this._closeModal()}
                onClick={() => this._getItemData()}
                message={(e: any) => this.message(e)}
                currentUser={this.currentUser}
                details={this.state.details}
                role={this.props.role}
                mode={this.state.mode}
            />
        );
    }
    private _closeModal = (): void => {
        this.setState({ showModal: false });
    }
    public message(message: any) {
        //console.log(message);
        this.setState({
            alertMessage: message + ' successfully',
            showAlert: true
        });
    }

    private _getItemData() {
        let dataToPassToService: any = {};
        this.setState({
            loading: true
        });
        // console.log("dataToPassToService", dataToPassToService);
        utility.genericGetAPICallForList(Config.SearchEmployeeApi, 'POST', dataToPassToService).then((data: any) => {
            _items = [];
            //console.log(data);

            for (let r of data) {
                _items.push({
                    id: r.id,
                    title: r.fullName,
                    firstName: r.firstName,
                    lastName: r.lastName,
                    employeeno: r.resourceCode,
                    loginName: r.loginName,
                    departmentid: r.departmentId,
                    department: r.departmentName,
                    departmentCode: r.departmentCode,
                    crewId: r.crewId,
                    crewName: r.crewName,
                    teamId: r.teamId,
                    teamName: r.teamName,
                    email: r.email,
                    phone: r.phone,
                    jobTitle: r.jobTitle,
                    active: r.isActive ? true : false,
                    isPlantOperator: r.isPlantOperator ? true : false,
                    userType: r.userType,
                    pin: r.pin,
                    roleId: r.roleId,
                    roleName: r.roleName,
                    updatedBy: r.updatedBy,
                    updatedOn: r.updated,
                    createdBy: r.createdBy,
                    createdOn: r.created,

                });
            }

            _items = utility._sortItems(_items, 'title', false);

            this.setState({ items: _items, callAfterInit: true, loading: false, preItems: _items });

        }, (err) => {
            console.log(err);
            this.setState({ loading: false, callAfterInit: true, });
        });
    }


    public setSelection = (id: any) => {
        this.setState({
            selectedRow: id
        });
    }
    private stopCommsAfterInit = (): void => {
        this.setState({ callAfterInit: false });
    }



    public render(): React.ReactElement<any> {
        const filterValue = [['active', '=', true], ['Department', 'notcontains', 'Default']];
        return (
            <React.Fragment>
                {this.renderNewModal()}
                {this.state.showDeleteAlert && this.DeleteAlert()}
                {this.state.showAlert && this.alert()}
                <div className={"SearchDiv"}>
                    <div className={'headingDiv'}>
                        <span className={"heading"}> Users </span>
                    </div>
                </div>
                <div>
                    {!this.state.loading ?
                        <div ref={this.myRef}>
                            <DataTable
                                style={{ width: '100%' }}
                                filteralbe={true}
                                groupable={true}
                                rows={this.state.items}
                                columns={this.state.columns}
                                fileName={'Users'}
                                columnChooser={'usersColumns'}
                                selectionMode={false}
                                showHideColumnMenu={true}
                                callAfterInit={this.state.callAfterInit}
                                stopCommsAfterInit={() => this.stopCommsAfterInit()}
                                loading={this.state.loading}
                                allowExportSelectedData={false}
                                showNewModal={() => this._showModal()}
                                deleteItem={(a: any) => this.DeleteDialog(a)}
                                editItem={(a: any) => this.EditItemModal(a)}
                                // viewFile={(e)=>this.viewFile(e)}
                                showCommandBtn={true}
                                hideCommandDeleteBtn={false}
                                showNewBtn={false}
                                showViewDetailBtn={false}
                                //hideNewBtn={this.state.showModal}
                                hideNewBtn={true}
                                defaultfiltervalue={filterValue}
                            />
                        </div>
                        :
                        <Spinner size='large' type={'converging-spinner'} className="loader" />
                    }

                </div>
            </React.Fragment>
        );
    }
    public alert() {
        return (
            <AlertDialog
                showDialog={this.state.showAlert}
                message={this.state.alertMessage}
                title={Config.productTitle}
                hideAlertButton={this.state.hideAlertButton}
                onClose={() => this.setState({ alertMessage: '', showAlert: false })}
            />
        );
    }
    public DeleteDialog(item: any) {
        this.setState({
            DeletealertMessage: 'Are you sure you want to delete?',
            showDeleteAlert: true,
            deleteItem: item
        });
    }
    public DeleteAlert() {
        return (
            <DeleteDialog
                showDialog={this.state.showDeleteAlert}
                message={this.state.DeletealertMessage}
                title={"worxOnline"}
                onClose={() => this.setState({ DeletealertMessage: '', showDeleteAlert: false })}
                onOkButton={() => this.DeleteItem(this.state.deleteItem)}
                onCancelButton={() => this.setState({ DeletealertMessage: '', showDeleteAlert: false })}
            />
        );
    }
}
