import * as React from 'react';
import * as PropTypes from 'prop-types';
import global from "../../classes/global";
import { Config } from '../../classes/config';
import moment from 'moment';
import utility from '../../classes/utility';
import AlertDialog from '../common/alertDialog';
import AddAssetAttachment from '../AddAttachment/AddAssetAttachment';
import Spinner from '../common/reusableComponents/Spinner';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import AssignDatatable from '../common/DataTable/AssignDatatable';
import LoadIndicator from 'devextreme-react/load-indicator';
import MyMapComponent from '../common/MapComponent/GoogleMapComponent';
import Geocode from "react-geocode";
import GavelIcon from '@material-ui/icons/Gavel';
import { Popup } from 'devextreme-react/popup';
import ScrollView from 'devextreme-react/scroll-view';
import CreateWO from '../CreateWorkOrder/CreateWO';
import RefreshIcon from '@material-ui/icons/Refresh';
import IconButton from '@material-ui/core/IconButton';
import PlaceOutlined from '@material-ui/icons/PlaceOutlined';
import DataGrid, { Scrolling, Pager, Paging, HeaderFilter, SearchPanel } from 'devextreme-react/data-grid';
let _scheduleWOItems: any[] = [];
let _defectsItems: any[] = [];
let _DocPhotosItems: any[] = [];
let payloadType = "";


// set Google Maps Geocoding API for purposes of quota management. Its optional but recommended.
Geocode.setApiKey(global.googleAPIKey);

// set response language. Defaults to english.
Geocode.setLanguage("en");

// set response region. Its optional.
Geocode.setRegion("au");

export default class SearchAssetDetail extends React.Component<any, any> {
  public allocationReferences: any = '';
  public AssetSearchNumber: any;
  public AsseticID: any;
  public AssetCateogry: any;
  public currentUser: any;
  public AssetType: any;
  public AssetTypeName: any;
  public Compkey: any;
  public _WOcolumns: any;
  public _scheduleWOcolumns: any;
  public _Docphotoscolumns: any;
  public _DefectsWOcolumns: any;
  public _otherDetailColumns: any;
  public _attributescolumns: any;
  public _meterReadingColumns: any;
  constructor(props: any) {
    super(props);
    if (!this.props.Department) {
      window.open('#/AccessRequest', '_self');
    };
    this.currentUser = this.props.currentUser;

    this.reloadIFrame = this.reloadIFrame.bind(this);
    this.viewdocument = this.viewdocument.bind(this);
    this.viewDefectDetials = this.viewDefectDetials.bind(this);

    _scheduleWOItems = [];
    _defectsItems = [];
    _DocPhotosItems = [];
    this._WOcolumns = [
      {
        field: 'Workordernumber',
        title: 'Work Order #',
        // width: 160,
        type: 'string',
        allowHiding: false,
        link: 'workorderLink',
      },
      {
        field: 'worktype',
        title: 'Activity',
        // width: 160,
        type: 'string',
        allowHiding: true
      },
      {
        field: 'priority',
        title: 'Priority',
        // width: 110,
        type: 'string',
        allowHiding: true
      },
      {
        field: 'crew',
        title: 'Crew',
        // width: 160,
        type: 'string',
        allowHiding: true
      },
      {
        field: 'fieldstatus',
        title: 'Status',
        // width: 120,
        type: 'string',
        allowHiding: true
      },
      {
        field: 'workrequest',
        title: 'Work Req #',
        width: 160,
        type: 'string',
        allowHiding: true
      },
      {
        field: 'workorderLocation',
        title: 'Location',
        // width: 180,
        type: 'string',
        allowHiding: true
      },
      {
        field: 'eststartdate',
        title: 'Scheduled Start',
        width: 180,
        type: 'datetime',
        allowHiding: true
      },
      {
        field: 'createdOn',
        title: 'Created',
        width: 180,
        type: 'datetime',
        allowHiding: true,
        visibleColumn: true
      }
    ];
    this._scheduleWOcolumns = [

      {
        field: 'ScheduleID',
        title: 'Schedule ID',
        width: 160,
        type: 'string',
        allowHiding: true
      },
      {
        field: 'ScheduleType',
        title: 'Schedule Type',
        width: 250,
        type: 'string',
        allowHiding: true
      },
      {
        field: 'ActivityDescription',
        title: 'Activity',
        width: 500,
        type: 'string',
        allowHiding: true
      },

      {
        field: 'scope',
        title: 'Scope',
        width: 250,
        type: 'string',
        allowHiding: true
      },
      {
        field: 'Interval',
        title: 'Interval',
        width: 160,
        type: 'string',
        allowHiding: true
      },
      {
        field: 'ScheduledDateTime',
        title: 'Scheduled On',
        width: 250,
        type: 'datetime',
        allowHiding: true
      },
      {
        field: 'ExpireDate',
        title: 'Expire Date',
        width: 100,
        type: 'datetime',
        allowHiding: true
      }
    ];
    this._Docphotoscolumns = [
      {
        field: 'id',
        title: 'ID',
        // width: 160,
        type: 'string'
      },
      {
        field: 'document',
        title: 'Document',
        // width: 160,
        type: 'string',
        link: 'file',
      },
      {
        field: 'comments',
        title: 'Comments',
        // width: 160,
        type: 'string'
      },
      {
        field: 'uploadedon',
        title: 'Uploaded On',
        // width: 160,
        type: 'datetime'
      },
      {
        field: 'uploadedby',
        title: 'Uploaded By',
        // width: 160,
        type: 'string'
      },
    ];
    this._DefectsWOcolumns = [
      {
        field: 'defectsCode',
        title: 'Defect Code',
        // width: 160,
        type: 'string'
      },
      {
        field: 'description',
        title: 'Defect',
        // width: 160,
        type: 'string'
      },
      {
        field: 'workOrderNo',
        title: 'Work Order #',
        // width: 160,
        type: 'string'
      },
      {
        field: 'created',
        title: 'Created',
        width: 160,
        type: 'datetime'
      },

    ];
    this._attributescolumns = [
      {
        field: 'fieldName',
        title: 'Field Name',
        // width: 160,
        type: 'string',
        allowHiding: false
      },
      {
        field: 'fieldValue',
        title: 'Value',
        // width: 160,
        type: 'string',
        allowHiding: false
      }
    ];
    this._meterReadingColumns = [
      {
        field: 'name',
        title: 'Name',
        type: 'string',
        allowHiding: false,
        groupIndex: 0
      },
      {
        field: 'value',
        title: 'Value',
        type: 'string',
        allowHiding: true
      },
      {
        field: 'date',
        title: 'Date',
        type: 'datetime',
        allowHiding: true,
        sortOrder: 'desc'
      },
      {
        field: 'unit',
        title: 'Unit',
        type: 'string',
        allowHiding: true
      }
    ];

    this.state = {
      WOItems: [],
      WOcolumns: this._WOcolumns,
      WOCount: 0,
      scheduleWPColumns: this._scheduleWOcolumns,
      scheduleWOItems: [],
      scheduleWOCount: 0,
      AssetWOItems: [],
      AssetWOcolumns: [],
      AssetWOCount: 0,
      DocPhotosItems: [],
      DocPhotoscolumns: [],
      attributescolumns: this._attributescolumns,
      meterReadingColumns: this._meterReadingColumns,
      DocPhotosCount: 0,
      defectsItems: [],
      otherDetailsItems: [],
      otherDetailsColumns: [],
      otherDeatilsCount: 0,
      iFrameUrl: global.AssetMapURL,
      selected: 0,
      selectedTopTab: 0
    };

  }

  public componentDidMount() {
    let search = this.props.location.search;
    let params = new URLSearchParams(search);
    let AsseticID = params.get('AssetID');
    let erpAssetID = params.get('erpAssetID');
    if (AsseticID !== null) {
      this.AsseticID = AsseticID;
    }
    else {
      this.AsseticID = erpAssetID;
      payloadType = "erpAssetID";
    }
    this.allocationReferences = '';
    this.loadAssetDetails();
  }

  public reloadIFrame() {
    window.addEventListener('message', this.handleIframeTaskfordetail);

  }
  public handleIframeTaskfordetail = (e: any) => {
    if (e.data.command === 'asset selected') {
      let mapProps: any = JSON.parse(e.data.properties);
      let AssetDetail = Config.AssetdetailURL + mapProps.ref;
      window.removeEventListener("message", this.handleIframeTaskfordetail, false);
      window.open(AssetDetail, "_self");
      // location.reload();
      window.removeEventListener("message", this.handleIframeTaskfordetail, false);

    }


  };
  public getLatlong(place: any) {
    let address = place.toLowerCase();
    if (address.includes('street')) {
      address = address.replace("street", "st");
    }

    Geocode.fromAddress(address).then(
      (response) => {
        const { lat, lng } = response.results[0].geometry.location;

        if (lat && lng) {
          let marker = { lat: lat, lng: lng };
          this.setState({ marker: marker });
        }
      },
      (error) => {
        console.error(error);
      }
    );

  }
  public loadAssetDetails() {
    this.setState({ loadingAsset: true });

    let dataToPassToService: any = {};

    if (payloadType) {
      dataToPassToService.erpAssetId = this.AsseticID;
    }
    else {
      dataToPassToService.assetId = this.AsseticID;
    }


    utility.genericPostAPIcall(Config.SearchAssetDetails, dataToPassToService)
      .then((data: any) => {
        let assetDetailsItems: any[] = [];
        let AssetPhoto: any;
        let marker: any;
        let workgroupDescription: any;
        if (data.length > 0) {
          data.forEach((item: any) => {
            let coordinates = JSON.parse(item.coordinates);
            this.getworkorderFromAsset(item.id);
            this.getAssetAttributes(item.id);
            this.getMeterReading(item.id);
            marker = item.coordinates ? { lng: coordinates.coordinates[0], lat: coordinates.coordinates[1] } : { lat: 0, lng: 0 };
            workgroupDescription = item.workGroups
              ? item.workGroups.map((workGroup: { title: string }) => workGroup.title).join('\n')
              : '';
            let geom;
            let type = '';
            let polygoncoordinates: any = [];
            if (item.geom) {
              geom = JSON.parse(item.geom);
              if (geom.geometries && geom.geometries.length > 0) {
                geom.geometries.forEach((element: any) => {

                  if (element.type !== "Point") {
                    type = element.type;
                    if (element.coordinates && element.coordinates[0].length > 0) {
                      element.coordinates[0].forEach((coordinate: any) => {
                        polygoncoordinates.push({ lat: coordinate[1], lng: coordinate[0] });
                      });
                    }
                  }

                });
              }
            }

            assetDetailsItems.push({
              Register: item.registerTitle,
              AssetNumber: item.id,
              AssetID: item.id,
              id: item.id,
              decommisionDate: item.decommisionDate,
              AssetType: item.typeTitle,
              AssetTypeId: item.typeId,
              activityId: item.activityId,
              taskId: item.taskId,
              locationId: item.locationId,
              AssetClass: item.classTitle,
              workGroupIds: item.workGroupIds,
              AssetName: item.title,
              erpAssetId: item.erpAssetId,
              erpExtAssetId: item.erpExtAssetId,
              ServiceStatus: item.assetStatus,
              maintenanceAssetSubType: item.maintenanceAssetSubType,
              Description: item.description,
              location: item.locationDescription,
              maintenanceAssetType: item.maintenanceAssetType,
              Category: item.assetCategoryTitle,
              assetCriticality: item.assetCriticality,
              departmentTitle: item.departmentTitle,
              assetsubclass: item.subclassTitle,
              teamTitle: item.teamTitle,
              typeTitle: item.typeTitle,
              subtypeTitle: item.subtypeTitle,
              accntSystemName: item.accntSystemName,
              accntSystemId: item.accntSystemId,
              barcode: item.barcode,
              assetLastModified: item.assetLastModified,
              projectCode: item.projectCode,
              erpExtSystemName: item.erpExtSystemName,
              functionalLocationL1Id: item.functionalLocationL1Id,
              functionalLocationL1Name: item.functionalLocationL1Name,
              functionalLocationL1Type: item.functionalLocationL1Type,
              functionalLocationL2Id: item.functionalLocationL2Id,
              functionalLocationL2Name: item.functionalLocationL2Name,
              functionalLocationL2Type: item.functionalLocationL2Type,
              parentAssetId: item.parentAssetId,
              masterAssetId: item.masterAssetId,
              assetStatusDate: item.assetStatusDate,
              Selected: false,
              workGroupDescriptions: workgroupDescription
            });

            this.setState({
              marker: marker,
              coordinates: polygoncoordinates,
              type: type
            });
          });

          this.setState({
            AssetPhoto: AssetPhoto,
            assetdetails: data,
            assetDetailsItems: assetDetailsItems,
            loadingAsset: false
          });
        } else {
          this.setState({
            assetdetails: [],
            loadingAsset: false
          });
        }


      })
      .catch((err: any) => {
        console.log(err);
        this.setState({ assetdetails: [], loadingAsset: false });
      });

  }
  public getworkorderFromAsset(assetId: string) {
    let dataToPassToServiceforWO: any = {};
    this.setState({ loadingWO: true });
    dataToPassToServiceforWO.assetId = this.AsseticID;

    utility.genericPostAPIcall(Config.WOSearchAPI, dataToPassToServiceforWO)
      .then((workorder: any) => {
        let _WorkOrderItems: any[] = [];

        if (workorder.length !== null || workorder.length !== 0) {
          _WorkOrderItems = workorder.map((item: any) => ({
            id: item.id,
            woid: item.id,
            workrequest: item.workRequestId && item.workRequestId > 1 ? item.workRequestId : '',
            Workordernumber: item.id,
            erpWorkorderNumber: item.erpWorkorderNumber,
            title: item.title,
            priority: item.priority,
            worktype: item.worktypeName,
            crew: item.crew,
            department: item.department,
            latlong: item.locationLatLong,
            createdOn: item.created,
            Datecompleted: item.dateCompleted || null,
            eststartdate: item.dateScheduledStart || null,
            fieldstatus: item.status,
            suburb: item.suburb || '',
            workorderLocation: item.workorderLocation || ''
          }));

          _WorkOrderItems = utility._sortItems(_WorkOrderItems, 'createdon', true);

          this.setState({
            WOCount: _WorkOrderItems.length,
            WOItems: _WorkOrderItems,
            WOcolumns: this._WOcolumns,
            loadingWO: false
          });
        } else {
          this.setState({
            WOCount: _WorkOrderItems.length,
            WOItems: _WorkOrderItems,
            WOcolumns: this._WOcolumns,
            loadingWO: false,
            callAfterInit: true
          });
        }
      })
      .catch((err: any) => {
        console.log(err);
        this.setState({ loadingWO: false });
      });


  }
  public getAssetAttributes(assetId: number) {
    this.setState({ attributesLoading: true });
    utility.genericGetDataByIdAPICall(Config.AssetAttributes, assetId).then((items: any) => {
      let _attributesItems: any = [];
      items.forEach((item: any) => {
        let attributeValue = item.attributeValue;
        if (item.fieldType === 'DateTime') {
          attributeValue = item.attributeValue ? moment(item.attributeValue).format("DD/MM/YYYY h:mm a") : '';
        }
        _attributesItems.push({
          fieldName: item.caption,
          fieldValue: attributeValue,
        })
      });

      this.setState({
        attributesItems: _attributesItems,
        attributescolumns: this._attributescolumns,
        attributesLoading: false,
        callAfterInit: true
      });
    }, (err: any) => {
      this.setState({ attributesLoading: false })
    });
  }
  public getMaintenanceSchedule() {
    this.setState({ scheduleLoading: true });
    let assetID = `?AssetID=${this.AssetSearchNumber}`;
    utility.genericGetDataByIdAPICall(Config.MaintenanceSchedule, assetID).then((items: any) => {
      _scheduleWOItems = [];
      items.forEach((item: any) => {
        _scheduleWOItems.push({
          id: item.ScheduleKey,
          ScheduleID: item.ScheduleKey ? item.ScheduleKey : "",
          ScheduleType: item.ScheduleType ? item.ScheduleType : "",
          IntervalUnitOfMeasure: item.IntervalUnitOfMeasure,
          TimeInterval: item.TimeInterval,
          Interval: item.TimeInterval + " " + item.IntervalUnitOfMeasure,
          ScheduledDateTime: this.validateDate(item.ScheduledDateTime),
          ExpireDate: this.validateDate(item.ExpireDate),
          updateBy: item.lastModifiedBy,
          updatedOn: this.validateDate(item.lastModifiedDateTime),
          scope: item.Scope ? item.Scope : "",
          ActivityDescription: item.Activity
        })
      });

      this.setState({
        scheduleWOCount: _scheduleWOItems.length,
        scheduleWOItems: _scheduleWOItems,
        scheduleWOcolumns: this._scheduleWOcolumns,
        scheduleLoading: false,
        callAfterInit: true
      });
    }, (err: any) => {
      this.setState({ scheduleLoading: false })
    });
  }
  public getDefects(id: any) {
    this.setState({ defectLoading: true })
    let dataToPassToService: any = {};
    dataToPassToService.assetId = parseInt(this.AssetSearchNumber);
    utility.genericPostAPIcall(Config.SearchDefect, dataToPassToService).then((defects: any) => {
      _defectsItems = [];
      //console.log("defects", defects);
      if (defects.length !== null || defects.length !== 0) {
        defects.forEach((item: any) => {
          _defectsItems.push({
            id: item.Id,
            defectsCode: item.DefectCode,
            workOrderNo: item.WorkOrderNumber,
            description: item.DefectTitle,
            WorkOrder: item.WorkOrder,
            defectQuestions: item.DefectQuestions,
            created: item.created,
          });
        });
        _defectsItems = utility._sortItems(_defectsItems, 'createdon', true);
        this.setState({
          defectsCount: defects.length,
          defectsItems: _defectsItems,
          defectsColumns: this._DefectsWOcolumns,
          defectLoading: false,
          callAfterInit: true
        });
      }
    }, (err: any) => {
      this.setState({ defectLoading: false })
    });
  }
  public getAssetDocument(id: any) {
    this.setState({ documentLoading: true });
    utility.genericGetDataByIdAPICall(Config.assetDocumentList, id).then((assetdoc: any) => {
      _DocPhotosItems = [];
      if (assetdoc.length !== null || assetdoc.length !== 0) {
        assetdoc.forEach((item: any) => {
          _DocPhotosItems.push({
            document: item.Name ? item.Name : '',
            comments: item.Comments,
            uploadedon: item.CreatedOn ? item.CreatedOn : '',
            id: item.ID ? item.ID : ' ',
            uploadedby: item.UploadedBy ? item.UploadedBy : '',
            fileURL: item.FileURL ? item.FileURL : ''
          });
        });
        _DocPhotosItems = utility._sortItems(_DocPhotosItems, 'uploadedon', true);
        this.setState({
          DocPhotosCount: assetdoc.length,
          DocPhotosItems: _DocPhotosItems,
          DocPhotoscolumns: this._Docphotoscolumns,
          documentLoading: false,
          callAfterInit: true
        });
      }
    }, (err: any) => {
      this.setState({ documentLoading: false })
    });
  }
  public getMeterReading(id: any) {
    this.setState({ meterReadingLoading: true });
    utility.genericGetDataByIdAPICall(Config.AssetMeterReadingList, id).then((meterReadingData: any) => {
      let _meterReadingItems: any = [];
      if (meterReadingData && meterReadingData.meterReadingValuesGroupByName && meterReadingData.meterReadingValuesGroupByName.length > 0) {
        meterReadingData.meterReadingValuesGroupByName.forEach((readingGroup: any) => {
          readingGroup.meterReadingValues.forEach((reading: any) => {
            _meterReadingItems.push({
              name: reading.meterReadingName,
              value: reading.meterReadingValue,
              date: new Date(reading.meterReadingDate),
              unit: reading.meterReadingUnit
            });
          });
        });
      }
      this.setState({
        meterReadingItems: _meterReadingItems,
        meterReadingColumns: this._meterReadingColumns,
        meterReadingLoading: false,
        callAfterInit: true
      });
    }, (err: any) => {
      this.setState({ meterReadingLoading: false })
    });
  }
  public validateDate(date: any) {
    let d = '';
    let validDateFrom = new Date("1950-01-01")

    if (date) {
      let tempdate = new Date(date);
      if (tempdate.getTime() > validDateFrom.getTime()) {
        d = date;
      }
    }
    return d
  }

  private alert() {
    return (
      <AlertDialog
        showDialog={this.state.showAlert}
        message={this.state.alertMessage}
        title={Config.productTitle}
        onClose={() => this.setState({ alertMessage: '', showAlert: false })}
      />
    );
  }
  public viewdocument(a: any) {
    let Asset = a;
    //console.log("Document", Asset);

    let dataToPassToService: any = {};
    if (!utility.validURL(Asset.fileURL) || !Asset.document) {
      this.setState({
        alertMessage: 'Unable to view this document. \n File URL or File name does exist',
        showAlert: true
      });
      return;
    }
    dataToPassToService.fileName = Asset.document;
    dataToPassToService.fileUrl = Asset.fileURL;
    utility.genericPostAPIcallForDowloadDoct(Config.dowloadDocumentList, dataToPassToService).then((doc: any) => {
      //console.log("doc", doc);
      window.open(doc, "_blank");
    });

  }
  public viewDefectDetials() {
    let selected;
    this.setState({
      details: selected,
      showDefectModal: true
    });
  }
  public _closeDefectModal = (): void => {
    this.setState({
      showDefectModal: false
    });
  }
  public renderDefectdetailModal() {
    // return (
    //   <DefectDetailModal
    //     showModal={this.state.showDefectModal}
    //     onClick={() => this._closeDefectModal()}
    //     details={this.state.details}
    //   />
    // );
  }

  private _showModalofAttachment = (): void => {
    //console.log(this.state.assetdetails.Id);
    this.setState({
      showModalofAttachment: true
    });
  }
  private _closeModalofAttachment = (item: any): void => {
    //console.log(item);
    this.setState({
      showModalofAttachment: false
    });
    if (item === true) {
      this.setState({
        alertMessage: 'Attachment(s) uploaded successfully.',
        showAlert: true,
      });
    }
  }
  private stopCommsAfterInit = (): void => {
    this.setState({ callAfterInit: false });
  }

  public handleSelect = (event: any, newValue: any) => {
    this.setState({ selected: newValue });
  }
  public handleSelectTopTab = (event: any, newValue: any) => {
    this.setState({ selectedTopTab: newValue });
  }
  public renderAttachmentModal() {
    return (
      <AddAssetAttachment
        showModal={this.state.showModalofAttachment}
        onClose={(item: any) => this._closeModalofAttachment(item)}
        AssetId={this.state.assetdetails ? this.state.assetdetails.Id : ""}
        updateDoc={() => setTimeout(this.getAssetDocument.bind(this), 2000)}
        details={this.state.assetdetails}
        currentUser={this.currentUser}
        context={this.props.context}
        message={() => this.message()}
      />
    );
  }
  public message(message?: any) {
    //console.log(message);
    this.setState({
      alertMessage: message + " successfully",
      showAlert: true,
      hideAlertButton: false,
      showCreateWOModal: false
    });
    setTimeout(this.loadAssetDetails.bind(this), 3000);
  }
  public renderCreateWOModal() {
    return (
      <Popup
        width={'80vw'}
        maxHeight={'85vh'}
        minHeight={200}
        height={'auto'}
        showTitle={true}
        showCloseButton={true}
        dragEnabled={true}
        hideOnOutsideClick={false}
        visible={this.state.showCreateWOModal}
        closeOnOutsideClick={true}
        onVisibleChange={() => this.setState({ showCreateWOModal: false })}
      >
        <ScrollView width='100%' height='100%' showScrollbar='onScroll' scrollByThumb={true}>
          <CreateWO
            currentUser={this.currentUser}
            Assets={this.state.assetDetailsItems}
            aadHttpClient={this.props.aadHttpClient}
            NewAssetWO={true}
            onClick={() => setTimeout(this.getworkorderFromAsset.bind(this), 1000)}
            onClose={() => this.setState({ showCreateWOModal: false })}
            message={(e: any) => this.message(e)}
          />
        </ScrollView>

      </Popup>
    );
  }
  public loaderTab(title: any, items: any, counts: any, isLoading: any) {
    if (isLoading) {
      return <span className='tabHeading'>{title} <LoadIndicator id="small-indicator" height={15} width={15} /></span>
    } else {
      return <span className='tabHeading'>{title} ({counts})</span>
    }
  }
  public rendermapModal() {
    return (
      <Popup
        width={'80vw'}
        maxHeight={'85vh'}
        minHeight={200}
        height={'auto'}
        showTitle={true}
        showCloseButton={true}
        dragEnabled={true}
        hideOnOutsideClick={false}
        visible={this.state.showMapPanel}
        closeOnOutsideClick={true}
        onVisibleChange={() => this.setState({ showMapPanel: false })}
      >
        <MyMapComponent
          singleMarkers={this.state.marker}
          center={this.state.marker}
          coordinates={this.state.coordinates}
          type={this.state.type}
          useStyle={this.state.useStyle}
          iconColor={'33cc33'}
          zoom={18}
          height="35vh"
          showMarker={true}

        />

      </Popup>
    );
  }
  public render(): React.ReactElement<any> {
    const {
      WOItems, WOcolumns, scheduleWOItems, scheduleWOcolumns,
      DocPhotoscolumns, DocPhotosItems,
      defectsItems, defectsColumns, attributesItems, assetDetailsItems, attributesColumns, meterReadingItems, meterReadingColumns
    } = this.state;
    return (
      <div>
        {this.state.showAlert && this.alert()}
        {this.state.showCreateWOModal && this.renderCreateWOModal()}
        <div>{this.state.showMapPanel && this.rendermapModal()}</div>
        {(this.state.assetDetailsItems) && (
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6} md={6}>
              <div className={"SearchDiv headingDiv"}>
                <span className={"heading"}>
                  Asset Details
                  {this.state.assetDetailsItems && (
                    <>
                      <span>
                        {' '}
                        - {this.state.assetDetailsItems[0].AssetName} ({this.state.assetDetailsItems[0].erpExtAssetId})
                      </span>
                    </>
                  )}
                </span>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <div className='icon-buttons-list'>
                <IconButton aria-label="view" color="primary" onClick={() => this.loadAssetDetails()} >
                  <RefreshIcon titleAccess='Refresh the data' className={'iconcolor'} />
                </IconButton>
                <IconButton onClick={() => this.setState({ showCreateWOModal: true, callAfterInitAssetWO: true })}
                >
                  <GavelIcon titleAccess='Create Work Order' className={'iconcolor'} />
                </IconButton>
                <IconButton onClick={() => this.setState({ showMapPanel: true })}>
                  <PlaceOutlined titleAccess='Show Map' className={'iconcolor'} />
                </IconButton>
              </div>
            </Grid>
          </Grid>
        )
        }

        {
          (this.state.loadingAsset ?
            <div>
              <Spinner size='large' label="Loading" />
              <br />
            </div> :
            null
          )
        }


        <div className={"detailFieldDiv"}>
          {!this.state.loadingAsset && (this.state.assetDetailsItems && this.state.assetDetailsItems.length > 0) &&
            (<Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={7}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={4}>Asset Name:</Grid>
                  <Grid item xs={12} sm={8} className={'semiBold'}>{this.state.assetDetailsItems[0].AssetName}</Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={4}>External Asset ID:</Grid>
                  <Grid item xs={12} sm={8} className={'semiBold'}>{this.state.assetDetailsItems[0].erpExtAssetId}</Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={4}>Assetic ID</Grid>
                  <Grid item xs={12} sm={8} className={'semiBold'}><a href={global.AsseticAssetURL + this.state.assetDetailsItems[0].erpAssetId} className="WOlink" rel="noreferrer" target="_blank">{this.state.assetDetailsItems[0].erpAssetId}</a></Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={4}>Description:</Grid>
                  <Grid item xs={12} sm={8} className={'semiBold'}>{this.state.assetDetailsItems[0].Description}</Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={4}>Asset Status:</Grid>
                  <Grid item xs={12} sm={8} className={'semiBold'}>{this.state.assetDetailsItems[0].ServiceStatus}</Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={4}>Location:</Grid>
                  <Grid item xs={12} sm={8} className={'semiBold'}>{this.state.assetDetailsItems[0].location}</Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={4}>Department:</Grid>
                  <Grid item xs={12} sm={8} className={'semiBold'}>{this.state.assetDetailsItems[0].departmentTitle}</Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={4}>Team:</Grid>
                  <Grid item xs={12} sm={8} className={'semiBold'}>{this.state.assetDetailsItems[0].teamTitle}</Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={4}>Category:</Grid>
                  <Grid item xs={12} sm={8} className={'semiBold'}>{this.state.assetDetailsItems[0].Category}</Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={4}>Maintenance Type:</Grid>
                  <Grid item xs={12} sm={8} className={'semiBold'}>{this.state.assetDetailsItems[0].maintenanceAssetType}</Grid>
                </Grid>

                <Grid container spacing={3}>
                  <Grid item xs={12} sm={4}>Asset Class:</Grid>
                  <Grid item xs={12} sm={8} className={'semiBold'}>{this.state.assetDetailsItems[0].AssetClass}</Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={4}>Asset Sub Class:</Grid>
                  <Grid item xs={12} sm={8} className={'semiBold'}>{this.state.assetDetailsItems[0].assetsubclass}</Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={4}>Asset Type:</Grid>
                  <Grid item xs={12} sm={8} className={'semiBold'}>{this.state.assetDetailsItems[0].AssetType}</Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={4}>Asset Sub Type:</Grid>
                  <Grid item xs={12} sm={8} className={'semiBold'}>{this.state.assetDetailsItems[0].subtypeTitle}</Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={5}>
                <Paper square className='mapwidth'>

                  <Tabs
                    value={this.state.selectedTopTab}
                    onChange={this.handleSelectTopTab}
                    indicatorColor="primary"
                    // textColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="scrollable auto tabs example"
                  >
                    <Tab label={<span className='tabHeading'>Map</span>} {...a11yPropsTop(0)} />
                    {/* <Tab label={<span className='tabHeading'>Photo</span>}  {...a11yPropsTop(1)} /> */}
                  </Tabs>
                </Paper>

                <TabPanel value={this.state.selectedTopTab} index={0}>
                  <div className='mapwidth'>
                    {this.state.marker && this.state.marker.lat !== 0 ?

                      <MyMapComponent
                        singleMarkers={this.state.marker}
                        center={this.state.marker}
                        coordinates={this.state.coordinates}
                        useStyle={this.state.useStyle}
                        type={this.state.type}
                        iconColor={'33cc33'}
                        zoom={18}
                        height="35vh"
                        showMarker={true}

                      /> :
                      <h3>No location data</h3>}
                  </div>
                </TabPanel>
                {/* <TabPanel value={this.state.selectedTopTab} index={1}>
                  {this.state.AssetPhoto ?
                    <a href={this.state.AssetPhoto} target="_blank" rel="noreferrer" >
                      <img className="assetImagePivot" src={this.state.AssetPhoto} alt='' />
                    </a> :
                    <h3>No Photo Found</h3>}
                </TabPanel> */}
              </Grid>
            </Grid>
            )}
        </div>

        <br />
        <br />
        <br />
        <div className={'tabsDiv '}>
          <Grid container spacing={0}>
            <Grid item xs={12} sm={12} md={12}>
              <Paper square>

                <Tabs
                  value={this.state.selected}
                  onChange={this.handleSelect}
                  indicatorColor="primary"
                  // textColor="primary"
                  variant="scrollable"
                  scrollButtons="auto"
                  aria-label="scrollable auto tabs example"
                >
                  <Tab label={this.loaderTab('Workorders ', WOItems, WOItems.length, this.state.loadingWO)} {...a11yProps(0)} />
                  <Tab label={this.loaderTab('Maintenance and Inspection', scheduleWOItems, scheduleWOItems.length, this.state.scheduleLoading)} {...a11yProps(1)} />
                  <Tab label={this.loaderTab('Documents', DocPhotosItems, DocPhotosItems.length, this.state.documentLoading)} {...a11yProps(2)} />
                  <Tab label={this.loaderTab('Defects', defectsItems, defectsItems.length, this.state.defectLoading)} {...a11yProps(3)} />
                  <Tab label={this.loaderTab('Attributes', attributesItems, attributesItems && attributesItems.length, this.state.attributesLoading)} {...a11yProps(4)} />
                  <Tab label={this.loaderTab('Asset Info', assetDetailsItems, assetDetailsItems && assetDetailsItems.length, this.state.assetLoading)} {...a11yProps(5)} />
                  {meterReadingItems && <Tab label={this.loaderTab('Meter Readings', meterReadingItems, meterReadingItems && meterReadingItems.length, this.state.meterReadingLoading)} {...a11yProps(6)} />}
                </Tabs>
              </Paper>
            </Grid>
          </Grid>
          <TabPanel value={this.state.selected} index={0}>
            {!this.state.loading ?
              <>
                {(WOItems && WOItems.length > 0) && (
                  <div className="tabStripDiv">

                    <AssignDatatable
                      style={{ width: '100%' }}
                      filteralbe={false}
                      groupable={false}
                      rows={WOItems}
                      columns={WOcolumns}
                      // fileName={'Timesheet'}
                      // columnChooser={'prestartchecklistColumns'}
                      selectionMode={false}
                      showHideColumnMenu={true}
                      callAfterInit={this.state.callAfterInit}
                      stopCommsAfterInit={() => this.stopCommsAfterInit()}
                      loading={this.state.loading}
                      allowExportSelectedData={false}
                      showNewBtn={false}
                      hideExport={true}
                      hideSearchPanel={true}
                      showCommandBtn={false}
                      showViewDetailBtn={false}
                    />
                  </div>
                )}
              </>
              :
              // <CircularProgress className="loader" />
              <Spinner size='large' label="Loading" />
            }
          </TabPanel>
          <TabPanel value={this.state.selected} index={1}>
            {(scheduleWOItems && scheduleWOItems.length > 0) && (
              <div className="tabStripDiv">
                <AssignDatatable
                  style={{ width: '100%' }}
                  filteralbe={false}
                  groupable={false}
                  rows={scheduleWOItems}
                  columns={scheduleWOcolumns}
                  // fileName={'Timesheet'}
                  // columnChooser={'prestartchecklistColumns'}
                  selectionMode={false}
                  showHideColumnMenu={false}
                  callAfterInit={this.state.callAfterInit}
                  stopCommsAfterInit={() => this.stopCommsAfterInit()}
                  loading={this.state.loading}
                  allowExportSelectedData={false}
                  showNewBtn={false}
                  hideExport={true}
                  hideSearchPanel={true}
                  showCommandBtn={false}
                  showViewDetailBtn={false}
                />
              </div>
            )}
          </TabPanel>
          <TabPanel value={this.state.selected} index={2}>
            {(DocPhotosItems && DocPhotosItems.length > 0) && (
              <div className="tabStripDiv">
                <AssignDatatable
                  style={{ width: '100%' }}
                  filteralbe={false}
                  groupable={false}
                  rows={DocPhotosItems}
                  columns={DocPhotoscolumns}
                  // fileName={'Timesheet'}
                  // columnChooser={'prestartchecklistColumns'}
                  selectionMode={false}
                  showHideColumnMenu={false}
                  callAfterInit={this.state.callAfterInit}
                  stopCommsAfterInit={() => this.stopCommsAfterInit()}
                  loading={this.state.loading}
                  allowExportSelectedData={false}
                  showNewBtn={false}
                  hideExport={true}
                  hideSearchPanel={true}
                  showCommandBtn={false}
                  showViewDetailBtn={false}
                  viewFile={(e: any) => this.viewdocument(e)}
                />
              </div>
            )}
          </TabPanel>
          <TabPanel value={this.state.selected} index={3}>
            {(defectsItems && defectsItems.length > 0) && (
              <div className="tabStripDiv">
                <AssignDatatable
                  style={{ width: '100%' }}
                  filteralbe={false}
                  groupable={false}
                  rows={defectsItems}
                  columns={defectsColumns}
                  // fileName={'Timesheet'}
                  // columnChooser={'prestartchecklistColumns'}
                  selectionMode={false}
                  showHideColumnMenu={false}
                  callAfterInit={this.state.callAfterInit}
                  stopCommsAfterInit={() => this.stopCommsAfterInit()}
                  loading={this.state.loading}
                  allowExportSelectedData={false}
                  showNewBtn={false}
                  hideExport={true}
                  hideSearchPanel={true}
                  showCommandBtn={false}
                  showViewDetailBtn={false}
                />
              </div>
            )}
          </TabPanel>
          <TabPanel value={this.state.selected} index={4}>
            {(attributesItems && attributesItems.length > 0) && (
              <div className="tabStripDiv">
                <DataGrid
                  id='gridContainer'
                  dataSource={attributesItems}
                  keyExpr="fieldName"
                  showBorders={true}
                  customizeColumns={attributesColumns}
                >
                  <SearchPanel visible={true}
                    width={240}
                    placeholder="Search..." />
                  <Scrolling rowRenderingMode='virtual'></Scrolling>
                  <HeaderFilter visible={true} />
                  <Paging defaultPageSize={20} />
                  <Pager
                    visible={true}
                    allowedPageSizes={[20, 40, 'all']}
                    showPageSizeSelector={true}
                    showNavigationButtons={true} />
                </DataGrid>
              </div>
            )}
          </TabPanel>
          <TabPanel value={this.state.selected} index={5}>
            <div className='p-20'>
              {(assetDetailsItems && assetDetailsItems.length > 0) && (

                <Grid container spacing={3}>
                  <Grid item xs={12} sm={12} md={6}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={3}>Project Identifier:</Grid>
                      <Grid item xs={12} sm={9} className={'semiBold'}>{this.state.assetDetailsItems[0].accntSystemId ? this.state.assetDetailsItems[0].accntSystemId : ''}</Grid>
                    </Grid>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={3}>Account System Name:</Grid>
                      <Grid item xs={12} sm={9} className={'semiBold'}>{this.state.assetDetailsItems[0].accntSystemName ? this.state.assetDetailsItems[0].accntSystemName : ''}</Grid>
                    </Grid>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={3}>Location ID:</Grid>
                      <Grid item xs={12} sm={9} className={'semiBold'}>{this.state.assetDetailsItems[0].locationId ? this.state.assetDetailsItems[0].locationId : ''}</Grid>
                    </Grid>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={3}>Activity ID:</Grid>
                      <Grid item xs={12} sm={9} className={'semiBold'}>{this.state.assetDetailsItems[0].activityId ? this.state.assetDetailsItems[0].activityId : ''}</Grid>
                    </Grid>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={3}>Task ID:</Grid>
                      <Grid item xs={12} sm={9} className={'semiBold'}>{this.state.assetDetailsItems[0].taskId ? this.state.assetDetailsItems[0].taskId : ''}</Grid>
                    </Grid>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={3}>Barcode:</Grid>
                      <Grid item xs={12} sm={9} className={'semiBold'}>{this.state.assetDetailsItems[0].barcode ? this.state.assetDetailsItems[0].barcode : ''}</Grid>
                    </Grid>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={3}>Maintenance Sub Type:</Grid>
                      <Grid item xs={12} sm={9} className={'semiBold'}>{this.state.assetDetailsItems[0].maintenanceAssetSubType ? this.state.assetDetailsItems[0].maintenanceAssetSubType : ''}</Grid>
                    </Grid>

                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={3}>Decommision Date:</Grid>
                      <Grid item xs={12} sm={9} className={'semiBold'}>
                        {this.state.assetDetailsItems[0].decommisionDate ?
                          moment(this.state.assetDetailsItems[0].decommisionDate).format("DD/MM/YY h:mm a") : null}
                      </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={3}>Modified Date:</Grid>
                      <Grid item xs={12} sm={9} className={'semiBold'}>
                        {this.state.assetDetailsItems[0].assetLastModified ?
                          moment(this.state.assetDetailsItems[0].assetLastModified).format("DD/MM/YY h:mm a") : null}
                      </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={3}>Asset Status Changed Date:</Grid>
                      <Grid item xs={12} sm={9} className={'semiBold'}>
                        {this.state.assetDetailsItems[0].assetStatusDate ?
                          moment(this.state.assetDetailsItems[0].assetStatusDate).format("DD/MM/YY h:mm a") : null}
                      </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={3}>Asset #:</Grid>
                      <Grid item xs={12} sm={9} className={'semiBold'}>{this.state.assetDetailsItems[0].AssetNumber}</Grid>
                    </Grid>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={3}>Work Group:</Grid>
                      <Grid item xs={12} sm={9} className={'semiBold'}><pre className='customPreLines'>{this.state.assetDetailsItems[0].workGroupDescriptions}</pre></Grid>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </div>
          </TabPanel>
          {meterReadingItems && <TabPanel value={this.state.selected} index={6}>
            {!this.state.loading ?
              <>
                {(meterReadingItems && meterReadingItems.length > 0) && (
                  <div className="tabStripDiv">
                    <AssignDatatable
                      style={{ width: '100%' }}
                      filteralbe={true}
                      groupable={true}
                      rows={meterReadingItems}
                      columns={meterReadingColumns}
                      selectionMode={false}
                      showHideColumnMenu={false}
                      callAfterInit={this.state.callAfterInit}
                      stopCommsAfterInit={() => this.stopCommsAfterInit()}
                      loading={this.state.loading}
                      allowExportSelectedData={false}
                      showNewBtn={false}
                      hideExport={true}
                      hideSearchPanel={true}
                      showCommandBtn={false}
                      showViewDetailBtn={false}
                    />
                  </div>
                )}
              </>
              :
              <Spinner size='large' type={'converging-spinner'} className="loader" />
            }
          </TabPanel>}
        </div>
        {this.renderAttachmentModal()}
        {this.renderDefectdetailModal()}
      </div >

    );
  }
}

function TabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
function a11yPropsTop(index: any) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}