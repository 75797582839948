import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import global from '../../classes/global';
export default class TimesheetDetailsModal extends React.Component<any, any> {

    public state = {
        details: this.props.details
    };

    public componentDidMount() {
        ////console.log('component load');

    }

    public afterInit() {
        ////console.log('init');

    }


    public render(): React.ReactElement<any> {
        const URL = window.location.href;
        const parentURL = URL.split("#");
        let link = `${parentURL[0]}/#/WorkOrderDetail?WOID=${this.props.details.woid}&WONum=${this.props.details.Workordernumber}`;

        return (
            <div>
                <Dialog
                    open={this.props.showModal}
                    onClose={() => this.props.onClick()}
                    // className={'addEditModal'}
                    fullWidth={true}
                    maxWidth={'lg'}
                    aria-labelledby="max-width-dialog-title"
                >
                    <DialogTitle id="scroll-dialog-title">Timesheet Details</DialogTitle>
                    <IconButton aria-label="close" onClick={() => this.props.onClick()} className="closeIcon">
                        <CloseIcon />
                    </IconButton>
                    <DialogContent dividers={true}>
                        <div>
                            {
                                (!this.props.details) && (
                                    <div>
                                        <h4 className="alignCenter">No data availabe.</h4>
                                        <br />
                                    </div>
                                )
                            }

                            {(this.props.details) && (
                                <div className="detailFieldDiv" dir="ltr">
                                    <Grid container spacing={3}>
                                        <Grid item xs={6} sm={3}>Work Order Number:</Grid>
                                        <Grid item xs={6} sm={9} className="semiBold">{this.props.details.Workordernumber}</Grid>
                                    </Grid>
                                    <Grid container spacing={3}>
                                        <Grid item xs={6} sm={3}>Assetic Work Order Number:</Grid>
                                        <Grid item xs={6} sm={9}><a href={global.AsseticWOLink + this.props.details.asseticWONumber} className="WOlink" rel="noreferrer" target="_blank">{this.props.details.asseticWONumber}</a></Grid>
                                    </Grid>
                                    <Grid container spacing={3}>
                                        <Grid item xs={6} sm={3}>Employee Name:</Grid>
                                        <Grid item xs={6} sm={9} className="semiBold">{this.props.details.employee}</Grid>
                                    </Grid>
                                    <Grid container spacing={3}>
                                        <Grid item xs={6} sm={3}>Timesheet Type:</Grid>
                                        <Grid item xs={6} sm={9} className="semiBold">{this.props.details.type}</Grid>
                                    </Grid>
                                    <Grid container spacing={3}>
                                        <Grid item xs={6} sm={3}>Original Start Time:</Grid>
                                        <Grid item xs={6} sm={9} className="semiBold">{this.props.details.starttime}</Grid>
                                    </Grid>
                                    <Grid container spacing={3}>
                                        <Grid item xs={6} sm={3}>Original End Time:</Grid>
                                        <Grid item xs={6} sm={9} className="semiBold"> {this.props.details.endtime}</Grid>
                                    </Grid>
                                    <Grid container spacing={3}>
                                        <Grid item xs={6} sm={3}>Original Hours Worked:</Grid>
                                        <Grid item xs={6} sm={9} className="semiBold">{this.props.details.hoursworked}</Grid>
                                    </Grid>
                                    <Grid container spacing={3}>
                                        <Grid item xs={6} sm={3}>Edited Start Time:</Grid>
                                        <Grid item xs={6} sm={9} className="semiBold danger">{this.props.details.Editedstarttime}</Grid>
                                    </Grid>
                                    <Grid container spacing={3}>
                                        <Grid item xs={6} sm={3}>Edited End Time:</Grid>
                                        <Grid item xs={6} sm={9} className="semiBold danger"> {this.props.details.Editedendtime}</Grid>
                                    </Grid>
                                    <Grid container spacing={3}>
                                        <Grid item xs={6} sm={3}>Edited Hours Worked:</Grid>
                                        <Grid item xs={6} sm={9} className="semiBold danger">{this.props.details.Editedhoursworked}</Grid>
                                    </Grid>
                                    {/* <Grid container spacing={3}>
                                        <Grid item xs={6} sm={3}>Update By:</Grid>
                                        <Grid item xs={6} sm={9} className="semiBold">{this.props.details.updatedBy}</Grid>
                                    </Grid> */}
                                </div>
                            )}

                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.props.onClick()} variant='outlined' color="primary" className={'button'}>
                            Close
                        </Button>

                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

