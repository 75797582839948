import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import utility from '../../classes/utility';
import { Config } from '../../classes/config';
import AlertDialog from '../common/alertDialog';
import AssignDatatable from '../common/DataTable/AssignDatatable';
import DateBoxInput from '../common/reusableComponents/DateBox';
import TextBoxInput from '../common/reusableComponents/TextBox';
import Spinner from '../common/reusableComponents/Spinner';

let _items: any = [];

export default class SearchMaterials extends React.Component<any, any> {

  public InventoryLocatioItems: any = [];
  public columns: any;

  constructor(props: any) {
    super(props);
    if (!this.props.Department) {
      window.open('#/AccessRequest', '_self');
    };
    this.InventoryLocatioItems = [];
    this.getWOMaterialwithcriteria = this.getWOMaterialwithcriteria.bind(this);
    this._handleKeyDown = this._handleKeyDown.bind(this);
    this.clearFilter = this.clearFilter.bind(this);


    this.columns = [

      {
        field: 'id',
        title: 'Id',
        // width: 160,
        type: 'string',
        allowHiding: false,
      },
      {
        field: 'title',
        title: 'Material Name',
        // width: 160,
        type: 'string',
        allowHiding: true,
      },
      {
        field: 'quantityUsed',
        title: 'Quantity Used',
        // width: 280,
        type: 'string',
        allowHiding: false,
      },
      {
        field: 'measuringUnit',
        title: 'Measuring Unit',
        width: 160,
        type: 'string',
        allowHiding: true
      },
      {
        field: 'Workordernumber',
        title: 'Workorder #',
        // width: 160,
        type: 'string',
        link: 'workorderLink',
        allowHiding: true
      },
      {
        field: 'comments',
        title: 'Comments',
        // width: 160,
        type: 'string',
        allowHiding: true
      },
      {
        field: 'updatedOn',
        title: 'Updated',
        width: 160,
        type: 'datetime',
        visibleColumn: false,
        allowHiding: true
      },
      {
        field: 'updatedBy',
        title: 'Updated By',
        width: 160,
        type: 'string',
        visibleColumn: false,
        allowHiding: true
      },
      {
        field: 'createdOn',
        title: 'Created',
        width: 160,
        type: 'datetime',
        visibleColumn: false,
        allowHiding: true
      },
      {
        field: 'createdBy',
        title: 'Created By',
        width: 160,
        type: 'string',
        visibleColumn: false,
        allowHiding: true
      },
    ];

    this.state = {
      items: _items,
      columns: this.columns,
      callAfterInit: false,
      loading: false,
    };

  }


  public componentDidMount() {

    this._getWOMaterialData();
  }
  public _handleKeyDown = (e: any) => {
    if (e.event.originalEvent.key === 'Enter') {
      this.getWOMaterialwithcriteria();
    }
  }
  public clearFilter() {
    this.setState({
      items: '',
      workorderno: '',
      materialname: '',
      dateCreatedfrom: '',
      dateCreatedTo: '',
      resourceCode: ''
    });
  }

  private _getWOMaterialData() {

    utility.genericGetAPICall(Config.inventoryLocationList).then((data: any) => {
      // console.log(data);
      this.InventoryLocatioItems = [];

      for (let r of data) {
        this.InventoryLocatioItems.push({ id: r.Id, text: r.Title });
      }
      this.setState({ InventoryLocatioItems: this.InventoryLocatioItems });

    }, (err) => {
      console.log(err);
    });
  }


  public getWOMaterialwithcriteria() {

    let criteriaSelected = false;

    let dataToPassToService: any = {};
    // if (this.state.inventoryLocation) {
    //   //dataToPassToService += "WorkOrder/WONumber eq '" + this.state.workorderno + "'and ";
    //   dataToPassToService.InventoryLocation = this.state.inventoryLocation.id;
    //   criteriaSelected = true;
    // }
    if (this.state.dateCreatedTo && this.state.dateCreatedfrom) {
      let dcreatedfrom = new Date(this.state.dateCreatedfrom);
      let dcreatedTo = new Date(this.state.dateCreatedTo);
      if (dcreatedTo.getTime() < dcreatedfrom.getTime()) {
        this.setState({
          alertMessage: 'Date To should be greater than Date From',
          showAlert: true
        });
        return;
      }
    }

    if (this.state.dateCreatedfrom || this.state.dateCreatedTo) {
      let dfrom;
      let dTo;
      if (this.state.dateCreatedfrom) {
        dfrom = new Date(this.state.dateCreatedfrom).toISOString();
        dataToPassToService.DateFrom = dfrom;
      }
      if (this.state.dateCreatedTo) {
        var extraday = new Date(this.state.dateCreatedTo);
        extraday.setDate(extraday.getDate() + 1);
        dTo = extraday.toISOString().substring(0, 10) + "T13:59:59.000Z";
        // dTo = this.state.dateCreatedTo.toISOString();
        dataToPassToService.DateTo = dTo;
      }

      criteriaSelected = true;
    }
    if (this.state.workorderno) {
      dataToPassToService.WorkOrderNumber = this.state.workorderno;
      criteriaSelected = true;
    }

    if (this.state.title) {
      //dataToPassToService += "Material eq '"+ this.state.materialname.text +"'and ";
      dataToPassToService.Title = this.state.title;
      criteriaSelected = true;
    }
    if (this.state.resourceCode) {
      //dataToPassToService += "Material eq '"+ this.state.materialname.text +"'and ";
      dataToPassToService.MaterialResourceCode = this.state.resourceCode;
      criteriaSelected = true;
    }
    if (!criteriaSelected) {
      this.setState({
        alertMessage: 'Please select search criteria',
        showAlert: true
      });
      return;
    }

    this.setState({
      callAfterInit: true,
      loading: true
    });
    utility.genericPostAPIcall(Config.WOMaterialSearchAPI, dataToPassToService).then((data: any) => {
      _items = [];
      // console.log(data);
      if (data.length === null) {
        if (_items.length <= 0) {
          this.setState({
            alertMessage: 'No results found for this criteria',
            showAlert: true
          });
        }
        this.setState({ items: _items, loading: false });
        return;
      }
      else {
        data.forEach((item: any) => {

          _items.push({
            id: item.MaterialId,
            title: item.MaterialName,
            measuringUnit: item.MeasuringUnit,
            quantityUsed: item.QuantityUsed,
            comments: item.Comments,
            Workordernumber: item.WorkOrderNumber + ' - ' + item.WorkOrderTitle,
            updatedBy: item.updatedBy,
            updatedOn: item.DateUpdated,
            createdBy: item.createdBy,
            createdOn: item.DateCreated,
          });
        });

      }
      // _items = utility._sortItems(_items, 'updatedOn', true);
      if (_items.length <= 0) {
        this.setState({
          alertMessage: 'No results found for this criteria',
          showAlert: true
        });
      }
      this.setState({ items: _items, loading: false });
      //console.log(_items);
    }, (err) => {
      console.log(err);
      this.setState({ loading: false });
    });
  }
  private stopCommsAfterInit = (): void => {
    this.setState({ callAfterInit: false });
  }
  private handleValueChange = (event: any, fieldName: string) => {
    this.setState({ [fieldName]: event.target.value });
  }

  public render(): React.ReactElement<any> {

    return (
      <div>
        {this.state.showAlert && this.alert()}
        <div className={"SearchDiv"}>
          <div className={'headingDiv'}>
            <span className={"heading"}>Material Usage</span>
          </div>
        </div>
        <div className={"searchFieldDiv"}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={4}>

              <DateBoxInput
                stylingMode='underlined'
                type="date"
                displayFormat={"dd/MM/yy"}
                label={'Date From'}
                showClearButton={true}
                value={this.state.dateCreatedfrom ? new Date(this.state.dateCreatedfrom) : null}
                onValueChanged={(e: any) => this.handleValueChange(e, 'dateCreatedfrom')}
              />
            </Grid>
            <Grid item xs={12} sm={4}>

              <DateBoxInput
                stylingMode='underlined'
                type="date"
                displayFormat={"dd/MM/yy"}
                label={'Date To'}
                showClearButton={true}
                value={this.state.dateCreatedTo ? new Date(this.state.dateCreatedTo) : null}
                onValueChanged={(e: any) => this.handleValueChange(e, 'dateCreatedTo')}
              />
            </Grid>
            <Grid item xs={12} sm={4} className={''}>
              <TextBoxInput
                stylingMode='underlined'
                label="Work Order #"
                // pattern={"[A-Za-z]+"}
                // minLength={2}
                value={this.state.workorderno}
                onValueChanged={(e: any) => this.handleValueChange(e, 'workorderno')}
                onKeyDown={this._handleKeyDown}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextBoxInput
                stylingMode='underlined'
                label="Title"
                // pattern={"[A-Za-z]+"}
                // minLength={2}
                value={this.state.title}
                onValueChanged={(e: any) => this.handleValueChange(e, 'title')}
                onKeyDown={this._handleKeyDown}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextBoxInput
                stylingMode='underlined'
                label="Resource Code"
                // pattern={"[A-Za-z]+"}
                // minLength={2}
                value={this.state.resourceCode}
                onValueChanged={(e: any) => this.handleValueChange(e, 'resourceCode')}
                onKeyDown={this._handleKeyDown}
              />
            </Grid>
            {/* <Grid item xs={12} sm={4}>
              <ComboBox
                className={"mt-10"}
                data={this.state.InventoryLocatioItems}
                  "
                dataItemKey="id"
                value={this.state.inventoryLocation}
                label='Inventory Location'
                onValueChanged={(e: any) => this.handleValueChange(e, 'inventoryLocation')}
                allowCustom={true}
                 
              />
            </Grid> */}

          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <div className={"searchButtonDiv"}>
                <Button onClick={this.clearFilter} variant='outlined'>
                  Clear
                </Button>
                <Button onClick={this.getWOMaterialwithcriteria} variant='outlined' color="primary" className={'button'}>
                  Search
                </Button>
              </div>
            </Grid>
          </Grid>
        </div>
        <div>

          {!this.state.loading ?
            <>
              {(this.state.items && this.state.items.length > 0) && (
                <AssignDatatable
                  style={{ width: '100%' }}
                  filteralbe={true}
                  groupable={true}
                  rows={this.state.items}
                  columns={this.state.columns}
                  fileName={'Material Usage'}
                  columnChooser={'materialUsageColumns'}
                  selectionMode={false}
                  showHideColumnMenu={true}
                  callAfterInit={this.state.callAfterInit}
                  stopCommsAfterInit={() => this.stopCommsAfterInit()}
                  loading={this.state.loading}
                  allowExportSelectedData={false}
                  showNewBtn={false}
                  //  viewItem={(a:any) => this.viewDetials(a)}
                  // showNewModal = {() => this._showModal()}
                  // deleteItem = {(a:any) => this.DeleteDialog(a)}
                  // editItem = {(a:any) => this.EditItemModal(a)}
                  showCommandBtn={false}
                  showViewDetailBtn={false}
                />
              )}
            </>
            :
            // <CircularProgress className="loader" />
            <Spinner size='large' lable="Loading..." className="loader" />
          }
        </div>


      </div>
    );
  }

  public alert() {
    return (
      <AlertDialog
        showDialog={this.state.showAlert}
        message={this.state.alertMessage}
        title={Config.productTitle}
        onClose={() => this.setState({ alertMessage: '', showAlert: false })}
      />
    );
  }

}

