import * as React from 'react';
import utility from '../../../classes/utility';
import { Config } from '../../../classes/config';
import Grid from '@material-ui/core/Grid';
import ScrollView from 'devextreme-react/scroll-view';
import AlertDialog from '../../common/alertDialog';
import SelectBoxInput from '../../common/reusableComponents/SelectBox';
import TextBoxInput from '../../common/reusableComponents/TextBox';
import CheckBoxInput from '../../common/reusableComponents/CheckBox';
import Spinner from '../../common/reusableComponents/Spinner';
import capitalize from '@material-ui/core/utils/capitalize';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
export default class EditEquipment extends React.Component<any, any> {

    public categoryItems: any = [];
    public prestartFormItems: any = [];

    constructor(props: any) {
        super(props);

        this.EditEquipment = this.EditEquipment.bind(this);
        this.clearFilter = this.clearFilter.bind(this);
        this.prestartFormItems = [];
        this.state = {
            inspectionFailed: false,
            isActive: false
        };
    }

    public async didMount() {
        await Promise.all([
            this._getDepartmentData(),
            this._getTeamDataAsync(),
            this._getCrewData(),
            this._getPrestartFormData()
        ]);
        if (this.props.details && this.props.mode === 'edit') {
            this.setState({ loading: true })
            this.populateValue(this.props.details, this.props.mode);
        } else {
            this.setState({
                hideAlertButton: false,
                mode: this.props.mode,
                isActive: true,
            });
        }

    }
    public populateValue(details: any, mode?: any) {
        this.setState({
            id: details.id,
            title: details.title,
            rCode: details.resourceCode,
            rego: details.regonumber,
            isActive: details.active ? true : false,
            inspectionFailed: details.inspectionFailed ? true : false,
            prestartForm: details.prestartformid ? { id: details.prestartformid, text: details.prestartformName } : '',
            department: details.departmentId ? { id: details.departmentId, text: details.department } : '',
            category: details.category ? details.category : '',
            loading: false
        });

    }

    private async _getTeamDataAsync() {
        try {
            let team: any = '';
            const data: any = await utility.genericGetAPICall(`${Config.TeamList}?showAll=true`);
            const teamsItems = data.map((r: any) => ({ id: r.id, text: r.title.toLowerCase(), departmentId: r.departmentId }));
            let sortedItems = utility._sortItems([...teamsItems], 'text', false);
            if (this.props.details && this.props.mode === 'edit') {
                let { departmentId, teamId } = this.props.details;

                sortedItems = teamsItems && teamsItems.filter((t: any) => t.departmentId === departmentId);

                if (sortedItems && sortedItems.length > 0) {
                    let filteredTeams = sortedItems.filter((t: any) => t.id === teamId);
                    if (filteredTeams && filteredTeams.length > 0) {
                        team = { id: filteredTeams[0].id, text: filteredTeams[0].text };
                    }
                }
            }

            this.setState({
                teamItems: sortedItems,
                allteamItems: sortedItems,
                team: team ? team : ''
            });
        } catch (err) {
            console.log(err);
        }
    }
    private async _getCrewData() {
        try {
            let crew: any = '';
            const data: any = await utility.genericGetAPICall(`${Config.crewswithdepartment}?showAll=true`);
            const crewItems = data.map((r: any) => ({
                id: r.id, text: r.title, teamId: r.teamId, departmentId: r.departmentId
            }));
            let sortedItems = utility._sortItems(crewItems, 'text', false);
            if (this.props.details && this.props.mode === 'edit') {
                let { departmentId, teamId } = this.props.details;
                sortedItems = crewItems && crewItems.filter((c: any) => c.teamId === teamId && c.departmentId === departmentId);


                if (sortedItems && sortedItems.length > 0) {
                    let selectedcrew = sortedItems && sortedItems.filter((c: any) => c.id === this.props.details.crewId && c.text === this.props.details.crew);
                    if (selectedcrew.length > 0) {
                        crew = { id: selectedcrew[0].id, text: selectedcrew[0].text };
                    }

                }
            }

            this.setState({
                crewItems: sortedItems, allcrewItems: sortedItems,
                crew: crew ? crew : ''
            });
        } catch (err) {
            console.log(err);
        }
    }

    public EditEquipment() {
        let dataToPassToService: any = {};

        if (!this.state.title) {
            this.setState({
                alertMessage: 'Please enter title',
                showAlert: true
            });
            return;
        }
        if (!this.state.rego) {
            this.setState({
                alertMessage: 'Please enter rego number',
                showAlert: true
            });
            return;
        }
        if (!this.state.rCode) {
            this.setState({
                alertMessage: 'Please enter resource code',
                showAlert: true
            });
            return;
        }
        this.setState({
            alertMessage: <div className={'alertLoader'}><Spinner size='large' label="Loading..." /></div>,
            hideAlertButton: true,
            showAlert: true
        });
        dataToPassToService.title = this.state.title;
        dataToPassToService.resourceCode = this.state.rCode;
        dataToPassToService.regoNumber = this.state.rego;
        dataToPassToService.isActive = this.state.isActive;
        dataToPassToService.tenantId = Config.tenantId;
        dataToPassToService.departmentId = this.state.department.id;
        dataToPassToService.teamId = this.state.team.id;
        dataToPassToService.defaultCrewId = this.state.crew.id;
        dataToPassToService.category = this.state.category.text;
        dataToPassToService.prestartFormId = this.state.prestartForm ? this.state.prestartForm.id : '';
        dataToPassToService.inspectionFailed = this.state.inspectionFailed;
        if (this.props.mode === 'edit') {
            dataToPassToService.id = this.state.id;
            dataToPassToService.updated = new Date();
            dataToPassToService.updatedBy = this.props.currentUser.Email;
            utility.genericUpdateAPICall(Config.EquipmentAPi, this.state.id, dataToPassToService).then((data: any) => {
                //console.log(data);
                this.setState({ showAlert: false });
                this.clearFilter();
                this.props.onClick();
                this.props.message("Plant and Fleet has been updated");
            }, (err: any) => {
                console.log(err);
                this.setState({
                    alertMessage: 'Something went wrong!',
                    hideAlertButton: false,
                    showAlert: true
                });
            });
        } else {
            dataToPassToService.createdBy = this.props.currentUser.Email;
            dataToPassToService.created = new Date();
            utility.genericPostAPIcall(Config.EquipmentAPi, dataToPassToService).then((data: any) => {
                //console.log(data);
                this.setState({ showAlert: false });
                this.clearFilter();
                this.props.onClick();
                this.props.message("Plant and Fleet has been created");
            }, (err: any) => {
                console.log(err);
                this.setState({
                    alertMessage: 'Something went wrong!',
                    hideAlertButton: false,
                    showAlert: true
                });
            });
        }

    }

    private async _getPrestartFormData() {
        try {
            const data: any = await utility.genericGetAPICall(`${Config.PrestartFormsListName}?showAll=true`);
            this.prestartFormItems = [];

            for (let r of data) {
                this.prestartFormItems.push({ id: r.id, text: r.title });
            }
            const sortedItems = utility._sortItems(this.prestartFormItems, 'text', false);
            this.setState({ prestartFormItems: sortedItems, allprestartFormItems: sortedItems });
        } catch (err) {
            console.log(err);
        }
    }
    private async _getDepartmentData() {
        try {
            const data: any = await utility.genericGetAPICall(`${Config.DepartmentList}?showAll=true`);
            const departmentItems = data.map((r: any) => ({
                id: r.id, text: r.title
            }));

            const sortedItems = utility._sortItems(departmentItems, 'text', false);
            this.setState({
                departmentItems: sortedItems, alldepartmentItems: sortedItems
            });

        } catch (err) {
            console.log(err);
        }
    }
    public clearFilter() {
        this.setState({
            title: '',
            rego: '',
            category: '',
            rCode: '',
            department: '',
            team: '',
            crew: '',
            prestartForm: '',
        });
        this.props.onClose();

    }

    private alert() {
        return (
            <AlertDialog
                showDialog={this.state.showAlert}
                message={this.state.alertMessage}
                title={Config.productTitle}
                onClose={() => this.setState({ alertMessage: '', showAlert: false })}
                hideAlertButton={this.state.hideAlertButton}
            />
        );
    }
    private handleValueChange = (event: any, fieldName: string) => {
        this.setState({ [fieldName]: event.value });
    }
    public handleDropdownChange(e: any, fieldName: string) {
        if (fieldName === "department" && e.selectedItem !== null) {
            this.setState({ team: '', teamItems: this.state.allteamItems });
            let filteredTeam = this.state.allteamItems && this.state.allteamItems.filter((t: any) => t.departmentId === e.selectedItem.id);
            this.setState({ team: '', teamItems: filteredTeam });
        }
        if (fieldName === "team" && e.selectedItem !== null) {
            this.setState({ crew: '', crewItems: this.state.allcrewItems });
            let filteredcrew = this.state.allcrewItems && this.state.allcrewItems.filter((t: any) => t.departmentId === e.selectedItem.departmentId && t.teamId === e.selectedItem.id);
            this.setState({ crew: '', crewItems: filteredcrew });
        }
        this.setState({ [fieldName]: e.selectedItem });
    }
    public render(): React.ReactElement<any> {

        return (
            <div>
                {this.state.showAlert && this.alert()}
                <Dialog
                    open={this.props.showModal}
                    onClose={() => this.clearFilter()}
                    fullWidth={true}
                    maxWidth={'lg'}
                    aria-labelledby="max-width-dialog-title"
                    onRendered={() => this.didMount()}
                >
                    <DialogTitle id="scroll-dialog-title">
                        {`${this.props.mode && capitalize(this.props.mode)} Plant and Fleet`}
                    </DialogTitle>
                    <IconButton aria-label="close" onClick={() => this.clearFilter()} className="closeIcon">
                        <CloseIcon />
                    </IconButton>
                    <DialogContent dividers={true}>
                        <ScrollView width='100%' height='100%' showScrollbar='onScroll' scrollByThumb={true}>
                            <div className='popContentDiv'>
                                {!this.state.loading ?
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={6}>
                                            <TextBoxInput
                                                stylingMode='underlined'
                                                label="Title *"
                                                value={this.state.title}
                                                onKeyDown={() => { }}
                                                onValueChanged={(e: any) => this.handleValueChange(e, 'title')}
                                                required={true}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextBoxInput
                                                stylingMode='underlined'
                                                label="Resource Code *"
                                                value={this.state.rCode}
                                                onKeyDown={() => { }}
                                                onValueChanged={(e: any) => this.handleValueChange(e, 'rCode')}
                                                required={true}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextBoxInput
                                                stylingMode='underlined'
                                                label="Rego Number *"
                                                value={this.state.rego}
                                                onKeyDown={() => { }}
                                                onValueChanged={(e: any) => this.handleValueChange(e, 'rego')}
                                                required={true}
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={6}>
                                            <SelectBoxInput
                                                stylingMode='underlined'
                                                displayExpr="text"
                                                valueExpr="id"
                                                showClearButton={true}
                                                items={this.state.departmentItems}
                                                selectedItem={this.state.department}
                                                label='Department'
                                                onSelectionChanged={(e: any) => this.handleDropdownChange(e, 'department')}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <SelectBoxInput
                                                stylingMode='underlined'
                                                displayExpr="text"
                                                valueExpr="id"
                                                showClearButton={true}
                                                items={this.state.teamItems}
                                                selectedItem={this.state.team}
                                                label='Team'
                                                onSelectionChanged={(e: any) => this.handleDropdownChange(e, 'team')}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <SelectBoxInput
                                                stylingMode='underlined'
                                                displayExpr="text"
                                                valueExpr="id"
                                                showClearButton={true}
                                                items={this.state.crewItems}
                                                selectedItem={this.state.crew}
                                                label='Crew'
                                                onSelectionChanged={(e: any) => this.handleDropdownChange(e, 'crew')}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>

                                            <TextBoxInput
                                                stylingMode='underlined'
                                                label="Category"
                                                value={this.state.category}
                                                disabled={true}
                                                onKeyDown={() => { }}
                                                onValueChanged={(e: any) => this.handleValueChange(e, 'category')}
                                                required={true}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <SelectBoxInput
                                                stylingMode='underlined'
                                                displayExpr="text"
                                                valueExpr="id"
                                                showClearButton={true}
                                                items={this.state.prestartFormItems}
                                                selectedItem={this.state.prestartForm}
                                                label='Prestart Form '
                                                onSelectionChanged={(e: any) => this.handleDropdownChange(e, 'prestartForm')}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={3}>
                                            <CheckBoxInput value={this.state.inspectionFailed} onValueChanged={(e: any) => this.handleValueChange(e, 'inspectionFailed')} text={'Inspection Failed'} />
                                        </Grid>
                                        <Grid item xs={12} sm={3}>
                                            <CheckBoxInput value={this.state.isActive} onValueChanged={(e: any) => this.handleValueChange(e, 'isActive')} text={'Is Active'} />
                                        </Grid>
                                    </Grid>
                                    :
                                    <Spinner size="large" label="Loading all component. Please wait..." />
                                }
                            </div>
                        </ScrollView>
                    </DialogContent>
                    <DialogActions>

                        <Button onClick={() => this.clearFilter()} variant='contained' color="primary" className={''}>
                            Cancel
                        </Button>
                        <Button onClick={() => this.EditEquipment()} variant='outlined' color="primary" className={'button'}>
                            Save
                        </Button>

                    </DialogActions>
                </Dialog>
            </div>
        );
    }

}

