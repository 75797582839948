import * as React from 'react';
import utility from '../../../classes/utility';
import { Config } from '../../../classes/config';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import ScrollView from 'devextreme-react/scroll-view';
import AlertDialog from '../../common/alertDialog';
import Spinner from '../../common/reusableComponents/Spinner';
import TextBoxInput from '../../common/reusableComponents/TextBox';
import capitalize from '@material-ui/core/utils/capitalize';
import CheckBoxInput from '../../common/reusableComponents/CheckBox';
import TreeView from 'devextreme-react/tree-view';
import SelectBoxInput from '../../common/reusableComponents/SelectBox';
export default class AddEditRiskControlMeasure extends React.Component<any, any> {
    public departmentItems: any = [];
    public HierarchyItems: any = [];
    public treeViewRef: any;
    constructor(props: any) {
        super(props);
        this.treeViewRef = React.createRef();
        this.addUpdateItem = this.addUpdateItem.bind(this);
        this.clearFilter = this.clearFilter.bind(this);
        this.treeViewSelectionChanged = this.treeViewSelectionChanged.bind(this);
        this.treeViewContentReady = this.treeViewContentReady.bind(this);
        this.valueChanged = this.valueChanged.bind(this);
        this.departmentItems = [];
        this.HierarchyItems = [];
        this.state = {
            isActive: true,
        };
    }

    public async didMount() {

        this._getAreaAndRisksData();
        this._getRiskHierarchyData();

        if (this.props.details && this.props.mode === 'edit') {
            this.setState({ loading: true })
            this.populateValue(this.props.details, this.props.mode);

        } else {
            this.setState({
                hideAlertButton: false,
                mode: this.props.mode,
                title: ''
            })
        }
    }
    public getRiskRelations() {
        const filteredArray = (this.state.ParentseletedItems || [])
            .filter((item: any, index: any) => this.state.ParentseletedItems.indexOf(item) === index);

        const riskRelations = filteredArray.reduce((acc: any, item: any) => {
            if (item && item.itemData) {
                const tempriskid = item.itemData.items
                    .filter((risks: any) => risks.selected === true)
                    .map((risks: any) => risks.riskId);
                acc.push({ riskIds: tempriskid, riskGroupId: item.itemData.id });
            }
            return acc;
        }, [] as any[]);

        return riskRelations;

    }

    private _getRiskHierarchyData() {
        try {
            utility.genericGetAPICall(`${Config.RiskHierarchy}?showAll=true`).then((data: any) => {
                this.HierarchyItems = [];
                let Hierarchytext: any;

                for (let r of data) {
                    if (r.type === "controlmeasure") {
                        this.HierarchyItems.push({ id: r.id, text: r.title });
                        if (this.props.details && this.props.mode === 'edit') {
                            if (r.id === this.props.details.hierarchyId) {
                                Hierarchytext = this.props.details.hierarchyId ? { id: r.id, text: r.title } : '';
                            }
                        }
                    }
                }
                this.HierarchyItems = this._sortItems(this.HierarchyItems, 'id', false);
                this.setState({ HierarchyItems: this.HierarchyItems, allHierarchyItems: this.HierarchyItems, Hierarchy: Hierarchytext });
            });
        } catch (err) {
            console.log(err);
        }
    }
    private _sortItems = (items: any[], sortBy: string, descending = false): any[] => {
        if (descending) {
            return items.sort((a: any, b: any) => {
                if (a[sortBy] < b[sortBy]) {
                    return 1;
                }
                if (a[sortBy] > b[sortBy]) {
                    return -1;
                }
                return 0;
            });
        } else {
            return items.sort((a: any, b: any) => {
                if (a[sortBy] < b[sortBy]) {
                    return -1;
                }
                if (a[sortBy] > b[sortBy]) {
                    return 1;
                }
                return 0;
            });
        }
    }
    public populateValue(details: any, mode?: any) {
        this.setState({
            id: this.props.details.id,
            title: this.props.details.title,
            Hierarchy: this.props.details.hierarchyId,
            isActive: this.props.details.isActive
        });
        setTimeout(() => this.setState({ loading: false }), 1000);
    }
    private _getAreaAndRisksData() {
        try {
            utility.genericGetAPICallForList(Config.RiskGroupWithRisks).then((data: any) => {
                let sortedItems = utility._sortItems(data, 'title', false);
                sortedItems.forEach((arr: any) => {
                    arr.items = arr.risks;
                    delete arr.risks;
                });
                sortedItems = sortedItems.filter((f: any) => f.items && f.items.length > 0);
                sortedItems.forEach((element: any) => {
                    if (element.items && element.items.length > 0) {
                        let sortedchildItems = utility._sortItems(element.items, 'title', false);
                        sortedchildItems.forEach((ChildItem: any) => {
                            ChildItem.riskId = ChildItem.id;
                            ChildItem.id = `${ChildItem.id}_${element.id}`;
                            if (this.props.details && this.props.mode === 'edit') {
                                if (this.props.details.risksRelations && this.props.details.risksRelations.length > 0) {
                                    this.props.details.risksRelations.forEach((selectedId: any) => {
                                        if (selectedId.riskIds.length > 0) {
                                            selectedId.riskIds.forEach((riskID: any) => {
                                                if (ChildItem.riskId === riskID && element.id === selectedId.riskGroupId) {
                                                    element.expanded = true;
                                                    ChildItem.selected = true;
                                                }
                                            });
                                        }
                                    });
                                }
                            }
                        });
                    }
                });

                this.setState({ allRiskCategories: sortedItems });

                if (this.props.mode === 'add') {
                    this.setState({ allRiskCategories: sortedItems });

                }
            });
        } catch (err) {
            console.log(err);
        }

    }
    public addUpdateItem() {
        let dataToPassToService: any = {};

        if (!this.state.title) {
            this.setState({
                alertMessage: 'Please enter title',
                showAlert: true
            });
            return;
        }

        this.setState({
            alertMessage: <div className={'alertLoader'}><Spinner size='large' type={'converging-spinner'} /> <br /> Loading...</div>,
            hideAlertButton: true,
            showAlert: true
        });
        dataToPassToService.title = this.state.title;
        dataToPassToService.tenantId = Config.tenantId;
        dataToPassToService.isActive = true;
        dataToPassToService.hierarchyId = this.state.Hierarchy ? this.state.Hierarchy.id : '';
        dataToPassToService.risksRelations = this.getRiskRelations();
        dataToPassToService.updatedBy = this.props.currentUser.Email;
        if (this.props.mode === 'edit') {
            dataToPassToService.id = this.state.id;
            dataToPassToService.updated = new Date();

            utility.genericUpdateAPICall(Config.RiskControlmeasures, this.state.id, dataToPassToService).then((data: any) => {
                //console.log(data);
                this.setState({ showAlert: false });
                this.clearFilter();
                this.props.onClick();
                this.props.message("Risk Control Measure has been updated");
            }, (err: any) => {
                console.log(err);
                this.setState({
                    alertMessage: 'Something went wrong!',
                    hideAlertButton: false,
                    showAlert: true
                });
            });
        } else {
            dataToPassToService.createdBy = this.props.currentUser.Email;
            dataToPassToService.created = new Date();
            utility.genericPostAPIcall(Config.RiskControlmeasures, dataToPassToService).then((data: any) => {
                //console.log(data);
                this.setState({ showAlert: false });
                this.clearFilter();
                this.props.onClick();
                this.props.message("Risk Control Measure has been created");
            }, (err: any) => {
                console.log(err);
                this.setState({
                    alertMessage: 'Something went wrong!',
                    hideAlertButton: false,
                    showAlert: true
                });
            });
        }

    }

    public clearFilter() {
        this.props.onClose();

    }
    private alert() {
        return (
            <AlertDialog
                showDialog={this.state.showAlert}
                message={this.state.alertMessage}
                title={Config.productTitle}
                onClose={() => this.setState({ alertMessage: '', showAlert: false })}
                hideAlertButton={this.state.hideAlertButton}
            />
        );
    }
    private handleValueChange = (event: any, fieldName: string) => {
        this.setState({ [fieldName]: event.value });
    }
    public handleDropdownChange(e: any, fieldName: string) {
        this.setState({ [fieldName]: e.selectedItem });
    }
    treeViewSelectionChanged(e: any) {
        this.syncSelection(e.component);
    }

    treeViewContentReady(e: any) {
        this.syncSelection(e.component);
    }

    syncSelection(treeView: any) {
        const seletedItems = treeView.getSelectedNodes()
            .map((node: any) => node.itemData);
        const ParentseletedItems = treeView.getSelectedNodes()
            .map((node: any) => node.parent);
        this.setState(() => ({ seletedItems: seletedItems, ParentseletedItems: ParentseletedItems }));
    }
    valueChanged(e: any) {
        this.setState({ value: e.value });
    }

    public render(): React.ReactElement<any> {
        return (
            <div>
                {this.state.showAlert && this.alert()}

                <Dialog
                    open={this.props.showModal}
                    onClose={() => this.clearFilter()}
                    fullWidth={true}
                    maxWidth={'lg'}
                    aria-labelledby="max-width-dialog-title"
                    onRendered={() => this.didMount()}
                >
                    <DialogTitle id="scroll-dialog-title">
                        {`${this.props.mode && capitalize(this.props.mode)} Risk Control Measure`}
                    </DialogTitle>
                    <IconButton aria-label="close" onClick={() => this.clearFilter()} className="closeIcon">
                        <CloseIcon />
                    </IconButton>
                    <DialogContent dividers={true}>
                        <ScrollView width='100%' height='100%' showScrollbar='onScroll' scrollByThumb={true}>

                            <div className='popContentDiv'>
                                {!this.state.loading ?
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={6}>
                                            <TextBoxInput
                                                stylingMode='underlined'
                                                label="Title *"
                                                // pattern={"[A-Za-z]+"}
                                                // minLength={2}
                                                value={this.state.title}
                                                onValueChanged={(e: any) => this.handleValueChange(e, 'title')}
                                                onKeyDown={() => { }}
                                                required={true}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <SelectBoxInput
                                                stylingMode='underlined'
                                                displayExpr="text"
                                                valueExpr="id"
                                                showClearButton={true}
                                                items={this.state.HierarchyItems}
                                                selectedItem={this.state.Hierarchy}
                                                label='Hierarchy'
                                                onSelectionChanged={(e: any) => this.handleDropdownChange(e, 'Hierarchy')}

                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12}>
                                            <Grid item xs={12} sm={4}>
                                                <CheckBoxInput value={this.state.isActive} onValueChanged={(e: any) => this.handleValueChange(e, 'isActive')} text={'Is Active'} />
                                            </Grid>
                                            <br />
                                            <Grid item xs={12} sm={12}>
                                                <div className='workTypeListContainer' >
                                                    <h3>Select Risks </h3>
                                                    <Grid container spacing={3}>
                                                        <Grid item xs={12} sm={12}>
                                                            {!this.state.loading ?
                                                                <>
                                                                    {(this.state.allRiskCategories && this.state.allRiskCategories.length > 0) &&
                                                                        <>
                                                                            <TreeView
                                                                                id="treeview"
                                                                                ref={this.treeViewRef}
                                                                                width={600}
                                                                                height={320}
                                                                                items={this.state.allRiskCategories ? this.state.allRiskCategories : ''}
                                                                                selectNodesRecursive={true}
                                                                                selectByClick={false}
                                                                                showCheckBoxesMode={'normal'}
                                                                                selectionMode={'multiple'}
                                                                                onSelectionChanged={this.treeViewSelectionChanged}
                                                                                onContentReady={this.treeViewContentReady}
                                                                                itemRender={renderTreeViewItem}
                                                                                searchMode={this.state.value}
                                                                                searchEnabled={true}
                                                                                searchExpr={'title'}
                                                                            />
                                                                        </>
                                                                    }
                                                                </> :
                                                                <Spinner size='large' label="Loading..." />
                                                            }

                                                        </Grid>
                                                    </Grid>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    :
                                    <Spinner size="large" label="Loading all component. Please wait..." />
                                }
                            </div>
                        </ScrollView>
                    </DialogContent>
                    <DialogActions>

                        <Button onClick={() => this.clearFilter()} variant='contained' color="primary" className={''}>
                            Cancel
                        </Button>
                        <Button onClick={() => this.addUpdateItem()} variant='outlined' color="primary" className={'button'}>
                            Save
                        </Button>

                    </DialogActions>
                </Dialog>
            </div >
        );
    }

}
function renderTreeViewItem(item: any) {
    return `${item.title}`;
}


