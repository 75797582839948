import * as React from 'react';
import utility from '../../../classes/utility';
import { Config } from '../../../classes/config';
import Grid from '@material-ui/core/Grid';
import ScrollView from 'devextreme-react/scroll-view';
import AlertDialog from '../../common/alertDialog';
import Spinner from '../../common/reusableComponents/Spinner';
import CheckBoxInput from '../../common/reusableComponents/CheckBox';
import TextAreaInput from '../../common/reusableComponents/TextArea';
import TextBoxInput from '../../common/reusableComponents/TextBox';
import capitalize from '@material-ui/core/utils/capitalize';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

export default class AddEditPrestartForms extends React.Component<any, any> {

    constructor(props: any) {
        super(props);

        this.updateForms = this.updateForms.bind(this);
        this.clearFilter = this.clearFilter.bind(this);
        this.state = {

        };
    }

    public didMount() {
        if (this.props.details && this.props.mode === 'edit') {
            this.populateValue(this.props.details, this.props.mode);
        } else {
            this.setState({
                isActive: true,
                hideAlertButton: false,
                mode: this.props.mode,
            })
        }

    }
    public populateValue(details: any, mode?: any) {
        this.setState({
            id: details.id,
            title: details.title,
            premodel: details.premodel,
            postModel: details.postModel,
            // postchecklist: details.postchecklist,
            header: details.header,
            isActive: details.active,
            PostChecklist: details.PostChecklist
        });

    }
    public updateForms() {
        //console.log(this.state.title);
        //console.log(this.props.currentUser);
        //genericPostAPIcallForAdminList
        let dataToPassToService: any = {};

        if (!this.state.title) {
            this.setState({
                alertMessage: 'Please enter title',
                showAlert: true
            });
            return;
        }
        if (!this.state.premodel) {
            this.setState({
                alertMessage: 'Please enter  premodel',
                showAlert: true
            });
            return;
        }
        if (!this.state.header) {
            this.setState({
                alertMessage: 'Please enter  header',
                showAlert: true
            });
            return;
        }
        this.setState({
            alertMessage: <div className={'alertLoader'}><Spinner size='large' label="Loading..." /></div>,
            hideAlertButton: true,
            showAlert: true
        });
        dataToPassToService.title = this.state.title;
        dataToPassToService.preModel = this.state.premodel;
        // if(this.state.postmodel){
        dataToPassToService.postModel = this.state.postModel ? this.state.postModel : "[]";
        // }
        dataToPassToService.postCheckList = this.state.PostChecklist;
        dataToPassToService.header = this.state.header;
        dataToPassToService.tenantId = Config.tenantId;
        dataToPassToService.isActive = this.state.isActive;
        if (this.props.mode === 'edit') {
            dataToPassToService.id = this.state.id;
            dataToPassToService.updated = new Date();
            dataToPassToService.updatedBy = this.props.currentUser.Email;
            utility.genericUpdateAPICall(Config.PrestartFormsListName, this.state.id, dataToPassToService).then((data: any) => {
                //console.log(data);
                this.setState({ showAlert: false });
                this.clearFilter();
                this.props.onClick();
                this.props.message("Prestart form has been updated");
            }, (err: any) => {
                console.log(err);
                this.setState({
                    alertMessage: 'Something went wrong!',
                    hideAlertButton: false,
                    showAlert: true
                });
            });
        } else {
            dataToPassToService.createdBy = this.props.currentUser.Email;
            dataToPassToService.updated = new Date();
            dataToPassToService.created = new Date();
            utility.genericPostAPIcall(Config.PrestartFormsListName, dataToPassToService).then((data: any) => {
                //console.log(data);
                this.setState({ showAlert: false });
                this.clearFilter();
                this.props.onClick();
                this.props.message("Prestart form has been updated");
            }, (err: any) => {
                console.log(err);
                this.setState({
                    alertMessage: 'Something went wrong!',
                    hideAlertButton: false,
                    showAlert: true
                });
            });
        }
    }

    public clearFilter() {
        this.setState({
            title: '',
            name: '',
            postModel: '',
            premodel: '',
            header: '',
        });
        this.props.onClose();

    }
    private alert() {
        return (
            <AlertDialog
                showDialog={this.state.showAlert}
                message={this.state.alertMessage}
                title={Config.productTitle}
                onClose={() => this.setState({ alertMessage: '', showAlert: false })}
                hideAlertButton={this.state.hideAlertButton}
            />
        );
    }
    private handleValueChange = (event: any, fieldName: string) => {
        this.setState({ [fieldName]: event.value });
    }
    public render(): React.ReactElement<any> {
        return (
            <div>
                {this.state.showAlert && this.alert()}

                <Dialog
                    open={this.props.showModal}
                    onClose={() => this.clearFilter()}
                    fullWidth={true}
                    maxWidth={'lg'}
                    aria-labelledby="max-width-dialog-title"
                    onRendered={() => this.didMount()}
                >
                    <DialogTitle id="scroll-dialog-title">
                        {`${this.props.mode && capitalize(this.props.mode)} Prestart Form`}
                    </DialogTitle>
                    <IconButton aria-label="close" onClick={() => this.clearFilter()} className="closeIcon">
                        <CloseIcon />
                    </IconButton>
                    <DialogContent dividers={true}>
                        <ScrollView width='100%' height='100%' showScrollbar='onScroll' scrollByThumb={true}>
                            <div className='popContentDiv'>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={12}>
                                        <TextBoxInput
                                            stylingMode='underlined'
                                            label="Title *"
                                            value={this.state.title}
                                            onValueChanged={(e: any) => this.handleValueChange(e, 'title')}
                                            required={true}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12}>

                                        <CheckBoxInput value={this.state.isActive} onValueChanged={(e: any) => this.handleValueChange(e, 'isActive')} text={'Is Active'} />

                                    </Grid>
                                    <Grid item xs={12} sm={12}>

                                        <CheckBoxInput value={this.state.PostChecklist} onValueChanged={(e: any) => this.handleValueChange(e, 'PostChecklist')} text={'Post Checklist'} />

                                    </Grid>
                                    <Grid item xs={12} sm={12}>

                                        <TextAreaInput
                                            stylingMode='underlined'
                                            label="Premodel"
                                            value={this.state.premodel}
                                            onValueChanged={(e: any) => this.handleValueChange(e, 'premodel')}

                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <TextAreaInput
                                            stylingMode='underlined'
                                            value={this.state.header}
                                            label="Header"
                                            onValueChanged={(e: any) => this.handleValueChange(e, 'header')}

                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12}>

                                        <TextAreaInput
                                            stylingMode='underlined'
                                            label="Post Model"
                                            value={this.state.postModel}
                                            onValueChanged={(e: any) => this.handleValueChange(e, 'postModel')}

                                        />
                                    </Grid>

                                </Grid>
                            </div>
                        </ScrollView>
                    </DialogContent>
                    <DialogActions>

                        <Button onClick={() => this.clearFilter()} variant='contained' color="primary" className={''}>
                            Cancel
                        </Button>
                        <Button onClick={() => this.updateForms()} variant='outlined' color="primary" className={'button'}>
                            Save
                        </Button>

                    </DialogActions>
                </Dialog>
            </div >

        );
    }

}
