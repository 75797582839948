import * as React from 'react';
import utility from '../../../classes/utility';
import { Config } from '../../../classes/config';
import Grid from '@material-ui/core/Grid';
import ScrollView from 'devextreme-react/scroll-view';
import AlertDialog from '../../common/alertDialog';
import { DropzoneComponent } from 'react-dropzone-component';
import Spinner from '../../common/reusableComponents/Spinner';
import TextBoxInput from '../../common/reusableComponents/TextBox';
import capitalize from '@material-ui/core/utils/capitalize';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
let myDropzone: any;

export default class EditTcp extends React.Component<any, any> {

    public uploadedFiles: any = [];
    public dataURL: any = [];
    public departmentItems: any = [];
    public currentUser: any;

    constructor(props: any) {
        super(props);

        this.uploadAttachment = this.uploadAttachment.bind(this);
        this.clearFilter = this.clearFilter.bind(this);
        this.departmentItems = [];
        this.uploadedFiles = [];
        this.dataURL = [];
        this.state = {
            isActive: true
        };
    }
    public async didMount() {

        await Promise.all([
            this._getDepartmentData()
        ]);
        if (this.props.details && this.props.mode === 'edit') {
            this.populateValue(this.props.details, this.props.mode);
        } else {
            this.setState({
                isActive: true,
                hideAlertButton: false,
                mode: this.props.mode,

            })
        }


        //console.log("str", ext);
    }
    public populateValue(details: any, mode?: any) {
        var ext = details.name;
        ext = ext.split(".").pop();
        this.setState({
            id: details.id,
            fileTitle: details.title,
            name: details.name,
            docNum: details.documentNumber,
            fileURL: details.fileURL,
            department: details.departmentId ? { id: details.departmentId, text: details.department } : '',
            data: details.thumbnailUrl,
            ext: ext,
            uploadedFile: ''
        });

    }
    private async _getDepartmentData() {
        try {
            this.departmentItems = [];
            const data: any = await utility.genericGetAPICall(`${Config.DepartmentList}?showAll=true`);
            this.departmentItems = data.map((r: any) => ({
                id: r.id, text: r.title
            }));
            const sortedItems = utility._sortItems(this.departmentItems, 'text', false);
            this.setState({
                departmentItems: sortedItems,
                alldepartmentItems: sortedItems
            });
        } catch (err) {
            console.log(err);
        }
    }

    public uploadAttachment() {
        let file: any = this.uploadedFiles;

        // if (!this.state.department) {
        //     this.setState({
        //         alertMessage: 'Please select department',
        //         showAlert: true
        //     });
        //     return;
        // }
        let accpeted;
        if (this.uploadedFiles) {
            this.uploadedFiles.forEach((item: any) => {
                if (item.file.accepted === false) {
                    accpeted = false;
                }
            });
            if (accpeted === false) {
                this.setState({
                    alertMessage: 'Selected file is not accepted. Please select another file.',
                    showAlert: true,
                    hideAlertButton: false,
                });
                return;
            }
        }
        if (!this.state.fileTitle) {
            this.setState({
                alertMessage: 'Please enter title',
                showAlert: true,
                hideAlertButton: false,
            });
            return;
        }


        if (!this.state.docNum) {
            this.setState({
                alertMessage: 'Please enter document number',
                showAlert: true,
                hideAlertButton: false,
            });
            return;
        }


        if (this.uploadedFiles.length === 0 && this.props.mode !== 'edit') {
            this.setState({
                alertMessage: 'Please attach a document',
                showAlert: true,
                hideAlertButton: false,
            });
            return;
        }
        this.setState({
            alertMessage: <div className={'alertLoader'}><Spinner size='large' label="Loading..." /></div>,
            hideAlertButton: true,
            showAlert: true
        });

        let d = new Date();
        let amOrPm = (d.getHours() < 12) ? "AM" : "PM";
        let hour = (d.getHours() < 12) ? d.getHours() : d.getHours() - 12;
        let date = d.getDate() + "_" + d.getMonth() + 1 + "_" + d.getFullYear() + "_" + hour + "_" + amOrPm;


        let fileType;
        let data;
        // for (var i = 0; i < file.length; i++) {
        if (this.state.uploadedFile) {
            fileType = file[0].file.type.replace("image/", ".");
            data = file[0].file.dataURL.replace("data:" + file[0].file.type + ";base64,", "");
        }
        let dataToPassToService: any = {};
        dataToPassToService.title = this.state.fileTitle;
        dataToPassToService.fileName = this.state.name ? this.state.name : ("TCP_" + this.state.docNum + "_" + date + fileType);
        dataToPassToService.isActive = true;
        dataToPassToService.tenantId = Config.tenantId;
        if (this.state.uploadedFile) {
            dataToPassToService.fileData = data;
            dataToPassToService.thumbnailUrl = data;
        }
        dataToPassToService.documentNumber = this.state.docNum;
        if (this.props.mode === 'edit') {
            dataToPassToService.id = this.state.id;
            dataToPassToService.updatedBy = this.props.currentUser.Email;
            dataToPassToService.updated = new Date();
            utility.genericUpdateAPICall(Config.tcpDocumentList, this.state.id, dataToPassToService).then((data: any) => {
                this.setState({ showAlert: false });
                this.props.onClick();
                this.props.message("TCP has been updated");
                this.clearFilter(true);

            }, (err: any) => {
                console.log(err);
                this.setState({
                    alertMessage: 'Something went wrong!',
                    hideAlertButton: false,
                    showAlert: true
                });
            });
        } else {
            dataToPassToService.createdBy = this.props.currentUser.Email;
            dataToPassToService.created = new Date();
            utility.genericPostAPIcall(Config.tcpDocumentList, dataToPassToService).then((data: any) => {
                //console.log(data);
                this.setState({ showAlert: false });
                this.props.onClick();
                this.props.message("TCP has been uploaded");
                this.clearFilter(true);

            }, (err: any) => {
                console.log(err);
                this.setState({
                    alertMessage: 'Something went wrong!',
                    hideAlertButton: false,
                    showAlert: true
                });
            });
        }
    }
    public handleEvent(file: any) {
        //console.log("file", file);
        this.uploadedFiles.push({ file });
        this.setState({ uploadedFile: this.uploadedFiles });
    }

    public clearFilter(item: any) {
        //console.log("clear filter");
        myDropzone.removeAllFiles(true);
        this.setState({
            comments: '',
            fileTitle: '',
            docNum: '',
            department: '',
            uploadedFiles: []
        });
        this.uploadedFiles = [];
        this.dataURL = [];
        this.props.onClose(item);

    }
    public deleteFile(file: any) {
        var deletingIndex = 0;
        //console.log("file", file);
        //console.log("this.upload", this.uploadedFiles);
        this.uploadedFiles.forEach((element: any, index: any) => {
            if (element.file.name === file.name) {
                deletingIndex = index;

            }
        });
        //console.log("deletingIndex", deletingIndex);
        this.uploadedFiles.splice(deletingIndex, 1);

    }

    private alert() {
        return (
            <AlertDialog
                showDialog={this.state.showAlert}
                message={this.state.alertMessage}
                title={Config.productTitle}
                onClose={() => this.setState({ alertMessage: '', showAlert: false })}
                hideAlertButton={this.state.hideAlertButton}
            />
        );
    }
    private handleValueChange = (event: any, fieldName: string) => {
        this.setState({ [fieldName]: event.value });
    }
    public handleDropdownChange(e: any, fieldName: string) {
        this.setState({ [fieldName]: e.selectedItem });
    }
    public render(): React.ReactElement<any> {
        const componentConfig = {
            showFiletypeIcon: true,
            postUrl: 'no-url'
        };
        const djsConfig = {
            autoProcessQueue: false,
            addRemoveLinks: true,
            maxFiles: 1,
            acceptedFiles: 'image/*',
        }
        const eventHandlers = {
            accept: (file: any, done: any) => {
                //console.log(file);
                if (file.type !== "image/jpeg" && file.type !== "image/png") {
                    done("Error! Files of this type are not accepted");
                }
                else { done(); }
            },
            init: function (dz: any) {
                myDropzone = dz;
            },
            addedfile: (file: any) => {
                //console.log(file);
                this.handleEvent(file);
            },
            removedfile: (file: any) => {
                this.deleteFile(file);
            }

        }
        return (
            <div>
                {this.state.showAlert && this.alert()}

                <Dialog
                    open={this.props.showModal}
                    onClose={this.clearFilter}
                    fullWidth={true}
                    maxWidth={'lg'}
                    aria-labelledby="max-width-dialog-title"
                    onRendered={() => this.didMount()}
                >
                    <DialogTitle id="scroll-dialog-title">
                        {`${this.props.mode && capitalize(this.props.mode)} TCP`}
                    </DialogTitle>
                    <IconButton aria-label="close" onClick={this.clearFilter} className="closeIcon">
                        <CloseIcon />
                    </IconButton>
                    <DialogContent dividers={true}>
                        <ScrollView width='100%' height='100%' showScrollbar='onScroll' scrollByThumb={true}>
                            <div className='popContentDiv'>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={12} md={4}>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12} sm={12}>
                                                <TextBoxInput
                                                    stylingMode='underlined'
                                                    label="Title *"
                                                    // pattern={"[A-Za-z]+"}
                                                    // minLength={2}
                                                    value={this.state.fileTitle}
                                                    onValueChanged={(e: any) => this.handleValueChange(e, 'fileTitle')}
                                                    required={true}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={12}>
                                                <TextBoxInput
                                                    stylingMode='underlined'
                                                    label="Document Number *"
                                                    // pattern={"[A-Za-z]+"}
                                                    // minLength={2}
                                                    value={this.state.docNum}
                                                    onValueChanged={(e: any) => this.handleValueChange(e, 'docNum')}
                                                    required={true}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={8}>
                                        <Grid container spacing={3}>
                                            {(this.props.mode && this.props.mode === 'edit') && (

                                                <Grid item xs={12} sm={12} md={6}>
                                                    <br />
                                                    <div className="alignCenter">
                                                        <img className={'tcpimage'} src={this.state.data} alt="" />
                                                    </div>
                                                </Grid>
                                            )}
                                            <Grid item xs={12} sm={12} md={this.props.mode && this.props.mode === 'edit' ? 6 : 12}>
                                                <br />
                                                <DropzoneComponent
                                                    eventHandlers={eventHandlers}
                                                    djsConfig={djsConfig}
                                                    config={componentConfig}
                                                    className="dz-message icon ion-upload"
                                                    action='Drop files here or click to upload.'
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                </Grid>
                            </div>
                        </ScrollView>
                    </DialogContent>
                    <DialogActions>

                        <Button onClick={this.clearFilter} variant='contained' color="primary" className={''}>
                            Cancel
                        </Button>
                        <Button onClick={() => this.uploadAttachment()} variant='outlined' color="primary" className={'button'}>
                            Save
                        </Button>

                    </DialogActions>
                </Dialog>
            </div >

        );
    }

}
