import * as React from 'react';
import utility from '../../../classes/utility';
import { Config } from '../../../classes/config';
import Grid from '@material-ui/core/Grid';
import ScrollView from 'devextreme-react/scroll-view';
import AlertDialog from '../../common/alertDialog';
import TextBoxInput from '../../common/reusableComponents/TextBox';
import SelectBoxInput from '../../common/reusableComponents/SelectBox';
import capitalize from '@material-ui/core/utils/capitalize';
import Spinner from '../../common/reusableComponents/Spinner';
import CheckBoxInput from '../../common/reusableComponents/CheckBox';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
export default class AddEditCounter extends React.Component<any, any> {

    public departmentItems: any = [];

    constructor(props: any) {
        super(props);

        this.updateCounter = this.updateCounter.bind(this);
        this.clearFilter = this.clearFilter.bind(this);
        this.departmentItems = [];
        this.state = {
            isActive: true
        };
    }
    public didMount() {
        //console.log(this.props.details);
        if (this.props.details) {

            this.populateValue(this.props.details, this.props.mode);
        } else {
            this.setState({
                hideAlertButton: false,
                mode: this.props.mode
            })
        }
        this._getDepartmentData();
    }
    public populateValue(details: any, mode?: any) {
        this.setState({
            id: details.id,
            title: details.title,
            counter: details.counter,
            isActive: details.isActive ? true : false,
        });

    }

    private _getDepartmentData() {

        utility.genericGetAPICall(`${Config.DepartmentList}?showAll=true`).then((data: any) => {
            //console.log(data);
            this.departmentItems = [];
            for (let r of data) {
                this.departmentItems.push({ id: r.id, text: r.title.toLowerCase() });
                if (this.props.details?.departmentId && (r.id === this.props.details?.departmentId)) {
                    this.setState({ department: { id: r.id, text: r.title } });
                }
            }
            const sortedItems = utility._sortItems(this.departmentItems, 'text', false);
            this.setState({ departmentItems: sortedItems });
        }, (err) => {
            console.log(err);
        });
    }
    public updateCounter() {
        let dataToPassToService: any = {};

        if (!this.state.title) {
            this.setState({
                alertMessage: 'Please enter title',
                showAlert: true
            });
            return;
        }
        if (!this.state.counter) {
            this.setState({
                alertMessage: 'Please enter counter',
                showAlert: true
            });
            return;
        }
        if (!this.state.department) {
            this.setState({
                alertMessage: 'Please select a department',
                showAlert: true
            });
            return;
        }

        this.setState({
            alertMessage: <div className={'alertLoader'}><Spinner size='large' label="Loading..." /></div>,
            hideAlertButton: true,
            showAlert: true
        });
        dataToPassToService.title = this.state.title;
        dataToPassToService.counter1 = this.state.counter;

        dataToPassToService.departmentId = this.state.department.key;
        dataToPassToService.tenantId = Config.tenantId;
        dataToPassToService.isActive = true;

        //console.log("dataToPassToService", dataToPassToService);
        if (this.props.mode === 'edit') {
            dataToPassToService.id = this.state.id;
            dataToPassToService.updatedBy = this.props.currentUser.Email;
            dataToPassToService.updated = new Date();
            utility.genericUpdateAPICall(Config.CounterList, this.state.id, dataToPassToService).then((data: any) => {
                //console.log(data);
                this.setState({ showAlert: false });
                this.clearFilter();
                this.props.onClick();
                this.props.message("Counter has been updated");
            }, (err: any) => {
                console.log(err);
                this.setState({
                    alertMessage: 'Something went wrong!',
                    hideAlertButton: false,
                    showAlert: true
                });
            });
        } else {
            dataToPassToService.createdBy = this.props.currentUser.Email;
            dataToPassToService.created = new Date();
            utility.genericPostAPIcall(Config.CounterList, dataToPassToService).then((data: any) => {
                //console.log(data);
                this.setState({ showAlert: false });
                this.clearFilter();
                this.props.onClick();
                this.props.message("Counter has been created");
            }, (err: any) => {
                console.log(err);
                this.setState({
                    alertMessage: 'Something went wrong!',
                    hideAlertButton: false,
                    showAlert: true
                });
            });
        }
    }

    public clearFilter() {
        this.setState({ title: '', counter: '', department: '' });
        this.props.onClose();

    }
    private alert() {
        return (
            <AlertDialog
                showDialog={this.state.showAlert}
                message={this.state.alertMessage}
                title={Config.productTitle}
                onClose={() => this.setState({ alertMessage: '', showAlert: false })}
                hideAlertButton={this.state.hideAlertButton}
            />
        );
    }
    private handleValueChange = (event: any, fieldName: string) => {
        this.setState({ [fieldName]: event.value });
    }
    public handleDropdownChange(e: any, fieldName: string) {
        this.setState({ [fieldName]: e.selectedItem });
    }
    public render(): React.ReactElement<any> {
        return (
            <div>
                {this.state.showAlert && this.alert()}
                <Dialog
                    open={this.props.showModal}
                    onClose={() => this.clearFilter()}
                    fullWidth={true}
                    maxWidth={'lg'}
                    aria-labelledby="max-width-dialog-title"
                    onRendered={() => this.didMount()}
                >
                    <DialogTitle id="scroll-dialog-title">
                        {`${this.props.mode && capitalize(this.props.mode)} Counter`}
                    </DialogTitle>
                    <IconButton aria-label="close" onClick={() => this.clearFilter()} className="closeIcon">
                        <CloseIcon />
                    </IconButton>
                    <DialogContent dividers={true}>
                        <ScrollView width='100%' height='100%' showScrollbar='onScroll' scrollByThumb={true}>
                            <div className='popContentDiv'>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={6}>
                                        <TextBoxInput
                                            stylingMode='underlined'
                                            label="Title *"
                                            // pattern={"[A-Za-z]+"}
                                            // minLength={2}
                                            value={this.state.title}
                                            onValueChanged={(e: any) => this.handleValueChange(e, 'title')}
                                            required={true}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextBoxInput
                                            stylingMode='underlined'
                                            label="Counter *"
                                            value={this.state.code}
                                            onValueChanged={(e: any) => this.handleValueChange(e, 'code')}
                                            required={true}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <SelectBoxInput
                                            stylingMode='underlined'
                                            displayExpr="text"
                                            valueExpr="id"
                                            showClearButton={true}
                                            items={this.state.departmentItems}
                                            selectedItem={this.state.department}
                                            label='Department'
                                            onSelectionChanged={(e: any) => this.handleValueChange(e, 'department')}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Grid item xs={12} sm={4}>
                                            <CheckBoxInput value={this.state.isActive} onValueChanged={(e: any) => this.handleValueChange(e, 'isActive')} text={'Is Active'} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </div>
                        </ScrollView>
                    </DialogContent>
                    <DialogActions>

                        <Button onClick={() => this.clearFilter()} variant='contained' color="primary" className={''}>
                            Cancel
                        </Button>
                        <Button onClick={() => this.updateCounter()} variant='outlined' color="primary" className={'button'}>
                            Save
                        </Button>

                    </DialogActions>
                </Dialog>
            </div>

        );
    }

}

