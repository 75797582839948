import * as React from 'react';
import utility from '../../../classes/utility';
import { Config } from '../../../classes/config';
import Grid from '@material-ui/core/Grid';
import ScrollView from 'devextreme-react/scroll-view';
import AlertDialog from '../../common/alertDialog';
import Spinner from '../../common/reusableComponents/Spinner';
import CheckBoxInput from '../../common/reusableComponents/CheckBox';
import TextBoxInput from '../../common/reusableComponents/TextBox';
import capitalize from '@material-ui/core/utils/capitalize';
import SelectBoxInput from '../../common/reusableComponents/SelectBox';
import TreeView from 'devextreme-react/tree-view';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { TagBox } from 'devextreme-react/tag-box';
import DataSource from 'devextreme/data/data_source';
const woSubcategoryItems: any = [
    { "id": "Reactive", "text": 'Reactive' },
    { "id": "Scheduled", "text": 'Scheduled' }
];
export default class AddEditActivities extends React.Component<any, any> {
    public departmentItems: any = [];
    public riskFormItems: any = [];
    public riskCategoryItems: any = [];
    public parentActivityItems: any = [];
    public treeViewRef: any;
    constructor(props: any) {
        super(props);

        this.addUpdateItem = this.addUpdateItem.bind(this);
        this.clearFilter = this.clearFilter.bind(this);
        this.treeViewSelectionChanged = this.treeViewSelectionChanged.bind(this);
        this.treeViewSelectionChanged = this.treeViewSelectionChanged.bind(this);
        this.treeViewContentReady = this.treeViewContentReady.bind(this);
        this.valueChanged = this.valueChanged.bind(this);
        this.departmentItems = [];
        this.riskFormItems = [];
        this.riskCategoryItems = [];
        this.parentActivityItems = [];
        this.treeViewRef = React.createRef();
        this.state = {
            befpicreq: false,
            aftpicreq: false,
            riskassessment: false,
            fastCompleted: false,
            fastCreated: false,
            highRisk: false,
            isActive: true,
            softComplete: false,
            riskgroup: [],
            parentActivityItems: [],
            parentActivity: '',
            allparentActivityItems: [],
            multiple: false,
            collapsible: false,
            animationDuration: 300,
            allRiskCategories: [],
            activity: '',
            costcentre: '',
            taskId: '',
            projectId: '',
            selectedItems: [],
            departmentItems: [],
            gisLayer: [],
            team: [],
            department: '',
            workGroup: []
        };
    }
    public async didMount() {
        this.setState({ loading: true });

        await Promise.all([
            this._getFormData(),
            this._getworkGroupData(),
            this._getRiskFormData(),
            this._getDepartmentData(),
            this._getRiskCategoriesData(),
            this._getworkRequestTypeDataAsync(),
            this._getTeamDataAsync(),
            this._getGISLayerDataAsync(),

        ]);

        if (this.props.details && this.props.mode === 'edit') {
            this.populateValue(this.props.details, this.props.mode);
        } else {
            this.setState({
                hideAlertButton: false,
                mode: this.props.mode,
                isActive: true,
                riskassessment: false,
                highRisk: false,
                fastCompleted: false,
                fastCreated: false,
                befpicreq: false,
                aftpicreq: false,
                tcp: false,
                activity: '',
                costcentre: '',
                taskId: '',
                projectId: '',
                selectedItems: [],
                gisLayer: [],
                team: [],
                department: '',
                workGroup: [],
                parentActivity: '',
                loading: false
            });
        }
    }

    private async _getworkGroupData() {
        try {
            const data: any = await utility.genericGetAPICallForList(Config.workGroup);

            const workGroupItems = data.map((r: any) => ({
                id: r.id,
                text: r.title,
                description: r.description,
                erpId: r.erpId,
                defaultSkillId: r.defaultSkillId,
                "Select All": r.title
            }));
            const sortedFormItems = utility._sortItems(workGroupItems, 'text', false);
            this.setState({
                workGroupItems: sortedFormItems,
                allworkGroupItems: sortedFormItems
            });
        } catch (err) {
            console.log(err);
        }
    }
    private async _getFormData() {
        try {
            const data: any = await utility.genericGetAPICallForList(Config.FormsListName);

            const formItems = data.map((r: any) => ({
                id: r.id, text: r.title, "Select All": r.title
            }));

            const sortedFormItems = utility._sortItems(formItems, 'text', false);
            this.setState({
                FormItems: sortedFormItems,
                allFormItems: sortedFormItems
            });
        } catch (err) {
            console.log(err);
        }
    }
    private async _getGISLayerDataAsync() {
        try {
            const data: any = await utility.LayerGetAPICallResponse(Config.GISLayer);

            const GISLayerItems = data.map((r: any) => ({
                id: r.Title,
                text: r.Title,
                "Select All": r.Title
            }));

            const sortedItems = utility._sortItems(GISLayerItems, 'text', false);

            const dataSource = new DataSource({
                store: sortedItems,
                key: 'id'
            });

            this.setState({
                GISLayerItems: dataSource,
                allGISLayersItems: dataSource
            });
        } catch (err) {
            console.log(err);
        }
    }
    private async _getTeamDataAsync() {
        try {
            const data: any = await utility.genericGetAPICall(`${Config.TeamList}?showAll=true`);
            const teams = data.map((item: any) => ({
                id: item.id,
                text: item.title,
                departmentId: item.departmentId,
            }));

            const sortedItems = utility._sortItems(teams, 'text', false);

            if (this.props.mode === 'edit' && this.props.details.departmentId) {
                const { departmentId } = this.props.details;
                const filteredTeams = teams.filter((item: any) => item.departmentId === departmentId);

                const filteredSortedItems = utility._sortItems(filteredTeams, 'text', false);

                this.setState({
                    teamItems: filteredSortedItems,
                    allTeamItems: sortedItems,
                });
            } else {
                this.setState({
                    teamItems: sortedItems,
                    allTeamItems: sortedItems,
                });
            }
        } catch (err) {
            console.log(err);
        }
    }
    private async _getRiskCategoriesData() {
        try {
            const data: any = await utility.genericGetAPICallForList(Config.RiskCategorieswithGroups);
            data.forEach((arr: any) => {
                arr.items = arr.riskGroups;
                delete arr.riskGroups;
            });
            const filtereddata = data.filter((f: any) => f.items && f.items.length > 0);
            filtereddata.forEach((element: any) => {
                if (element.items && element.items.length > 0) {
                    element.items.forEach((ChildItem: any) => {
                        ChildItem.riskgrpId = element.id;
                        ChildItem.selectedId = ChildItem.id;
                        ChildItem.id = `${ChildItem.id}_${element.id}`;
                        if (this.props.details && this.props.mode === 'edit') {
                            if (this.props.details.riskGroupId && this.props.details.riskGroupId.length > 0) {
                                this.props.details.riskGroupId.forEach((selectedId: any) => {
                                    if (ChildItem.selectedId === selectedId) {
                                        element.expanded = true;
                                        ChildItem.selected = true;
                                    }

                                });
                            }
                        }
                    });
                }
            });

            this.setState({ allRiskCategories: data });

            if (this.props.mode === 'add') {
                this.setState({ allRiskCategories: data });
            }
        } catch (err) {
            console.log(err);
        }

    }

    public populateValue(details: any, mode?: any) {
        this.setState({
            id: this.props.details.id,
            title: this.props.details.title,
            code: this.props.details.code,
            rego: this.props.details.regonumber,
            riskassessment: this.props.details.riskassesment ? true : false,
            highRisk: this.props.details.highRisk ? true : false,
            fastCompleted: this.props.details.fastCompleted ? true : false,
            fastCreated: this.props.details.fastCreated ? true : false,
            befpicreq: this.props.details.befpicreq ? true : false,
            aftpicreq: this.props.details.aftpicreq ? true : false,
            tcp: this.props.details.tcp ? true : false,
            isActive: this.props.details.isActive ? true : false,
            softComplete: this.props.details.softComplete ? true : false,
            reuseRiskassessmentforDays: this.props.details.reuseRiskassessmentforDays,
            form: this.props.details.form,
            workGroup: this.props.details.workGroupIds,
            department: this.props.details.department ? { id: this.props.details.departmentId, text: this.props.details.department } : '',
            hideAlertButton: false,
            taskId: this.props.details.taskId,
            projectId: this.props.details.projectId,
            activity: this.props.details.activity,
            woSubcategory: this.props.details.category ? { id: this.props.details.category, text: this.props.details.category } : '',
            costcentre: this.props.details.costCentre,
            gisLayer: this.props.details.gisLayer,
            team: this.props.details.teamIds,
            loading: false
        });
    }

    public getRiskRelations() {
        let riskGroupId: any = [];
        if (this.state.seletedItems && this.state.seletedItems.length > 0) {
            this.state.seletedItems.forEach((riskCat: any) => {
                if (!riskCat.items && riskCat.selected) {
                    riskGroupId.push(riskCat.selectedId);
                }

            });
        }
        return riskGroupId;
    }

    private async _getRiskFormData() {
        try {
            const data: any = await utility.genericGetAPICallForList(Config.RiskForms);
            this.riskFormItems = [];
            this.riskFormItems = data.map((r: any) => ({ id: r.id, text: r.title }));
            const sortedItems = utility._sortItems(this.riskFormItems, 'text', false);
            if (this.props.mode === 'edit' && this.props.details.formId) {
                const selectedForm = data.find((r: any) => r.id === this.props.details.formId[0]);
                if (selectedForm) {
                    this.setState({ riskForm: { id: selectedForm.id, text: selectedForm.title } });
                }
            }

            this.setState({ riskFormItems: sortedItems, allriskFormItems: sortedItems });
        } catch (err) {
            console.log(err);
        }

    }

    private async _getDepartmentData() {
        try {
            this.departmentItems = [];
            const data: any = await utility.genericGetAPICall(`${Config.DepartmentList}?showAll=true`);

            this.departmentItems = data.map((r: any) => ({
                id: r.id, text: r.title
            }));
            const sortedItems = utility._sortItems(this.departmentItems, 'text', false);
            this.setState({
                departmentItems: sortedItems,
                alldepartmentItems: sortedItems
            });
        } catch (err) {
            console.log(err);
        }
    }
    private async _getworkRequestTypeDataAsync() {
        try {
            const data: any = await utility.genericGetAPICall(`${Config.WRTlistname}?showAll=true`);

            this.parentActivityItems = data.map((item: any) => {
                return {
                    id: item.id,
                    text: item.title,
                    departmentId: item.departmentId
                };
            });
            const sortedItems = utility._sortItems(this.parentActivityItems, 'text', false);
            if (this.props.mode === 'edit') {
                if (this.props.details.formId) {
                    const selectedForm = data.find((r: any) => r.id === this.props.details.formId[0]);
                    if (selectedForm) {
                        this.setState({ riskForm: { id: selectedForm.id, text: selectedForm.title } });
                    }
                }
                if (this.props.details.workRequestTypeId) {
                    const parentActivity = data.find((r: any) => r.id === this.props.details.workRequestTypeId);
                    if (parentActivity) {
                        this.setState({ parentActivity: { id: parentActivity.id, text: parentActivity.title } });
                    }
                }
            }

            this.setState({ parentActivityItems: sortedItems });
        } catch (err) {
            console.log(err);
        }

    }


    public addUpdateItem() {
        let dataToPassToService: any = {};
        let swms: any = [];
        if (this.state.swms) {
            this.state.swms.forEach((element: any) => {
                swms.push(element.id);
            });
        }
        if (!this.state.title) {
            this.setState({
                alertMessage: 'Please enter title',
                showAlert: true
            });
            return;
        }
        if (!this.state.code) {
            this.setState({
                alertMessage: 'Please enter  code',
                showAlert: true
            });
            return;
        }
        if (!this.state.department) {
            this.setState({
                alertMessage: 'Please select Department',
                showAlert: true
            });
            return;
        }
        if (!this.state.parentActivity) {
            this.setState({
                alertMessage: 'Please select Parent Activity',
                showAlert: true
            });
            return;
        }

        if (this.state.highRisk && !this.state.riskForm) {
            this.setState({
                alertMessage: 'Please select risk form',
                showAlert: true
            });
            return;
        }
        this.setState({
            alertMessage: <div className={'alertLoader'}><Spinner size='large' type={'converging-spinner'} /> <br /> Loading...</div>,
            hideAlertButton: true,
            showAlert: true
        });
        let tempGisLayer: any = [];
        if (this.state.gisLayer) {
            this.state.gisLayer.forEach((element: any) => {
                tempGisLayer.push(element);
            });
        }
        let tempteam: any = [];
        if (this.state.team) {
            this.state.team.forEach((element: any) => {
                tempteam.push(element);
            });
        }
        let tempform: any = [];
        if (this.state.form) {
            this.state.form.forEach((element: any) => {
                tempform.push(element);
            });
        }
        let tempworkGroup: any = [];
        if (this.state.workGroup) {
            this.state.workGroup.forEach((element: any) => {
                tempworkGroup.push(element);
            });
        }
        dataToPassToService.workGroupIds = tempworkGroup;
        dataToPassToService.title = this.state.title;
        dataToPassToService.code = this.state.code;
        dataToPassToService.departmentId = this.state.department.id ? this.state.department.id : '';
        dataToPassToService.riskAssessmentRequired = this.state.riskassessment;
        dataToPassToService.photoRequiredBefore = this.state.befpicreq;
        dataToPassToService.photoRequiredAfter = this.state.aftpicreq;
        dataToPassToService.allowFastComplete = this.state.fastCompleted;
        dataToPassToService.allowFastCreate = this.state.fastCreated;
        dataToPassToService.isActive = this.state.isActive;
        dataToPassToService.softComplete = this.state.softComplete;
        dataToPassToService.tcp = this.state.tcp;
        dataToPassToService.allowWorkorderReassignment = this.state.allowWorkorderReassignment;
        dataToPassToService.highRisk = this.state.highRisk;
        dataToPassToService.workRequestTypeId = this.state.parentActivity.id;
        dataToPassToService.projectId = this.state.projectId;
        dataToPassToService.costCentre = this.state.costcentre;
        dataToPassToService.taskId = this.state.taskId;
        dataToPassToService.activity = this.state.activity;
        dataToPassToService.gisLayers = { ReadOnlyLayers: [], layers: tempGisLayer };
        dataToPassToService.formIds = tempform;
        dataToPassToService.category = this.state.woSubcategory.text;
        dataToPassToService.tenantId = Config.tenantId;
        dataToPassToService.teamIds = tempteam ? tempteam : '';
        dataToPassToService.riskFormIds = this.state.riskForm && [this.state.riskForm.id];
        dataToPassToService.riskGroupIds = this.getRiskRelations();
        if (this.props.mode === 'edit') {
            dataToPassToService.id = this.state.id;
            dataToPassToService.updated = new Date();
            dataToPassToService.updatedBy = this.props.currentUser.Email;
            utility.genericUpdateAPICall(Config.WTlistname, this.state.id, dataToPassToService).then((data: any) => {
                //console.log(data);
                this.setState({ showAlert: false });
                this.clearFilter();
                this.props.onClick();
                this.props.message("Activity has been updated");
            }, (err: any) => {
                console.log(err);
                this.setState({
                    alertMessage: 'Something went wrong!',
                    hideAlertButton: false,
                    showAlert: true
                });
            });
        } else {
            dataToPassToService.createdBy = this.props.currentUser.Email;
            dataToPassToService.created = new Date();
            utility.genericPostAPIcall(Config.WTlistname, dataToPassToService).then((data: any) => {
                this.setState({ showAlert: false });
                this.clearFilter();
                this.props.onClick();
                this.props.message("Activity has been created");
            }, (err: any) => {
                console.log(err);
                this.setState({
                    alertMessage: 'Something went wrong!',
                    hideAlertButton: false,
                    showAlert: true
                });
            });
        }
    }

    public clearFilter() {
        this.setState({
            title: '',
            code: '',
            department: '',
            befpicreq: false,
            aftpicreq: false,
            riskassessment: false,
            highRisk: false,
            fastCompleted: false,
            fastCreated: false,
            WorkOrderInSap: false,
            Closepathwayrequest: false,
            allowWorkorderReassignment: false,
            tcp: false,
            swms: [],
            riskgroup: [],
            riskForm: '',
            form: [],
            layerCategory: [],
            gisLayer: [],
            woSubcategory: '',
            workGroup: '',
        });
        this.props.onClose();

    }
    private alert() {
        return (
            <AlertDialog
                showDialog={this.state.showAlert}
                message={this.state.alertMessage}
                title={Config.productTitle}
                onClose={() => this.setState({ alertMessage: '', showAlert: false })}
                hideAlertButton={this.state.hideAlertButton}
            />
        );
    }
    private handleValueChange = (event: any, fieldName: string) => {
        this.setState({ [fieldName]: event.value });
    }
    public handleDropdownChange(e: any, fieldName: string) {
        if (fieldName === "department" && e.selectedItem !== null) {
            let filteredTeam = this.state.allTeamItems && this.state.allTeamItems.filter((t: any) => t.departmentId === e.selectedItem.id);
            this.setState({ team: '', teamItems: filteredTeam });
        }
        this.setState({ [fieldName]: e.selectedItem });
    }
    private _multiFieldChanged = (values: any, fieldName: string) => {
        this.setState({ [fieldName]: values });
    };


    treeViewSelectionChanged(e: any) {
        this.syncSelection(e.component);
    }

    treeViewContentReady(e: any) {
        this.syncSelection(e.component);
    }

    syncSelection(treeView: any) {
        const seletedItems = treeView.getSelectedNodes()
            .map((node: any) => node.itemData);

        this.setState(() => ({ seletedItems: seletedItems }));
    }
    valueChanged(e: any) {
        this.setState({ value: e.value });
    }
    public render(): React.ReactElement<any> {
        return (
            <div>
                {this.state.showAlert && this.alert()}
                <Dialog
                    open={this.props.showModal}
                    onClose={() => this.clearFilter()}
                    fullWidth={true}
                    maxWidth={'lg'}
                    aria-labelledby="max-width-dialog-title"
                    onRendered={() => this.didMount()}
                >
                    <DialogTitle id="scroll-dialog-title">
                        {`${this.props.mode && capitalize(this.props.mode)} Activity`}
                    </DialogTitle>
                    <IconButton aria-label="close" onClick={() => this.clearFilter()} className="closeIcon">
                        <CloseIcon />
                    </IconButton>
                    <DialogContent dividers={true}>
                        <ScrollView width='100%' height='100%' showScrollbar='onScroll' scrollByThumb={true}>

                            <div className='popContentDiv'>
                                {!this.state.loading ?
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={6}>
                                            <TextBoxInput
                                                stylingMode='underlined'
                                                label="Title *"
                                                // pattern={"[A-Za-z]+"}
                                                // minLength={2}
                                                value={this.state.title}
                                                onValueChanged={(e: any) => this.handleValueChange(e, 'title')}
                                                onKeyDown={() => { }}
                                                required={true}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextBoxInput
                                                stylingMode='underlined'
                                                label="Code *"
                                                // pattern={"[A-Za-z]+"}
                                                // minLength={2}
                                                value={this.state.code}
                                                onKeyDown={() => { }}
                                                onValueChanged={(e: any) => this.handleValueChange(e, 'code')}
                                                required={true}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <SelectBoxInput
                                                stylingMode='underlined'
                                                displayExpr="text"
                                                valueExpr="id"
                                                showClearButton={true}
                                                items={this.state.departmentItems}
                                                selectedItem={this.state.department}
                                                label='Department *'
                                                onSelectionChanged={(e: any) => this.handleDropdownChange(e, 'department')}

                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TagBox
                                                dataSource={this.state.teamItems}
                                                valueExpr="id"
                                                value={this.state.team ? this.state.team : ''}
                                                searchEnabled={true}
                                                displayExpr="text"
                                                onValueChanged={(e: any) => this.handleValueChange(e, 'team')}
                                                //onContentReady={(e: any) => this._fieldChanged(e, 'swms')}
                                                label="Team" />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TagBox
                                                dataSource={this.state.workGroupItems}
                                                valueExpr="id"
                                                value={this.state.workGroup ? this.state.workGroup : ''}
                                                searchEnabled={true}
                                                displayExpr="text"
                                                onValueChanged={(e: any) => this.handleValueChange(e, 'workGroup')}
                                                //onContentReady={(e: any) => this._fieldChanged(e, 'swms')}
                                                label="Work Group *" />
                                        </Grid>
                                        {/* <Grid item xs={12} sm={6}>
                                            <MultiSelect
                                                items={this.state.FormItems}
                                                onChange={(e: any) => this._multiFieldChanged(e, 'form')}
                                                selectedItem={this.state.form}
                                                label="Form"
                                                stylingMode='underlined'
                                                displayExpr="text"
                                                showClearButton={true}
                                                placeholder="Select a value..."
                                            />
                                        </Grid> */}
                                        <Grid item xs={12} sm={6}>
                                            <TagBox
                                                dataSource={this.state.FormItems}
                                                valueExpr="id"
                                                value={this.state.form ? this.state.form : ''}
                                                searchEnabled={true}
                                                displayExpr="text"
                                                onValueChanged={(e: any) => this.handleValueChange(e, 'form')}
                                                //onContentReady={(e: any) => this._fieldChanged(e, 'swms')}
                                                label="Form" />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <SelectBoxInput
                                                stylingMode='underlined'
                                                displayExpr="text"
                                                valueExpr="id"
                                                showClearButton={true}
                                                items={this.state.parentActivityItems}
                                                selectedItem={this.state.parentActivity}
                                                label='Parent Activity *'
                                                onSelectionChanged={(e: any) => this.handleDropdownChange(e, 'parentActivity')}

                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TagBox
                                                dataSource={this.state.GISLayerItems}
                                                valueExpr="id"
                                                value={this.state.gisLayer ? this.state.gisLayer : ''}
                                                searchEnabled={true}
                                                displayExpr="text"
                                                onValueChanged={(e: any) => this.handleValueChange(e, 'gisLayer')}
                                                //onContentReady={(e: any) => this._fieldChanged(e, 'swms')}
                                                label="GIS Layer" />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <SelectBoxInput
                                                stylingMode='underlined'
                                                displayExpr="text"
                                                valueExpr="id"
                                                showClearButton={true}
                                                items={woSubcategoryItems}
                                                selectedItem={this.state.woSubcategory}
                                                label='Activity Category'
                                                onSelectionChanged={(e: any) => this.handleDropdownChange(e, 'woSubcategory')}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextBoxInput
                                                stylingMode='underlined'
                                                label="Project ID"
                                                value={this.state.projectId}
                                                onValueChanged={(e: any) => this.handleValueChange(e, 'projectId')}
                                                onKeyDown={() => { }}
                                                required={true}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextBoxInput
                                                stylingMode='underlined'
                                                label="Task ID"
                                                value={this.state.taskId}
                                                onValueChanged={(e: any) => this.handleValueChange(e, 'taskId')}
                                                onKeyDown={() => { }}
                                                required={true}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextBoxInput
                                                stylingMode='underlined'
                                                label="Activity"
                                                value={this.state.activity}
                                                onValueChanged={(e: any) => this.handleValueChange(e, 'activity')}
                                                onKeyDown={() => { }}
                                                required={true}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextBoxInput
                                                stylingMode='underlined'
                                                label="Cost Centre"
                                                value={this.state.costcentre}
                                                onValueChanged={(e: any) => this.handleValueChange(e, 'costcentre')}
                                                onKeyDown={() => { }}
                                                required={true}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={3}>
                                            <CheckBoxInput value={this.state.befpicreq} onValueChanged={(e: any) => this.handleValueChange(e, 'befpicreq')} text={'Before Photo Required'} />
                                        </Grid>
                                        <Grid item xs={12} sm={3}>
                                            <CheckBoxInput value={this.state.aftpicreq} onValueChanged={(e: any) => this.handleValueChange(e, 'aftpicreq')} text={'After Photo Required'} />
                                        </Grid>
                                        <Grid item xs={12} sm={3}>
                                            <CheckBoxInput value={this.state.riskassessment} onValueChanged={(e: any) => this.handleValueChange(e, 'riskassessment')} text={'Site Risk Assessment'} />
                                        </Grid>
                                        <Grid item xs={12} sm={3}>
                                            <CheckBoxInput value={this.state.highRisk ? this.state.highRisk : false} disabled={!this.state.riskassessment} onValueChanged={(e: any) => this.handleValueChange(e, 'highRisk')} text={'High Risk'} />
                                        </Grid>
                                        <Grid item xs={12} sm={3}>
                                            <CheckBoxInput value={this.state.reuseRiskassessmentforDays ? this.state.reuseRiskassessmentforDays : false} disabled={this.state.highRisk} onValueChanged={(e: any) => this.handleValueChange(e, 'reuseRiskassessmentforDays')} text={'Reuse Risk Assessment'} />
                                        </Grid>
                                        <Grid item xs={12} sm={3}>
                                            <CheckBoxInput value={this.state.fastCompleted} onValueChanged={(e: any) => this.handleValueChange(e, 'fastCompleted')} text={'Fast Complete'} />
                                        </Grid>
                                        <Grid item xs={12} sm={3}>
                                            <CheckBoxInput value={this.state.fastCreated} onValueChanged={(e: any) => this.handleValueChange(e, 'fastCreated')} text={'Fast Create'} />
                                        </Grid>
                                        <Grid item xs={12} sm={3}>
                                            <CheckBoxInput value={this.state.tcp} onValueChanged={(e: any) => this.handleValueChange(e, 'tcp')} text={'TCP'} />
                                        </Grid>
                                        <Grid item xs={12} sm={3}>
                                            <CheckBoxInput value={this.state.isActive} onValueChanged={(e: any) => this.handleValueChange(e, 'isActive')} text={'Is Active'} />
                                        </Grid>
                                        <Grid item xs={12} sm={3}>
                                            <CheckBoxInput value={this.state.softComplete ? this.state.softComplete : false} onValueChanged={(e: any) => this.handleValueChange(e, 'softComplete')} text={'Soft Complete'} />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <SelectBoxInput
                                                stylingMode='underlined'
                                                displayExpr="text"
                                                valueExpr="id"
                                                showClearButton={true}
                                                items={this.state.riskFormItems}
                                                selectedItem={this.state.riskForm}
                                                label='Risk Form'
                                                onSelectionChanged={(e: any) => this.handleDropdownChange(e, 'riskForm')}

                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12}>
                                            <div className='workTypeListContainer' >
                                                {
                                                    (this.state.allRiskCategories && this.state.allRiskCategories.length > 0) && (
                                                        <h3>Risk Groups</h3>
                                                    )
                                                }

                                                <Grid container spacing={3}>
                                                    <Grid item xs={12} sm={12}>
                                                        {!this.state.loading ?
                                                            <>
                                                                {(this.state.allRiskCategories && this.state.allRiskCategories.length > 0) &&
                                                                    <>
                                                                        <TreeView
                                                                            id="treeview"
                                                                            ref={this.treeViewRef}
                                                                            width={600}
                                                                            height={320}
                                                                            items={this.state.filteredRiskCategories ? this.state.filteredRiskCategories : this.state.allRiskCategories}
                                                                            selectNodesRecursive={true}
                                                                            selectByClick={false}
                                                                            showCheckBoxesMode={'normal'}
                                                                            selectionMode={'multiple'}
                                                                            onSelectionChanged={this.treeViewSelectionChanged}
                                                                            onContentReady={this.treeViewContentReady}
                                                                            itemRender={renderTreeViewItem}
                                                                            searchMode={this.state.value}
                                                                            searchEnabled={true}
                                                                            searchExpr={'title'}
                                                                        />

                                                                    </>
                                                                }
                                                            </> :
                                                            <Spinner size='large' label="Loading..." />
                                                        }

                                                    </Grid>
                                                </Grid>
                                            </div>
                                        </Grid>
                                    </Grid>
                                    :
                                    <Spinner size="large" label="Loading all component. Please wait..." />
                                }
                            </div>
                        </ScrollView>
                    </DialogContent>
                    <DialogActions>

                        <Button onClick={() => this.clearFilter()} variant='contained' color="primary" className={''}>
                            Cancel
                        </Button>
                        <Button onClick={() => this.addUpdateItem()} variant='outlined' color="primary" className={'button'}>
                            Save
                        </Button>

                    </DialogActions>
                </Dialog>
            </div >
        );
    }

}

function renderTreeViewItem(item: any) {
    return `${item.title}`;
}
