import * as React from 'react';
import Paper from '@material-ui/core/Paper';
import {
    NavLink
} from "react-router-dom";
import global from '../../classes/global';
export default class AccessDenied extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        if (this.props.role === global.worxOnline_Admin) {
            window.open('#/', '_self');
        }
        this.state = {
            details: this.props.details,
        };
    }
    public componentDidMount() {


    }


    public render(): React.ReactElement<any> {


        return (
            <div className="alignCenter">
                <div className="accessDeniedDiv" >
                    <Paper elevation={3} className={'p-20'}>
                        <h2 >Access Denied</h2>
                        <h3>
                            Look like you dont have access. Please contact site adminstrator
                        </h3>
                        {(this.props.role) && (
                            <NavLink to="/">
                                Home
                            </NavLink>
                        )}
                    </Paper>
                </div>

            </div>
        );
    }
}

