import * as React from 'react';
import utility from '../../classes/utility';
import { Config } from '../../classes/config';
import Grid from '@material-ui/core/Grid';
import AlertDialog from '../common/alertDialog';
import TextBoxInput from '../common/reusableComponents/TextBox';
import { DropzoneComponent } from 'react-dropzone-component';
import Spinner from '../common/reusableComponents/Spinner';
import ScrollView from 'devextreme-react/scroll-view';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
export default class AddAttachment extends React.Component<any, any> {

    public uploadedFiles: any = [];
    public dataURL: any = [];
    public details: any = this.props.details;
    public currentUser: any;

    constructor(props: any) {
        super(props);

        this.uploadAttachment = this.uploadAttachment.bind(this);
        this.clearFilter = this.clearFilter.bind(this);
        this.uploadedFiles = [];
        this.dataURL = [];
        this.currentUser = this.props.currentUser;
        this.state = {
            details: this.props.details,
        };
    }

    public componentDidMount() {
        //console.log('component load');
    }
    public didMount() {
        this.uploadedFiles = [];
        this.dataURL = [];
        this.setState({ hideAlertButton: false, uploadedFiles: [] });
    }

    public uploadAttachment() {
        let file = this.uploadedFiles;
        let WOID = this.props.workorderID;
        let accpeted;
        if (this.uploadedFiles) {
            this.uploadedFiles.forEach((item: any) => {
                if (item.file.accepted === false) {
                    accpeted = false;
                }
            });
            if (accpeted === false) {
                this.setState({
                    alertMessage: 'Selected file is not accepted. Please select another file.',
                    showAlert: true
                });
                return;
            }
        }
        if (this.uploadedFiles.length > 3) {
            this.setState({
                alertMessage: 'You cannot select more than 3 files.',
                showAlert: true
            });
            return;
        }
        if (!this.state.fileTitle) {
            this.setState({
                alertMessage: 'Please enter title',
                showAlert: true
            });
            return;
        }
        if (this.uploadedFiles.length === 0) {
            this.setState({
                alertMessage: 'Please attach a document',
                showAlert: true
            });
            return;
        }
        this.setState({
            alertMessage: <Spinner label='Uploading Document(s)' />,
            hideAlertButton: true,
            showAlert: true
        });

        let d = new Date();
        let amOrPm = (d.getHours() < 12) ? "AM" : "PM";
        let hour = (d.getHours() < 12) ? d.getHours() : d.getHours() - 12;
        let date = d.getDate() + "_" + d.getMonth() + "_" + d.getFullYear() + "_" + hour + "_" + amOrPm;

        for (var i = 0; i < file.length; i++) {

            let fileExtension;
            let tempFileExt = file[i].file.name.split('.');
            tempFileExt.reverse();
            fileExtension = `.${tempFileExt[0]}`
            let fileName = tempFileExt[1];
            let tempData = this.dataURL[i].dataUrl.result.split("base64,");
            let data = tempData[1];
            let dataToPassToService: any = {};
            dataToPassToService.workorderId = +WOID;
            dataToPassToService.fileName = fileName + "_" + this.props.details.workorderNumber + "_" + date + "_" + fileExtension;
            dataToPassToService.documentType = 'Photo';
            dataToPassToService.title = this.state.fileTitle;
            //dataToPassToService.comments = this.state.comments;
            dataToPassToService.tenantId = Config.tenantId;
            dataToPassToService.data = data;
            dataToPassToService.updated = new Date();
            dataToPassToService.created = new Date();
            dataToPassToService.updatedBy = this.props.currentUser.DisplayName;
            utility.genericPostAPIcall(Config.workorderDocumentList, dataToPassToService).then((data: any) => {
                //console.log(data);
                this.setState({ showAlert: false, hideAlertButton: false, });
                this.props.updateDoc();
                this.props.message();
                this.clearFilter(true);

            }, (err: any) => {
                console.log(err);
                this.setState({ showAlert: false });
            });
        }


    }

    public handleEvent(file: any) {
        this.onDropGeneral(file);
        this.uploadedFiles.push({ file });
        this.setState({ uploadedFiles: this.uploadedFiles, fileTitle: file.name });
    }
    public onDropGeneral(file: any) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (event) => {
            this.dataURL.push({ file: file.name, dataUrl: event ? event.currentTarget : "" });

        };
    }
    public clearFilter(item?: any) {
        this.setState({ fileTitle: '' });
        this.props.onClose();

    }
    public deleteFile(file: any) {
        var deletingIndex = 0;
        this.uploadedFiles.forEach((element: any, index: any) => {
            if (element.file.name === file.name) {
                deletingIndex = index;

            }
        });
        this.uploadedFiles.splice(deletingIndex, 1);
        this.dataURL.forEach((element: any, index: any) => {
            if (element.file.name === file.name) {
                deletingIndex = index;

            }
        });
        this.dataURL.splice(deletingIndex, 1);
    }

    private alert() {
        return (
            <AlertDialog
                showDialog={this.state.showAlert}
                message={this.state.alertMessage}
                title={Config.productTitle}
                onClose={() => this.setState({ alertMessage: '', showAlert: false })}
                hideAlertButton={this.state.hideAlertButton}
            />
        );
    }
    private _fieldChanged = (event: any, fieldName: string) => {
        this.setState({ [fieldName]: event.value });
    }
    public _textAreaChanged = (e: any, fieldName: string) => {

        this.setState({ [fieldName]: e.value });
    }

    public render(): React.ReactElement<any> {
        const componentConfig = {
            showFiletypeIcon: true,
            postUrl: 'no-url'
        };
        const djsConfig = {
            autoProcessQueue: false,
            addRemoveLinks: true,
            // maxFiles: 5,
            maxFilesize: 10,
            // acceptedFiles: 'image/*',
            //   acceptedFiles: 'image/*,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/msword,application/pdf,application/vnd.ms-outlook'

        }
        //var myDropzone;
        const eventHandlers = {
            // accept: (file, done) => {
            //     //console.log(file);
            //     if (file.type !== "image/jpeg" && file.type !== "image/png") {
            //         done("Error! Files of this type are not accepted");
            //     }
            //     else { done(); }
            // },
            init: function (dz: any) {
                //console.log(dz)
                //  myDropzone = dz;
            },
            addedfile: (file: any) => {
                //console.log(file);
                this.handleEvent(file);
            },
            removedfile: (file: any) => {
                this.deleteFile(file);
            }

        }
        return (
            <div>
                {this.state.showAlert && this.alert()}
                <Dialog
                    open={this.props.showModal}
                    onClose={() => this.clearFilter()}
                    // className={'addEditModal'}
                    fullWidth={true}
                    maxWidth={'md'}
                    aria-labelledby="max-width-dialog-title"
                    onRendered={() => this.didMount()}
                >
                    <DialogTitle id="scroll-dialog-title">
                        Add Attachment
                    </DialogTitle>
                    <IconButton aria-label="close" onClick={() => this.clearFilter()} className="closeIcon">
                        <CloseIcon />
                    </IconButton>
                    <DialogContent dividers={true}>
                        <ScrollView width='100%' height='100%' >
                            <div className='popContentDiv'>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={12} md={6}>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12} sm={12}>
                                                <TextBoxInput
                                                    stylingMode='underlined'
                                                    label="Title *"
                                                    // pattern={"[A-Za-z]+"}
                                                    // minLength={2}
                                                    value={this.state.fileTitle}
                                                    onValueChanged={(e: any) => this._fieldChanged(e, 'fileTitle')}
                                                    required={true}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6}>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12} sm={11}>
                                                <DropzoneComponent
                                                    eventHandlers={eventHandlers}
                                                    djsConfig={djsConfig}
                                                    config={componentConfig}
                                                    className="dz-message icon ion-upload"
                                                    action='Drop files here or click to upload.'
                                                />
                                                <span>Max no. of file: 3. Max file size: 10Mb</span>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                </Grid>
                            </div>
                        </ScrollView>
                    </DialogContent>
                    <DialogActions>

                        <Button onClick={() => this.clearFilter()} variant='contained' color="primary" className={''}>
                            Cancel
                        </Button>
                        <Button onClick={() => this.uploadAttachment()} variant='outlined' color="primary" className={'button'}>
                            Add
                        </Button>

                    </DialogActions>
                </Dialog>
            </div>

        );
    }

}
