import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { PublicClientApplication, EventMessage, AuthenticationResult, EventType} from "@azure/msal-browser";
import { msalConfig } from "./classes/authConfig";
// import { msalConfig } from "./authConfig";
const LightTheme = React.lazy(() => import('./components/common/theme/lightTheme'));
const DarkTheme = React.lazy(() => import('./components/common/theme/darkTheme'));

export const msalInstance = new PublicClientApplication(msalConfig);

const ThemeSelector = ({ children }: { children: React.ReactNode }) => {
    const CHOSEN_THEME = localStorage.getItem('theme') || 'theme-light';
    return (
      <>
        <React.Suspense fallback={<></>}>
          {(CHOSEN_THEME === 'theme-light') && <LightTheme />}
          {(CHOSEN_THEME === 'theme-dark') && <DarkTheme />}
        </React.Suspense>
        {children}
      </>
    )
}

const Index = () => {
  const [loginTime, setLoginTime] = useState<any | null>(null);

  useEffect(() => {
    msalInstance.initialize().then(() => {
      // Account selection logic is app dependent. Adjust as needed for different use cases.
      const accounts = msalInstance.getAllAccounts();
      if (accounts.length > 0) {
          msalInstance.setActiveAccount(accounts[0]);
      }

      msalInstance.addEventCallback((event: EventMessage) => {
          if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
              const payload = event.payload as AuthenticationResult;
              const account = payload.account;
              if (account) {
                msalInstance.setActiveAccount(account);
                const newLoginTime = account.idTokenClaims?.auth_time || null;
                if (newLoginTime !== null) {
                  setLoginTime(new Date(newLoginTime * 1000).toISOString());
                }
              }
          }
      });
    });
  }, []);

  return (
    <React.StrictMode>
      <ThemeSelector>
        <App msalInstance={msalInstance} loginTime={loginTime}/> {/* Pass loginTime to App */}
      </ThemeSelector>
    </React.StrictMode>
  );
}

ReactDOM.render(
  <Index />,
  document.getElementById('root')
);
