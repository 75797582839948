import * as React from 'react';
import utility from '../../../classes/utility';
import { Config } from '../../../classes/config';
import global from '../../../classes/global';

import DataTable from '../../common/DataTable/DataTable';
import AlertDialog from '../../common/alertDialog';
import Spinner from '../../common/reusableComponents/Spinner';
import TextBoxInput from "../../common/reusableComponents/TextBox";
import DateBoxInput from "../../common/reusableComponents/DateBox";
import NumberBoxInput from "../../common/reusableComponents/NumberBox";
import CheckBoxInput from "../../common/reusableComponents/CheckBox";
import SelectBoxInput from "../../common/reusableComponents/SelectBox";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";

let _items: any = [];

export default class MobileAppIssues extends React.Component<any, any> {

  public currentUser: any;
  public columns: any;

  constructor(props: any) {
    super(props);

    if (this.props.role !== global.worxOnline_Admin) {
      window.open('#/AccessDenied', '_self');
    }
    this._handleKeyDown = this._handleKeyDown.bind(this);
    this.clearFilter = this.clearFilter.bind(this);
    this._getMobileAppIssuesData = this._getMobileAppIssuesData.bind(this);
    this.handleValueChange = this.handleValueChange.bind(this);
    this.handleDropdownChange = this.handleDropdownChange.bind(this);

    this.currentUser = this.props.currentUser;
    this.columns = [

      // {
      //     field: 'id',
      //     title: 'ID',
      //     // width: 80,
      //     type: 'string',
      //     allowHiding: true,
      //     visibleColumn: false
      // },
      {
        field: 'error',
        title: 'Error',
        //width: 600,
        type: 'string',
        allowHiding: false
      },
      {
        field: 'empName',
        title: 'User',
        width: 250,
        type: 'string',
        allowHiding: false,
      },
      {
        field: 'dateCreated',
        title: 'Created',
        width: 200,
        type: 'datetime',
        allowHiding: false,
        sortOrder: 'desc'
      }

    ];
    let showHideColumns = localStorage.getItem("MobileAppIssuesColumns");
    let tempColumns;
    if (showHideColumns !== null) {
      tempColumns = JSON.parse(showHideColumns);
    } else {
      tempColumns = this.columns;
      localStorage.setItem("MobileAppIssuesColumns", JSON.stringify(this.columns));
    }
    this.state = {
      items: _items,
      columns: this.columns,
      showAllMobileAppIssueData: false,
      EmpName: "",
      completedDateFrom: "",
      completedDateTo: "",
      loading: false,
      totalResults: 100,
      selectedItemKeys: [],
    };
  }

  public componentDidMount() {
    this.getEmpNamesData();
  }

  public message(message: any) {
    //console.log(message);
    this.setState({
      alertMessage: message + ' successfully',
      showAlert: true
    });
  }

  private getEmpNamesData() {
    let dataToPassToService: any = {};
    this.setState({
      loading: true,
    });
    utility.genericGetAPICallForList(Config.SearchEmployeeApi, "POST", dataToPassToService).then(
      (data: any) => {
        _items = [];
        const filteredItems = data.filter((r: any) => r.isActive && r.userType && r.userType.includes('App'))
          .map((r: any) => ({
            id: r.id,
            text: r.fullName,
            departmentId: r.departmentId,
            active: true,
          }));
        _items = utility._sortItems(filteredItems, "text", false);
        this.setState({
          empNames: _items,
          loading: false
        });
      },
      (err) => {
        console.log(err);
        this.setState({ loading: false });
      }
    );
  }

  private _getMobileAppIssuesData() {
    let dataToPassToService: any = {};
    if (this.state.empName) {
      dataToPassToService.userId = this.state.empName ? this.state.empName.id : null;
    }
    if (this.state.completedDateFrom) {
      dataToPassToService.from = this.state.completedDateFrom;
    }
    if (this.state.completedDateTo) {
      dataToPassToService.to = this.state.completedDateTo;
    }
    this.setState({
      loading: true
    });
    //console.log("dataToPassToService", dataToPassToService);
    let pageNumber = 1;
    let pageSize = this.state.totalResults ? this.state.totalResults : 100;
    let searchCriteria = `${Config.SearchMobileAppFailedItems}?showAll=${this.state.showAllMobileAppIssueData}&pageNumber=${pageNumber}&pageSize=${pageSize}`
    utility.genericGetAPICallForList(searchCriteria, 'POST', dataToPassToService).then((data: any) => {
      _items = [];
      if (data.length === null || data.length === 0) {
        this.setState({
          alertMessage: 'No results found for this criteria',
          showAlert: true
        });
        this.setState({ items: _items, loading: false });
        return;
      }
      else {
        data.forEach((element: any, index: any) => {
          let errorMessage: any = '';
          // if (element.failedData) {
          //     try {
          //         const errorObj = JSON.parse(element.failedData);
          //         errorMessage = Object.values(errorObj).join(' ');
          //         errorMessage = errorMessage ? errorMessage : 'The application failed to process the request.';
          //     }
          //     catch {
          //         errorMessage = 'The application failed to process the request.';
          //     }

          // }

          _items.push({
            id: element.id,
            empName: element.userFullName,
            error: element.failedData,
            dateCreated: element.created
          });
        });
      }
      _items = utility._sortItems(_items, 'dateCreated', true);

      this.setState({ items: _items, callAfterInit: true, loading: false, preItems: _items });

      //console.log(_items);
    }, (err) => {
      console.log(err);
      this.setState({ loading: false });
    });
  }

  public setSelection = (id: any) => {
    this.setState({
      selectedRow: id
    });
  }
  private stopCommsAfterInit = (): void => {
    this.setState({ callAfterInit: false });
  }

  private handleValueChange(newValue: any, fieldName: string): void {
    this.setState({ [fieldName]: newValue.value });
  }

  public handleDropdownChange(e: any, fieldName: string) {
    this.setState({ [fieldName]: e.selectedItem });
  }

  public _handleKeyDown = (e: any) => {
    if (e.event.originalEvent.key === 'Enter') {
      this._getMobileAppIssuesData();
    }
  }

  public clearFilter() {
    this.setState({
      empName: '',
      completedDateFrom: '',
      completedDateTo: '',
      loading: false,
      totalResults: 100,
      showAllMobileAppIssueData: false,
    });
  }

  public render(): React.ReactElement<any> {

    return (
      <React.Fragment>
        {this.state.showAlert && this.alert()}
        <div className={"SearchDiv"}>
          <div className={"headingDiv"}>
            <span className={"heading"}> Mobile App Issues </span>
          </div>
        </div>
        <div className="searchFieldDiv">
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={6} lg={4}>
              <DateBoxInput
                stylingMode="underlined"
                label={"Error Generated From"}
                value={
                  this.state.completedDateFrom ? new Date(this.state.completedDateFrom) : null
                }
                type="date"
                displayFormat={"dd/MM/yyyy"}
                placeholder={"DD/MM/YYYY"}
                showClearButton={true}
                onValueChanged={(e: any) =>
                  this.handleValueChange(e, "completedDateFrom")
                }
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={4}>
              <DateBoxInput
                stylingMode="underlined"
                label={"Error Generated To"}
                value={this.state.completedDateTo ? new Date(this.state.completedDateTo) : null}
                type="date"
                displayFormat={"dd/MM/yyyy"}
                placeholder={"DD/MM/YYYY"}
                showClearButton={true}
                onValueChanged={(e: any) => this.handleValueChange(e, "completedDateTo")}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={4}>
              <SelectBoxInput
                stylingMode='underlined'
                displayExpr="text"
                valueExpr="id"
                showClearButton={true}
                items={this.state.empNames}
                selectedItem={this.state.empName}
                label="Employee"
                onSelectionChanged={(e: any) => this.handleDropdownChange(e, 'empName')}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <div className={"searchButtonDiv displayFlex"}>
                {this.state.totalRecords && (
                  <div className="totalRecordsDiv">
                    Total records available for this search is{" "}
                    {this.state.totalRecords}
                  </div>
                )}
                <div>
                  <NumberBoxInput
                    stylingMode="underlined"
                    label="Total Results to Display"
                    min={1}
                    max={100000000}
                    value={this.state.totalResults}
                    onValueChanged={(e: any) =>
                      this.handleValueChange(e, "totalResults")
                    }
                  />
                </div>
                <div>
                  <Button
                    onClick={this.clearFilter}
                    variant="outlined"
                    className={"clearButton"}
                  >
                    Clear
                  </Button>
                  <Button
                    onClick={this._getMobileAppIssuesData}
                    variant="outlined"
                    color="primary"
                    className={"button"}
                  >
                    Search
                  </Button>

                  <CheckBoxInput
                    value={this.state.showAllMobileAppIssueData}
                    onValueChanged={(e: any) =>
                      this.handleValueChange(e, "showAllMobileAppIssueData")
                    }
                    text={"Show All"}
                  />
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
        <div>
          {!this.state.loading ? (
            <div className={"searchFieldDiv"}>
              {this.state.items && this.state.items.length > 0 && (
                <DataTable
                  style={{ width: "100%" }}
                  filteralbe={true}
                  groupable={true}
                  rows={this.state.items}
                  columns={this.state.columns}
                  fileName={"MobileAppIssues"}
                  columnChooser={"MobileAppIssuesColumns"}
                  selectionMode={'single'}
                  showHideColumnMenu={true}
                  callAfterInit={this.state.callAfterInit}
                  stopCommsAfterInit={() => this.stopCommsAfterInit()}
                  loading={this.state.loading}
                  allowExportSelectedData={true}
                  showCommandBtn={false}
                  showNewBtn={false}
                  hideNewBtn={
                    this.state.showModal ||
                    this.state.showModal ||
                    this.props.hideNewBtn
                  }
                  wordWrapEnabled={true}
                />
              )}
            </div>
          ) : (
            <Spinner size="large" label="Loading.." className="loader" />
          )}
        </div>
      </React.Fragment>
    );
  }
  public alert() {
    return (
      <AlertDialog
        showDialog={this.state.showAlert}
        message={this.state.alertMessage}
        title={Config.productTitle}
        hideAlertButton={this.state.hideAlertButton}
        onClose={() => this.setState({ alertMessage: '', showAlert: false })}
      />
    );
  }

}