import * as React from 'react';
import utility from '../../../classes/utility';
import { Config } from '../../../classes/config';
import DataTable from '../../common/DataTable/DataTable';
import AlertDialog from '../../common/alertDialog';
import DeleteDialog from '../../common/DeleteAlert';
import Spinner from '../../common/reusableComponents/Spinner';
import AddEditRiskControlMeasure from './AddEditRiskControlMeasure';
import ViewControlMeasure from './ViewRiskControlMeasure';
import global from '../../../classes/global';
let _items: any = [];

export default class RiskControlMeasure extends React.Component<any, any> {

    public currentUser: any;
    public columns: any;
    public myRef: any;
    public RisksItems: any = [];
    constructor(props: any) {
        super(props);
        this.DeleteItem = this.DeleteItem.bind(this);
        this.EditItemModal = this.EditItemModal.bind(this);
        this.viewDetials = this.viewDetials.bind(this);
        this.DeleteDialog = this.DeleteDialog.bind(this);
        this.viewDetials = this.viewDetials.bind(this);
        this.myRef = React.createRef();
        this.RisksItems = [];
        this.currentUser = this.props.currentUser;
        if (this.props.role !== global.worxOnline_Admin) {
            window.open('#/AccessDenied', '_self');
        }
        this.columns = [
            {
                field: 'id',
                title: 'ID',
                width: 80,
                type: 'string',
                allowHiding: false,
            },
            {
                field: 'title',
                title: 'Title',
                width: 300,
                type: 'string',
                allowHiding: false,
            },
            {
                field: 'isActive',
                title: 'Active',
                width: 80,
                type: 'boolean',
                allowHiding: false,
            },
            {
                field: 'updatedOn',
                title: 'Updated',
                width: 160,
                type: 'datetime',
                visible: false,
                allowHiding: true
            },
            {
                field: 'updatedBy',
                title: 'Updated By',
                width: 160,
                type: 'string',
                visible: false,
                allowHiding: true
            },

            {
                field: 'createdOn',
                title: 'Created',
                width: 160,
                type: 'datetime',
                visible: false,
                allowHiding: true
            },
            {
                field: 'createdBy',
                title: 'Created By',
                width: 160,
                type: 'string',
                visible: false,
                allowHiding: true
            },
        ];
        this.state = {
            items: _items,
            columns: this.columns
        };
    }

    public componentDidMount() {
        this._getItemData();
    }

    public DeleteItem(id: any) {
        this.setState({
            alertMessage: <div className={'alertLoader'}><Spinner size='large' label="Loading..." /></div>,
            hideAlertButton: true,
            showAlert: true,
            showDeleteAlert: false,
        });

        utility.genericDeleteAPICall(Config.RiskControlmeasures, id).then((data: any) => {
            this.setState({
                alertMessage: 'Risk Control Measure has been deleted.',
                showAlert: true,
                showDeleteAlert: false,
                hideAlertButton: false,
                loading: false
            });
            this._getItemData()
        }, (err) => {
            // console.log(err);
            this.setState({
                alertMessage: 'Cannot delete Risk Control Measure. Please try again later.',
                showAlert: true,
                showDeleteAlert: false,
                hideAlertButton: false,
            });
        });

    }

    // edit activty modal

    private EditItemModal(item: any) {
        this.setState({ showModal: true, details: item, mode: 'edit' });
    }

    public renderNewModal() {
        return (
            <AddEditRiskControlMeasure
                showModal={this.state.showModal}
                onClose={() => this._closeModal()}
                onClick={() => this._getItemData()}
                message={(e: any) => this.message(e)}
                currentUser={this.currentUser}
                mode={this.state.mode}
                details={this.state.details}
            />
        );
    }

    public renderViewModal() {
        return (
            <ViewControlMeasure
                showViewModal={this.state.showViewModal}
                onClose={() => this._closeviewModal()}
                onClick={() => this._getItemData()}
                currentUser={this.currentUser}
                details={this.state.details}
            />
        );
    }
    private _showModal = (): void => {

        this.setState({ showModal: true, details: null, mode: 'add' });
    }

    private _closeModal = (): void => {
        this.setState({ showModal: false });
    }
    private _closeviewModal = (): void => {
        this.setState({ showViewModal: false });
    }


    public message(message: any) {
        // //console.log(message);
        this.setState({
            alertMessage: message + ' successfully',
            showAlert: true
        });
    }
    public viewDetials(item: any) {
        this.setState({
            details: item,
            showViewModal: true
        });
    }


    private _getItemData() {
        this.setState({ loading: true });
        utility.genericGetAPICallForList(Config.RiskControlmeasures)
            .then((data: any) => {
                let items = data.map((r: any) => {
                    const riskGroupIds = r.risksRelations.map((element: any) => ({ groupid: element.riskGroupId }));
                    const riskIds = r.risksRelations.map((element: any) => ({ riskId: element.riskIds }));
                    return {
                        id: r.id,
                        title: r.title,
                        isActive: r.isActive,
                        updatedBy: r.updatedBy,
                        updatedOn: r.updated,
                        createdBy: r.createdBy,
                        createdOn: r.created,
                        controlmeasureScore: r.controlmeasureScore,
                        hierarchyId: r.hierarchyId,
                        riskGroupIds: riskGroupIds,
                        riskIds: riskIds,
                        risksRelations: r.risksRelations
                    };
                });
                items = this._sortItems(items, 'createdOn', false);
                this.setState({ items: items, callAfterInit: true });
            })
            .catch(err => {
                console.log(err);
            })
            .finally(() => {
                this.setState({ loading: false });
            });

    }

    public setSelection = (id: any) => {
        this.setState({
            selectedRow: id
        });
    }
    private stopCommsAfterInit = (): void => {
        this.setState({ callAfterInit: false });
    }
    private _sortItems = (items: any[], sortBy: string, descending = false): any[] => {
        if (descending) {
            return items.sort((a: any, b: any) => {
                if (a[sortBy] < b[sortBy]) {
                    return 1;
                }
                if (a[sortBy] > b[sortBy]) {
                    return -1;
                }
                return 0;
            });
        } else {
            return items.sort((a: any, b: any) => {
                if (a[sortBy] < b[sortBy]) {
                    return -1;
                }
                if (a[sortBy] > b[sortBy]) {
                    return 1;
                }
                return 0;
            });
        }
    }
    public render(): React.ReactElement<any> {
        const filterValue = [['isActive', '=', true]];
        return (
            <React.Fragment >
                {this.renderNewModal()}
                {this.renderViewModal()}
                {this.state.showDeleteAlert && this.DeleteAlert()}
                {this.state.showAlert && this.alert()}
                <div className={"SearchDiv"}>
                    <div className={'headingDiv'}>
                        <span className={"heading"}> Risk Control Measure </span>
                    </div>
                </div>
                <div>
                    {!this.state.loading && this.state.items ?
                        <div ref={this.myRef}>
                            <DataTable
                                style={{ width: '100%' }}
                                filteralbe={true}
                                groupable={true}
                                rows={this.state.items}
                                columns={this.state.columns}
                                fileName={'Risk Control Measure'}
                                columnChooser={'riskcontrolmeasureColumns'}
                                selectionMode={false}
                                showHideColumnMenu={true}
                                callAfterInit={this.state.callAfterInit}
                                stopCommsAfterInit={() => this.stopCommsAfterInit()}
                                loading={this.state.loading}
                                allowExportSelectedData={false}
                                showNewModal={() => this._showModal()}
                                deleteItem={(a: any) => this.DeleteDialog(a)}
                                editItem={(a: any) => this.EditItemModal(a)}
                                viewItem={(a: any) => this.viewDetials(a)}
                                showCommandBtn={true}
                                showNewBtn={true}
                                hideNewBtn={this.state.showModal || this.state.showEditModal || this.props.hideNewBtn}
                                showViewDetailBtn={true}
                                defaultfiltervalue={filterValue}
                            />
                        </div>
                        :
                        <Spinner size='large' type={'converging-spinner'} className="loader" />
                    }

                </div>
            </React.Fragment >
        );
    }
    public alert() {
        return (
            <AlertDialog
                showDialog={this.state.showAlert}
                message={this.state.alertMessage}
                title={Config.productTitle}
                hideAlertButton={this.state.hideAlertButton}
                onClose={() => this.setState({ alertMessage: '', showAlert: false })}
            />
        );
    }
    public DeleteDialog(item: any) {
        // //console.log({ item });
        this.setState({
            DeletealertMessage: 'Are you sure you want to delete?',
            showDeleteAlert: true,
            deleteItem: item
        });
    }
    public DeleteAlert() {
        return (
            <DeleteDialog
                showDialog={this.state.showDeleteAlert}
                message={this.state.DeletealertMessage}
                title={"worxOnline"}
                onClose={() => this.setState({ DeletealertMessage: '', showDeleteAlert: false })}
                onOkButton={() => this.DeleteItem(this.state.deleteItem)}
                onCancelButton={() => this.setState({ DeletealertMessage: '', showDeleteAlert: false })}
            />
        );
    }

}

