import * as React from 'react';
import utility from '../../../classes/utility';
import { Config } from '../../../classes/config';
import global from '../../../classes/global';

import DataTable from '../../common/DataTable/DataTable';
import AlertDialog from '../../common/alertDialog';
import Spinner from '../../common/reusableComponents/Spinner';
import TextBoxInput from "../../common/reusableComponents/TextBox";
import DateBoxInput from "../../common/reusableComponents/DateBox";
import NumberBoxInput from "../../common/reusableComponents/NumberBox";
import CheckBoxInput from "../../common/reusableComponents/CheckBox";
import SelectBoxInput from "../../common/reusableComponents/SelectBox";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";

let _items: any = [];

export default class FailedWorkorders extends React.Component<any, any> {

  public departmentItems: any = [];
  public teamsItems: any = [];
  public currentUser: any;
  public columns: any;

  constructor(props: any) {
    super(props);

    this.departmentItems = [];
    this.teamsItems = [];

    if (this.props.role !== global.worxOnline_Admin) {
      window.open('#/AccessDenied', '_self');
    }
    this._handleKeyDown = this._handleKeyDown.bind(this);
    this.clearFilter = this.clearFilter.bind(this);
    this._getFailedWorkordersData = this._getFailedWorkordersData.bind(this);
    this.handleValueChange = this.handleValueChange.bind(this);
    this.onSelectionChanged = this.onSelectionChanged.bind(this);

    this.currentUser = this.props.currentUser;
    this.columns = [

      {
        field: 'id',
        title: 'ID',
        // width: 80,
        type: 'string',
        allowHiding: true,
        visibleColumn: false
      },
      {
        field: 'AsseticWonumber',
        title: 'Assetic Work Order #',
        width: 200,
        type: 'string',
        allowHiding: false,
        link: 'asseticWoLink'
      },
      {
        field: 'Wonumber',
        title: 'WorxOnline Work Order #',
        width: 210,
        type: 'string',
        allowHiding: false,
        link: 'wolink'
      },
      {
        field: 'Error',
        title: 'Error',
        // width: 650,
        type: 'string',
        allowHiding: false,
      },
      {
        field: 'Datecreated',
        title: 'Created',
        // width: 180,
        type: 'datetime',
        allowHiding: false,
        sortOrder: 'desc'
      }

    ];
    let showHideColumns = localStorage.getItem("FailedWorkordersColumns");
    let tempColumns;
    if (showHideColumns !== null) {
      tempColumns = JSON.parse(showHideColumns);
    } else {
      tempColumns = this.columns;
      localStorage.setItem("FailedWorkordersColumns", JSON.stringify(this.columns));
    }
    this.state = {
      items: _items,
      columns: this.columns,
      showAllFailedWOData: false,
      WOnumber: "",
      completedDateFrom: "",
      completedDateTo: "",
      loading: false,
      totalResults: 100,
      selectedItemKeys: [],
    };
  }

  public message(message: any) {
    //console.log(message);
    this.setState({
      alertMessage: message + ' successfully',
      showAlert: true
    });
  }

  private _getFailedWorkordersData() {
    let dataToPassToService: any = {};
    if (this.state.WOnumber) {
      dataToPassToService.workorderId = this.state.WOnumber;
    }
    if (this.state.completedDateFrom) {
      dataToPassToService.from = this.state.completedDateFrom;
    }
    if (this.state.completedDateTo) {
      dataToPassToService.to = this.state.completedDateTo;
    }
    this.setState({
      loading: true
    });
    //console.log("dataToPassToService", dataToPassToService);
    let pageNumber = 1;
    let pageSize = this.state.totalResults ? this.state.totalResults : 100;
    let searchCriteia = `${Config.SearchSyncFailedWorkordersapi}?showAll=${this.state.showAllFailedWOData}&pageNumber=${pageNumber}&pageSize=${pageSize}`
    utility.genericGetAPICallForList(searchCriteia, 'POST', dataToPassToService).then((data: any) => {
      _items = [];
      if (data.length === null || data.length === 0) {
        this.setState({
          alertMessage: 'No results found for this criteria',
          showAlert: true
        });
        this.setState({ items: _items, loading: false });
        return;
      }
      else {
        data.forEach((element: any, index: any) => {
          let errorMessage: any = '';
          if (element.syncError) {
            try {
              const errorObj = JSON.parse(element.syncError);
              errorMessage = Object.values(errorObj).join(' ');
              errorMessage = errorMessage ? errorMessage : 'The application failed to process the request.';
            }
            catch {
              errorMessage = 'The application failed to process the request.';
            }

          }

          _items.push({
            id: element.id,
            Wonumber: element.workorderId,
            AsseticWonumber: element.erpWorkorderNumber,
            Error: errorMessage,
            Wohistoryid: element.Wohistoryid,
            Datecreated: element.created
          });
        });
      }
      _items = utility._sortItems(_items, 'Datecreated', true);

      this.setState({ items: _items, callAfterInit: true, loading: false, preItems: _items });

      //console.log(_items);
    }, (err) => {
      console.log(err);
      this.setState({ loading: false });
    });
  }

  public setSelection = (id: any) => {
    this.setState({
      selectedRow: id
    });
  }

  private onSelectionChanged = ((rowData: any) => {
    if (rowData && rowData.length > 0) {
      this.setState({ loading: true });
      let selectedItemsToResync: any = [];
      rowData.forEach((element: any) => {
        selectedItemsToResync.push(element.Wonumber);
      });
      utility.genericUpdateNatureStripAPICall(Config.RetrySyncFailedWorkordersapi, selectedItemsToResync).then((data: any) => {
        if (data) {
          this.setState({
            alertMessage: 'The selected Workorders are resynced successfully. If errors persists, it will appear in the Failed Workorders list.',
            showAlert: true,
            loading: false
          });

          this._getFailedWorkordersData();
        }
      }, (err) => {
        console.log(err);
        this.setState({
          alertMessage: 'Unable to resync the selected Workorders. Please try again later.',
          showAlert: true,
          loading: false
        });
      });
    }
  });

  private stopCommsAfterInit = (): void => {
    this.setState({ callAfterInit: false });
  }

  private handleValueChange(newValue: any, fieldName: string): void {
    this.setState({ [fieldName]: newValue.value });
  }

  public _handleKeyDown = (e: any) => {
    if (e.event.originalEvent.key === 'Enter') {
      this._getFailedWorkordersData();
    }
  }

  public clearFilter() {
    this.setState({
      WOnumber: '',
      completedDateFrom: '',
      completedDateTo: '',
      loading: false,
      totalResults: 100,
      showAllFailedWOData: false,
    });
  }

  public render(): React.ReactElement<any> {

    return (
      <React.Fragment>
        {this.state.showAlert && this.alert()}
        <div className={"SearchDiv"}>
          <div className={"headingDiv"}>
            <span className={"heading"}> Failed Workorders </span>
          </div>
        </div>
        <div className="searchFieldDiv">
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={6} lg={4}>
              <TextBoxInput
                stylingMode='underlined'
                label="Work Order #"
                value={this.state.WOnumber}
                onValueChanged={(e: any) => this.handleValueChange(e, 'WOnumber')}
                onKeyDown={(e: any) => this._handleKeyDown(e)}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={4}>
              <DateBoxInput
                stylingMode="underlined"
                label={"Completed Date From"}
                value={
                  this.state.completedDateFrom ? new Date(this.state.completedDateFrom) : null
                }
                type="date"
                displayFormat={"dd/MM/yyyy"}
                placeholder={"DD/MM/YYYY"}
                showClearButton={true}
                onValueChanged={(e: any) =>
                  this.handleValueChange(e, "completedDateFrom")
                }
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={4}>
              <DateBoxInput
                stylingMode="underlined"
                label={"Completed Date To"}
                value={this.state.completedDateTo ? new Date(this.state.completedDateTo) : null}
                type="date"
                displayFormat={"dd/MM/yyyy"}
                placeholder={"DD/MM/YYYY"}
                showClearButton={true}
                onValueChanged={(e: any) => this.handleValueChange(e, "completedDateTo")}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <div className={"searchButtonDiv displayFlex"}>
                {this.state.totalRecords && (
                  <div className="totalRecordsDiv">
                    Total records available for this search is{" "}
                    {this.state.totalRecords}
                  </div>
                )}
                <div>
                  <NumberBoxInput
                    stylingMode="underlined"
                    label="Total Results to Display"
                    min={1}
                    max={100000000}
                    value={this.state.totalResults}
                    onValueChanged={(e: any) =>
                      this.handleValueChange(e, "totalResults")
                    }
                  />
                </div>
                <div>
                  <Button
                    onClick={this.clearFilter}
                    variant="outlined"
                    className={"clearButton"}
                  >
                    Clear
                  </Button>
                  <Button
                    onClick={this._getFailedWorkordersData}
                    variant="outlined"
                    color="primary"
                    className={"button"}
                  >
                    Search
                  </Button>

                  <CheckBoxInput
                    value={this.state.showAllFailedWOData}
                    onValueChanged={(e: any) =>
                      this.handleValueChange(e, "showAllFailedWOData")
                    }
                    text={"Show All"}
                  />
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
        <div>
          {!this.state.loading ? (
            <div className={"searchFieldDiv"}>
              {this.state.items && this.state.items.length > 0 && (
                <DataTable
                  style={{ width: "100%" }}
                  filteralbe={true}
                  groupable={true}
                  rows={this.state.items}
                  columns={this.state.columns}
                  fileName={"FailedWorkorders"}
                  columnChooser={"FailedWorkordersColumns"}
                  selectionMode={'multiple'}
                  showHideColumnMenu={true}
                  callAfterInit={this.state.callAfterInit}
                  stopCommsAfterInit={() => this.stopCommsAfterInit()}
                  loading={this.state.loading}
                  allowExportSelectedData={true}
                  showCommandBtn={true}
                  showNewBtn={false}
                  hideNewBtn={
                    this.state.showModal ||
                    this.state.showModal ||
                    this.props.hideNewBtn
                  }
                  hideCommandDeleteBtn={true}
                  hideCommandEditBtn={true}
                  showViewDetailBtn={false}
                  selectedRowKeys={this.state.selectedItemKeys}
                  onSelectionChanged={this.onSelectionChanged}
                  customSelectRows={true}
                  customToolbarButtonText={'Retry Failed Workorders'}
                  customToolbarButtonIcon={'pulldown'}
                  toolbarItemsVisible={false}
                />
              )}
            </div>
          ) : (
            <Spinner size="large" label="Loading.." className="loader" />
          )}
        </div>
      </React.Fragment>
    );
  }
  public alert() {
    return (
      <AlertDialog
        showDialog={this.state.showAlert}
        message={this.state.alertMessage}
        title={Config.productTitle}
        hideAlertButton={this.state.hideAlertButton}
        onClose={() => this.setState({ alertMessage: '', showAlert: false })}
      />
    );
  }

}