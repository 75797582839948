export default class global {

  //------------------------------------------------this is for UAT Developement ---------------------------------------------------------------------------
  // public static homeURL: string = 'https://coa-uat-worx-portal.azurewebsites.net/';
  // public static spAPIURL: string = 'https://coa-uat-worx-api.azurewebsites.net';
  // public static AssetMapURL: string = 'https://coauatworxstr.z26.web.core.windows.net/';
  // public static mapIconFolderURL: string = 'https://coauatworxstr.blob.core.windows.net/worxonline-container-uat/Images/MapIcon';
  // public static singInB2C: string = 'https://w0rx.b2clogin.com/w0rx.onmicrosoft.com/B2C_1_COA_SignupAndSignin'
  // public static singInB2CKnownAuthority: string = 'w0rx.b2clogin.com'
  // public static AzureClientAppId: string = "a4288a2c-9c74-414d-8555-3508489d1d88";
  // public static AzureTenantId: string = "777c851c-c2df-4f6c-b1ad-0ba6f8a4059e";
  // public static AzureScope: string = 'https://w0rx.onmicrosoft.com/Coa-api/.default';
  // public static AsseticAssetURL: string = "https://coasandbox.assetic.net/Assets/ComplexAsset/";
  // public static accessToken: string = 'uataccesstoken';
  // public static isAzurefile: string = 'https://coauatworxstr.blob.core.windows.net/';
  // public static AsseticWOLink: string = "https://coasandbox.assetic.net/maintenance/Workorder/";
  // public static AsseticWRLink: string = "https://coasandbox.assetic.net/maintenance/Workrequest/";
  // public static domainName: string = "https://coauatcontractor.worx.online/"
  //---------------------------------------------------Production Start-----------------------------------------------------------------------
  public static homeURL: string = 'https://coa.worx.online/#/';
  public static spAPIURL: string = 'https://coa-prod-worxonline-api.azurewebsites.net';
  public static AssetMapURL: string = 'https://coaprodworxonlinestorage.z8.web.core.windows.net/';
  public static mapIconFolderURL: string = 'https://coaprodworxonlinestorage.blob.core.windows.net/worxonline-container-prod/Images/MapIcon';
  public static singInB2C: string = 'https://worxonlineaad.b2clogin.com/worxonlineaad.onmicrosoft.com/B2C_1_COA_SignupAndSignin';
  public static singInB2CKnownAuthority: string = 'worxonlineaad.b2clogin.com';
  public static AzureClientAppId: string = "58bbf9a1-8ea8-4af9-a7ad-5f5ab1cf58cf";
  public static AzureTenantId: string = "57e10ea9-4e34-4f74-aec8-e8fec13fcc28";
  public static AzureScope: string = 'https://worxonlineaad.onmicrosoft.com/coa-api/.default';
  public static AsseticAssetURL: string = "https://coa.assetic.net/Assets/ComplexAsset/";
  public static accessToken: string = 'prodaccesstoken';
  public static isAzurefile: string = 'https://coaprodworxonlinestorage.blob.core.windows.net/';
  public static AsseticWOLink: string = "https://coa.assetic.net/maintenance/Workorder/";
  public static AsseticWRLink: string = "https://coa.assetic.net/maintenance/Workrequest/";
  public static domainName: string = "https://coacontractor.worx.online/"
  //---------------------------------------------------Production End-----------------------------------------------------------------------
  public static version: string = "7.1";
  public static filterWOType: string = "Reactive";
  // name of the Service Bus topic
  public static topicName: string = "workordercreated";
  // name of the subscription to the topic
  public static subscriptionName: string = "s1";
  //for role assignment
  public static worxOnline_Admin: string = 'Admin';
  public static worxOnline_Writer: string = 'Write';
  public static worxOnline_Contractor: string = 'Contractor';
  public static worxOnline_Reader: string = 'Read';
  public static worxOnline_ContractorAdmin: string = 'ContractorAdmin';


  // for scheduler status
  public static Assigned: string = 'Assigned';
  public static Unassigned: string = 'Unassigned';
  public static Reassigned: string = 'Reassigned';
  public static Complete: string = 'Complete';
  public static Closed: string = 'Closed';
  public static Cancelled: string = 'Cancelled';
  public static defaultResultCount = 100;

  public static googleAPIKey: string = 'AIzaSyARLXNcNKXtTd7VtFrKhOZ8d2IC66kZi2A';
  public showPathwaylink: string = 'No';
  public static HelpfulLinks: string = 'Helpful Links';
  public static ResultCount = 150;//From Pathway Class Type API for application code LAP
  public static Userid = '11726';
  public static costCenter = '26020';
  public static returnStatusid = 14;
  public static contractorStartedStatus = 13;
  public static withContractorStatusId = 15;
  public static InProgressStatus = 3;
  public static OnHoldStatusId = 4;
  public static UnAssignedStatusId = 5;
  public static AssignedStatusId = 2;
}