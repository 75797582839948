import * as React from 'react';

import MyMapComponent from '../common/MapComponent/GoogleMapComponent';

import { Popup, ToolbarItem } from 'devextreme-react/popup';
import ScrollView from 'devextreme-react/scroll-view';
import global from '../../classes/global';
export default class MapModal extends React.Component<any, any> {

  constructor(props: any) {
    super(props);
    this.state = {

      useStyle: localStorage.getItem('theme') === 'theme-dark' ? true : false,
    }
  }

  public componentDidMount() {
    ////console.log('component load');
  }

  public afterInit() {
    ////console.log('init');
  }

  public render(): React.ReactElement<any> {
    // const style =
    // {
    //   height: '45vh',
    //   position: 'relative !important',
    //   top: 0,
    //   left: 0,
    //   right: 0,
    //   bottom: 0,
    // };

    // const containerStyle = { position: 'relative !important' }
    return (
      <div>

        <Popup
          width={'60vw'}
          maxHeight={'85vh'}
          minHeight={200}
          height={'auto'}
          showTitle={true}
          showCloseButton={true}
          title={this.props.polylines ? this.props.department : this.props.address}
          dragEnabled={false}
          hideOnOutsideClick={false}
          visible={this.props.showModal}
          // onContentReady={() => this.didMount()}
          //closeOnOutsideClick={true}
          onVisibleChange={() => this.props.onClick()}


        >

          <ScrollView width='100%' height='100%' showScrollbar='onScroll' scrollByThumb={true}>

            {(this.props.polylines || (this.props.center && this.props.center.lat !== '40.854885')) && (
              <div>
                {/* {(!this.props.polylines) && ( */}

                <MyMapComponent
                  singleMarkers={this.props.singlemarker}
                  center={this.props.center}
                  // iconColor={'33cc33'}
                  zoom={this.props.zoom ? this.props.zoom : 11}
                  useStyle={this.state.useStyle}
                  googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${global.googleAPIKey}&v=3.exp&libraries=geometry,drawing,places`}
                  showMarker={true}
                />
                {/* )} */}

              </div>

            )}

          </ScrollView>
          <ToolbarItem
            widget="dxButton"
            toolbar="bottom"
            location="after"
            options={{
              icon: 'close',
              text: 'Close',
              stylingMode: "outlined",
              onClick: () => this.props.onClick(),
            }}
          />
        </Popup>

      </div>
    );
  }
}



