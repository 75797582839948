import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import AlertDialog from '../common/alertDialog';
import utility from '../../classes/utility';
import TextAreaInput from '../common/reusableComponents/TextArea';
import { Config } from '../../classes/config';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
export default class AddNotes extends React.Component<any, any> {
    public currentUser: any;
    constructor(props: any) {
        super(props);
        this.currentUser = this.props.currentUser;
        this.state = {
            notesType: '',
            notes: '',
            showButton: false
        };
    }
    public didMount() {


    }

    public afterInit() {
        if (this.props.workorder) {
            let item = this.props.workorder;
            this.setState({
                showButton: false,
                notes: '',
                itemID: item.id
            });
        };
    }

    public addNote() {
        if (!this.state.notes) {
            this.setState({
                alertMessage: 'Please enter notes',
                showAlert: true
            });
            return;
        }
        let dataToPassToService: any = {};
        dataToPassToService.workorderId = this.state.itemID;
        dataToPassToService.statusId = 8;
        dataToPassToService.updatedBy = this.currentUser.Email;
        dataToPassToService.createdBy = this.currentUser.Email;
        dataToPassToService.updated = new Date();
        dataToPassToService.created = new Date();
        dataToPassToService.comments = this.state.notes;
        utility.genericPostAPIcall(Config.workorderHistoryList, dataToPassToService).then((results: any) => {
            //console.log(results);
            this.setState({
                hideAlertButton: false,
                alertMessage: 'Work Order note has been updated',
                showAlert: true
            });
            this.props.onClick();
        }, (err: any) => {
            console.log(err);
            this.setState({
                alertMessage: 'An error occurred. Unable to update details.',
                showAlert: true,
                hideAlertButton: false
            });
        });
    }
    public clearFilter() {
        this.setState({
            notes: '',
        });
        this.props.onClose()
    }
    public alert() {
        return (
            <AlertDialog
                showDialog={this.state.showAlert}
                message={this.state.alertMessage}
                title={"worxOnline"}
                onClose={() => this._closeDialog()}
                hideAlertButton={this.state.hideAlertButton}
            />
        );
    }
    private _closeDialog = (): void => {
        this.setState({ alertMessage: '', showAlert: false });

    }

    public render(): React.ReactElement<any> {
        return (
            <div>
                {this.state.showAlert && this.alert()}
                <Dialog
                    open={this.props.showModal}
                    onClose={() => this.clearFilter()}
                    // className={'addEditModal'}
                    fullWidth={true}
                    maxWidth={'sm'}
                    aria-labelledby="max-width-dialog-title"
                    onRendered={() => this.afterInit()}
                >
                    <DialogTitle id="scroll-dialog-title">
                        Add Notes
                    </DialogTitle>
                    <IconButton aria-label="close" onClick={() => this.props.onClose()} className="closeIcon">
                        <CloseIcon />
                    </IconButton>
                    <DialogContent dividers={true}>
                        <div>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={12}>
                                    <TextAreaInput
                                        stylingMode='underlined'
                                        label="Notes"
                                        value={this.state.notes}
                                        onValueChanged={(e: any) => this.handleValueChange(e, 'notes')}
                                        spellcheck={true}
                                        showClearButton={true}
                                    />

                                </Grid>
                            </Grid>
                        </div>
                    </DialogContent>
                    <DialogActions>

                        <Button onClick={() => this.clearFilter()} variant='contained' color="primary" className={''}>
                            Cancel
                        </Button>
                        <Button onClick={() => this.addNote()} variant='outlined' color="primary" className={'button'}>
                            Add
                        </Button>

                    </DialogActions>
                </Dialog>
            </div>
        );
    }
    public handleValueChange(e: any, fieldName: string) {
        this.setState({ [fieldName]: e.value });
    }
    public handleDropdownChange(e: any, fieldName: string) {
        this.setState({ [fieldName]: e.selectedItem });
    }
}

