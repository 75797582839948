import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import utility from '../../classes/utility';
import global from '../../classes/global';
import { Config } from '../../classes/config';
import AlertDialog from '../common/alertDialog';
import AssignDatatable from '../common/DataTable/AssignDatatable';
import DateBoxInput from '../common/reusableComponents/DateBox';
import SelectBoxInput from '../common/reusableComponents/SelectBox';
import TextBoxInput from '../common/reusableComponents/TextBox';
import Spinner from '../common/reusableComponents/Spinner';
import DisplayDetailsModal from '../common/DisplayDetailModal/DisplayDetailModal';
import moment from 'moment-timezone';

let _items: any = [];

export default class SearchEquipment extends React.Component<any, any> {

  public equipmentItems: any = [];
  public departmentItems: any = [];
  public columns: any;

  constructor(props: any) {
    super(props);
    if (!this.props.Department) {
      window.open('#/AccessRequest', '_self');
    };

    this.equipmentItems = [];
    this.departmentItems = [];

    this.getequipmentnumberwithcriteria = this.getequipmentnumberwithcriteria.bind(this);
    this._handleKeyDown = this._handleKeyDown.bind(this);
    this.clearFilter = this.clearFilter.bind(this);
    this.viewDetials = this.viewDetials.bind(this);

    this.columns = [

      {
        field: 'equipmentnumber',
        title: 'Plant and Fleet #',
        // width: 160,
        type: 'string',
        allowHiding: false,
      },
      {
        field: 'Workordernumber',
        title: 'Workorder #',
        // width: 160,
        type: 'string',
        link: 'workorderLink',
        allowHiding: true
      },
      {
        field: 'title',
        title: 'Title',
        // width: 160,
        type: 'string',
        allowHiding: true,
      },
      {
        field: 'Regonumber',
        title: 'Rego #',
        // width: 280,
        type: 'string',
        allowHiding: false,
      },

      {
        field: 'starttime',
        title: 'Start Time',
        width: 160,
        type: 'datetime',
        allowHiding: true
      },
      {
        field: 'endtime',
        title: 'End Time',
        width: 160,
        type: 'datetime',
        allowHiding: true
      },
      {
        field: 'hoursworked',
        title: 'Hours Worked',
        // width: 160,
        type: 'string',
        allowHiding: true
      },
      {
        field: 'department',
        title: 'Department',
        // width: 160,
        type: 'string',
        allowHiding: true
      },
      {
        field: 'updatedOn',
        title: 'Updated',
        width: 160,
        type: 'datetime',
        visibleColumn: false,
        allowHiding: true
      },
      {
        field: 'updatedBy',
        title: 'Updated By',
        width: 160,
        type: 'string',
        visibleColumn: false,
        allowHiding: true
      },
      {
        field: 'createdOn',
        title: 'Created',
        width: 160,
        type: 'datetime',
        visibleColumn: false,
        allowHiding: true
      },
      {
        field: 'createdBy',
        title: 'Created By',
        width: 160,
        type: 'string',
        visibleColumn: false,
        allowHiding: true
      },
    ];
    this.state = {
      items: _items,
      columns: this.columns,
      callAfterInit: false,
      loading: false,
      department: (this.props.role === global.worxOnline_Contractor || this.props.role === global.worxOnline_ContractorAdmin) ? this.props.Department : '',
    };

  }


  public componentDidMount() {

    let tempstate = localStorage.getItem('equipmentUsageState');
    //this._getPlanttypeData();
    this._getDepartmentData();
    if (tempstate) {
      // this.state = JSON.parse(tempstate)
      let allState = JSON.parse(tempstate);

      this.setState({
        dateCreatedfrom: allState.dateCreatedfrom ? allState.dateCreatedfrom : '',
        dateCreatedTo: allState.dateCreatedTo ? allState.dateCreatedTo : '',
        equipmentno: allState.equipmentno ? allState.equipmentno : '',
        workorderno: allState.workorderno ? allState.workorderno : '',
        department: allState.department ? allState.department : '',
      })
    }

  }

  public _handleKeyDown = (e: any) => {
    if (e.event.originalEvent.key === 'Enter') {
      this.getequipmentnumberwithcriteria();
    }
  }
  public clearFilter() {
    this.setState({
      items: [],
      workorderno: '',
      equipmentType: '',
      equipmentno: '',
      dateCreatedfrom: '',
      dateCreatedTo: '',
      department: (this.props.role === global.worxOnline_Contractor || this.props.role === global.worxOnline_ContractorAdmin) ? this.state.department : ''
    });
    localStorage.setItem('equipmentUsageState', '');
    let datagrid = localStorage.getItem('datagridFilterValue');
    if (datagrid) {
      localStorage.removeItem('datagridFilterValue');
    }

  }

  public viewDetials(item: any) {
    this.setState({
      details: item,
      showModal: true
    });
  }
  public renderDetailsModal() {
    return (
      <DisplayDetailsModal
        details={this.state.details}
        columns={this.columns}
        showModal={this.state.showModal}
        onClick={() => this._closeModal()}
      />
    );
  }
  private _closeModal = (): void => {
    this.setState({ showModal: false });
  }

  private _getDepartmentData() {
    utility.genericGetAPICall(`${Config.DepartmentList}?showAll=true`).then((data: any) => {

      this.departmentItems = [];

      for (let r of data) {
        this.departmentItems.push({ id: r.id, text: r.title });
      }
      this.departmentItems = utility._sortItems(this.departmentItems, 'text', false);
      this.setState({ departmentItems: this.departmentItems, alldepartmentItems: this.departmentItems });
    }, (err) => {
      console.log(err);
    });
  }
  public getequipmentnumberwithcriteria() {
    let criteriaSelected = false;

    let dataToPassToService: any = {};

    if (this.state.workorderno) {
      dataToPassToService.workorderId = this.state.workorderno;
      criteriaSelected = true;
    }

    if (this.state.equipmentno) {
      dataToPassToService.equipmentNumber = this.state.equipmentno;
      criteriaSelected = true;
    }
    if (this.state.techoneworkorderno) {
      dataToPassToService.erpWorkorderNumber = this.state.techoneworkorderno;
      criteriaSelected = true;
    }
    if (this.state.department) {
      dataToPassToService.departmentId = this.state.department.id;
      criteriaSelected = true;
    }

    if (this.state.dateCreatedTo && this.state.dateCreatedfrom) {
      let dcreatedfrom = new Date(this.state.dateCreatedfrom);
      let dcreatedTo = new Date(this.state.dateCreatedTo);
      if (dcreatedTo.getTime() < dcreatedfrom.getTime()) {
        this.setState({
          alertMessage: 'Date To should be greater than Date From',
          showAlert: true
        });
        return;
      }
    }

    if (this.state.dateCreatedfrom || this.state.dateCreatedTo) {
      if (this.state.dateCreatedfrom) {
        const startOfDay = moment(this.state.dateCreatedfrom).startOf('day');
        const startOfDayUTC = startOfDay.utc();
        dataToPassToService.usedFrom = startOfDayUTC.toISOString();
      }
      if (this.state.dateCreatedTo) {
        const endOfDay = moment(this.state.dateCreatedTo).endOf('day');
        const endOfDayUTC = endOfDay.utc();
        dataToPassToService.usedTo = endOfDayUTC.toISOString();
      }
      criteriaSelected = true;
    }

    if (!criteriaSelected) {
      this.setState({
        alertMessage: 'Please select search criteria',
        showAlert: true
      });
      return;
    }
    this.setState({
      callAfterInit: true,
      loading: true
    });

    utility.genericPostAPIcall(Config.WOequipmentSearchAPI, dataToPassToService).then((data: any) => {
      _items = [];
      if (data.length === null || data.length <= 0) {
        this.setState({
          alertMessage: 'No results found for this criteria',
          showAlert: true
        });
        this.setState({ items: _items, loading: false });
        return;
      }
      else {
        data.forEach((item: any) => {
          let starttime: any = new Date(item.startTime);
          let endTime: any = new Date(item.endTime);
          let delta: any = Math.abs(endTime - starttime) / 1000;

          // calculate (and subtract) whole days
          let days = Math.floor(delta / 86400);
          delta -= days * 86400;

          // calculate (and subtract) whole hours
          let hours = Math.floor(delta / 3600) % 24;
          delta -= hours * 3600;

          // calculate (and subtract) whole minutes
          let minutes = Math.floor(delta / 60) % 60;
          delta -= minutes * 60;
          _items.push({
            Workordernumber: item.workorderId,
            techoneWorkordernumber: item.erpWorkorderNumber,
            title: item.title,
            id: item.id,
            equipmentnumber: item.resourceCode,
            Category: item.category,
            Regonumber: item.regoNumber,
            department: item.departmentTitle,
            equipmentid: item.equipmentId,
            Comments: item.comments,
            starttime: item.startTime ? item.startTime : '',
            endtime: item.endTime ? item.endTime : '',
            viewTimesheet: '',
            //hoursworked: item.hoursWorked,
            hoursworked: days + ' days ' + hours + ' hours ' + minutes + ' minutes',
            woid: item.workorderId,
            updatedBy: item.updatedBy,
            updatedOn: item.updated ? item.updated : null,
            createdBy: item.createdBy,
            createdOn: item.created ? item.created : null,

            // servicerequest: item.WorkOrder.ServiceRequest
          });
        });

      }
      if (_items.length <= 0) {
        this.setState({
          alertMessage: 'No results found for this criteria',
          showAlert: true
        });
      }
      _items = utility._sortItems(_items, 'updatedOn', true);

      this.setState({ items: _items, loading: false });
      var stateData = { ...this.state };
      localStorage.setItem('equipmentUsageState', JSON.stringify(stateData));
    }, (err) => {
      console.log(err);
      this.setState({ loading: false });
    });
  }

  private stopCommsAfterInit = (): void => {
    this.setState({ callAfterInit: false });
  }

  public handleValueChange(e: any, fieldName: string) {
    this.setState({ [fieldName]: e.value });
  }

  public handleDropdownChange(e: any, fieldName: string) {
    this.setState({ [fieldName]: e.selectedItem });
  }
  public render(): React.ReactElement<any> {
    return (
      <div>
        {this.state.showModal && this.renderDetailsModal()}
        {/* {this.state.showTimeEditModal && this.renderTimeEditDetailsModal()} */}

        {this.state.showAlert && this.alert()}
        <div className={"SearchDiv"}>
          <div className={'headingDiv'}>
            <span className={"heading"}>Plant and Fleet Usage</span>
          </div>
        </div>
        <div className={"searchFieldDiv"}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={6} lg={4}>

              <DateBoxInput
                stylingMode='underlined'
                type="date"
                displayFormat={"dd/MM/yy"}
                label={'Date From'}
                showClearButton={true}
                value={this.state.dateCreatedfrom ? new Date(this.state.dateCreatedfrom) : null}
                onValueChanged={(e: any) => this.handleValueChange(e, 'dateCreatedfrom')}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={4}>

              <DateBoxInput
                stylingMode='underlined'
                type="date"
                displayFormat={"dd/MM/yy"}
                label={'Date To'}
                showClearButton={true}
                value={this.state.dateCreatedTo ? new Date(this.state.dateCreatedTo) : null}
                onValueChanged={(e: any) => this.handleValueChange(e, 'dateCreatedTo')}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={4} className={''}>
              <TextBoxInput
                stylingMode='underlined'
                label="Plant and Fleet #"
                // pattern={"[A-Za-z]+"}
                // minLength={2}
                value={this.state.equipmentno}
                onValueChanged={(e: any) => this.handleValueChange(e, 'equipmentno')}
                onKeyDown={this._handleKeyDown}
              />
            </Grid>
            {/* <Grid item xs={12} sm={6} md={6} lg={4}>

              <SelectBoxInput
                stylingMode='underlined'
                label='Type'
                displayExpr="text"
                valueExpr="id"
                showClearButton={true}
                items={this.state.plantItems}
                selectedItem={this.state.plantType}
                onSelectionChanged={(e: any) => this.handleDropdownChange(e, 'plantType')}
              />
            </Grid> */}
            <Grid item xs={12} sm={6} md={6} lg={4}>
              <TextBoxInput
                stylingMode='underlined'
                label="Work Order #"
                // pattern={"[A-Za-z]+"}
                // minLength={2}
                value={this.state.workorderno}
                onValueChanged={(e: any) => this.handleValueChange(e, 'workorderno')}
                onKeyDown={this._handleKeyDown}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={4}>

              <SelectBoxInput
                stylingMode='underlined'
                displayExpr="text"
                valueExpr="id"
                showClearButton={true}
                label='Department'
                items={this.state.departmentItems}
                selectedItem={this.state.department}
                onSelectionChanged={(e: any) => this.handleDropdownChange(e, 'department')}
                disabled={(this.props.role === global.worxOnline_Contractor || this.props.role === global.worxOnline_ContractorAdmin) ? true : false}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <div className={"searchButtonDiv"}>
                {(this.state.itemSelected && this.state.itemSelected.length > 0) && (
                  <Button onClick={() => this.setState({ submitequipmentUsageModal: true })} variant='contained' color="primary" className={'button'}>
                    Submit
                  </Button>
                )}

                <Button onClick={this.clearFilter} variant='outlined'>
                  Clear
                </Button>
                <Button onClick={this.getequipmentnumberwithcriteria} variant='outlined' color="primary" className={'button'}>
                  Search
                </Button>
              </div>
            </Grid>
          </Grid>
        </div>
        <div>
          {!this.state.loading ?
            <div className='tabStripDiv'>
              {(this.state.items && this.state.items.length > 0) && (
                <AssignDatatable
                  style={{ width: '100%' }}
                  filteralbe={true}
                  groupable={true}
                  rows={this.state.items}
                  columns={this.state.columns}
                  fileName={'Plant and Fleet Usage'}
                  columnChooser={'equipmenttUsageColumns'}
                  selectionMode={false}
                  showHideColumnMenu={true}
                  callAfterInit={this.state.callAfterInit}
                  stopCommsAfterInit={() => this.stopCommsAfterInit()}
                  loading={this.state.loading}
                  allowExportSelectedData={false}
                  showNewBtn={false}
                  viewItem={(a: any) => this.viewDetials(a)}
                  // showNewModal = {() => this._showModal()}
                  // deleteItem = {(a:any) => this.DeleteDialog(a)}
                  // editItem = {(a:any) => this.EditItemModal(a)}
                  showCommandBtn={false}
                  showViewDetailBtn={true}
                />
              )}
            </div>
            :
            // <CircularProgress className="loader" />
            <Spinner size='large' label={"Loading.."} />
          }
        </div>


      </div>
    );
  }

  public alert() {
    return (
      <AlertDialog
        showDialog={this.state.showAlert}
        message={this.state.alertMessage}
        title={Config.productTitle}
        onClose={() => this.setState({ alertMessage: '', showAlert: false })}
      />
    );
  }

}

