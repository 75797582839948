import * as React from 'react';
import DropDownBox from 'devextreme-react/drop-down-box';
import DataGrid, {
  Selection, FilterRow, Scrolling,
} from 'devextreme-react/data-grid';

const gridColumns = ['Select All'];
interface Props {
  label?: string;
  stylingMode: string | any;
  placeholder?: string | any;
  displayExpr: string | any;
  valueExpr?: string | any
  grouped?: Boolean | any;
  selectedItem: any;
  showClearButton?: Boolean | any;
  readOnly?: Boolean | any;
  disabled?: Boolean | any;
  onChange: any;
  required?: any;
  items: any;
  spellcheck?: Boolean | any;
}
export default class MultiSelect extends React.Component<Props, any> {
  public treeView: any;
  constructor(props: Props) {
    super(props);

    this.treeView = null;

    this.dataGridOnSelectionChanged = this.dataGridOnSelectionChanged.bind(this);
    this.syncDataGridSelection = this.syncDataGridSelection.bind(this);
    this.dataGridRender = this.dataGridRender.bind(this);

    this.state = {
      items: this.props.items,
      loading: true
    }

  }
  public afterInit() {
    this.setState({
      items: this.props.items
    });
  }
  public afterInitValue() {

    this.setState({
      selectedItem: this.props.selectedItem
    });

  }
  public render(): React.ReactElement<any> {
    if ((this.props.items && !this.state.items) || ((this.props.items && this.props.items.length) !== (this.state.items && this.state.items.length))) {

      this.afterInit();
    }
    if ((this.props.selectedItem && !this.state.selectedItem) || ((this.props.selectedItem && this.props.selectedItem.length) !== (this.state.selectedItem && this.state.selectedItem.length))) {

      this.afterInitValue()
      // setTimeout(()=>this.afterInitValue(),3000);
    }
    return (

      <DropDownBox
        displayExpr={this.props.displayExpr}
        placeholder={this.props.placeholder}
        showClearButton={this.props.showClearButton}
        stylingMode={this.props.stylingMode}
        dataSource={this.state.items}
        value={this.state.selectedItem}
        onValueChanged={this.syncDataGridSelection}
        contentRender={() => this.dataGridRender(this.state.items)}
        label={this.props.label}
        labelMode={'floating'}
        disabled={this.props.disabled}
      />
    );
  }
  dataGridRender(items: any) {
    return (
      <DataGrid
        height={430}
        dataSource={items}
        columns={gridColumns}
        hoverStateEnabled={true}
        selectedRowKeys={this.state.selectedItem}
        onSelectionChanged={this.dataGridOnSelectionChanged}>
        <Selection mode="multiple" />
        <Scrolling mode="virtual" />
        {/* <SearchPanel visible={true} width={'100%'} /> */}
        {/* <Paging enabled={true} pageSize={10} /> */}
        <FilterRow visible={true} />
      </DataGrid>
    );
  }
  dataGridOnSelectionChanged(e: any) {

    this.setState({
      selectedItem: (e.selectedRowKeys.length && e.selectedRowKeys) || [],
    });
    let gridBoxValue = (e.selectedRowKeys.length && e.selectedRowKeys) || []
    this.props.onChange(gridBoxValue)
  }
  syncDataGridSelection(e: any) {
    this.setState({
      selectedItem: e.value || [],
    });
    let gridBoxValue = e.value || []
    this.props.onChange(gridBoxValue)

  }
}


