import * as React from 'react';
import utility from '../../../classes/utility';
import { Config } from '../../../classes/config';

import Grid from '@material-ui/core/Grid';
import ScrollView from 'devextreme-react/scroll-view';
import Spinner from '../../common/reusableComponents/Spinner';
import AlertDialog from '../../common/alertDialog';
import TextAreaInput from '../../common/reusableComponents/TextArea';
import TextBoxInput from '../../common/reusableComponents/TextBox';
import capitalize from '@material-ui/core/utils/capitalize';
import CheckBoxInput from '../../common/reusableComponents/CheckBox';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

export default class AddEditForms extends React.Component<any, any> {

    constructor(props: any) {
        super(props);

        this.updateForms = this.updateForms.bind(this);
        this.clearFilter = this.clearFilter.bind(this);

        this.state = {
            builderLink: '/',
            isActive: true,
        };
    }
    public didMount() {
        if (this.props.details && this.props.mode === 'edit') {
            this.populateValue(this.props.details, this.props.mode);
        } else {
            this.setState({
                isActive: true,
                hideAlertButton: false,
                mode: this.props.mode
            })
        }
    }
    public populateValue(details: any, mode?: any) {
        this.setState({
            id: details.id,
            title: details.title,
            model: details.model,
            guid: details.guid,
            builderLink: '/Builder?ID=' + details.id,
            isActive: this.props.details.isActive,
        });
    }
    private _checkBoxChanged = (event: any, fieldName: string) => {
        this.setState({ [fieldName]: event.value });
    }
    public updateForms() {
        let dataToPassToService: any = {};

        if (!this.state.title) {
            this.setState({
                alertMessage: 'Please enter title',
                showAlert: true
            });
            return;
        }
        if (!this.state.model) {
            this.setState({
                alertMessage: 'Please enter  model',
                showAlert: true
            });
            return;
        }
        this.setState({
            alertMessage: <div className={'alertLoader'}><Spinner size='large' label="Loading..." /></div>,
            hideAlertButton: true,
            showAlert: true
        });
        dataToPassToService.title = this.state.title;
        dataToPassToService.formName = this.state.title;
        dataToPassToService.model = this.state.model;
        dataToPassToService.isActive = this.state.isActive ? this.state.isActive : '';
        dataToPassToService.tenantId = Config.tenantId;
        if (this.props.mode === 'edit') {
            dataToPassToService.id = this.state.id;
            dataToPassToService.updatedBy = this.props.currentUser.Email;
            dataToPassToService.updated = new Date();
            utility.genericUpdateAPICall(Config.FormsListName, this.state.id, dataToPassToService).then((data: any) => {
                //console.log(data);
                this.setState({ showAlert: false });
                this.clearFilter();
                this.props.onClick();
                this.props.message("Forms has been updated");
            }, (err: any) => {
                console.log(err);
                this.setState({
                    alertMessage: 'Something went wrong!',
                    hideAlertButton: false,
                    showAlert: true
                });
            });
        } else {
            dataToPassToService.createdBy = this.props.currentUser.Email;
            dataToPassToService.created = new Date();
            utility.genericPostAPIcall(Config.FormsListName, dataToPassToService).then((data: any) => {
                //console.log(data);
                this.setState({ showAlert: false });
                this.clearFilter();
                this.props.onClick();
                this.props.message("Forms has been created");
            }, (err: any) => {
                console.log(err);
                this.setState({
                    alertMessage: 'Something went wrong!',
                    hideAlertButton: false,
                    showAlert: true
                });
            });
        }

    }

    public clearFilter() {
        this.setState({ title: '', name: '', model: '' });
        this.props.onClose();

    }
    private alert() {
        return (
            <AlertDialog
                showDialog={this.state.showAlert}
                message={this.state.alertMessage}
                title={Config.productTitle}
                onClose={() => this.setState({ alertMessage: '', showAlert: false })}
                hideAlertButton={this.state.hideAlertButton}
            />
        );
    }
    private handleValueChange = (event: any, fieldName: string) => {
        this.setState({ [fieldName]: event.value });
    }

    public render(): React.ReactElement<any> {
        return (
            <div>
                {this.state.showAlert && this.alert()}

                <Dialog
                    open={this.props.showModal}
                    onClose={() => this.clearFilter()}
                    fullWidth={true}
                    maxWidth={'lg'}
                    aria-labelledby="max-width-dialog-title"
                    onRendered={() => this.didMount()}
                >
                    <DialogTitle id="scroll-dialog-title">
                        {`${this.props.mode && capitalize(this.props.mode)} Form`}
                    </DialogTitle>
                    <IconButton aria-label="close" onClick={() => this.clearFilter()} className="closeIcon">
                        <CloseIcon />
                    </IconButton>
                    <DialogContent dividers={true}>
                        <ScrollView width='100%' height='100%' showScrollbar='onScroll' scrollByThumb={true}>
                            <div className='popContentDiv'>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={12}>
                                        <TextBoxInput
                                            stylingMode='underlined'
                                            label="Title *"
                                            value={this.state.title}
                                            onValueChanged={(e: any) => this.handleValueChange(e, 'title')}

                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12}>

                                        <TextAreaInput
                                            stylingMode='underlined'
                                            value={this.state.guid}
                                            onValueChanged={(e: any) => this.handleValueChange(e, 'guid')}
                                            label="Guid "
                                            disabled={true}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12}>

                                        <TextAreaInput
                                            stylingMode='underlined'
                                            value={this.state.model}
                                            onValueChanged={(e: any) => this.handleValueChange(e, 'model')}
                                            label="Model "
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={3}>
                                        <br />
                                        <CheckBoxInput value={this.state.isActive} onValueChanged={(e: any) => this._checkBoxChanged(e, 'isActive')} text={'Is Active'} />
                                    </Grid>

                                </Grid>
                            </div>
                        </ScrollView>
                    </DialogContent>
                    <DialogActions>

                        <Button onClick={() => this.clearFilter()} variant='contained' color="primary" className={''}>
                            Cancel
                        </Button>
                        <Button onClick={() => this.updateForms()} variant='outlined' color="primary" className={'button'}>
                            Save
                        </Button>

                    </DialogActions>
                </Dialog>
            </div>

        );
    }

}

