import * as React from 'react';

import {
    NavLink
} from "react-router-dom";
import Grid from '@material-ui/core/Grid';
import { Paper } from '@material-ui/core';
import utility from '../../classes/utility';
import { Config } from '../../classes/config';

import ChevronRightOutlinedIcon from '@material-ui/icons/ChevronRightOutlined';
import ChevronLeftOutlinedIcon from '@material-ui/icons/ChevronLeftOutlined';
import Spinner from '../common/reusableComponents/Spinner';

let _items: any = [];
const searchOptions: any = [
    { id: 'All', text: 'All Result' },
    { id: 'Work Order', text: 'Work Order' },
    { id: 'Plant', text: 'Plant and Fleet Usage' },
    { id: 'Asset', text: 'Asset' },
    { id: 'Timesheet', text: 'Timesheet' },
]
let tempWindow: any = window;
export default class SearchPage extends React.Component<any, any> {
    public searchCriteria;
    constructor(props: any) {
        super(props);
        //let currentDetail = localStorage.getItem("currentDetail");
        if (!this.props.Department) {
            window.open('#/AccessRequest', '_self');
        };
        let search = this.props.location.search;

        let params = new URLSearchParams(search);

        this.searchCriteria = params.get('search');

        this.state = {
            loading: false,
            resultCount: 0,
            selectedSearchOption: { id: 'All', text: 'All Result' }
        };
    }



    public componentDidMount() {
        this.getGlobalSearchResult();
    }


    public getGlobalSearchResult() {
        this.setState({ loading: true });
        let searchTerm = Config.GloabSearchAPI + this.searchCriteria
        utility.genericGetAPICall(searchTerm).then((data: any) => {

            _items = [];
            data.forEach((item: any) => {
                let link = item.Entity === 'Work Order' ? `/WorkOrderDetail?WOID=${item.Id}+&ReqId=${item.Wonumber}` : item.Entity === "Asset" ? `/AssetDetail?ASSETNO=${item.Code}` : `/Search${item.Entity}?Code=${item.Code}`;

                _items.push({
                    code: item.Wonumber ? item.Wonumber : item.Code,
                    title: item.Title,
                    description: item.Description,
                    Entity: item.Entity,
                    link: link
                })
            });

            let resultGroup = utility.groupBy(_items, 'Entity');
            this.setState({ results: resultGroup, resultCount: _items.length, loading: false });
        }, (err: any) => {
            console.log(err);
            this.setState({ loading: false });
        });
    }
    public scrollDepartment = (e: any) => {

        // e.preventDefault()
        // var container = tempWindow.document.getElementById('scrollDept')
        // var containerScrollPosition = tempWindow.document.getElementById('scrollDept').scrollLeft
        // container.scrollTo({
        //     // top: 0,
        //     left: containerScrollPosition + 20,

        // });


        function scrollHorizontally(e: any) {
            e = window.event || e;
            var delta: any = Math.max(-1, Math.min(1, (e.wheelDelta || -e.detail)));

            tempWindow.document.getElementById('scrollDept').scrollLeft -= (delta * 40); // Multiplied by 40
            e.preventDefault();
        }

        if (tempWindow.document.getElementById('scrollDept').addEventListener) {
            // IE9, Chrome, Safari, Opera
            tempWindow.document.getElementById('scrollDept').addEventListener('mousewheel', scrollHorizontally, false);
            // Firefox
            tempWindow.document.getElementById('scrollDept').addEventListener('DOMMouseScroll', scrollHorizontally, false);
        } else {
            // IE 6/7/8
            // tempWindow.document.getElementById('yourDiv').attachEvent('onmousewheel', scrollHorizontally);
        }
        if (tempWindow.document.getElementById('scrollDept').scrollLeft === (tempWindow.document.getElementById('scrollDept').scrollWidth - tempWindow.document.getElementById('scrollDept').clientWidth)) {

            this.setState({ showRightArrow: false });
        } else {
            this.setState({ showRightArrow: true });

        }
        if (tempWindow.document.getElementById('scrollDept').scrollLeft === 0) {
            this.setState({ showLeftArrow: false });
        } else {
            this.setState({ showLeftArrow: true });

        }
        window.scroll(0, 0)
    }
    public scrollLeft() {
        tempWindow.document.getElementById('scrollDept').scrollLeft -= 400;

        if (tempWindow.document.getElementById('scrollDept').scrollLeft === 0) {
            this.setState({ showLeftArrow: false });
        }
        this.setState({ showRightArrow: true });
    }
    public scrollRight() {
        //scrollWidth
        tempWindow.document.getElementById('scrollDept').scrollLeft += 400;
        if (tempWindow.document.getElementById('scrollDept').scrollLeft === (tempWindow.document.getElementById('scrollDept').scrollWidth - tempWindow.document.getElementById('scrollDept').clientWidth)) {

            this.setState({ showRightArrow: false });
        }
        this.setState({ showLeftArrow: true });
    }
    public onSelectEntity(item: any) {
        let text = item.id.toLowerCase();
        // this.setState({filterWordOrders: text, items: text ? _items.filter(i => (i.name && i.name.toLowerCase().indexOf(text) > -1) || (i.Workordernumber && i.Workordernumber.toLowerCase().indexOf(text) > -1) || (i.location && i.location.toLowerCase().indexOf(text) > -1) || (i.suburb && i.suburb.toLowerCase().indexOf(text) > -1) || (i.priority && i.priority.toLowerCase().indexOf(text) > -1)) : _items });
        let tempItems = item.id === 'All' ? _items : _items.filter((i: any) => (i.Entity && i.Entity.toLowerCase().indexOf(text) > -1));

        let resultGroup = utility.groupBy(tempItems, 'Entity');
        this.setState({
            results: resultGroup,
            selectedSearchOption: item
        });
    }
    public render(): React.ReactElement<any> {

        let newSearch = new URLSearchParams(this.props.location.search).get('search');
        if (newSearch !== this.searchCriteria) {
            this.searchCriteria = newSearch;
            this.getGlobalSearchResult();

        }
        var keys;
        if (this.state.results) {
            keys = Object.keys(this.state.results);
        }
        return (
            <div>

                <div className={'searchResultPage'}>

                    <div className={"SearchDiv"}>
                        <div className={'headingDiv'}>
                            <span className={"heading"}>Seacrh Results</span>
                        </div>
                    </div>
                    <div className={"searchFieldDiv"}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={12}>
                                <div className="department-wrapper">
                                    <ul id={'scrollDept'} className="departmentList" onWheel={this.scrollDepartment}>
                                        {searchOptions && searchOptions.map((item: any, i: any) => (

                                            <li className={this.state.selectedSearchOption && this.state.selectedSearchOption.id === item.id ? "department selectedDept" : "department"} key={i} onClick={() => this.onSelectEntity(item)}>{item.text}</li>
                                        ))}
                                    </ul>

                                    <div className="paddles">
                                        {this.state.showLeftArrow && (<ChevronLeftOutlinedIcon className="left-paddle paddle" onClick={() => this.scrollLeft()} />)}
                                        {this.state.showRightArrow && (<ChevronRightOutlinedIcon className="right-paddle paddle" onClick={() => this.scrollRight()} />)}
                                    </div>

                                </div>
                            </Grid>
                            <Grid item xs={12} sm={12} >
                                {!this.state.loading ?
                                    <div className="">
                                        {this.state.resultCount > 0 ?
                                            <div>
                                                {keys ?
                                                    <>
                                                        {keys.map((key) => {
                                                            return (
                                                                <div>

                                                                    <Paper elevation={6} className={'searchPageList'}>
                                                                        <div className={'searchPageHeading'}> {key}</div>
                                                                        {this.state.results[key].map((item: any, i: any) => {
                                                                            return (
                                                                                <div key={i} className={i === (this.state.results.length - 1) ? "searchPageContent" : "searchPageContent borderBottom"}>

                                                                                    <Grid container spacing={3}>
                                                                                        <Grid item xs={12} sm={12} >
                                                                                            <NavLink to={item.link}>
                                                                                                <div className='WOlink'>
                                                                                                    <strong>{item.code} </strong>
                                                                                                </div>
                                                                                            </NavLink>
                                                                                            <div>{item.title}</div>
                                                                                            {item.description && (
                                                                                                <div className="searchPageDescription">{item.description}</div>
                                                                                            )}
                                                                                        </Grid>

                                                                                    </Grid>
                                                                                </div>
                                                                            );
                                                                        })}
                                                                    </Paper>
                                                                </div>
                                                            );
                                                        })
                                                        }
                                                    </>
                                                    :
                                                    <div>
                                                        <div className="noResultFoundDiv">
                                                            <div className="noResultFoundTitle"> No Result Found </div>
                                                            <p> Try different search </p>
                                                        </div>
                                                    </div>}

                                            </div> :
                                            <div className="alignCenter p-10">
                                                <div className="noResultFoundDiv">
                                                    <div className="noResultFoundTitle"> No Result Found </div>
                                                    <p> Try different search </p>
                                                </div>
                                            </div>}
                                    </div>
                                    :
                                    <Spinner size='large' />
                                }
                            </Grid>
                        </Grid>
                    </div>


                </div>
            </div>
        );
    }


}

