import * as React from 'react';
import global from '../../classes/global';


export interface ITestFrameState {
    iFramePanelOpenedforDetail: boolean;
    iFrameUrl: string;
}

export default class AssetMapforDetail extends React.Component<any, any> {

    constructor(props: any) {
        super(props);

        this.state = {
            iFramePanelOpenedforDetail: false,
            iFrameUrl: global.AssetMapURL
        };
    }

    public _onIframeLoaded() {
        window.addEventListener('message', this.handleIframeTaskfordetail);

    }

    public componentDidMount() {
    }

    public handleIframeTaskfordetail = (e: any) => {

        if (e.data.command === 'asset selected') {
            let mapProps = e.data.assets[0];
            let properties = JSON.parse(mapProps.properties);

            if (this.props.selectMode === true) {
                this.props.selectAssets(properties);
                window.removeEventListener("message", this.handleIframeTaskfordetail, false);
            } else {
                let parseddata = JSON.parse(mapProps.properties)
                let location = window.location.origin + window.location.pathname + "#/AssetDetail?erpAssetID=" + mapProps.id;
                window.removeEventListener("message", this.handleIframeTaskfordetail, false);
                window.open(location, "_self");

            }
            this.setState({
                iFramePanelOpenedforDetail: false
            });
        }


    };


    public render(): React.ReactElement<any> {
        return (
            <div style={{ height: '100%' }}>
                <iframe
                    src={this.state.iFrameUrl}
                    allowFullScreen={true}
                    width={'100%'}
                    style={{ height: '82vh' }}
                    onLoad={this._onIframeLoaded.bind(this)}
                />
            </div>
        );
    }
}
