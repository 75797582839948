import * as React from 'react';
import utility from '../../../classes/utility';
import { Config } from '../../../classes/config';
import DataTable from '../../common/DataTable/DataTable';
import AlertDialog from '../../common/alertDialog';
import DeleteDialog from '../../common/DeleteAlert';
import Spinner from '../../common/reusableComponents/Spinner';
import EditWorkRequestActivities from './EditWorkRequestActivities';
import global from '../../../classes/global';
let _items: any = [];

export default class WorkRequestActivities extends React.Component<any, any> {

    public currentUser: any;
    public columns: any;
    public myRef: any;
    constructor(props: any) {
        super(props);
        this.EditItemModal = this.EditItemModal.bind(this);
        this.myRef = React.createRef();

        this.currentUser = {
            DisplayName: this.props.currentUser.DisplayName ? this.props.currentUser.DisplayName : this.props.currentUser.displayName,
            Email: this.props.currentUser.Email ? this.props.currentUser.Email : this.props.currentUser.email,
            LoginName: this.props.currentUser.LoginName ? this.props.currentUser.LoginName : this.props.currentUser.loginName
        };
        if (this.props.role !== global.worxOnline_Admin) {
            window.open('#/AccessDenied', '_self');
        }
        this.columns = [
            {
                field: 'id',
                title: 'ID',
                width: 80,
                type: 'string',
                visibleColumn: false,
                allowHiding: false
            },
            {
                field: 'title',
                title: 'Title',
                width: 360,
                type: 'string',
            },
            {
                field: 'description',
                title: 'Description',
                width: 360,
                type: 'string'
            },
            {
                field: 'department',
                title: 'Department',
                // width: 160,
                type: 'string',
                allowHiding: true
            },
            {
                field: 'team',
                title: 'Team',
                // width: 160,
                type: 'string',
                allowHiding: true
            },

            {
                field: 'updatedOn',
                title: 'Updated',
                width: 160,
                type: 'datetime',
                visibleColumn: false,
                allowHiding: true,
                sortOrder: 'desc'
            },
            {
                field: 'updatedBy',
                title: 'Updated By',
                width: 160,
                type: 'string',
                visibleColumn: false,
                allowHiding: true
            },
            {
                field: 'createdOn',
                title: 'Created',
                width: 160,
                type: 'datetime',
                visibleColumn: false,
                allowHiding: true
            },
            {
                field: 'createdBy',
                title: 'Created By',
                width: 160,
                type: 'string',
                visibleColumn: false,
                allowHiding: true
            },
        ];
        this.state = {
            items: _items,
            columns: this.columns
        };
    }

    public componentDidMount() {
        this._getItemsData();

    }
    private EditItemModal(item: any) {
        this.setState({ showModal: true, details: item, mode: 'edit' });
    }

    public message(message: any) {
        // //console.log(message);
        this.setState({
            alertMessage: message + ' successfully',
            showAlert: true
        });
    }
    public renderNewModal() {
        return (
            <EditWorkRequestActivities
                showModal={this.state.showModal}
                onClose={() => this._closeModal()}
                onClick={() => this._getItemsData()}
                message={(e: any) => this.message(e)}
                currentUser={this.currentUser}
                mode={this.state.mode}
                details={this.state.details}
            />
        );
    }
    private _closeModal = (): void => {
        this.setState({ showModal: false });
    }
    private _showModal = (): void => {

        this.setState({ showModal: true, details: null, mode: 'edit' });
    }
    private _getItemsData() {

        let dataToPassToService: any = {};
        this.setState({
            loading: true
        });
        utility.genericGetAPICallForList(Config.SearchWRTlist, 'POST', dataToPassToService).then((data: any) => {
            _items = [];


            data.map((r: any) => {
                let teamTitle: String = '';
                if (r.teams && r.teams.length > 0) {
                    r.teams.forEach((team: any) => {
                        teamTitle += team.title + ',';
                    });
                    teamTitle = teamTitle.substring(0, teamTitle.length - 1);
                }
                _items.push({
                    id: r.id,
                    title: r.title,
                    description: r.description,
                    departmentId: r.departmentId,
                    department: r.department,
                    teamIds: r.teamIds,
                    team: teamTitle,
                    isActive: r.isActive ? true : false,
                    updatedBy: r.updatedBy,
                    updatedOn: r.updated || null,
                    createdBy: r.createdBy,
                    createdOn: r.created || null,
                })
            });

            const sortedItems = utility._sortItems(_items, 'updatedOn', true);

            if (sortedItems.length === 0) {
                this.setState({
                    alertMessage: 'No results found for this criteria',
                    showAlert: true,
                    items: [],
                    preItems: [],
                    callAfterInit: true,
                    loading: false,
                });
            } else {
                this.setState({
                    items: sortedItems,
                    preItems: sortedItems,
                    callAfterInit: true,
                    loading: false,
                });
            }
        }, (err) => {
            this.setState({ loading: false, callAfterInit: true, });
        });
    }

    public setSelection = (id: any) => {
        this.setState({
            selectedRow: id
        });
    }
    private stopCommsAfterInit = (): void => {
        this.setState({ callAfterInit: false });
    }
    private _sortItems = (items: any[], sortBy: string, descending = false): any[] => {
        if (descending) {
            return items.sort((a: any, b: any) => {
                if (a[sortBy] < b[sortBy]) {
                    return 1;
                }
                if (a[sortBy] > b[sortBy]) {
                    return -1;
                }
                return 0;
            });
        } else {
            return items.sort((a: any, b: any) => {
                if (a[sortBy] < b[sortBy]) {
                    return -1;
                }
                if (a[sortBy] > b[sortBy]) {
                    return 1;
                }
                return 0;
            });
        }
    }
    public render(): React.ReactElement<any> {

        return (
            <React.Fragment>
                {this.renderNewModal()}
                {this.state.showAlert && this.alert()}
                <div className={"SearchDiv"}>
                    <div className={'headingDiv'}>
                        <span className={"heading"}> Work Request Activities </span>
                    </div>
                </div>
                <div>
                    {!this.state.loading ?
                        <div ref={this.myRef}>
                            <DataTable
                                style={{ width: '100%' }}
                                filteralbe={true}
                                groupable={true}
                                rows={this.state.items}
                                columns={this.state.columns}
                                fileName={'Work Request Activities'}
                                columnChooser={'workrequestactivitiesColumns'}
                                selectionMode={false}
                                showHideColumnMenu={true}
                                callAfterInit={this.state.callAfterInit}
                                stopCommsAfterInit={() => this.stopCommsAfterInit()}
                                loading={this.state.loading}
                                allowExportSelectedData={false}
                                showNewModal={() => this._showModal()}
                                editItem={(a: any) => this.EditItemModal(a)}
                                // viewFile={(e)=>this.viewFile(e)}
                                showCommandBtn={true}
                                showNewBtn={false}
                                hideNewBtn={this.state.showModal || this.state.showEditModal || this.props.hideNewBtn}
                                showViewDetailBtn={false}
                                hideCommandDeleteBtn={true}
                                hideCommandEditBtn={false}
                            />
                        </div>
                        :
                        <Spinner size='large' type={'converging-spinner'} className="loader" />
                    }

                </div>
            </React.Fragment>
        );
    }
    public alert() {
        return (
            <AlertDialog
                showDialog={this.state.showAlert}
                message={this.state.alertMessage}
                title={Config.productTitle}
                hideAlertButton={this.state.hideAlertButton}
                onClose={() => this.setState({ alertMessage: '', showAlert: false })}
            />
        );
    }

}

