import * as React from 'react';
import moment from 'moment';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';


import utility from '../../classes/utility';
import { Config } from '../../classes/config';
import AlertDialog from '../common/alertDialog';
import AssignDatatable from '../common/DataTable/AssignDatatable';
import global from '../../classes/global';
import TextBoxInput from '../common/reusableComponents/TextBox';
import SelectBoxInput from '../common/reusableComponents/SelectBox';
import Spinner from '../common/reusableComponents/Spinner';

let _items: any = [];

const inspectionFailedItems = [
    { id: true, text: 'Yes' },
    { id: false, text: 'No' }
];

export default class EquipmentsInspection extends React.Component<any, any> {


    public currentUser: any;
    public columns: any;

    constructor(props: any) {
        super(props);

        this._getItemData = this._getItemData.bind(this);


        this.currentUser = this.props.currentUser;


        this.columns = [

            {
                field: 'inspectionFailed',
                title: 'Inspection failed',
                width: 110,
                allowHiding: false,
                type: 'boolean'
            },
            {
                field: 'id',
                title: 'ID',
                // width: 160,
                type: 'string',
                allowHiding: false,
            },
            {
                field: 'resourceCode',
                title: 'Resource Code',
                // width: 280,
                type: 'string',
                allowHiding: false,
            },
            {
                field: 'title',
                title: 'Title',
                // width: 160,
                type: 'string',
            },
            {
                field: 'category',
                title: 'Category',
                // width: 160,
                type: 'string',
                allowHiding: true
            },
            {
                field: 'regoNumber',
                title: 'Rego Number',
                // width: 160,
                type: 'string',
                allowHiding: true
            },
            {
                field: 'department',
                title: 'Department',
                // width: 160,
                type: 'string',
                allowHiding: true
            },
            // {
            //     field: 'prestartformName',
            //     title: 'Prestart Form',
            //     // width: 160,
            //     type: 'string',
            //     allowHiding: true
            // },
            {
                field: 'active',
                title: 'Is Active',
                // width: 160,
                type: 'boolean',
                allowHiding: true
            },
            {
                field: 'updatedOn',
                title: 'Updated',
                width: 160,
                type: 'datetime',
                visibleColumn: false,
                allowHiding: true,
                sortOrder: 'desc'
            },
            {
                field: 'updatedBy',
                title: 'Updated By',
                width: 160,
                type: 'string',
                visibleColumn: false,
                allowHiding: true
            },
            {
                field: 'createdOn',
                title: 'Created',
                width: 160,
                type: 'datetime',
                visibleColumn: false,
                allowHiding: true
            },
            {
                field: 'createdBy',
                title: 'Created By',
                width: 160,
                type: 'string',
                visibleColumn: false,
                allowHiding: true
            },
        ];
        this.state = {
            items: _items,
            columns: this.columns,
            showSearchDiv: false,
            tableHeight: 665,
            inspectionFailedItems: inspectionFailedItems,
            allinspectionFailedItems: inspectionFailedItems,
            department: (this.props.role === global.worxOnline_Contractor || this.props.role === global.worxOnline_ContractorAdmin) ? this.props.Department : '',

        };
    }

    public componentDidMount() {
        this._getDepartmentData();
        let tempstate = localStorage.getItem('EquipmentDataState');
        if (tempstate) {
            let allState = JSON.parse(tempstate);
            console.log({ allState })
            this.setState({
                inspectionfailed: allState.inspectionfailed ? allState.inspectionfailed : '',
                title: allState.title ? allState.title : '',
                category: allState.category ? allState.category : '',
                loadingFields: false
            })
        }

    }
    private _getDepartmentData() {

        utility.genericGetAPICall(`${Config.DepartmentList}?showAll=true`).then((data: any) => {
            // console.log(data);
            let departmentItems = [];

            for (let r of data) {
                departmentItems.push({ id: r.id, text: r.title });
            }
            departmentItems = utility._sortItems(departmentItems, 'text', false);
            this.setState({ departmentItems: departmentItems, alldepartmentItems: departmentItems });
        }, (err) => {
            console.log(err);
        });
    }
    public clearFilter() {
        this.setState({
            items: [],
            title: '',
            category: '',
            department: '',
            inspectionfailed: '',
        });
        localStorage.setItem('EquipmentDataState', '');
        let datagrid = localStorage.getItem('datagridFilterValue');
        if (datagrid) {
            localStorage.removeItem('datagridFilterValue');
        }
    }

    private _getItemData() {
        let criteriaSelected = false;
        let dataToPassToService: any = {};
        if (this.state.title) {
            dataToPassToService.title = this.state.title;
            criteriaSelected = true;
        }
        if (this.state.department) {
            dataToPassToService.departmentId = this.state.department.id;
            criteriaSelected = true;
        }
        if (this.state.inspectionfailed) {
            dataToPassToService.inspectionFailed = this.state.inspectionfailed.id;
            criteriaSelected = true;
        }
        if (!criteriaSelected) {
            this.setState({
                alertMessage: 'Please select search criteria',
                showAlert: true
            });
            return;
        }

        this.setState({
            loading: true
        });

        utility.genericPostAPIcall(Config.WOequipmentSearchAPI, dataToPassToService).then((data: any) => {
            _items = [];
            if (data.length === null || data.length <= 0) {
                this.setState({
                    alertMessage: 'No results found for this criteria',
                    showAlert: true
                });
                this.setState({ items: _items, loading: false });
                return;
            }
            else {
                for (let r of data) {
                    _items.push({
                        //Selected: false,
                        id: r.id,
                        title: r.title,
                        resourceCode: r.resourceCode,
                        category: r.category,
                        //categoryId: r.Categoryid,
                        regoNumber: r.regoNumber,
                        department: r.departmentTitle,
                        departmentId: r.departmentId,
                        prestartformid: r.prestartFormId,
                        //prestartformName: r.Prestartformtitle,
                        inspectionFailed: r.inspectionFailed,
                        active: r.isActive,
                        default: r.isDefault,
                        updatedBy: r.updatedBy,
                        // updatedOn: r.Dateupdated ? new Date(r.Dateupdated) : null,
                        createdBy: r.createdBy,
                        updatedOn: r.updated ? moment(r.updated).format("DD/MM/YY h:mm a") : null,
                        createdOn: r.created ? moment(r.created).format("DD/MM/YY h:mm a") : null,

                    });
                }
            }

            this.setState({ items: _items, callAfterInit: true, loading: false, preItems: _items });

            var stateData = { ...this.state };
            localStorage.setItem('EquipmentDataState', JSON.stringify(stateData));
        }, (err) => {
            console.log(err);
            this.setState({ loading: false, callAfterInit: true, });
        });
    }

    public setSelection = (id: any) => {
        this.setState({
            selectedRow: id
        });
    }
    private stopCommsAfterInit = (): void => {
        this.setState({ callAfterInit: false });
    }
    public _changeInspectiponFailed(event: any, item: any) {

        let dataToPassToService: any = {};
        dataToPassToService.id = item.id;
        dataToPassToService.Title = item.title;
        dataToPassToService.Resourcecode = item.rCode;
        dataToPassToService.Category = item.category;
        dataToPassToService.Regonumber = item.regonumber;
        dataToPassToService.Isactive = item.active;
        dataToPassToService.Tenantid = Config.tenantId;
        dataToPassToService.DepartmentId = item.departmentId;
        dataToPassToService.Prestartformid = item.prestartformid;
        dataToPassToService.createdBy = this.currentUser.Email;
        dataToPassToService.updatedBy = this.currentUser.Email;
        dataToPassToService.inspectionFailed = event;
        //console.log("dataToPassToService", dataToPassToService);
        utility.genericUpdateAPICall(Config.EquipmentsListName, item.id, dataToPassToService).then((data: any) => {
            this.setState({
                loading: true
            });
            setTimeout(() => this._getItemData(), 500);
        }, (err: any) => {
            console.log(err);
        });
    }


    public _handleKeyDown = (e: any) => {
        if (e.event.originalEvent.key === 'Enter') {
            this._getItemData();
        }
    }
    private handleValueChange = (event: any, fieldName: string) => {
        this.setState({ [fieldName]: event.value });
    }
    public handleDropdownChange(e: any, fieldName: string) {
        this.setState({ [fieldName]: e.selectedItem });
    }
    public render(): React.ReactElement<any> {

        return (
            <React.Fragment>
                {this.state.showAlert && this.alert()}
                <div className={"SearchDiv"}>
                    <div className={'headingDiv'}>
                        <span className={"heading"}> Plant and Fleet </span>
                    </div>
                </div>
                <div className={"searchFieldDiv"}>
                    <Grid container spacing={3}>

                        <Grid item xs={12} sm={6} md={6} lg={4}>
                            <TextBoxInput
                                stylingMode='underlined'
                                label="Title"
                                // pattern={"[A-Za-z]+"}
                                // minLength={2}
                                value={this.state.title}
                                onValueChanged={(e: any) => this.handleValueChange(e, 'title')}
                                onKeyDown={(e: any) => this._handleKeyDown(e)}
                            />
                        </Grid>
                        {/* <Grid item xs={12} sm={6} md={6} lg={4}>
                            <SelectBoxInput
                                stylingMode='underlined'
                                displayExpr="text"
                                valueExpr="id"
                                showClearButton={true}
                                items={this.state.categoryItems}
                                selectedItem={this.state.category}
                                label='Category '
                                onSelectionChanged={(e: any) => this.handleDropdownChange(e, 'category')}
                            />
                        </Grid> */}
                        <Grid item xs={12} sm={6} md={6} lg={4}>
                            <SelectBoxInput
                                stylingMode='underlined'
                                displayExpr="text"
                                valueExpr="id"
                                showClearButton={true}
                                items={this.state.departmentItems}
                                selectedItem={this.state.department}
                                label='Department '
                                onSelectionChanged={(e: any) => this.handleDropdownChange(e, 'department')}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={4}>
                            <SelectBoxInput
                                stylingMode='underlined'
                                displayExpr="text"
                                valueExpr="id"
                                showClearButton={true}
                                items={this.state.inspectionFailedItems}
                                selectedItem={this.state.inspectionfailed}
                                label='Inspection Failed'
                                onSelectionChanged={(e: any) => this.handleDropdownChange(e, 'inspectionfailed')}
                            />                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={12}>
                            <div className={"searchButtonDiv"}>
                                {(this.state.itemSelected && this.state.itemSelected.length > 0) && (
                                    <Button onClick={() => this.setState({ submitEquipmentUsageModal: true })} variant='contained' color="primary" className={'button'}>
                                        Submit
                                    </Button>
                                )}
                                <Button onClick={() => this.clearFilter()} variant='outlined'>
                                    Clear
                                </Button>
                                <Button onClick={this._getItemData} variant='outlined' color="primary" className={'button'}>
                                    Search
                                </Button>
                            </div>
                        </Grid>
                    </Grid>
                </div>
                <div>
                    {!this.state.loading ?
                        <div className='tabStripDiv'>
                            {(this.state.items && this.state.items.length > 0) && (

                                <AssignDatatable
                                    style={{ width: '100%' }}
                                    filteralbe={true}
                                    groupable={true}
                                    rows={this.state.items}
                                    columns={this.state.columns}
                                    fileName={'Plant and Fleet'}
                                    columnChooser={'EquipmentFailColumns'}
                                    selectionMode={false}
                                    showHideColumnMenu={true}
                                    callAfterInit={this.state.callAfterInit}
                                    stopCommsAfterInit={() => this.stopCommsAfterInit()}
                                    loading={this.state.loading}
                                    allowExportSelectedData={false}
                                    showNewBtn={false}
                                    // showNewModal = {() => this._showModal()}
                                    // deleteItem = {(a) => this.DeleteDialog(a)}
                                    // editItem = {(a) => this.EditItemModal(a)}
                                    // viewFile={(e)=>this.viewFile(e)}
                                    showCommandBtn={false}
                                    showViewDetailBtn={false}
                                    _changeInspectiponFailed={(e: any, data: any) => this._changeInspectiponFailed(e, data)}
                                />
                            )}
                        </div> :
                        <Spinner size='large' label="Loading..." />
                    }

                </div>
            </React.Fragment>
        );
    }
    public alert() {
        return (
            <AlertDialog
                showDialog={this.state.showAlert}
                message={this.state.alertMessage}
                title={Config.productTitle}
                hideAlertButton={this.state.hideAlertButton}
                onClose={() => this.setState({ alertMessage: '', showAlert: false })}
            />
        );
    }

}

