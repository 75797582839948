import * as React from 'react';
import AlertDialog from '../../common/alertDialog';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Spinner from '../../common/reusableComponents/Spinner';
import TextBoxInput from '../../common/reusableComponents/TextBox';
import CheckBoxInput from '../../common/reusableComponents/CheckBox';
import AddIcon from '@material-ui/icons/Add';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import DeleteIcon from '@material-ui/icons/Delete';
import NumberBoxInput from '../../common/reusableComponents/NumberBox';

const Fields: any = [
    {
        Type: "TextField",
        ID: 2,
        icon: 'font'
    },
    {
        Type: 'Number',
        ID: 2,
        icon: 'datafield'
    },
    {
        Type: "TextArea",
        ID: 3,
        icon: 'comment'
    },
    {
        Type: 'Date',
        ID: 4,
        icon: 'event'
    },

    {
        Type: 'Choices',
        ID: 6,
        icon: 'fieldchooser'
    },
    {
        Type: 'CheckBox',
        ID: 6,
        gridDesignerMode: true,
        icon: 'check'
    },
];
interface Props {
    selectedItem: any
    onSave: any
}
export default class AddEditFieldBuilder extends React.Component<Props, any> {

    public renderSpFields: any = [];
    public FormId: any;
    public currentUser: any;
    public anchor: any = null;
    constructor(props: any) {
        super(props);
        // Globalize.locale('en')

        // globalizeLocalizer()

        this.handleClonePopOver = this.handleClonePopOver.bind(this);
        this.handleCloseClonePopOver = this.handleCloseClonePopOver.bind(this);



        this.state = {
            fields: [],
            FormName: '',
            showPreview: false,
            parentOpen: false,
            multipleCondition: false
        }
    }


    public componentDidMount() {



        Fields.forEach((element: any) => {
            var tempField: any = {
                key: element.Type,
                name: element.Type,
                icon: element.icon,
            }
            // if (element.gridDesignerMode)
            //     tempField.className = this.props.gridDesignerMode ? 'hidden' : '';
            this.renderSpFields.push(tempField);
        });
        // console.log(this.props.selectedItem)
        this.setState({
            renderSpFields: this.renderSpFields,
            fields: this.props.selectedItem?.AddEditField
        });

    }

    public returnField(item: any, i: any, parentItem: any = "", isSectionField?: boolean, parentId?: any) {
        return this.returnInputField(item, i, parentItem, isSectionField, parentId);
    }

    public fieldHeadingRow(item: any, i: any, isSectionField?: any, parentItem?: any, parentId?: any) {

        return (<Grid container spacing={1} className={this.state.parentOpen && (this.state.parentItem && this.state.parentItem.ID !== item.ID) ? this.state.parentFieldSelectedToMove && this.state.childSelectedIndex === i ? "fieldHeadingRowSelectedForCondition fieldHeadingRowParent" : "fieldHeadingRowParent" : isSectionField ? "fieldHeadingRowSection" : "fieldHeadingRow"}  >
            <Grid item xs={6} sm={6} md={6}>
                <div className="fieldTypeDescription">{item.Type}</div>
            </Grid>
            <Grid item xs={6} sm={6} md={6}>
                {this.fieldCommandRow(i, item, isSectionField, parentItem, parentId)}
            </Grid>
        </Grid>);
    }

    public fieldCommandRow(i: any, item: any, isSectionField?: any, parentItem?: any, parentId?: any) {
        return (
            <div className="floatRight mr-10">
                <Grid container spacing={3} className="floatRight">

                    <Grid item xs={6} sm={3} md={3}>
                        <ArrowUpwardIcon className={i === 0 ? 'editFieldIcons grey' : 'editFieldIcons hand'} onClick={() => this.moveUp(i)}></ArrowUpwardIcon>
                    </Grid>
                    <Grid item xs={6} sm={3} md={3}>
                        <ArrowDownwardIcon className={i === this.state.fields.length - 1 ? 'editFieldIcons grey' : 'editFieldIcons hand'} onClick={() => this.moveDown(i)}></ArrowDownwardIcon>
                    </Grid>
                    <Grid item xs={6} sm={3} md={3}>
                        <DeleteIcon className="red hand editFieldIcons" onClick={() => this.deleteField(i)} aria-hidden="true"></DeleteIcon>
                    </Grid>
                    <Grid item xs={6} sm={3} md={3}>
                        <FileCopyIcon className="hand editFieldIcons" onClick={() => this.cloneField(i, item, isSectionField, parentItem, parentId)}></FileCopyIcon>

                    </Grid>
                </Grid>
            </div>);
    }


    public fieldQuestionTitleRow(item: any, field: string, fieldTitle: string, parentItem?: any) {

        return (<Grid item xs={12} sm={12} md={6}>
            <TextBoxInput
                stylingMode='underlined'
                label={fieldTitle}
                value={item[field]}
                placeholder={`Please enter ${field}`}
                // pattern={"[A-Za-z]+"}
                // minLength={2}
                onValueChanged={(e: any) => this._fieldChanged(e, item, field)}
            />
        </Grid>);
    }
    public fieldGridView(item: any, field: string, fieldTitle: string, isGrid?: any) {
        return (
            <NumberBoxInput
                stylingMode='underlined'
                label={fieldTitle}
                value={item.Grid[field]}
                min={3}
                max={12}
                placeholder={`Please enter ${field}`}
                onValueChanged={(e: any) => this._fieldChanged(e, item, field, isGrid)}
            />);
    }

    public returnInputField(item: any, i: any, parentItem?: any, isSectionField?: boolean, parentId?: any) {

        return (<Grid key={i} className={this.state.parentOpen ? (this.state.parentItem && this.state.parentItem.ID !== item.ID) ? 'fieldBlock dragCursor' : 'fieldBlock stopCursor' : 'fieldBlock'} item xs={12} sm={12} md={12}>
            <div
                className={i === this.state.activeFieldIndex ? 'editFieldContainer editActiveFieldBorder' : 'editFieldContainer'}>
                {this.fieldHeadingRow(item, i, isSectionField, parentItem, parentId)}
                <br />
                <Grid container spacing={1}>
                    {this.fieldQuestionTitleRow(item, "Label", "Label", parentItem)}
                    {item.Type !== 'CheckBox' && this.fieldQuestionTitleRow(item, "Placeholder", "Placeholder", parentItem)}
                    {this.fieldQuestionTitleRow(item, "addeditfield", "Add Edit Field", parentItem)}
                    {this.fieldQuestionTitleRow(item, "field", "Field", parentItem)}
                    {item.Type === 'Choices' && !item.IsLocalData && this.fieldQuestionTitleRow(item, "APIListName", "API List Name", parentItem)}
                </Grid>
                <hr className={"builderDivHRLine"} />
                <Grid container spacing={1}>

                    <Grid item xs={12} sm={6} md={3}>
                        {item.Type !== 'CheckBox' && this.fieldToggle(item, 'IsMandatory', 'Is Mandatory')}
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        {item.Type === 'Choices' && this.fieldToggle(item, 'IsMultiple', 'Is Multiple')}
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        {item.Type === 'Choices' && this.fieldToggle(item, 'IsLocalData', 'Is Local Data')}
                    </Grid>
                </Grid>
                {item.IsLocalData && (
                    <>
                        <hr className={"builderDivHRLine"} />
                        <h3>Choices:</h3>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={12} md={12}>
                                <div className={'mt-10'}>
                                    <Grid container spacing={2} >
                                        <Grid item xs={6} sm={5} md={5}>
                                            <div className="label">Title</div>
                                        </Grid>
                                        <Grid item xs={6} sm={5} md={5}>
                                            <div className="label">Code</div>
                                        </Grid>

                                    </Grid>
                                    {item.APIListNameItems && item.APIListNameItems.map((option: any, optIndex: any) => {
                                        return (
                                            <Grid container spacing={2}>

                                                <Grid item xs={12} sm={5} md={5}>
                                                    <TextBoxInput
                                                        stylingMode='underlined'
                                                        label=""
                                                        value={option.text}
                                                        placeholder={`Option ${optIndex + 1}`}
                                                        onValueChanged={(e: any) => this._optionFieldChanged(e, i, 'Title', item.ID, optIndex)}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={5} md={5}>

                                                    <TextBoxInput
                                                        stylingMode='underlined'
                                                        label=""
                                                        value={option.id}
                                                        placeholder={`Option ${optIndex + 1}`}
                                                        onValueChanged={(e: any) => this._optionFieldChanged(e, i, 'Code', item.ID, optIndex)}
                                                    />
                                                </Grid>
                                                <Grid item xs={4} sm={1} md={2}>
                                                    <div className="chocieOptionIconDiv">
                                                        <ArrowUpwardIcon className={optIndex === 0 ? 'chocieOptionIcons grey' : 'chocieOptionIcons hand'} onClick={() => this.moveOptionUp(i, optIndex)} aria-hidden="true"></ArrowUpwardIcon>

                                                        <ArrowDownwardIcon className={optIndex === item.APIListNameItems.length - 1 ? ' chocieOptionIcons grey' : ' chocieOptionIcons hand'} onClick={() => this.moveOptionDown(i, optIndex)} aria-hidden="true"></ArrowDownwardIcon>

                                                        <DeleteIcon className="red hand chocieOptionIcons" onClick={() => this.deleteOption(i, optIndex)} aria-hidden="true"></DeleteIcon>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        );
                                    })}
                                    <Grid container spacing={1}>
                                        <Grid item xs={12} sm={12} md={12}>
                                            {this.addOptionRow(item.ID, parentItem)}
                                        </Grid>
                                    </Grid>
                                </div>
                            </Grid>
                        </Grid>
                    </>
                )}
                <hr />
                <h3>Grid View </h3>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={4} md={4}>
                        {this.fieldGridView(item, "sm", "Small Size Device", true)}
                    </Grid>
                    <Grid item xs={12} sm={4} md={4}>
                        {this.fieldGridView(item, "md", "Medium Size Device", true)}
                    </Grid>
                    <Grid item xs={12} sm={4} md={4}>
                        {this.fieldGridView(item, "lg", "Large Size Device", true)}
                    </Grid>
                </Grid>
            </div>
            {this.state.showDropArea && !isSectionField ? this.renderDropZones(i, item) : null}
        </Grid>);
    }


    public addOptionRow(item: any, parentField?: any) {
        return (
            <Button variant="outlined" onClick={() => this.addOption(item, parentField)}>Add Option</Button>

        );
    }

    public fieldToggle(item: any, field: any, fieldTitle: any) {
        return (

            <CheckBoxInput
                onValueChanged={(newValue: any) => this._fieldChanged(newValue, item, field,)}
                value={item[field] ? item[field] : false}
                text={fieldTitle}
            />
        );
    }

    public renderQuestions() {
        if (this.state.fields && this.state.fields.length > 0) {
            return (<Grid container spacing={0}>
                {this.state.fields.map((item: any, i: any) => {
                    return (this.returnField(item, i));
                })}
            </Grid>);
        }
    }
    public renderDropZones(i: any, item: any) {
        return (
            <div onDragEnter={(e: any) => this.onDragEnter(e, i)} onDragLeave={(e: any) => this.onDragLeave(e, i)}>

                {this.state.addSection === i && this.state.addSelectionOn ?
                    <div className="droppable"
                        onDragOver={(e: any) => this.onDragOver(e)}
                        onDrop={(e: any) => this.onDrop(e)}
                        onClick={(e: any) => this.onDrop(e)}>
                        {this.state.fieldSelectedToMove ?
                            <h2>Click here to drop a section</h2> :
                            <h2>Drop a section here</h2>}
                    </div> :
                    <div className="alignCenter displayFlex dragDropLineArea" onClick={() => this.addSectionClick(i, item)}>
                        <div style={{ width: '48%' }}><hr className="dragDropLine" /></div>
                        <div style={{ width: '4%' }}><AddIcon></AddIcon></div>
                        <div style={{ width: '48%' }}><hr className="dragDropLine" /></div>
                    </div>}
            </div>
        );
    }


    public generateID() {

        let id;
        let lastId = this.getLastIdValue(this.state.fields, 'ID');
        if (lastId) {
            id = lastId.ID + 1
        } else {
            id = 1000 + (this.state.fields ? this.state.fields.length : 1);
        }

        return id;
    }
    public handleClonePopOver(event: React.MouseEvent<HTMLButtonElement>) {
        this.setState({
            anchorEl: event.currentTarget,
            showCloneMenu: true
        })
    }
    public handleCloseClonePopOver(event: React.MouseEvent<HTMLButtonElement>) {
        this.setState({
            anchorEl: null,
            showCloneMenu: false
        })
    }
    public cloneField(i: any, item: any, isSectionField?: any, parentItem?: any, parentId?: any) {
        let genratedID = this.generateID();
        let tempFields = JSON.parse(JSON.stringify(this.state.fields));
        let tempItem = { ...item };
        tempItem.ID = genratedID;

        this.setState({
            fields: tempFields
        });
    }
    private getLastIdValue(arr: any, prop: any) {
        var id;
        for (var i = 0; i < arr.length; i++) {
            if (id === null || parseInt(arr[i][prop]) > parseInt(id[prop]))
                id = arr[i];
        }
        return id;
    }
    public deleteField(index: any) {
        let tempFields = this.state.fields;
        tempFields.splice(index, 1);
        this.setState({
            fields: tempFields
        });
    }

    public deleteOption(i: any, optIndex: any) {
        let tempFields = this.state.fields;
        let optionArray = tempFields[i].APIListNameItems;
        optionArray.splice(optIndex, 1);
        this.setState({
            fields: tempFields
        });
    }
    public deleteChild(optIndex: any) {
        let tempFields = this.state.fields;
        let optionArray = tempFields[this.state.parentIndex].Children;
        optionArray.splice(optIndex, 1);
        this.setState({
            fields: tempFields
        });
    }
    public moveDown(i: any) {
        let tempFields = this.state.fields;
        if (i === tempFields.length - 1) {
            return;
        }
        [tempFields[i], tempFields[i + 1]] = [tempFields[i + 1], tempFields[i]];
        this.setState({ fields: tempFields });
    }

    public moveUp(i: any) {
        let tempFields = this.state.fields;
        if (i === 0) {
            return;
        }
        [tempFields[i], tempFields[i - 1]] = [tempFields[i - 1], tempFields[i]];
        this.setState({ fields: tempFields });
    }

    public moveOptionUp(i: any, optIndex: any) {
        let tempFields = this.state.fields;
        let optionArray = tempFields[i].APIListNameItems;
        if (optIndex === 0) {
            return;
        }
        [optionArray[optIndex], optionArray[optIndex - 1]] = [optionArray[optIndex - 1], optionArray[optIndex]];
        this.setState({ fields: tempFields });
    }

    public moveOptionDown(i: any, optIndex: any) {
        let tempFields = this.state.fields;
        let optionArray = tempFields[i].APIListNameItems;
        if (optIndex === optionArray.length - 1) {
            return;
        }
        [optionArray[optIndex], optionArray[optIndex + 1]] = [optionArray[optIndex + 1], optionArray[optIndex]];
        this.setState({ fields: tempFields });
    }

    private _fieldChanged(event: any, item: any, fieldProperty: string, isGrid?: any): void {
        let tempFieldsArray = this.state.fields;
        let tempField: any = {};

        tempField = tempFieldsArray.find((f: any) => f.ID === item.ID);
        if (fieldProperty && !isGrid) {
            tempField[fieldProperty] = event.value;
        }
        if (isGrid) {
            tempField.Grid[fieldProperty] = event.value;
        }
        this.setState({ fields: tempFieldsArray });
    }

    private _optionFieldChanged(newValue: any, index: any, fieldName: any, fieldID: any, optIndex: any, parentField?: any): void {
        // let tempFieldsArray = JSON.parse(JSON.stringify(this.state.fields));

        let tempFieldsArray = this.state.fields;
        let tempField: any = {};

        tempField = tempFieldsArray.find((f: any) => f.ID === fieldID);

        if (fieldName === 'Code') {
            tempField.APIListNameItems[optIndex].id = newValue.value;
        } else {
            tempField.APIListNameItems[optIndex].text = newValue.value;
        }

        this.setState({ fields: tempFieldsArray });
    }

    public addOption(fieldID: any, parentField?: any) {

        let tempFieldsArray = this.state.fields;
        let tempField: any = {};

        tempField = tempFieldsArray.find((f: any) => f.ID === fieldID);

        if (!tempField.APIListNameItems) {
            tempField.APIListNameItems = [];
        }
        tempField.APIListNameItems.push({
            id: '',
            text: '',
        });

        this.setState({
            fields: tempFieldsArray
        });
    }


    public alert() {
        return (
            <AlertDialog
                showDialog={this.state.showAlert}
                message={this.state.alertMessage}
                title={"worxOnline Forms"}
                onClose={() => this.successAlertClose()}
                hideAlertButton={this.state.hideAlertButton}
                showCreateButton={this.state.showCreateButton}
                link={this.state.createLink}
            />
        );
    }

    private _closeDialog = (): void => {
        this.setState({ showAlert: false });
    }


    public goBack() {
        window.history.back();
        // this.web.lists.add("Test List", "desc", 100, true).then((f:any) => {
        //   console.log(f);
        // });
    }


    public successAlertClose() {
        this.setState({ alertMessage: '', showAlert: false, loading: false });

    }


    public handleActionButton = (eventType: any) => {
        if (eventType.itemProps.text === 'Update') {
            this.setState({ showModal: true });
        }
        if (eventType.itemProps.text === 'Preview') {
            this.setState({ showPreview: true, builderPreview: true, callAfterInit: true })
        }
        if (eventType.itemProps.text === 'Back') {
            window.history.back();
        }
    };
    private _closeModal = (): void => {
        this.setState({ showModal: false });
    }

    public renderGridPanel() {
        return (
            <div>
                {/* <Panel
                    isOpen={this.state.showGridPanel}
                    type={PanelType.custom}
                    onDismiss={this._onCloseGridPanel}
                    headerText=''
                    closeButtonAriaLabel='Close'
                    customWidth='888px'
                    onOuterClick={() => { }}
                >
                    <Builder
                        gridDesignerMode={true}
                        onClick={(fields) => this.saveGridFields(fields)}
                        GridFields={this.state.GridFields}
                        GridTitle={this.state.GridTitle}
                    />
                </Panel> */}
            </div>
        );
    }

    public saveGridFields(gridFields: any) {

        let tempFields = this.state.fields;
        tempFields[this.state.parentOfGridIndex].GridFields = gridFields;
        this.setState({
            fields: tempFields,
            showGridPanel: false
        });
    }

    public openGridDesigner(item: any, i: any) {
        this.setState({ parentOfGridIndex: i, showGridPanel: true, GridFields: item.GridFields ? item.GridFields : [], GridTitle: item.Title ? item.Title : "" });
    }

    private _onCloseGridPanel = (): void => {
        this.setState({ showGridPanel: false });
    }
    public _onSearchSectionChanged = (searchtext: any): void => {

        let text = searchtext.value.toLowerCase();
        this.setState({ searchSection: text, renderSpFields: text ? this.renderSpFields.filter((i: any) => (i.name && i.name.toLowerCase().indexOf(text) > -1)) : this.renderSpFields });

    }
    public _onFormNameChanged = (event: any, fieldName: string) => {
        this.setState({ [fieldName]: event.value });
    }
    public addSectionClick(i: any, item: any) {
        this.setState({
            addSection: i,
            addSelectionOn: true
        });
    }
    public addToFieldJson(json: any, index: any, item: any, parentId?: any) {

        // Create a temp object and index variable
        var temp: any = [];
        // Loop through the original object
        if (json && json.length > 0) {
            json.forEach((element: any, i: any) => {

                temp.push(element);

                if (i === index) {
                    let genratedID = this.generateID();
                    temp.push({
                        Type: item.key,
                        ID: genratedID,
                        Label: "",
                        Placeholder: "",
                        StateValue: "",
                        Value: "",
                        addeditfield: "",
                        field: "",
                        Grid: { sm: 12, md: 12, lg: 6 }
                    });


                    // Disable
                    if (item.key === 'Text' || item.key === 'Choices') {
                        temp[temp.length - 1].ReadOnly = false;
                        temp[temp.length - 1].IsMandatory = false;
                    }

                    //Choice
                    if (item.key === 'Choices') {
                        temp[temp.length - 1].APIListNameItems = [];
                        temp[temp.length - 1].APIListName = '';
                        temp[temp.length - 1].IsLocalData = false;
                        temp[temp.length - 1].IsMultiple = false;
                    }
                }
            });

        } else {
            let genratedID = this.generateID();
            temp.push({
                Type: item.key,
                ID: genratedID,
                Label: "",
                Placeholder: "",
                StateValue: "",
                Value: "",
                addeditfield: "",
                field: "",
                Grid: { sm: 12, md: 12, lg: 6 }
            });


            // Disable
            if (item.key === 'Text' || item.key === 'Choices') {
                temp[temp.length - 1].ReadOnly = false;
                temp[temp.length - 1].IsMandatory = false;
            }

            //Choice
            if (item.key === 'Choices') {
                temp[temp.length - 1].APIListNameItems = [];
                temp[temp.length - 1].APIListName = '';
                temp[temp.length - 1].IsLocalData = false;
                temp[temp.length - 1].IsMultiple = false;
            }
        }
        return temp;
    }
    public onDoubleClickField(item: any) {

        this.setState({
            showDropArea: true,
            fieldSelectedToMove: true,
            fieldSelectedName: item,
        });
    }
    public onDragStart = (ev: any, id: any) => {
        ev.dataTransfer.setData("id", id);
        this.setState({ showDropArea: true });
    }
    public onDragOver = (ev: any) => {
        ev.preventDefault();

    }
    public onDragEnter = (ev: any, i: any) => {
        this.setState({
            addSection: i,
            addSelectionOn: true
        });
    }
    public onDragLeave = (ev: any, i: any) => {
        this.setState({
            // addSection: i,
            addSelectionOn: false
        });
    }

    public onDrop = (ev: any) => {
        let id = this.state.fieldSelectedToMove ? this.state.fieldSelectedName : ev.dataTransfer.getData("id");

        let tempItem = this.renderSpFields.filter((s: any) => s.name === id);
        let item = tempItem && tempItem.length > 0 ? tempItem[0] : '';

        let temp = this.state.fields ? this.state.fields : [];


        let tempField = this.addToFieldJson(temp, this.state.addSection, item);

        this.setState({
            fields: tempField,
            addSelectionOn: false,
            showDropArea: false,
            fieldSelectedToMove: false,
        });
    }
    public onDropSection(ev: any, parent: any, aboveItem?: any, parentId?: any) {
        let id = this.state.fieldSelectedToMove ? this.state.fieldSelectedName : ev.dataTransfer.getData("id");

        let tempItem = this.renderSpFields.filter((s: any) => s.name === id);
        let item = tempItem && tempItem.length > 0 ? tempItem[0] : '';

        let temp = parent.Template;

        let tempTemplate = this.addToFieldJson(temp, aboveItem, item, parent.ID);
        parent.Template = tempTemplate;
        let tempFields = this.state.fields ? this.state.fields : [];
        tempFields[parentId] = parent;

        this.setState({
            fields: tempFields,
            addSelectionOn: false,
            showDropArea: false
        });
    }
    public saveData() {
        this.props.onSave(this.state.fields)
    }


    public render(): React.ReactElement<any> {
        return (
            <div className="builder" >
                {this.state.showAlert && this.alert()}
                <div className='alignLeft'>
                    <Button variant='outlined' className='button' color="primary" onClick={() => this.saveData()} >
                        Save
                    </Button>
                </div>
                <div className="builderContent">
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={12} md={2} >
                            <div className="draggableDiv" style={{ height: '70vh' }}>
                                <Grid container spacing={1}>
                                    {this.state.renderSpFields && this.state.renderSpFields.map((item: any, i: any) => {
                                        return (
                                            <Grid item xs={6} sm={6} md={12} lg={12}>
                                                <Paper className={this.state.fieldSelectedToMove && (this.state.fieldSelectedName === item.name) ? "formpaper draggableSelected " : "formpaper"} elevation={3} >
                                                    <div key={item.name}
                                                        onDragStart={(e: any) => this.onDragStart(e, item.name)}
                                                        draggable={!this.state.parentOpen}
                                                        onDoubleClick={() => this.onDoubleClickField(item.name)}
                                                        onClick={() => this.setState({ fieldSelectedToMove: false })}
                                                        className={"draggable"}
                                                    >
                                                        <div className=" builderIconDiv">
                                                            <i className={`dx-icon-${item.icon} builderIcon dx-icon-custom-style`}></i>
                                                        </div>
                                                        <div className="builderLable">
                                                            {item.name}
                                                        </div>
                                                    </div>
                                                </Paper>
                                            </Grid>
                                        );
                                    })}
                                </Grid>
                            </div>

                        </Grid>
                        <Grid item xs={12} sm={12} md={10} >
                            {!this.state.loading ?
                                <>
                                    {(this.state.fields && this.state.fields.length > 0) ?
                                        <div className="droppableDiv" style={{ height: '70vh' }}>

                                            {this.renderQuestions()}
                                        </div> :
                                        <>

                                            <div>

                                                <div className="droppableIntial"
                                                    onDragOver={(e: any) => this.onDragOver(e)}
                                                    onDrop={(e: any) => this.onDrop(e)}
                                                    onClick={(e: any) => this.onDrop(e)}>
                                                    {this.state.fieldSelectedToMove ?
                                                        <h2>Click here to drop a section</h2> :
                                                        <h2>Drop a section here</h2>}
                                                </div>
                                            </div>
                                        </>}

                                </> :
                                <Spinner size='large' type={'converging-spinner'} className="loader" />}
                        </Grid>

                    </Grid>

                    {this.state.showAlert && this.alert()}
                </div>

            </div>
        );
    }

}