import * as React from 'react';
import utility from '../../../classes/utility';
import { Config } from '../../../classes/config';
import DataTable from '../../common/DataTable/DataTable';
import AlertDialog from '../../common/alertDialog';
import DeleteDialog from '../../common/DeleteAlert';
import Spinner from '../../common/reusableComponents/Spinner';
import AddEditStatus from './AddEditStatus';
import global from '../../../classes/global';
let _items: any = [];

export default class Status extends React.Component<any, any> {

  public currentUser: any;
  public columns: any;

  constructor(props: any) {
    super(props);

    this.DeleteDialog = this.DeleteDialog.bind(this);

    this.currentUser = this.props.currentUser;
    if (this.props.role !== global.worxOnline_Admin) {
      window.open('#/AccessDenied', '_self');
    }

    this.columns = [
      {
        field: 'id',
        title: 'ID',
        width: 80,
        type: 'string',
        allowHiding: false,
      },
      {
        field: 'title',
        title: 'Title',
        // width: 160,
        type: 'string',
        allowHiding: false,
      },
      {
        field: 'description',
        title: 'Description',
        // width: 160,
        type: 'string',
        allowHiding: false,
      },
      // {
      //   field: 'StatusType',
      //   title: 'Status Type',
      //   // width: 160,
      //   type: 'string',
      //   allowHiding: false,
      // },
      {
        field: 'isActive',
        title: 'Active',
        // width: 160,
        type: 'boolean',
        allowHiding: false,
      },
      {
        field: 'updatedOn',
        title: 'Updated',
        width: 160,
        type: 'datetime',
        visibleColumn: false,
        allowHiding: true,
        sortOrder: 'desc'
      },
      {
        field: 'updatedBy',
        title: 'Updated By',
        width: 160,
        type: 'string',
        visibleColumn: false,
        allowHiding: true
      },
      {
        field: 'createdOn',
        title: 'Created',
        width: 160,
        type: 'datetime',
        visibleColumn: false,
        allowHiding: true
      },
      {
        field: 'createdBy',
        title: 'Created By',
        width: 160,
        type: 'string',
        visibleColumn: false,
        allowHiding: true
      },
    ];
    this.state = {
      items: _items,
      columns: this.columns,
    };
  }

  public componentDidMount() {
    this._getItemData();
  }
  public DeleteItem(item: any) {
    //console.log("item", item);

    this.setState({
      alertMessage: <div className={'alertLoader'}><Spinner size='large' label="Loading..." /></div>,
      hideAlertButton: true,
      showAlert: true,
      showDeleteAlert: false,
    });
    let dataToPassToService: any = {};
    dataToPassToService.id = item;
    utility.genericDeleteAPICall(Config.statuslist, item).then((data: any) => {
      this.setState({
        alertMessage: 'Status has been deleted.',
        showAlert: true,
        showDeleteAlert: false,
        hideAlertButton: false,
        loading: false
      });
      this._getItemData();
    }, (err) => {
      console.log(err);
      this.setState({
        alertMessage: 'Cannot delete Status. Please try again later.',
        showAlert: true,
        showDeleteAlert: false,
        hideAlertButton: false,
      });
    });

  }

  // edit activty modal

  private EditItemModal(item: any) {
    //console.log("item", item);
    this.setState({ showModal: true, details: item, mode: 'edit' });
  }
  public renderNewModal() {
    return (
      <AddEditStatus
        showModal={this.state.showModal}
        onClose={() => this._closeModal()}
        onClick={() => this._getItemData()}
        message={(e: any) => this.message(e)}
        currentUser={this.currentUser}
        details={this.state.details}
        mode={this.state.mode}
      />
    );
  }

  private _showModal = (): void => {
    this.setState({ showModal: true, details: null, mode: 'add' });
  }
  private _closeModal = (): void => {
    this.setState({ showModal: false });
  }

  public message(message: any) {
    //console.log(message);
    this.setState({
      alertMessage: message + ' successfully',
      showAlert: true
    });
  }
  private _getItemData() {

    this.setState({
      loading: true
    });
    utility.genericGetAPICallForList(Config.statuslist, 'GET').then((data: any) => {
      _items = [];
      //console.log(data);
      for (let r of data) {
        _items.push({
          id: r.id,
          title: r.title,
          description: r.description,
          // StatusType: r.Statustype,
          isActive: r.isActive ? true : false,
          updatedBy: r.updatedBy,
          updatedOn: r.updated,
          createdBy: r.createdBy,
          createdOn: r.created,

        });
      }
      _items = utility._sortItems(_items, 'StatusType', false);

      this.setState({ items: _items, callAfterInit: true, loading: false });
      //console.log(_items);
    }, (err) => {
      console.log(err);
      this.setState({ loading: false });
    });
  }

  public setSelection = (id: any) => {
    this.setState({
      selectedRow: id
    });
  }
  private stopCommsAfterInit = (): void => {
    this.setState({ callAfterInit: false });
  }

  private _sortItems = (items: any[], sortBy: string, descending = false): any[] => {
    if (descending) {
      return items.sort((a: any, b: any) => {
        if (a[sortBy] < b[sortBy]) {
          return 1;
        }
        if (a[sortBy] > b[sortBy]) {
          return -1;
        }
        return 0;
      });
    } else {
      return items.sort((a: any, b: any) => {
        if (a[sortBy] < b[sortBy]) {
          return -1;
        }
        if (a[sortBy] > b[sortBy]) {
          return 1;
        }
        return 0;
      });
    }
  }

  public render(): React.ReactElement<any> {
    return (
      <React.Fragment>
        {this.renderNewModal()}
        {this.state.showDeleteAlert && this.DeleteAlert()}
        {this.state.showAlert && this.alert()}
        <div className={"SearchDiv"}>
          <div className={'headingDiv'}>
            <span className={"heading"}> Status </span>
          </div>
        </div>
        <div>
          {!this.state.loading ?
            <DataTable
              style={{ width: '100%' }}
              filteralbe={true}
              groupable={true}
              rows={this.state.items}
              columns={this.state.columns}
              fileName={'Status'}
              columnChooser={'statusColumns'}
              selectionMode={false}
              showHideColumnMenu={true}
              callAfterInit={this.state.callAfterInit}
              stopCommsAfterInit={() => this.stopCommsAfterInit()}
              loading={this.state.loading}
              allowExportSelectedData={false}
              //showNewModal={() => this._showModal()}
              hideCommandDeleteBtn={true}
              hideCommandEditBtn={true}
              // viewFile={(e:any)=>this.viewFile(e)}
              showCommandBtn={true}
              showNewBtn={false}
              hideNewBtn={true}
              showViewDetailBtn={false}
            /> :
            <Spinner size='large' label="Loading.." />
          }

        </div>
      </React.Fragment>
    );
  }
  public alert() {
    return (
      <AlertDialog
        showDialog={this.state.showAlert}
        message={this.state.alertMessage}
        title={Config.productTitle}
        hideAlertButton={this.state.hideAlertButton}
        onClose={() => this.setState({ alertMessage: '', showAlert: false })}
      />
    );
  }
  public DeleteDialog(item: any) {
    this.setState({
      DeletealertMessage: 'Are you sure you want to delete?',
      showDeleteAlert: true,
      deleteItem: item
    });
  }
  public DeleteAlert() {
    return (
      <DeleteDialog
        showDialog={this.state.showDeleteAlert}
        message={this.state.DeletealertMessage}
        title={"worxOnline"}
        onClose={() => this.setState({ DeletealertMessage: '', showDeleteAlert: false })}
        onOkButton={() => this.DeleteItem(this.state.deleteItem)}
        onCancelButton={() => this.setState({ DeletealertMessage: '', showDeleteAlert: false })}
      />
    );
  }
}