import { Configuration, PopupRequest, LogLevel } from "@azure/msal-browser";
import global from './global';

export const b2cPolicies = {
    names: {
        signUpSignIn: "B2C_1_SignupAndSignin",
        forgotPassword: "<Forgot Password Name - in the form of B2C_1_xxx>",
        editProfile: "<Edit Profile Name - in the form of B2C_1_xxx>"
    },
    authorities: {
        signUpSignIn: {
            authority: global.singInB2C,
        },
        forgotPassword: {
            authority: "https://w0rx.b2clogin.com/w0rx.onmicrosoft.com/<Forgot Password Name - in the form of B2C_1_xxx>",
        },
        editProfile: {
            authority: "https://w0rx.b2clogin.com/w0rx.onmicrosoft.com/<Edit Profile Name - in the form of B2C_1_xxx>"
        }
    },
    authorityDomain: global.singInB2CKnownAuthority
}
// Config object to be passed to MSAL on creation
export const msalConfig: Configuration = {
    auth: {
        clientId: global.AzureClientAppId,
        authority: b2cPolicies.authorities.signUpSignIn.authority,
        knownAuthorities: [b2cPolicies.authorityDomain],
        redirectUri: "/",
        postLogoutRedirectUri: "/",
        navigateToLoginRequestUrl: false,
        // clientSecret: "~o08Q~556VOo0HjPbK_S6I-bUaNwy_d.JfmO-cnR"
    },
    cache: {
        // cacheLocation: "sessionStorage",
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: true,
    },
    system: {
        loggerOptions: {
            loggerCallback: (level: any, message: any, containsPii: any) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        //console.info(message);		
                        return;
                    case LogLevel.Verbose:
                        //console.debug(message);		
                        return;
                    case LogLevel.Warning:
                        //console.warn(message);		
                        return;
                    default:
                        return;
                }
            }
        }
    }
};

// Add here scopes for id token to used at MS Identity platforms endpoints
export const loginRequest: PopupRequest = {

    scopes: []
};

// Add here the endpoints for MS Graph API services you would like to use
export const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft.com/v1.0/me"
}