import * as React from 'react';
import SelectBox from 'devextreme-react/select-box';

interface Props {
    label?: string;
    stylingMode: string | any;
    placeholder?: string | any;
    displayExpr: string | any;
    valueExpr?: string | any;
    grouped?: Boolean | any;
    selectedItem: any;
    showClearButton?: Boolean | any;
    readOnly?: Boolean | any;
    disabled?: Boolean | any;
    onSelectionChanged: any;
    required?: any;
    items: any;
    spellcheck?: Boolean | any;
}

interface State {
    selectedItem: any;
}

export default class SelectBoxUpdated extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            selectedItem: this.props.selectedItem
        };
    }

    componentDidUpdate(prevProps: Props) {
        if (prevProps.selectedItem !== this.props.selectedItem) {
            this.setState({ selectedItem: this.props.selectedItem });
        }
    }

    render(): React.ReactElement<any> {
        return (
            <SelectBox
                dataSource={this.props.items}
                stylingMode={this.props.stylingMode}
                placeholder={this.props.placeholder}
                value={this.state.selectedItem}
                onSelectionChanged={this.props.onSelectionChanged}
                showClearButton={this.props.showClearButton}
                displayExpr={this.props.displayExpr}
                searchEnabled={true}
                searchMode={'contains'}
                searchExpr={"text"}
                label={this.props.label}
                labelMode={'floating'}
                grouped={this.props.grouped}
                readOnly={this.props.readOnly}
                disabled={this.props.disabled}
            />
        );
    }
}
