import * as React from 'react';
import utility from '../../../classes/utility';
import { Config } from '../../../classes/config';
import DataTable from '../../common/DataTable/DataTable';
import AlertDialog from '../../common/alertDialog';
import DeleteDialog from '../../common/DeleteAlert';
import Spinner from '../../common/reusableComponents/Spinner';
import AddEditSwms from './AddEditSwms';
import global from '../../../classes/global';
let _items: any = [];

export default class Swms extends React.Component<any, any> {

  public currentUser: any;
  public columns: any;

  constructor(props: any) {
    super(props);

    this.viewDocument = this.viewDocument.bind(this);
    this.DeleteDialog = this.DeleteDialog.bind(this);

    this.currentUser = this.props.currentUser;
    if (this.props.role !== global.worxOnline_Admin) {
      window.open('#/AccessDenied', '_self');
    }
    this.columns = [

      {
        field: 'id',
        title: 'ID',
        width: 70,
        type: 'string',
        allowHiding: true,
      },
      {
        field: 'docNum',
        title: 'Document #',
        width: 120,
        type: 'string',
        allowHiding: true,
      },
      {
        field: 'name',
        title: 'Name',
        width: 400,
        type: 'string',
        allowHiding: false,
        link: 'file',
      },
      {
        field: 'title',
        title: 'Title',
        width: 280,
        type: 'string',
        allowHiding: true,
      },
      {
        field: 'department',
        title: 'Department',
        width: 200,
        type: 'string',
        allowHiding: false,
      },
      {
        field: 'fileURL',
        title: 'File Url',
        width: 100,
        type: 'string',
        visble: false,
        allowHiding: true
      },
      {
        field: 'isActive',
        title: 'Active',
        // width: 160,
        type: 'boolean',
      },
      {
        field: 'updatedOn',
        title: 'Updated',
        width: 160,
        type: 'datetime',
        visibleColumn: false,
        allowHiding: true,
        sortOrder: 'desc'
      },
      {
        field: 'updatedBy',
        title: 'Updated By',
        width: 160,
        type: 'string',
        visibleColumn: false,
        allowHiding: true
      },
      {
        field: 'createdOn',
        title: 'Created',
        width: 160,
        type: 'datetime',
        visibleColumn: false,
        allowHiding: true
      },
      {
        field: 'createdBy',
        title: 'Created By',
        width: 160,
        type: 'string',
        visibleColumn: false,
        allowHiding: true
      },
    ];
    this.state = {
      items: _items,
      columns: this.columns,
    };
  }

  public componentDidMount() {
    this._getItemData();
  }

  public DeleteItem(item: any) {
    //console.log("item", item);


    this.setState({
      alertMessage: <div className={'alertLoader'}><Spinner size='large' label="Loading..." /></div>,
      hideAlertButton: true,
      showAlert: true,
      showDeleteAlert: false,
    });
    let dataToPassToService: any = {};
    dataToPassToService.id = item;
    utility.genericDeleteAPICall(Config.swmsDocumentList,
      item
    )
      .then(
        (data: any) => {
          this.setState({
            alertMessage: "Swms has been deleted.",
            showAlert: true,
            showDeleteAlert: false,
            hideAlertButton: false,
            loading: false
          });
          this._getItemData();
        },
        (err) => {
          console.log(err);
          this.setState({
            alertMessage: "Cannot delete swms. Please try again later.",
            showAlert: true,
            showDeleteAlert: false,
            hideAlertButton: false,
          });
        }
      );

  }

  // edit activty modal

  private EditItemModal(item: any) {
    //console.log("item", item);
    this.setState({ showModal: true, details: item, mode: 'edit' });
  }
  public renderNewModal() {
    return (
      <AddEditSwms
        showModal={this.state.showModal}
        onClose={() => this._closeModal()}
        onClick={() => this._getItemData()}
        message={(e: any) => this.message(e)}
        currentUser={this.currentUser}
        mode={this.state.mode}
        details={this.state.details}
        role={this.props.role}
        Department={this.props.Department}
      />
    );
  }
  private _showModal = (): void => {
    this.setState({ showModal: true, details: null, mode: 'add' });
  };
  private _closeModal = (): void => {
    this.setState({ showModal: false });
  };

  public message(message: any) {
    //console.log(message);
    this.setState({
      alertMessage: message + " successfully",
      showAlert: true,
    });
  }

  public viewDocument(file: any) {
    let item = file;

    if (file.isExternalUrl) {
      window.open(file.fileURL, "_blank");
    } else {
      let dataToPassToService: any = {};
      if (!utility.validURL(item.fileURL) || !item.name) {
        this.setState({
          alertMessage: 'Unable to view this document. \n File URL or File name does exist',
          showAlert: true
        });
        return;
      }
      dataToPassToService.fileName = item.name;
      dataToPassToService.fileUrl = item.fileURL;
      utility.genericPostAPIcallForDowloadDoct(Config.dowloadDocumentList, dataToPassToService).then((doc: any) => {

        window.open(doc, "_blank");
      });
    }
  }

  private _getItemData() {
    this.setState({
      loading: true,
    });
    utility.genericGetAPICallForList(Config.searchswmsDocumentList)
      .then(
        (data: any) => {
          _items = [];

          data.forEach((r: any) => {
            let departmentTitle = '';
            let departmentIds;

            if (r.departments && r.departments.length > 0) {
              departmentTitle = r.departments.map((dept: any) => dept.title).join(',');
              departmentIds = r.departments.map((dept: any) => dept.id).join(',');
            }

            _items.push({
              id: r.id,
              title: r.title,
              name: r.fileName,
              docNum: r.documentNumber,
              fileURL: r.fileUrl || '',
              departmentId: departmentIds,
              department: departmentTitle,
              departmentIds: r.departmentIds,
              isExternalUrl: r.isExternalUrl,
              isActive: r.isActive,
              updatedBy: r.updatedBy,
              updatedOn: r.updated,
              createdBy: r.createdBy,
              createdOn: r.created,
            });
          });

          _items.sort((a: any, b: any) => (a.updatedOn > b.updatedOn ? 1 : -1));

          this.setState({ items: _items, callAfterInit: true, loading: false });


        },
        (err) => {
          console.log(err);
          this.setState({ loading: false });
        }
      );
  }

  private stopCommsAfterInit = (): void => {
    this.setState({ callAfterInit: false });
  }

  public render(): React.ReactElement<any> {
    const filterValue = [['isActive', '=', true]];
    return (
      <React.Fragment >
        {this.renderNewModal()}
        {this.state.showDeleteAlert && this.DeleteAlert()}
        {this.state.showAlert && this.alert()}
        <div className={"SearchDiv"}>
          <div className={'headingDiv'}>
            <span className={"heading"}> SWMS </span>
          </div>
        </div>
        <div>
          {!this.state.loading && this.state.items ?
            <DataTable
              style={{ width: '100%' }}
              filteralbe={true}
              groupable={true}
              rows={this.state.items}
              columns={this.state.columns}
              fileName={'SWMS'}
              columnChooser={'swmsColumns'}
              selectionMode={false}
              showHideColumnMenu={true}
              callAfterInit={this.state.callAfterInit}
              stopCommsAfterInit={() => this.stopCommsAfterInit()}
              loading={this.state.loading}
              allowExportSelectedData={false}
              showNewModal={() => this._showModal()}
              deleteItem={(a: any) => this.DeleteDialog(a)}
              editItem={(a: any) => this.EditItemModal(a)}
              viewFile={(e: any) => this.viewDocument(e)}
              showCommandBtn={true}
              showNewBtn={true}
              hideNewBtn={this.state.showModal || this.state.showModal || this.props.hideNewBtn}
              showViewDetailBtn={false}
              defaultfiltervalue={filterValue}
            /> :
            <Spinner size='large' label="Loading.." />
          }

        </div>
      </React.Fragment >
    );
  }
  public alert() {
    return (
      <AlertDialog
        showDialog={this.state.showAlert}
        message={this.state.alertMessage}
        title={Config.productTitle}
        hideAlertButton={this.state.hideAlertButton}
        onClose={() => this.setState({ alertMessage: '', showAlert: false })}
      />
    );
  }
  public DeleteDialog(item: any) {
    this.setState({
      DeletealertMessage: 'Are you sure you want to delete?',
      showDeleteAlert: true,
      deleteItem: item
    });
  }
  public DeleteAlert() {
    return (
      <DeleteDialog
        showDialog={this.state.showDeleteAlert}
        message={this.state.DeletealertMessage}
        title={"worxOnline"}
        onClose={() => this.setState({ DeletealertMessage: '', showDeleteAlert: false })}
        onOkButton={() => this.DeleteItem(this.state.deleteItem)}
        onCancelButton={() => this.setState({ DeletealertMessage: '', showDeleteAlert: false })}
      />
    );
  }
}