import * as React from 'react';
import moment from 'moment';
import utility from '../../classes/utility';
import { Config } from '../../classes/config';
import AlertDialog from '../common/alertDialog';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import Paper from '@material-ui/core/Paper';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import AssetSearch from '../AssetSearch/AssetSearch';
import AssetMap from '../AssetSearch/AssetMap';
import Spinner from '../common/reusableComponents/Spinner';
import SelectBoxInput from '../common/reusableComponents/SelectBox';
import CheckBoxInput from '../common/reusableComponents/CheckBox';
import TextBoxInput from '../common/reusableComponents/TextBox';
import DateBoxInput from '../common/reusableComponents/DateBox';
import TextAreaInput from '../common/reusableComponents/TextArea';
import DataGrid, { Column, Scrolling, Sorting, LoadPanel } from 'devextreme-react/data-grid';
import RadioGroupInput from '../common/reusableComponents/RadioGroup';
import NumberBoxInput from '../common/reusableComponents/NumberBox';


let _items: any = [];
const options: any = [
  { value: 'Maintenance', label: 'Maintenance' },
  { value: 'Inspection', label: 'Inspection' },
];
const optionsofSingle: any = [
  { value: 'A', label: 'One work order for all assets', disabled: true },
  { value: 'B', label: 'Work order per asset' },
];
// const optionsofGenerateImmediately: IChoiceGroupOption[] = [
//   { id: 'A', text: 'Scheduled run' },
//   { id: 'B', text: 'Generate work order immediately', disabled: this.state.generateWO? this.state.generateWO:this.state.generateWO},
// ];


const Period: any = [
  { id: '1', text: 'Day' },
  { id: '7', text: 'Week' },
  { id: '30', text: 'Month' }
];
const ScheduleType: any = [
  { id: 'Time', text: 'Time' },
  // { id: 'Counter', text: 'Counter' }
];


export default class EditscheduleWork extends React.Component<any, any> {

  public Assets: any;
  //public AssetsonMap;
  public suburbItems: any = [];
  public crewItems: any = [];
  public worktypeItems: any = [];
  public currentUser: any;
  public departmentItems: any = [];
  public teamsItems: any = [];
  public priorityItems: any = [];
  public inspectionFormItems: any = [];
  public StatusItems: any = [];
  public scheduelWOID: any;
  public scheduelCODE: any;

  constructor(props: any) {
    super(props);

    this.suburbItems = [];
    this.departmentItems = [];
    this.teamsItems = [];
    this.crewItems = [];
    this.worktypeItems = [];
    this.Assets = [];
    this.priorityItems = [];
    this.inspectionFormItems = [];
    // this.AssetsonMap = [];
    // this.attachmentBase64Array = [];
    // this.clearFilter = this.clearFilter.bind(this);
    this.clearAsset = this.clearAsset.bind(this);
    // this.up = this.createWorkOrder.bind(this);
    this.updateScheduleWorkOrder = this.updateScheduleWorkOrder.bind(this);
    this.createLineItems = this.createLineItems.bind(this);
    this.SearchClicked = this.SearchClicked.bind(this);
    this.generateTask = this.generateTask.bind(this);
    //console.log(props.context.pageContext.web.absoluteUrl);

    this.currentUser = this.props.currentUser;
    //console.log(this.currentUser);
    const _optionsofGenerateImmediately: any = [
      { value: 'A', label: 'Scheduled run', disabled: true },
      { value: 'B', label: 'Generate work order immediately' },
    ];

    this.state = {
      lineItems: _items,
      optionsofGenerateImmediately: _optionsofGenerateImmediately,
      location: '',
      priority: null,
      woworktype: null,
      title: '',
      Eststartdate: null,
      Estenddate: null,
      befpicreq: false,
      aftpicreq: false,
      riskassessment: false,
      crew: '',
      comments: '',
      description: '',
      // Assets: this.Assets,
      //  AssetsonMap: this.AssetsonMap,
      showPanel: false,
      folderName: "",
      departmentID: "",
      disableTeam: true,
      disableCrew: true,
      selected: 0,
      scheduleWOListCount: 0

    };
  }



  public componentDidMount() {
    let search = this.props.location.search;
    let params = new URLSearchParams(search);
    let scheduelID = params.get('ID');
    let scheduleCode = params.get('code');
    this.scheduelWOID = scheduelID;
    this.scheduelCODE = scheduleCode;
    this._getCrewDataAsync();
    this._getworkTypeDataAsync();
    this.getAllDatefromScheduleWO();
    this._getPrioritiesData();
    this._getsuburbDataAsync();
    this.getWOofScheduledWork();
    this._getInspectionFormData();
  }

  public clearAsset() {
    this.Assets = [];
    this.setState({ Assets: [] });
  }
  private _getInspectionFormData() {
    utility.genericGetAPICall(Config.InspectionFormList).then((data: any) => {
      //console.log(data);
      this.inspectionFormItems = [];

      for (let r of data) {
        this.inspectionFormItems.push({ id: (r.Id), text: (r.Title) });
      }
      this.setState({
        inspectionFormItems: this.inspectionFormItems,
        allinspectionFormItems: this.inspectionFormItems,
      });
    }, (err) => {
      console.log(err);
    });
  }
  private _getsuburbDataAsync() {
    utility.genericGetAPICall(Config.suburblistname).then((data: any) => {
      this.suburbItems = [];
      //console.log("suburb result", data);
      for (let r of data) {
        this.suburbItems.push({ id: r.Id, text: r.Title });
      }
      this.setState({
        suburbItems: this.suburbItems,
        allsuburbItems: this.suburbItems,
      });
    });
  }

  private _getPrioritiesData() {
    utility.genericGetAPICall(Config.PriorityListName).then((data: any) => {
      //console.log(data);
      this.priorityItems = [];

      for (let r of data) {
        this.priorityItems.push({ id: (r.Id), text: (r.Title) });
      }
      this.setState({ priorityItems: this.priorityItems });
    }, (err) => {
      console.log(err);
    });
  }

  private _getDepartmentData(selectedDept: any) {

    utility.genericGetAPICall(Config.DepartmentList).then((data: any) => {
      //console.log(data);
      this.departmentItems = [];

      for (let r of data) {
        this.departmentItems.push({ id: r.id, text: r.title });
        if (r.Id === selectedDept) {
          this.setState({ department: { id: r.id, text: r.title } });
        }
      }
      this.setState({
        departmentItems: this.departmentItems,
        alldepartmentItems: this.departmentItems,
      });
    }, (err) => {
      console.log(err);
    });
  }
  private _getTeamDataAsync(selectedTeam: any) {

    utility.genericGetAPICall(Config.TeamList).then((data: any) => {
      //console.log(data);
      this.teamsItems = [];

      for (let r of data) {
        this.teamsItems.push({ id: r.Id, text: (r.Code + ' - ' + r.Title), departmentId: r.Departmentid });
        if (r.Id === selectedTeam) {
          this.setState({ teams: { id: r.Id, text: (r.Code + ' - ' + r.Title), departmentId: r.Departmentid } });
        }
      }
      this.setState({ teamsItems: this.teamsItems, tempTeamItems: this.teamsItems });
    }, (err) => {
      console.log(err);
    });
  }
  private _getCrewDataAsync() {

    utility.genericGetAPICall(Config.crewlistname).then((data: any) => {
      //console.log(data);
      this.crewItems = [];

      for (let r of data) {
        this.crewItems.push({ id: (r.id), text: (r.code + ' - ' + r.title), teamId: r.teamid });
      }
      this.setState({ crewItems: this.crewItems, tempCrewItems: this.crewItems });
    }, (err) => {
      console.log(err);
    });
  }
  private _getworkTypeDataAsync() {

    utility.genericGetAPICall(Config.WTlistname).then((data: any) => {
      //console.log(data);
      this.worktypeItems = [];

      for (let r of data) {
        this.worktypeItems.push({
          key: r.Id,
          text: (r.Code + ' - ' + r.Title),
          befpicreqvalue: r.IsBeforePhotoRequired,
          aftpicreqvalue: r.IsAfterPhotoRequired
        });
      }

      this.worktypeItems = utility._sortItems(this.worktypeItems, 'text', false);
      this.setState({
        worktypeItems: this.worktypeItems,
        allworktypeItems: this.worktypeItems,
      });
    }, (err) => {
      console.log(err);
    });
  }
  public previousPage() {
    window.history.back();
  }
  public getWOofScheduledWork() {

    let dataToPassToService: any = {};
    dataToPassToService.Scheduleid = this.scheduelWOID;
    utility.genericPostAPIcall(Config.WOdetailsSearchAPI, dataToPassToService).then((results: any) => {

      let tempScheduleWOItems: any = [];
      results.forEach((item: any) => {
        tempScheduleWOItems.push({
          Id: item.Id,
          title: item.Title,
          Status: item.Workorderstatus,
          WOnumber: item.Wonumber,
          startDate: moment(item.Datescheduledstart).format("DD/MM/YY h:mm a"),
          startDateTemp: item.Datescheduledstart,
          endDate: moment(item.Datescheduledend).format("DD/MM/YY h:mm a"),
          // lineItemNo: item.LineItemNo,
        })
      });
      tempScheduleWOItems = utility._sortItems(tempScheduleWOItems, 'startDateTemp', false);
      this.setState({
        scheduleWOList: tempScheduleWOItems,
        scheduleWOListCount: tempScheduleWOItems.length
      })
    }, (err) => {
      console.log(err);
    });

  }
  public getAllDatefromScheduleWO() {
    //console.log(this.scheduelWOID);
    let dataToPassToService: any = {};
    // utility.genericGetDataByIdAPICall( Config.scheduledworklistname, this.scheduelWOID).then((item: any) => {
    dataToPassToService.id = +this.scheduelWOID;
    utility.genericPostAPIcall(Config.WOSearchScheduleAPI, dataToPassToService).then((results: any) => {

      //console.log(JSON.stringify(results));
      results.forEach((item: any) => {
        var JSONAssetData;
        if (item.Assets !== null) {
          JSONAssetData = JSON.parse(item.Assets);
          if (!Array.isArray(JSONAssetData)) {

            JSONAssetData = [JSONAssetData];
          }
        }
        this.setState({
          // loading: false,
          // location: item.Location,
          title: item.Title,
          id: item.Id,
          scheduleCode: item.Code,
          activity: item.ActivityCode,
          priority: { id: item.Priorityid, text: item.Priority },
          // teams: { id: item.Teamid, text: item.Teamid },
          // department: { id: item.Departmentid, text: item.Departmnentid },
          crew: { id: item.Crewid, text: item.Crewtitle },
          plannedHours: item.Plannedduration,
          woworktype: { id: item.Workordertypeid, text: item.Workordertypeid },
          period: { id: item.Recurrenceperiod === "Days" ? "1" : item.Recurrenceperiod === "Week" ? "7" : "30", text: item.Recurrenceperiod },
          frequency: item.Recurrencenumber,
          scheduleType: { id: item.Scheduletype, text: item.ScheduleType },
          suburb: { id: item.Suburbid, text: item.Suburb },
          location: item.Location,
          comment: item.Comments,
          inspectionForm: item.Inspectionform ? { key: item.Inspectionform, text: item.Inspectionform } : null,
          workOrderType: item.Maintenancetype,
          // departmentID: item.DepartmentID,        
          WOTypeSelectedKey: item.Inspectionform ? "A" : "B",
          nextSchedule: item.Datenextscheduled,
          description: item.Schedulegroup,
          lineItems: JSON.parse(item.Lineitems),
          createSingleWO: item.isSingleWO === true ? "A" : "B",
          generateImmediately: item.Generateimmediately === true ? "B" : "A",
          isActive: item.Isactive,
          Eststartdate: item.Datecyclestart,
          Estenddate: item.Datecycleend,
          befpicreq: item.Photorequiredbefore,
          aftpicreq: item.Photorequiredafter,
          Assets: JSONAssetData,
          riskassessment: item.Riskassessmentrequired
        });

        this.Assets.push(JSONAssetData);

        this._getDepartmentData(item.Departmentid);
        this._getTeamDataAsync(item.Teamid);
      });
    }, (err) => {
      console.log(err);
      this.setState({ loading: false });
    });

  }

  public createLineItems() {

    if (this.state.scheduleCode && this.state.Eststartdate && this.state.Estenddate && this.state.frequency && this.state.period && this.state.woworktype) {

      let startDate = new Date(this.state.Eststartdate);
      let startDateFirstOccurance = new Date(this.state.Eststartdate);
      let endDate = new Date(this.state.Estenddate);
      let frequency = this.state.frequency;
      let interval = frequency * + this.state.period.id;
      let tempInterval = frequency * + this.state.period.id
      // To calculate the time difference of two dates 
      var DifferenceInTime = endDate.getTime() - startDate.getTime();
      // To calculate the no. of days between two dates 
      var DifferenceInDays = DifferenceInTime / (1000 * 3600 * 24);
      let intervalDifference;
      if (this.state.firstOccurance) {
        intervalDifference = DifferenceInDays / interval;
      } else {
        intervalDifference = (DifferenceInDays / interval) - 1;
      }
      let line = 1;
      let lineItems = [];
      for (let i = 0; i <= intervalDifference; i++) {

        if (this.state.period.text === "Month") {
          startDateFirstOccurance.setMonth(startDate.getMonth() + (+frequency));
        }
        else {
          startDateFirstOccurance.setDate(startDate.getDate() + (frequency * +this.state.period.id));
        }
        let dateInterval = this.state.firstOccurance ? (interval - tempInterval) / +this.state.period.id : interval / +this.state.period.id;
        let dInterval = dateInterval + ' ' + this.state.period.text;
        let tempScheduleStartdate = this.state.firstOccurance ? startDate.toISOString() : startDateFirstOccurance.toISOString();

        lineItems.push({
          LineNo: line,
          ScheduledDate: tempScheduleStartdate,
          ScheduledDateView: moment(tempScheduleStartdate).format("DD/MM/YY h:mm a"),
          DateInterval: dateInterval,
          DateIntervalType: this.state.period.text,
          Interval: dInterval,
          Crew: this.state.crew.text,
          CrewId: this.state.crew.id,
          Status: this.state.generateImmediately && this.state.generateImmediately === "B" ? "Work Order Generated" : "Planned",
          GenerateWorkOrder: true,
          Activity: this.state.woworktype.text,
          ScheduleCode: this.state.scheduleCode,
          Department: this.state.department.text,
          DepartmentID: this.state.department.id,
          WorkOrderNos: []

        })
        if (this.state.period.text === "Month") {
          startDate.setMonth(startDate.getMonth() + (+frequency));
        }
        else {
          startDate.setDate(startDate.getDate() + (frequency * +this.state.period.id));
        }
        interval = interval + (frequency * +this.state.period.id);
        //console.log("startDate", startDate);
        //console.log("startDateFirstOccurance", startDateFirstOccurance);
        line++;
      }
      this.setState({
        lineItems: lineItems,
        nextSchedule: lineItems[0].ScheduledDate,
      });
    }
  }

  public updateScheduleWorkOrder() {
    let dataToPassToService: any = {};
    //console.log("this.scheduelWOID", this.scheduelWOID);
    //console.log(this.state.generateImmediately);
    //console.log(this.state.nextSchedule);
    // console.log(this.Assets);
    this.setState({
      alertMessage: <Spinner label='Loading...' />,
      hideAlertButton: true,
      showAlert: true
    });
    let JSONAsset = "";
    if (this.Assets && this.Assets.length > 0) {
      JSONAsset = JSON.stringify(this.Assets);
    }
    let currentdate = new Date();
    dataToPassToService.id = +this.scheduelWOID;
    dataToPassToService.Title = this.state.title;
    dataToPassToService.Code = this.state.scheduleCode;
    // dataToPassToService.Schedulegroup = this.state.scheduleType.text;
    dataToPassToService.Scheduletype = this.state.scheduleType.id;
    dataToPassToService.Description = this.state.comment ? this.state.comment : '';
    dataToPassToService.Workordertypeid = this.state.woworktype.id;
    dataToPassToService.Tenantid = Config.tenantId;
    dataToPassToService.Location = this.state.location;
    dataToPassToService.Suburbid = this.state.suburb.id;
    dataToPassToService.Priorityid = this.state.priority.id;
    dataToPassToService.Recurrenceperiod = this.state.period.text;
    dataToPassToService.Recurrencenumber = +this.state.frequency;
    dataToPassToService.Datecyclestart = new Date(this.state.Eststartdate);
    dataToPassToService.Datecycleend = new Date(this.state.Estenddate);
    dataToPassToService.Departmnentid = this.state.department.id;
    dataToPassToService.Teamid = this.state.teams.id;
    dataToPassToService.CrewId = this.state.crew.id;
    dataToPassToService.Riskassessmentrequired = this.state.riskassessment ? true : false;
    dataToPassToService.Photorequiredbefore = this.state.befpicreq ? true : false;
    dataToPassToService.Photorequiredafter = this.state.aftpicreq ? true : false;
    dataToPassToService.Assets = JSONAsset;
    dataToPassToService.Lineitems = JSON.stringify(this.state.lineItems);
    dataToPassToService.Wocategory = "test";
    dataToPassToService.Plannedduration = +this.state.plannedHours;
    if (this.state.inspectionForm) {
      dataToPassToService.Inspectionform = this.state.inspectionForm.id;
    }
    dataToPassToService.Generateimmediately = this.state.generateImmediately ? (this.state.generateImmediately === "A" ? false : true) : false;
    dataToPassToService.Issingleworkorder = this.state.createSingleWO ? (this.state.createSingleWO === "A" ? true : false) : true;
    dataToPassToService.Isactive = this.state.isActive;
    dataToPassToService.Maintenancetype = this.state.workOrderType;
    dataToPassToService.Datenextscheduled = this.state.nextSchedule;
    dataToPassToService.createdBy = this.currentUser.Email;
    dataToPassToService.updatedBy = this.currentUser.Email;
    dataToPassToService.created = currentdate.toISOString();
    dataToPassToService.updated = currentdate.toISOString();
    // console.log("dataToPassToService", dataToPassToService);
    // console.log("dataToPassToService", JSON.stringify(dataToPassToService));
    utility.genericUpdateAPICall(Config.scheduledworklistname, this.scheduelWOID, dataToPassToService).then((data: any) => {
      //console.log(data);
      this.setState({
        alertMessage: 'Scheduled work order has been updated successfully',
        showAlert: true,
        hideAlertButton: false,
        loading: true,
        previousPage: true,
      });

      this.updateWorkOrder();
    }, (err: any) => {
      console.log(err);
    });
  }
  public updateWorkOrder() {
    //console.log("update WO");
    //console.log("Asset", this.state.Assets);

    let scheduleWOListItems = this.state.scheduleWOList;
    let dataToPassToService: any = {};
    //ActivityDescr: this.state.wodesc,
    dataToPassToService.Location = this.state.location;
    dataToPassToService.Priority = this.state.priority.id;
    dataToPassToService.CrewId = this.state.crew ? this.state.crew.id : null;
    // dataToPassToService.= this.state.WOComments;
    dataToPassToService.Datescheduledstart = this.state.Eststartdate ? new Date(this.state.Eststartdate).toISOString() : null;
    dataToPassToService.Datescheduledend = this.state.Estenddate ? new Date(this.state.Estenddate).toISOString() : null;
    dataToPassToService.PhotoRequiredAfter = this.state.aftpicreq;
    dataToPassToService.PhotoRequiredBefore = this.state.befpicreq;
    dataToPassToService.RiskAssessmentRequired = this.state.riskassessment;
    dataToPassToService.UpdatedBy = this.currentUser.Email;
    return new Promise<void>((resolve, reject) => {
      let promises: Promise<any>[] = [];
      for (var i = 0; i < scheduleWOListItems.length; i += 1) {
        if (scheduleWOListItems[i].Status === "Assigned" || scheduleWOListItems[i].Status === "Unassigned") {

          dataToPassToService.id = scheduleWOListItems[i].Id;
          // dataToPassToService.WorkOrderStatusID = this.state.status.id;
          let promise = utility.genericUpdatePortalAPICall(Config.WorkOrderUpdateList, dataToPassToService).then((results: any) => {
            //console.log(results);

            // this.updateHistoryWO();
            // this.setState({ alertMessage: "" });
          }, (err: any) => {
            console.log(err);
          });
          promises.push(promise);
        }
      }
      Promise.all(promises).then(result => {
        // console.log(result);
        resolve();
      });
    });
  }
  public generateTask(item: any) {
    //console.log("general task clicked");
    //console.log("item", item);
    //console.log("this.state.lineItems.length", this.state.lineItems.length);
    let itemIndex = -1;
    for (var k = 0; k < this.state.lineItems.length; k++) {
      if (item.LineNo === this.state.lineItems[k].LineNo) {
        itemIndex = k;
        break;
      }
    }
    //console.log("itemIndex", itemIndex);
    let tempLineItem = JSON.parse(JSON.stringify(this.state.lineItems));
    if (tempLineItem[itemIndex].GenerateWorkOrder === true) {
      tempLineItem[itemIndex].GenerateWorkOrder = false;
    } else if (tempLineItem[itemIndex].GenerateWorkOrder === false) {
      tempLineItem[itemIndex].GenerateWorkOrder = true;
    }
    //console.log(tempLineItem);
    this.setState({ lineItems: tempLineItem });
  }

  public renderAssetPanel() {
    return (
      <div>
        <Dialog
          open={this.state.showPanel}
          onClose={this._onClosePanel}
          // className={'addEditModal'}
          fullWidth={true}
          maxWidth={'lg'}
          aria-labelledby="max-width-dialog-title"
        >
          <DialogTitle id="scroll-dialog-title">Select Asset</DialogTitle>
          <DialogContent dividers={true}>
            <AssetSearch
              selectMode={true}
              onClick={(Asset: any) => this.selectAssets(Asset)}
              heading={false}
              hideHeading={true}
              hideAssetMapButton={true}
              myhttpclient={this.props.myhttpclient}
              aadHttpClient={this.props.aadHttpClient}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.setState({ showPanel: false })} >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>);
  }


  public selectAssets(AssetItem: any) {
    this.setState({ showPanel: false });
    // let assetid = AssetItem.AssetNumber;
    // let category = AssetItem.Category;
    // let tempAsset;

    // utility.getAssetCoordinates( Config.gisAssetCoordinates, assetid, category).then((data: any) => {
    //   // console.log(data);
    //   let selectedAssetArray = data && data.features;
    //   if (selectedAssetArray && selectedAssetArray.length > 0) {
    //     selectedAssetArray.forEach(selectedAsset => {
    //       //console.log(cheackAllAsset);
    //       let Asset = selectedAsset.properties;
    //       let cheackAllAsset = this.Assets.find(a => a.AssetNumber === Asset.ASSET_ID);
    //       if (!cheackAllAsset) {
    //         this.Assets.push({
    //           Register: "Asset",
    //           AssetID: Asset.GIS_ID,
    //           AssetNumber: Asset.ASSET_ID,
    //           AssetType: Asset.ASSET_TYPE,
    //           AssetClass: Asset.ASSET_CLASS,
    //           Category: Asset.MYDATA_CATEGORY,
    //           Description: Asset.ASSET_NAME,
    //           Suburb: Asset.SUBURB,
    //           Location: Asset.LOCATION,
    //           SAPFunctionLocation: AssetItem.SAPFunctionLocation,
    //           LAYER_NAME: Asset.LAYER_NAME,
    //           GISInfo: {
    //             type: "Feature",
    //             geometry: {
    //               type: selectedAsset.geometry.type,
    //               coordinates: selectedAsset.geometry.coordinates
    //             },
    //             properties: {
    //               AssetID: Asset.GIS_ID,
    //               Name: Asset.ASSET_NAME,
    //               Type: Asset.ASSET_TYPE,
    //               Hierarchy: "1.0",
    //               Zone: Asset.SUBURB,
    //               RoadGroupName: Asset.ASSET_NAME,
    //               Link: null,
    //               GID: Asset.GIS_ID,
    //               ExternalOSID: null,
    //               Location: Asset.LOCATION,
    //               Ownership: "Council as CBC",
    //               ExtAssetID: "",
    //               subclass: Asset.ASSET_SUB_CLASS,
    //               MYDATA_CATEGORY: Asset.MYDATA_CATEGORY
    //             }
    //           },
    //           ERPInfo: {
    //             MaintenanceScheduleCode: "NR_BWT_M_INSP",
    //             MaintenanceHistoryUniqueID: "0",
    //             PercentAllocation: "1.000000",
    //             AttrCode: "ASSET_TYPE",
    //             SearchPath: "Buildings"
    //           },
    //           AssetQuestions: [],
    //           AttachmentUrls: []

    //         });
    //       }

    //       // get locality and use is for location
    //       let lat;
    //       let lng;
    //       if (selectedAsset.geometry.type === 'Point') {
    //         lat = selectedAsset.geometry.coordinates[1].toString();
    //         lng = selectedAsset.geometry.coordinates[0].toString();
    //       } else {
    //         lat = selectedAsset.geometry.coordinates[0][0][1].toString();
    //         lng = selectedAsset.geometry.coordinates[0][0][0].toString();
    //       }

    //       this.setState({
    //         location: Asset.LOCATION,
    //         lat: lat,
    //         lng: lng
    //       });
    //     });

    //     let suburbFound = this.state.suburbItems.find(s => s.text === this.Assets[0].Suburb);

    //     let FuncLocation = this.Assets.length > 1 ? 'BCC-AST-MAS-01' : this.Assets.length === 0 ? 'BCC-AST-NAS-01' : this.Assets[0].SAPFunctionLocation;

    //     this.setState({
    //       Assets: this.Assets,
    //       functionlocation: FuncLocation,
    //       //showPanel: false
    //       suburb: suburbFound,
    //     });
    //   } else {
    //     this.setState({
    //       alertMessage: "Can't get data from GIS",
    //       showAlert: true,
    //       hideAlertButton: false,
    //     })
    //   }
    // }, (err: any) => {
    //   console.log(err);
    // });
  }

  private SearchClicked() {
    //console.log("mapseacrh clicke");
    this.setState(
      {
        iFramePanelOpened: true,
        selectModeMap: true,
        selectParent: false,
      });

  }
  public renderAssetMap() {
    return (
      <AssetMap
        selectAssets={(Asset: any) => this.selectAssetsonMap(Asset)}
        iFramePanelOpened={this.state.iFramePanelOpened}
        onClose={() => this._onDismissMap()}
        selectParent={this.state.selectParent}
        selectModeMap={this.state.selectModeMap}
      />
    );
  }


  public selectAssetsonMap(selectedAssetArray: any) {
    let flatCoordinates: any = [];

    selectedAssetArray.forEach((selectedAsset: any) => {

      let Asset = JSON.parse(selectedAsset.properties);
      let checkassetonMap = this.Assets.find((a: any) => a.AssetNumber === selectedAsset.id);
      if (checkassetonMap) {
        this.setState({
          alertMessage: 'Asset: (' + selectedAsset.name + ') already added once',
          showAlert: true
        });
        return;
      } else {
        this.Assets.push({
          Register: "Asset",
          AssetID: Asset.GIS_ID,
          AssetNumber: Asset.ASSET_ID,
          AssetType: Asset.ASSET_TYPE,
          AssetClass: Asset.ASSET_CLASS,
          Category: Asset.MYDATA_CATEGORY,
          Description: Asset.ASSET_NAME,
          Suburb: Asset.SUBURB,
          Location: Asset.LOCATION,
          LAYER_NAME: Asset.LAYER_NAME,
          GISInfo: {
            type: "Feature",
            geometry: {
              type: selectedAsset.geometry.type,
              coordinates: selectedAsset.geometry.coordinates
            },
            properties: {
              AssetID: Asset.GIS_ID,
              Name: Asset.ASSET_NAME,
              Type: Asset.ASSET_TYPE,
              Hierarchy: "1.0",
              Zone: Asset.SUBURB,
              RoadGroupName: Asset.ASSET_NAME,
              Link: null,
              GID: Asset.GIS_ID,
              ExternalOSID: null,
              Location: Asset.LOCATION,
              Ownership: "Council as CBC",
              ExtAssetID: "",
              subclass: Asset.ASSET_SUB_CLASS,
              MYDATA_CATEGORY: Asset.MYDATA_CATEGORY
            }
          },
          ERPInfo: {
            MaintenanceScheduleCode: "NR_BWT_M_INSP",
            MaintenanceHistoryUniqueID: "0",
            PercentAllocation: "1.000000",
            AttrCode: "ASSET_TYPE",
            SearchPath: "Buildings"
          },
          AssetQuestions: [],
          AttachmentUrls: []

        });
        // get locality and use is for location
        this.setState({ location: Asset.LOCATION });

      }
    });

    let suburbFound = this.state.suburbItems.find((s: any) => s.text === this.Assets[0].Suburb);
    let FuncLocation = this.Assets.length > 1 ? 'BCC-AST-MAS-01' : this.Assets.length === 0 ? 'BCC-AST-NAS-01' : this.Assets[0].SAPFunctionLocation;
    this.setState({
      Assets: this.Assets,
      //showPanel: false
      suburb: suburbFound,
      lat: selectedAssetArray[0].center[1].toString(),
      lng: selectedAssetArray[0].center[0].toString(),
      latLong: flatCoordinates[0],
      // latLong:flatCoordinates[0].lng+','+flatCoordinates[0].lat,
      iFramePanelOpened: false,
      selectParent: false,
      selectModeMap: false,
      functionlocation: FuncLocation,
    });
  }

  public _onDismissMap() {
    this.setState({
      iFramePanelOpened: false
    });
  }
  private _onShowPanel = (): void => {
    this.setState({ showPanel: true });
  }
  private _onClosePanel = (): void => {
    this.setState({ showPanel: false });
  }
  public removeAssetNumber(index: any) {
    this.Assets.splice(index, 1);
    this.setState({
      Assets: this.Assets
    });
  }
  public handleSelect = (e: any) => {
    this.setState({ selected: e.selected });
  }

  public handleDropdownChange(e: any, fieldName: string) {

    if (fieldName === "period" || fieldName === "crew" || fieldName === "Eststartdate" || fieldName === "Estenddate" || fieldName === "frequency" || fieldName === "scheduleCode") {
      //this.createLineItems();
      setTimeout(this.createLineItems.bind(this), 2000);
    }
    if (fieldName === 'department' && e.selectedItem !== null) {
      let tempTeams = this.state.teamsItems;

      let filteredTeam = tempTeams.filter((t: any) => t.departmentId === e.selectedItem.id);

      this.setState({
        disableTeam: false,
        tempTeamItems: filteredTeam,
        alltempTeamItems: filteredTeam,
        disableCrew: true,
        tempCrewItems: [],
        // [fieldName]: newValue
      });
    }
    if (fieldName === 'teams' && e.selectedItem !== null) {
      let tempCrew = this.state.crewItems;
      let filteredCrew: any = [];
      tempCrew.forEach((c: any) => {
        if (c.teamId === e.selectedItem.id || c.title === "Default Crew") {
          filteredCrew.push(c);
        }
      });
      this.setState({
        disableCrew: false,
        tempCrewItems: filteredCrew,
        alltempCrewItems: filteredCrew,
        // [fieldName]: newValue
      });
    }
    this.setState({ [fieldName]: e.selectedItem });
  }
  private handleValueChange = (event: any, fieldName: string) => {


    this.setState({ [fieldName]: event.value });
  }
  private _checkBoxChanged = (event: any, fieldName: string) => {
    this.setState({ [fieldName]: event.value });
    if (fieldName === 'firstOccurance') {
      setTimeout(this.createLineItems.bind(this), 2000);
    }
  }
  public _textAreaChanged = (e: any, fieldName: string) => {

    this.setState({ [fieldName]: e.value });
  }
  public _onRadioChange = (e: any, fieldName: string) => {
    this.setState({ [fieldName]: e.value });

  }
  public alert() {
    return (
      <AlertDialog
        showDialog={this.state.showAlert}
        message={this.state.alertMessage}
        title={Config.productTitle}
        onClose={() => this._closeDialog()}
        hideAlertButton={this.state.hideAlertButton}
      />
    );
  }

  private _closeDialog = (): void => {
    this.setState({
      alertMessage: '',
      showAlert: false
    });

    if (this.state.previousPage) {
      window.history.back();
    }
  }
  public render(): React.ReactElement<any> {

    return (
      <div className={'dashboardHome'}>
        {this.state.showAlert && this.alert()}
        <div className={"SearchDiv"}>
          <div className={'headingDiv'}>
            <span className={"heading"}> Edit Schedule Work </span>
          </div>
        </div>
        <div className={"searchFieldDiv"}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <Paper elevation={6} className={'p-20'}>
                <div className="DocumentCardHeading">Work Order Details</div>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={12} className=' mb-10'>
                    <div style={{ display: 'flex', alignItems: 'stretch', height: '40px', background: '#dbdbdb' }}>
                      {/* <CommandBarButton className={"commandBarButtomforCreate"} iconProps={{ iconName: 'Add' }} text="Search Asset" onClick={() => this._onShowPanel()} />
                      <CommandBarButton className={"commandBarButtomforCreate"} iconProps={{ iconName: 'MapPin' }} text="Search Asset From Map" onClick={this.SearchClicked} /> */}
                    </div>
                  </Grid>

                  {this.state.Assets && this.state.Assets.map((item: any, i: any) => {
                    return (
                      <Grid item xs={12} sm={12}>

                        <Paper elevation={6} className={"assetSelectPaper"}>
                          <span className={''}>{item.AssetNumber}</span>
                          <span className="floatRight">
                            <DeleteIcon className={'danger'} onClick={() => this.removeAssetNumber(i)} />
                          </span><br />
                          <span className="mt-0 mb-3">{item.Description}</span>
                        </Paper>

                      </Grid>
                    );
                  })}
                </Grid>
              </Paper>
            </Grid>
          </Grid>
          <br />
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <Paper elevation={6} className="scheduelPaper">
                <span className="DocumentCardHeading">Schedule Details</span>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={4}>
                    <TextBoxInput
                      stylingMode='underlined'
                      label="Title"
                      // pattern={"[A-Za-z]+"}
                      // minLength={2}
                      value={this.state.title}
                      onValueChanged={(e: any) => this.handleValueChange(e, 'title')}

                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextBoxInput
                      stylingMode='underlined'
                      label="Schedule Code"
                      // pattern={"[A-Za-z]+"}
                      // minLength={2}
                      value={this.state.scheduleCode}
                      onValueChanged={(e: any) => this.handleValueChange(e, 'scheduleCode')}

                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <br />
                    <br />
                    <CheckBoxInput text="Active"
                      value={this.state.isActive}
                      onValueChanged={(e: any) => this.handleValueChange(e, 'isActive')}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <SelectBoxInput
                      stylingMode='underlined'
                      displayExpr="text"
                      valueExpr="id"
                      showClearButton={true}
                      items={ScheduleType}
                      selectedItem={this.state.scheduleType}
                      label='Schedule Type'
                      onSelectionChanged={(e: any) => this.handleDropdownChange(e, 'scheduleType')}

                    />

                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextBoxInput
                      stylingMode='underlined'
                      placeholder={"Interval"}
                      value={this.state.frequency}
                      onValueChanged={(e: any) => this.handleValueChange(e, 'frequency')}

                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <SelectBoxInput
                      stylingMode='underlined'
                      displayExpr="text"
                      valueExpr="id"
                      showClearButton={true}
                      items={Period}
                      selectedItem={this.state.period}
                      label='Period'
                      onSelectionChanged={(e: any) => this.handleDropdownChange(e, 'period')}

                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <DateBoxInput
                      stylingMode='underlined'
                      label={"Cycle Start Date"}
                      value={this.state.Eststartdate ? new Date(this.state.Eststartdate) : null}
                      type="date"
                      displayFormat={"dd/MM/yy"}
                      showClearButton={true}
                      onValueChanged={(e: any) => this.handleValueChange(e, 'Eststartdate')}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <DateBoxInput
                      stylingMode='underlined'
                      label={"Cycle End Date"}
                      value={this.state.Estenddate ? new Date(this.state.Estenddate) : null}
                      type="date"
                      displayFormat={"dd/MM/yy"}
                      showClearButton={true}
                      onValueChanged={(e: any) => this.handleValueChange(e, 'Estenddate')}
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
          <br />
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <Paper elevation={6} className="scheduelPaper">

                <span className="DocumentCardHeading">Work Order Details</span>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={4}>
                    <SelectBoxInput
                      stylingMode='underlined'
                      displayExpr="text"
                      valueExpr="id"
                      showClearButton={true}
                      items={this.state.worktypeItems}
                      selectedItem={this.state.woworktype}
                      label='Activity *'
                      onSelectionChanged={(e: any) => this.handleDropdownChange(e, 'woworktype')}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextBoxInput
                      stylingMode='underlined'
                      label="Location"
                      // pattern={"[A-Za-z]+"}
                      // minLength={2}
                      value={this.state.location}
                      onValueChanged={(e: any) => this.handleValueChange(e, 'location')}

                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <SelectBoxInput
                      stylingMode='underlined'
                      displayExpr="text"
                      valueExpr="id"
                      showClearButton={true}
                      items={this.state.suburbItems}
                      selectedItem={this.state.suburb}
                      label='Suburb'
                      onSelectionChanged={(e: any) => this.handleDropdownChange(e, 'suburb')}
                    />

                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Grid container spacing={1}>

                      <Grid item xs={12} sm={4}>
                        <CheckBoxInput value={this.state.befpicreq} onValueChanged={(e: any) => this.handleValueChange(e, 'befpicreq')} text={'Before Photo Required'} />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <CheckBoxInput value={this.state.aftpicreq} onValueChanged={(e: any) => this.handleValueChange(e, 'aftpicreq')} text={'After Photo Required'} />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <CheckBoxInput value={this.state.riskassessment} onValueChanged={(e: any) => this.handleValueChange(e, 'riskassessment')} text={'Site Risk Assessment'} />
                      </Grid>
                    </Grid>

                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <SelectBoxInput
                      stylingMode='underlined'
                      displayExpr="text"
                      valueExpr="id"
                      showClearButton={true}
                      items={this.state.departmentItems}
                      selectedItem={this.state.department}
                      label='Department *'
                      onSelectionChanged={(e: any) => this.handleDropdownChange(e, 'department')}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <SelectBoxInput
                      stylingMode='underlined'
                      displayExpr="text"
                      valueExpr="id"
                      showClearButton={true}
                      items={this.state.tempTeamItems}

                      disabled={this.state.disableTeam}
                      selectedItem={this.state.teams}
                      label='Teams *'
                      onSelectionChanged={(e: any) => this.handleDropdownChange(e, 'teams')}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <SelectBoxInput
                      stylingMode='underlined'
                      displayExpr="text"
                      valueExpr="id"
                      showClearButton={true}
                      items={this.state.tempCrewItems}
                      selectedItem={this.state.crew}
                      disabled={this.state.disableCrew}
                      label='Crew *'
                      onSelectionChanged={(e: any) => this.handleDropdownChange(e, 'crew')}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <SelectBoxInput
                      stylingMode='underlined'
                      displayExpr="text"
                      valueExpr="id"
                      showClearButton={true}
                      items={this.state.priorityItems}
                      selectedItem={this.state.priority}
                      label='Priority'
                      onSelectionChanged={(e: any) => this.handleValueChange(e, 'priority')}

                    />

                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <NumberBoxInput
                      stylingMode='underlined'
                      label="Planned Hours of Task"
                      min={1}
                      value={this.state.plannedHours}
                      onValueChanged={(e: any) => this.handleValueChange(e, 'plannedHours')}

                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>

                    <RadioGroupInput
                      label="Workorder Type"
                      valueExpr={'id'}
                      displayExpr={'text'}
                      value={this.state.workOrderType}
                      items={options}
                      layout={'horizontal'}
                      onValueChanged={(e: any) => this._onRadioChange(e, 'workOrderType')}
                    />
                  </Grid>
                  {this.state.workOrderType === "Inspection" ?
                    <Grid item xs={12} sm={4}>

                      <SelectBoxInput
                        stylingMode='underlined'
                        displayExpr="text"
                        valueExpr="id"
                        showClearButton={true}
                        items={this.state.inspectionFormItems}
                        selectedItem={this.state.inspectionForm}
                        label='Inspection Form'
                        onSelectionChanged={(e: any) => this.handleDropdownChange(e, 'inspectionForm')}
                      />
                    </Grid> : null
                  }
                  <Grid item xs={12} sm={4}>

                    <TextAreaInput
                      label='comment'
                      value={this.state.comment}

                      onValueChanged={(e: any) => this._textAreaChanged(e, 'comment')}

                    />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
          <br />
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <Paper elevation={6} className="scheduelPaper">
                <span className="DocumentCardHeading">Planned Work Orders</span>
                <span className="floatRight includeFirst">
                  <CheckBoxInput
                    text="Include first occurance"
                    value={this.state.firstOccurance}
                    onValueChanged={(e: any) => this.handleValueChange(e, 'firstOccurance')}
                  />
                </span>

                <Grid item xs={12} sm={12}>
                  <br />

                  <DataGrid
                    dataSource={this.state.lineItems}
                    keyExpr="LineNo"
                    height={440}
                    showBorders={true}
                    allowColumnResizing={true}
                    allowColumnReordering={true}
                    columnMinWidth={10}
                    columnAutoWidth={true}
                  >
                    <Sorting mode="none" />
                    <Scrolling mode="infinite" />
                    <LoadPanel enabled={false} />

                    <Column
                      dataField='LineNo'
                      caption='Line #'
                      width={90}
                    />
                    <Column
                      dataField='GenerateWorkOrder'
                      caption='Create Work Order'
                      cellRender={(props) => {
                        return (
                          <div>
                            <CheckBoxInput value={props.data.GenerateWorkOrder} onValueChanged={() => this.generateTask(props.data)} />
                          </div>
                        );
                      }}
                    />
                    <Column
                      dataField='ScheduledDate'
                      caption='Schedule Date'
                      dataType='datetime'
                      format={'dd/MM/yyyy'}
                    // width={90}
                    />
                    <Column
                      dataField='Interval'
                      caption='Interval'
                      cellRender={(props) => {
                        return (
                          <div>
                            {props.data.DateInterval + '-' + props.data.DateIntervalType}
                          </div>
                        );
                      }}
                    // width={90}
                    />
                    <Column
                      dataField='Crew'
                      caption='Crew'
                    // width={90}
                    />
                    <Column
                      dataField='Status'
                      caption='Status'
                    // width={90}
                    />


                  </DataGrid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
          <br />
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <Paper elevation={6} className="scheduelPaper">
                <span className="DocumentCardHeading">Options</span>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>

                    <RadioGroupInput
                      label="Create"
                      valueExpr={'id'}
                      displayExpr={'text'}
                      value={this.state.createSingleWO}
                      items={optionsofSingle}
                      layout={'vertical'}
                      onValueChanged={(e: any) => this._onRadioChange(e, 'createSingleWO')}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} className={'borderLeft'}>

                    <RadioGroupInput
                      label="Generate Workorder"
                      valueExpr={'id'}
                      displayExpr={'text'}
                      value={this.state.generateImmediately}
                      items={this.state.optionsofGenerateImmediately}
                      layout={'vertical'}
                      onValueChanged={(e: any) => this._onRadioChange(e, 'generateImmediately')}
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
          <br />
          <br />
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <div className={"searchButtonDiv"}>
                <Button onClick={this.previousPage} variant='outlined'>
                  Cancel
                </Button>
                <Button onClick={this.updateScheduleWorkOrder} variant='outlined' color="primary" className={'button'}>
                  Update
                </Button>
              </div>
            </Grid>
          </Grid>
        </div>
        {this.renderAssetPanel()}
        {this.renderAssetMap()}

      </div >

    );
  }
}

