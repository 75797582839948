import * as React from 'react';
import { Button } from 'devextreme-react';
import Navigation from '../Navigation/Navigation';
export default class AccessRequest extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        if (this.props.Department) {
            window.open('#/', '_self');
        };
        this.state = {
            details: this.props.details,
        };

    }
    public componentDidMount() {


    }


    public render(): React.ReactElement<any> {

        return (
            <div className={"root"}>
                <Navigation
                    hideDrawer={true}
                    role={this.props.role}
                    hideDetail={true}
                    userId={this.props.userId}
                />
                <div className="alignCenter">
                    <div className="accessDeniedDiv" >

                        <h1 className='woLink' >Hi, {this.props.currentUser.DisplayName}</h1>
                        <h4>
                            Look like you have not been assigned any role and department. Please contact site administrator to assign you a role and department.
                        </h4>

                        <Button
                            width={120}
                            text="Refresh"
                            type="normal"
                            stylingMode="contained"
                            onClick={() => window.location.reload()}
                        />


                    </div>

                </div>
            </div>
        );
    }
}

