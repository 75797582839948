import * as React from 'react';
import utility from '../../../classes/utility';
import { Config } from '../../../classes/config';
import Grid from '@material-ui/core/Grid';
import ScrollView from 'devextreme-react/scroll-view';
import AlertDialog from '../../common/alertDialog';
import Spinner from '../../common/reusableComponents/Spinner';
import TextBoxInput from '../../common/reusableComponents/TextBox';
import capitalize from '@material-ui/core/utils/capitalize';
import CheckBoxInput from '../../common/reusableComponents/CheckBox';
import TextAreaInput from '../../common/reusableComponents/TextArea';
import DataSource from 'devextreme/data/data_source';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
export default class AddEditRiskForm extends React.Component<any, any> {
    public departmentItems: any = [];
    public dataSource: any = [];
    public treeView: any;
    constructor(props: any) {
        super(props);

        this.addUpdateItem = this.addUpdateItem.bind(this);
        this.clearFilter = this.clearFilter.bind(this);
        this.departmentItems = [];
        this.dataSource = [];
        this.state = {
        };
    }
    public didMount() {
        if (this.props.details && this.props.mode === 'edit') {
            this.setState({ department: this.props.details.departmentids });
            this.setState({ loading: true })
            this.setState({
                id: this.props.details.id,
                title: this.props.details.title,
                model: this.props.details.model,
                isActive: this.props.details.isActive,
                loading: true
            });

            setTimeout(() => this.setState({ loading: false }), 1000);

        } else {
            this.setState({
                hideAlertButton: false,
                mode: this.props.mode
            })
        }
        this.getdepartmentItems();
    }

    public getdepartmentItems() {
        utility.genericGetAPICall(`${Config.DepartmentList}?showAll=true`).then((data: any) => {
            //console.log(data);
            this.departmentItems = [];

            for (let r of data) {

                this.departmentItems.push({ id: r.id, text: r.title });

            }
            const sortedItems = utility._sortItems(this.departmentItems, 'text', false);
            this.dataSource = new DataSource({
                store: sortedItems,
                key: 'id'
            });
            this.setState({ departmentItems: this.dataSource, loading: false });
        }, (err) => {
            console.log(err);
        });
    }


    public addUpdateItem() {
        let dataToPassToService: any = {};
        if (!this.state.title) {
            this.setState({
                alertMessage: 'Please enter title',
                showAlert: true
            });
            return;
        }

        this.setState({
            alertMessage: <div className={'alertLoader'}><Spinner size='large' type={'converging-spinner'} /> <br /> Loading...</div>,
            hideAlertButton: true,
            showAlert: true
        });
        let selecteddepartment: any = [];

        if (this.state.department) {
            this.state.department.forEach((i: any) => {
                selecteddepartment.push(i);
            });
        }
        dataToPassToService.title = this.state.title;
        dataToPassToService.tenantId = Config.tenantId;
        //dataToPassToService.departmentIds = selecteddepartment;
        dataToPassToService.isActive = true;
        dataToPassToService.model = this.state.model;
        if (this.props.mode === 'edit') {
            dataToPassToService.id = this.state.id;
            dataToPassToService.updated = new Date();
            dataToPassToService.updatedBy = this.props.currentUser.Email;
            utility.genericUpdateAPICall(Config.RiskForms, this.state.id, dataToPassToService).then((data: any) => {
                //console.log(data);
                this.setState({ showAlert: false });
                this.clearFilter();
                this.props.onClick();
                this.props.message("Risk Form has been updated");
            }, (err: any) => {
                console.log(err);
                this.setState({
                    alertMessage: 'Something went wrong!',
                    hideAlertButton: false,
                    showAlert: true
                });
            });
        } else {
            dataToPassToService.createdBy = this.props.currentUser.Email;
            dataToPassToService.created = new Date();
            utility.genericPostAPIcall(Config.RiskForms, dataToPassToService).then((data: any) => {
                //console.log(data);
                this.setState({ showAlert: false });
                this.clearFilter();
                this.props.onClick();
                this.props.message("Risk Form has been created");
            }, (err: any) => {
                console.log(err);
                this.setState({
                    alertMessage: 'Something went wrong!',
                    hideAlertButton: false,
                    showAlert: true
                });
            });
        }

    }

    public clearFilter() {
        this.props.onClose();

    }
    private alert() {
        return (
            <AlertDialog
                showDialog={this.state.showAlert}
                message={this.state.alertMessage}
                title={Config.productTitle}
                onClose={() => this.setState({ alertMessage: '', showAlert: false })}
                hideAlertButton={this.state.hideAlertButton}
            />
        );
    }
    private handleValueChange = (event: any, fieldName: string) => {
        this.setState({ [fieldName]: event.value });
    }
    public handleDropdownChange(e: any, fieldName: string) {
        this.setState({ [fieldName]: e.selectedItem });
    }


    public render(): React.ReactElement<any> {
        return (
            <div>
                {this.state.showAlert && this.alert()}

                <Dialog
                    open={this.props.showModal}
                    onClose={() => this.clearFilter()}
                    fullWidth={true}
                    maxWidth={'lg'}
                    aria-labelledby="max-width-dialog-title"
                    onRendered={() => this.didMount()}
                >
                    <DialogTitle id="scroll-dialog-title">
                        {`${this.props.mode && capitalize(this.props.mode)} Risk Form`}
                    </DialogTitle>
                    <IconButton aria-label="close" onClick={() => this.clearFilter()} className="closeIcon">
                        <CloseIcon />
                    </IconButton>
                    <DialogContent dividers={true}>
                        <ScrollView width='100%' height='100%' showScrollbar='onScroll' scrollByThumb={true}>

                            <div className='popContentDiv'>
                                {!this.state.loading ?
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={6}>
                                            <TextBoxInput
                                                stylingMode='underlined'
                                                label="Title *"
                                                // pattern={"[A-Za-z]+"}
                                                // minLength={2}
                                                value={this.state.title}
                                                onValueChanged={(e: any) => this.handleValueChange(e, 'title')}
                                                onKeyDown={() => { }}
                                                required={true}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12}>
                                            <TextAreaInput
                                                stylingMode='underlined'
                                                value={this.state.model}
                                                onValueChanged={(e: any) => this.handleValueChange(e, 'model')}
                                                label="Model "
                                            />
                                            <Grid item xs={12} sm={4}>
                                                <CheckBoxInput value={this.state.isActive} onValueChanged={(e: any) => this.handleValueChange(e, 'isActive')} text={'Is Active'} />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    :
                                    <Spinner size="large" label="Loading all component. Please wait..." />
                                }
                            </div>
                        </ScrollView>
                    </DialogContent>
                    <DialogActions>

                        <Button onClick={() => this.clearFilter()} variant='contained' color="primary" className={''}>
                            Cancel
                        </Button>
                        <Button onClick={() => this.addUpdateItem()} variant='outlined' color="primary" className={'button'}>
                            Save
                        </Button>

                    </DialogActions>
                </Dialog>
            </div >
        );
    }

}

