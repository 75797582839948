import React from 'react';
import utility from '../../classes/utility';
import { Config } from '../../classes/config';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import LockIcon from '@material-ui/icons/Lock';
import TextBoxInput from '../common/reusableComponents/TextBox';
export default class SignInOld extends React.Component<any, any> {

    public currentUser: any;

    constructor(props: any) {
        super(props);
        this._handleKeyDown = this._handleKeyDown.bind(this);
        this.state = {
        }
    }
    private _fieldChanged = (e: any, fieldName: string) => {
        this.setState({ [fieldName]: e.value });
    }
    private _checkBoxChanged = (event: any, fieldName: string) => {
        this.setState({ [fieldName]: event.value });
    }
    public _handleKeyDown = (e: any) => {
        if (e.event.originalEvent.key === 'Enter') {
            this.signIn();
        }
    }
    private signIn() {
        this.setState({
            errorEmailMessage: '',
            errorPasswordMessage: ''
        })
        let dataToPassToService: any = {};
        if (!this.state.email) {
            this.setState({
                errorEmailMessage: 'Please enter email',
            });
            return;
        }
        if (!this.state.password) {
            this.setState({
                errorPasswordMessage: 'Please enter password',
            });
            return;
        }
        this.setState({
            loading: true
        });
        dataToPassToService.Email = this.state.email;
        dataToPassToService.Password = this.state.password;
        utility.genericPostAPIcall(Config.Token, dataToPassToService).then((data: any) => {

            if (data.Success === true) {
                let tempdetail = {
                    Token: data.Token,
                    RefreshToken: data.RefreshToken,
                    timeStamp: new Date()
                };
                // global.accountInfo = { Email: this.state.email, Password: this.state.password };
                localStorage.setItem("currentDetail", this.state.email);
                localStorage.setItem("tokenDetail", JSON.stringify(tempdetail));

                window.history.back();
                // window.open('/', '_self');
            } else {
                this.setState({
                    showSignInError: true,
                    loading: false
                });
            }
            // this.props.onClick();
            // this.props.message("Counter has been created");
        }, (err: any) => {
            console.log(err);
            this.setState({ showAlert: false, loading: false });
        });
    }
    public render(): React.ReactElement<any> {
        return (
            <div>
                <AppBar position="fixed" className="theme">
                    <Toolbar>
                        <Grid container>
                            <Grid item xs={4} sm={12}>
                                <div className={'logoDiv'}>
                                    {/* <img src={logowo} className="logowo" alt="pic" /> */}
                                </div>
                            </Grid>
                        </Grid>
                    </Toolbar>
                </AppBar>
                <div className={"loginDiv"}>
                    <Grid container spacing={3}>
                        <Grid item xs={10} sm={8} md={4}>
                            <div className="loginInnerDiv">
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={12}>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12} sm={12} className={"homeIconDiv"}>
                                                <LockIcon className={"homeIcon danger"} />
                                            </Grid>
                                            <Grid item xs={12} sm={12}>
                                                <p className={"boxTitle alignCenter signin"}>Sign In</p>
                                            </Grid>
                                            {this.state.showSignInError && (
                                                <Grid item xs={12} sm={12}>
                                                    <div className='signInErrorMsg'>
                                                        Invalid Email or Password. Please try again.
                                                        <span className="far fa-times hand signInErrorMsgIcons" onClick={() => this.setState({ showSignInError: false })} aria-hidden="true"></span>
                                                    </div>
                                                </Grid>
                                            )}
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} sm={12}>

                                        <TextBoxInput
                                            stylingMode='underlined'
                                            label="Email"
                                            value={this.state.email}
                                            onValueChanged={(e: any) => this._fieldChanged(e, 'email')}
                                            spellcheck={false}
                                            showClearButton={true}
                                            mode={'email'}
                                            onKeyDown={this._handleKeyDown}
                                        />
                                        {this.state.errorEmailMessage && (
                                            <span className={'signInFieldError'}>{this.state.errorEmailMessage}</span>
                                        )}
                                    </Grid>
                                    <br />
                                    <br />
                                    <Grid item xs={12} sm={12}>
                                        <TextBoxInput
                                            spellcheck={false}
                                            stylingMode='underlined'
                                            showClearButton={true}
                                            mode={'password'}
                                            value={this.state.password}
                                            onValueChanged={(e: any) => this._fieldChanged(e, 'password')}
                                            onKeyDown={this._handleKeyDown}
                                        />
                                        {this.state.errorPasswordMessage && (
                                            <span className={'signInFieldError'}>{this.state.errorPasswordMessage}</span>
                                        )}
                                    </Grid>
                                    <br />
                                    <br />
                                    {/* <Grid item xs={12} sm={12}>
                                <Checkbox value={this.state.remember} onChange={(e:any) => this.handleValueChange(e, 'remember')} label={'Remember me'} />

                            </Grid> */}
                                    <br />
                                    <Grid item xs={12} sm={12}>
                                        {this.state.loading ?
                                            <div className="alignCenter">Loading</div> :
                                            <Button variant='outlined' color="primary" fullWidth={true} onClick={() => this.signIn()}>
                                                Sign In
                                            </Button>}
                                    </Grid>
                                    <br />

                                </Grid>
                            </div>
                        </Grid>
                    </Grid>

                </div>
            </div>
        );
    }
}